import { interval, of, Subscription } from 'rxjs';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { AlertAccountService } from '@wlp/ui-bs-alert';
import { DeviceService, SendCodeService } from '@wlp/ui-bs-device-validation';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UiBsMyDataService, UserAccountInfoResponse } from '@wlp/ui-bs-my-data';
import { TokenCodeResponse, TokenCodeService } from '@wlp/ui-bs-token';
import { UiCSendCodeComponent } from '@wlp/ui-c-send-code';
import { catchError, tap } from 'rxjs/operators';
import { sendCodeV2 } from '@wlp/ui-bs-token/lib/dto/send-code.interface';
import { Device } from '@awesome-cordova-plugins/device/ngx';

@Component({
  selector: 'app-valid-sms',
  templateUrl: './valid-sms.page.html',
  styleUrls: ['./valid-sms.page.scss'],
})
export class ValidSmsPage implements OnInit {
  @Input() type: string;

  brand: string;
  sub: Subscription;
  errorMessages: any;
  cellphone: string;
  isDisabled: boolean;
  formDevice: FormGroup;
  formSendCode: FormGroup;
  progressbarValue: number;
  showGroupButtons: boolean;
  navigationBackground: string;
  userProfile: UserInfoProfile;
  dataForm: UserAccountInfoResponse;
  token = '';
  lastCardDigits: string
  noMethodDefaultAvaible = false

  @ViewChild('cd', { static: false }) public sendToken: UiCSendCodeComponent;

  constructor(
    private myDataService: UiBsMyDataService,
    private navCtrl: NavController,
    public deviceService: DeviceService,
    public toastController: ToastController,
    private sendCodeService: SendCodeService,
    private alertController: AlertController,
    private layoutConfigService: LayoutConfigService,
    protected userInfoService: UserInfoService,
    private alertAccountService: AlertAccountService,
    private tokenCodeService: TokenCodeService,
    private modalController: ModalController,
    private device: Device
  ) {
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
  }
  ngOnInit(): void {
    this.loadUser();
    this.isDisabled = false;
    this.progressbarValue = 1;
    this.formDevice = this.deviceService.getForm();
    this.formSendCode = this.sendCodeService.getForm();
    this.errorMessages = this.deviceService.getErrorFormMessagen();
    this.errorMessages = this.sendCodeService.getErrorFormMessage();
    this.deviceService.updateForm();
    this.showGroupButtons = true;
  }

 async ionViewWillEnter() {
    this.startProgress();
    this.sendToken.countdown.restart();
    this.sendToken.countdown.begin();
    this.formDevice.disable();
    this.formSendCode.enable();
    this.isDisabled = false;
    console.log('Passou por aqui')
    await this.checkDefaultMethod();
    //console.log('Type', this.type);
  }

  startProgress() {
    this.sendToken.countdown.config.leftTime = 180;
    const timer$ = interval(180);
    this.sub = timer$.subscribe((sec) => {
      this.progressbarValue = 1 - (sec * 0.37) / 240;
      if (this.progressbarValue === 0) {
        this.sub.unsubscribe();
      }
    });
  }

  restartProgress() {
    this.sub.unsubscribe();
    this.startProgress();
  }

  resendCode(data): void {
    this.loadTokenCode(this.userProfile);
    this.restartProgress();
    this.sendCodeService.resend();
    this.sendToken.countdown.restart();
    this.sendToken.countdown.begin();
  }

  continueCountDown(event) {
    if (event.action === 'stop') {
      this.sendToken.countdown.restart();
    }
  }

  // continueDevice() {
  //   this.deviceService
  //     .createDevice()
  //     .then((res) => null)
  //     .catch((err) => this.deviceService.getErrorDevice(err));
  //   this.startProgress();
  //   this.sendToken.countdown.begin();
  //   this.formDevice.disable();
  //   this.formSendCode.enable();
  //   this.isDisabled = false;
  //   this.showGroupButtons = true;
  // }

  async continueSendCode() {
    this.token =
      this.formSendCode.get('codeNumberOne').value +
      this.formSendCode.get('codeNumberTwo').value +
      this.formSendCode.get('codeNumberThree').value +
      this.formSendCode.get('codeNumberFour').value +
      this.formSendCode.get('codeNumberFive').value;

    const validToken = await this.tokenCodeService.confirmToken(
      this.userProfile.uuidWhiteLabel,
      this.userProfile.uuidAccount,
      this.token,
      this.userProfile.userId
    );

    if (validToken === true) {
      const returnData = [
        {
          validToken,
          token: this.token,
        },
      ];
      this.modalController.dismiss(returnData);
      return;
    } else {
      if (!this.sendToken.countdown.left) {
        this.alertAccountService.expiredCode();
        this.formSendCode.reset();
        return;
      }
      this.alertAccountService.invalidCode();
    }
  }

  async dismissSuccess(status: boolean, token: string) {
    this.modalController.dismiss(status, token);
  }

  resetFlow() {
    this.progressbarValue = 1;
    this.formDevice.enable();
    this.formSendCode.disable();
    this.isDisabled = false;
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sendToken.countdown.stop();
  }

  goBack() {
    this.modalController.dismiss();
  }

  formatPhone(numero) {
    const ddd = numero.substr(0, 2);
    //  let firstSeq =  numero.substr(2,5)
    const lastSeq = numero.substr(7, 4);
    return `(${ddd}) *****-${lastSeq}`;
  }

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
        this.loadTokenCode(this.userProfile);
        this.myDataService
          .getPersonalData(this.userProfile.uuidAccount)
          .pipe(
            tap((data) => {
              this.dataForm = data;
              this.cellphone = this.formatPhone(data.cellphone.replace(/[^0-9]/g, ''));
              //console.log('User data', data);
            })
          )
          .subscribe();
      });
    } catch (e) {
      //console.log('Failed to load user details', e);
    }
  }

  private loadTokenCode(userProfile) {
    this.tokenCodeService
      .getTokenSms(userProfile.uuidWhiteLabel, userProfile.uuidAccount, userProfile.userId)
      .pipe(
        tap((res) => {
          // //console.log(res)
        }),
        catchError((error) => {
          return error;
        })
      )
      .subscribe();
  }

  public getImageTag(tag: string) {
    const item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }

  async checkDefaultMethod() {
    this.tokenCodeService.getCodeMethodPreference()
      .pipe(
        tap(res => {

        console.log('Teste', res)

        }), catchError(async err => {
          return of(err)
        })
      ).subscribe();
  }
}
