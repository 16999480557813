import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FaqPage } from './faq/faq.page';
import { StatusAccountEcPage } from './notifications/status-account-ec/status-account-ec.page';
import { StatusResponseEcPage } from './notifications/status-response-ec/status-response-ec.page';
import { OnboardingEcPage } from './onboarding-ec.page';
import { ChooseDocumentEcPage } from './sign-up/choose-document-ec/choose-document-ec.page';
import { ComercialAgentEcPage } from './sign-up/comercial-agent-ec/comercial-agent-ec.page';
import { ContractEcPage } from './sign-up/contract-ec/contract-ec.page';
import { DocumentTypePageEC } from './sign-up/document-type/document-type.page';
import { PartnersECPage } from './sign-up/partners-ec/partners-ec.page';
import { PrivacyPolicyEcPage } from './sign-up/privacy-policy-ec/privacy-policy-ec.page';
import {
    ProductsAndServicesEcPage
} from './sign-up/products-and-services-ec/products-and-services-ec.page';
import { ShowcaseEcPage } from './sign-up/showcase-ec/showcase-ec.page';
import { UploadDocumentsEcPage } from './sign-up/upload-documents-ec/upload-documents-ec.page';

const routes: Routes = [
  {
    path: '',
    component: OnboardingEcPage,
    children: [
      {
        path: 'products-and-services-ec',
        component: ProductsAndServicesEcPage,
      },
      {
        path: 'choose-document-ec',
        component: ChooseDocumentEcPage,
      },
      {
        path: 'showcase-ec',
        component: ShowcaseEcPage,
      },
      {
        path: 'faq',
        component: FaqPage,
      },
      {
        path: 'partners-ec',
        component: PartnersECPage,
      },
      {
        path: 'upload-documents-ec',
        component: UploadDocumentsEcPage,
      },
      {
        path: 'comercial-agent-ec',
        component: ComercialAgentEcPage,
      },
      {
        path: 'contract-ec',
        component: ContractEcPage,
      },
      {
        path: 'privacy-policy-ec',
        component: PrivacyPolicyEcPage,
      },
      {
        path: 'status-response-ec',
        component: StatusResponseEcPage,
      },
      {
        path: 'status-account-ec',
        component: StatusAccountEcPage,
      },
      {
        path: 'document-type-ec',
        component: DocumentTypePageEC,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OnboardingEcPageRoutingModule {}
