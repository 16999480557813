import { NavController, ModalController } from '@ionic/angular';
import { Component, OnInit, Output } from '@angular/core';
import { UiCPixConfirmationNotificationModalComponent } from '@wlp/ui-c-pix-confirmation-notification-modal'
import { PrePixService } from '@wlp/ui-bs-pre-pix';
@Component({
  selector: 'app-confirmation-pix',
  templateUrl: './confirmation-pix.component.html',
  styleUrls: ['./confirmation-pix.component.scss'],
})
export class ConfirmationPixComponent implements OnInit {

  constructor(
    private navCtrl: NavController,
    private modalController: ModalController,
    private prePixService: PrePixService ) { }

  private modalWasAlreadyShown : boolean = false;
  public confirmationData : {
    cpf?: string,
    cnpj?: string,
    email?: string,
    phone?: string
    type_account ?: string
  }

  typeAccountText : string

  ngOnInit() { 
    this.prePixService.getModalVisibility()
      .subscribe(data => {
        if(data && !this.modalWasAlreadyShown){
          this.modalWasAlreadyShown = true
          this.confirmationNotificationModal()
        }
      })
    this.prePixService.getPrePixFormFromBehavior()
      .subscribe(data => {

        switch (data.type_account) {
          case "PHHYSICAL_PERSON":
            this.typeAccountText = "Pessoa física"
            break;
          case "LEGAL_PERSON":
            this.typeAccountText = "Pessoa jurídica"
            break;
          case "MEI":
            this.typeAccountText = "Microempreendedor Individual"
            break;
          case "LIBERAL_PROFESSIONAL":
            this.typeAccountText = "Profissional Liberal"
            break;
        }
        this.confirmationData = { ...data }
      })
  }

  continue() {
    this.navCtrl.navigateRoot(['app/pix/pre-register/contract-pix']);
  }

  goBack() {
    this.navCtrl.navigateRoot(['app/pix/pre-register/choose-key']);
  }

  cancel() {
    this.navCtrl.navigateRoot(['app/dashboard']);
  }

  async confirmationNotificationModal() {
    const modal = await this.modalController.create({
      component: UiCPixConfirmationNotificationModalComponent,
      cssClass: 'pix-confirmation-notification-modal',
    });

    modal.onDidDismiss()
      .then((data) => {
        this.prePixService.setModalVisibility(data['data'].data)
      })

    return await modal.present();
  }
}
