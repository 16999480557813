import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { IonInput, NavController, Platform, ToastController } from '@ionic/angular';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { TokenCodeService } from '@wlp/ui-bs-token'
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { registerCodeMethod } from '@wlp/ui-bs-token/lib/dto/register-code-method.interface';
import { sendCodeV2 } from '@wlp/ui-bs-token/lib/dto/send-code.interface';
import { validateCode } from '@wlp/ui-bs-token/lib/dto/validate-code.interface';
import { encryptDataService } from 'src/app/views/themes/sentinel/functions/encrypt/encrypt.page';
import { Storage } from '@ionic/storage';
import { environment } from "src/environments/environment"

@Component({
  selector: 'app-security-validate',
  templateUrl: './security-validate.page.html',
  styleUrls: ['./security-validate.page.scss'],
})
export class SecurityValidatePage implements OnInit {

  backgroundImage: string;
  typeActive: string
  activeNewMethod: string
  androidIcon = false;
  iosIcon = false;
  progressValue = 1
  activeMethod: string
  deleted = false
  disableMethod: boolean

  userProfile
  cards: any = []

  registerStep = ''

  code: any[] = [null, null, null, null, null];
  digits: number[] = [1, 2, 3, 4, 5];
  cardPassDigits: number[] = [1, 2, 3, 4];

  firstPass = true

  @ViewChildren('codigoInput') codigoInputs: QueryList<ElementRef>;


  constructor(
    private layoutConfigService: LayoutConfigService,
    private router: Router,
    private platform: Platform,
    private device: Device,
    private tokenService: TokenCodeService,
    private toast: ToastController,
    private navCtrl: NavController,
    private encrypt: encryptDataService,
    private storage: Storage
  ) {
    if(this.router.getCurrentNavigation().extras.state.defaultCodeType == undefined){
      this.registerStep = 'CONSUMETOKEN'
    } else {
      this.registerStep = 'REMOVEPREFERENCE'
    }
    this.backgroundImage = this.layoutConfigService.getConfig('wlTheme.header.background.image')
    this.typeActive = this.router.getCurrentNavigation().extras.state.type
    this.activeMethod = this.router.getCurrentNavigation().extras.state.activeMethod
    this.activeNewMethod = this.router.getCurrentNavigation().extras.state.defaultCodeType != null ? this.router.getCurrentNavigation().extras.state.defaultCodeType  : this.router.getCurrentNavigation().extras.state.type
    this.disableMethod = this.router.getCurrentNavigation().extras.state.disableMethod
   }

  ngOnInit() {

    this.userProfile = JSON.parse(sessionStorage.getItem('UserData'))
    this.cards = JSON.parse(sessionStorage.getItem('haveCards'))
    const colorPrimary = this.layoutConfigService.getConfig('wlTheme.color.primary');
    document.documentElement.style.setProperty('--toggleLineColor', this.hexToRgba(colorPrimary, 0.3))



    this.startProgressBar();

    if(this.registerStep == 'CONSUMETOKEN'){
      this.sendRegisterMethodDefaul()
    } else {
      this.sendCode();
    }

    document.addEventListener('keydown', (event) => {
      // Verifica se o evento é realmente um evento de teclado
      if (event instanceof KeyboardEvent) {
        if (event.key == "Unidentified") {
          console.log(event)
        }
      }
    });

  }

  sendCode(){
    const body: sendCodeV2 = {
      deviceManufacturer: this.device.manufacturer,
      deviceSerial: this.device.serial,
      devicePlatform: this.device.platform,
      deviceModel: this.device.model
    }

    this.tokenService.newSendCode(body)
    .pipe(
      tap(res => {
        console.log('Response', res)
      }),catchError(err => {
        console.log(err)
        return of(err)
      })
    ).subscribe()
  }

  hexToRgba(hex, alpha) {
    hex = hex.replace(/^#/, '')

    const bigint = parseInt(hex, 16)
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }


  registerBiometric() {

    const body: registerCodeMethod = {
      codeMethod: this.activeNewMethod,
      deviceModel: this.device.model,
      deviceManufacturer: this.device.manufacturer,
      deviceSerial: this.device.serial,
      devicePlatform: this.device.platform
    }

    this.tokenService.defineCodeMethodPreference(body)
    .pipe(
      tap(res => {
        console.log('Response', res)
      }),catchError(err => {
        console.log('Error', err)
        return of(err)
      })
    ).subscribe();
  }

  registerMethodDefaul(code: string){
    let body: registerCodeMethod
     
    if(this.cards.cards.length > 0) {
      body = {
        codeMethod: this.typeActive,
        code: code,
        uuidAccountPaymentCard: this.cards.cards[0].uuid,
        deviceModel: this.device.model,
        deviceManufacturer: this.device.manufacturer,
        deviceSerial: this.device.serial,
        devicePlatform: this.device.platform,
        source: 'wl-app',
        registerStep: this.registerStep
      }
    } else {
      body = {
        codeMethod: this.typeActive,
        code: code,
        deviceModel: this.device.model,
        deviceManufacturer: this.device.manufacturer,
        deviceSerial: this.device.serial,
        devicePlatform: this.device.platform,
        source: 'wl-app',
        registerStep: this.registerStep
      }
    }

    this.tokenService.defineCodeMethodPreference(body)
    .pipe(
      tap(res => {
        // this.toastAlert("Método de autorização de transação atualizado com sucesso")
        if(this.registerStep == 'REMOVEPREFERENCE' && this.disableMethod == true){
          this.toastAlert("Método padrão desabilitado")
          this.goBack();
        }
        else if(this.registerStep == 'REMOVEPREFERENCE'){
          this.sendRegisterMethodDefaul()
        } else {
          this.toastAlert("Novo método padrão definido")
          this.goBack();
        }
        // this.goBack();
      }),catchError(err => {
        console.log('Error', err)
        this.toastAlert("Erro ao registrar o novo método padrão")
        // this.goBack();
        return of(err)
      })
    ).subscribe();
  }

  sendRegisterMethodDefaul(){
    let body: registerCodeMethod
     
      body = {
        codeMethod: this.typeActive,
        deviceModel: this.device.model,
        deviceManufacturer: this.device.manufacturer,
        deviceSerial: this.device.serial,
        devicePlatform: this.device.platform,
        source: 'wl-app',
        registerStep: 'SENDTOKEN'
      }

    this.tokenService.defineCodeMethodPreference(body)
    .pipe(
      tap(res => {
        this.toastAlert("Código enviado")
        this.activeNewMethod = this.typeActive
        this.registerStep = 'CONSUMETOKEN'
        this.code = [null, null, null, null, null];
      }),catchError(err => {
        console.log('Error', err)
        return of(err)
      })
    ).subscribe();
  }
  

  focusInput(index: number) {
    const input = this.codigoInputs.toArray()[index];
    const nativeInput = input.nativeElement as HTMLInputElement;
    nativeInput.focus();
  }

  focusNextInput(index: number) {
    const nextIndex = index + 1;
    if (nextIndex < this.codigoInputs.length) {
      this.focusInput(nextIndex);
    }
    if(nextIndex == this.codigoInputs.length){
      this.codeValidation();
    }
  }

  focusPreviousInput(index: number) {
    const previousIndex = index - 1;
    if (previousIndex >= 0) {
      this.focusInput(previousIndex);
    }
  }

  handleKeyDown(event: KeyboardEvent, index: number) {
    const currentValue = this.code[index];

    // Verifica se a tecla pressionada é o backspace
    if (event.key === 'Backspace' && (currentValue === '' || currentValue === null)) {
      event.preventDefault(); // Evita que o navegador processe a tecla de volta
      this.focusPreviousInput(index);
    } else if (event.key.length === 1) {
      // Se uma tecla alfanumérica for pressionada, move para o próximo campo
      this.focusNextInput(index);
    }
  }

  handlePaste(event: ClipboardEvent, index: number) {

    const clipboardData = event.clipboardData || (window as any).clipboardData;
    console.log('Aqui', clipboardData)
    const pastedData = clipboardData.getData('text');

    for(let i in pastedData){
      this.code[i] = pastedData[i]
      this.focusNextInput(parseInt(i));
    }
  }

  startProgressBar() {
    const totalTime = 60; // Tempo total em segundos
    const updateInterval = 1000; // Intervalo de atualização em milissegundos
    const steps = totalTime / (updateInterval / 1000); // Número de etapas

    const interval = setInterval(() => {
      this.progressValue -= 1 / steps;

      if (this.progressValue <= 0) {
        this.progressValue = 0;
        clearInterval(interval);
      }
    }, updateInterval);
  }

  resendCode() {
    if(this.progressValue > 0){
      this.toastAlert('Em breve você podera solicitar um novo código')
    } else {
      this.progressValue = 1
      this.startProgressBar();
      this.sendCode();
    }
  }

  async toastAlert(message: string) {
    const toast = await this.toast.create({
      message: message,
      duration: 1500,
      cssClass: 'customToastColor',
      mode: 'ios'
    })

    await toast.present();
  }

  enableButtonConfirm(){
    const codigoCompleto = this.code.join('');
    if(this.typeActive == 'CARD_PASSWORD'){
      if(codigoCompleto.length <= 4){
        return true
      } else {
        return false
      }
    } else {
      if(codigoCompleto.length < 5){
        return true
      } else {
        return false
      }
    }
  }


  codeValidation() {

    let codeEncrypt
      codeEncrypt = this.encrypt.codeEncrypt(this.code.join(''), environment.publicKeyApp)
    this.registerMethodDefaul(codeEncrypt)

  }

  goBack(){
    this.router.navigate(['/app/security'])
  }

}
