import { Component, OnInit } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { PrePixService } from '@wlp/ui-bs-pre-pix';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

@Component({
  selector: 'app-contract-pix',
  templateUrl: './contract-pix.component.html',
  styleUrls: ['./contract-pix.component.scss'],
})
export class ContractPixComponent implements OnInit {

  constructor(
    private navCtrl: NavController,
    private loadingService: LoadingService,
    private prePixService: PrePixService,
    private alertController: AlertController
  ) { }

  acceptPix() {
    this.loadingService.show();
    this.prePixService.getPrePixFormFromBehavior()
    .subscribe(prePix => {
      prePix = {...prePix, accepted: "TRUE", dateRegister: (new Date()).toISOString()}
      this.prePixService.createPrePix(prePix).subscribe(
        prePixResponse => {
          this.loadingService.hide();
          const {user, message} = prePixResponse
          this.alertController.create({message})
          if(user){
            this.navCtrl.navigateRoot(['app/pix/pre-register/pix-conclusion']);
            return
          }
        }
      )
    }) 
  }

  ngOnInit() {
  }

  goBack() {
    this.navCtrl.navigateRoot(['app/pix/pre-register/choose-key']);
  }
  continue() {
    this.acceptPix()
  }
}
