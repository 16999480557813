import { CardsService } from '@wlp/ui-bs-cards';
import { FormGroup } from '@angular/forms';
import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { NotificationsController } from '../../notifications/notifications.controller';

@Component({
  selector: 'modal-active-card',
  templateUrl: './modal-active-card.component.html',
  styleUrls: ['./modal-active-card.component.scss'],
})
export class ModalActiveCardComponent {
  activeModalForm: FormGroup;
  public errorMessages: any;
  constructor(
    public modalController: ModalController,
    private cardService: CardsService,
  ) {
    this.errorMessages = this.cardService.getFormMessageActiveCard();
    this.activeModalForm = this.cardService.createFormActiveCard();
  }

  dismiss() {
    console.log("DESBLOQUEAR CARTAO");
  }

  confirm() {
    this.modalController.dismiss().then(
      (res) => { },
      (err) => {
        // console.error(err);
      }
    );

    NotificationsController.requestNotification()
      .then((res) => this.dismiss())
      .catch((err) => {
        // console.warn(err)
      });
  }
}
