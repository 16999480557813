import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { NavController, ToastController } from '@ionic/angular';
import { TokenCodeService } from '@wlp/ui-bs-token';
import { registerCodeMethod } from '@wlp/ui-bs-token/lib/dto/register-code-method.interface';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import Swal from 'sweetalert2'
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { CardsService } from '@wlp/ui-bs-cards';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-security',
  templateUrl: './security.page.html',
  styleUrls: ['./security.page.scss'],
})
export class SecurityPage implements OnInit {

  backgroundImage: string;
  loginBiometricActive: boolean
  biometricValidationTransaction: boolean
  emailValidationTransaction: boolean
  smsValidationTransaction: boolean
  passValidationTransaction: boolean
  defaultCodeExists: boolean
  defaultCodeType: String

  permitNavigatePageSms: boolean = true
  permitNavigatePageEmail: boolean = true
  permitNavigatePageCard: boolean = true

  forCancelButton = false
  cancelPressed = false
  backPressed = false

  cards: any = []
  userProfile

  haveBiometric : boolean

  constructor(
    private layoutConfigService: LayoutConfigService,
    private router: Router,
    private navCtrl: NavController,
    private toast: ToastController,
    private tokenService: TokenCodeService,
    private device: Device,
    private storage: Storage
  ) {
    this.backgroundImage = this.layoutConfigService.getConfig('wlTheme.header.background.image')
    // this.loginBiometricActive = this.toBoolean(localStorage.getItem('activeBiometric')



    this.userProfile = JSON.parse(sessionStorage.getItem('UserData'))
    this.cards = JSON.parse(sessionStorage.getItem('haveCards'))
    console.log(this.cards)
   }

  ngOnInit() {
    const colorPrimary = this.layoutConfigService.getConfig('wlTheme.color.secondary');
    document.documentElement.style.setProperty('--toggleLineColor', this.hexToRgba(colorPrimary, 0.3))
    this.haveBiometric = false
  }

  async ionViewWillEnter() {

    await this.storage.get('activeBiometric')
    .then(res => {
      if(res){
        this.loginBiometricActive = true
      } else {
        this.loginBiometricActive = false
      }
    })

    this.tokenService.getCodeMethodPreference()
    .pipe(
      tap(res => {
        this.defaultCodeType = res.codeType
        if(res.codeType){
          this.defaultCodeExists = true
        }
        if(res.codeType == "EMAIL"){
          this.emailValidationTransaction = true
          this.smsValidationTransaction = false
          this.passValidationTransaction = false
          this.permitNavigatePageSms = true
          this.permitNavigatePageEmail = false
        }
        if(res.codeType == "SMS"){
          this.smsValidationTransaction = true
          this.emailValidationTransaction = false
          this.passValidationTransaction = false
          this.permitNavigatePageSms = false
          this.permitNavigatePageEmail = true
        }
        if(res.codeType == "CARD_PASSWORD"){
          this.passValidationTransaction = true
          this.emailValidationTransaction = false
          this.smsValidationTransaction = false
          this.permitNavigatePageCard = false
        }
      }),catchError(err => {
        console.log(err.status)
        if(err.status === 400){
          this.defaultCodeExists = false
          this.smsValidationTransaction = false
          this.emailValidationTransaction = false
          this.passValidationTransaction = false
        }
        return of(err)
      })
    ).subscribe();
    
  }

  async checkBackPressed(): Promise<boolean> {
    try {
      const res = await this.storage.get('backPressed');
      this.backPressed = res;
      return !!res; // Isso converte o valor de res em um booleano (true/false)
    } catch (error) {
      console.error('Erro ao obter o valor de "backPressed":', error);
      return false;
    }
  }

  toBoolean(value) {
    return value === 'true';
  }

  goBack(){
    this.navCtrl.back();
  }

  hexToRgba(hex, alpha) {
    hex = hex.replace(/^#/, '')

    const bigint = parseInt(hex, 16)
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }
  
  async emailValidationTransactionActive(ev) {

    this.backPressed = await this.checkBackPressed()

    this.emailValidationTransaction = ev.detail.checked

    let disable = false
    if(this.emailValidationTransaction == false){
      disable = true
      this.permitNavigatePageEmail = true
    }

    if(this.permitNavigatePageEmail == true && this.cancelPressed == false && this.backPressed != true){
      this.router.navigate(['/app/security-validate'], {
        state: {
          type: 'EMAIL',
          defaultCodeType: this.defaultCodeType,
          activeMethod: 'newactive',
          disableMethod: disable
        }
      })
    }

  }

  async smsValidationTransactionActive(ev) {

    this.backPressed = await this.checkBackPressed()

    this.smsValidationTransaction = ev.detail.checked

    let disable = false
    if(this.smsValidationTransaction == false){
      disable = true
      this.permitNavigatePageSms = true
    }

    let fingerData: any
    if(this.permitNavigatePageSms == true && this.cancelPressed == false && this.backPressed != true){
      this.router.navigate(['/app/security-validate'], {
        state: {
          type: 'SMS',
          defaultCodeType: this.defaultCodeType,
          fingerData: fingerData,
          disableMethod: disable
        }
      })
    }

  }

  async cardPassValidationTransactionActive(ev) {

    this.backPressed = await this.checkBackPressed()

    let disable = false
    if(this.passValidationTransaction == false){
      disable = true
      this.permitNavigatePageCard = true
    }

    let fingerData
    if(this.permitNavigatePageCard == true && this.cancelPressed == false && this.backPressed != true){
      if(this.loginBiometricActive == true){
      fingerData =  await this.requireFinger('card')
      }
      this.router.navigate(['/app/security-validate'], {
        state: {
          type: 'CARD_PASSWORD',
          defaultCodeType: this.defaultCodeType,
          fingerData: fingerData,
          disableMethod: disable
        }
      })
    }

  }

  defineMethodPreference(type){
    const body: registerCodeMethod = {
      codeMethod: type,
      deviceModel: this.device.model,
      deviceManufacturer: this.device.manufacturer,
      deviceSerial: this.device.serial,
      devicePlatform: this.device.platform
    }

    this.tokenService.defineCodeMethodPreference(body)
    .pipe(
      tap(res => {
        // console.log("Response", res)
        this.toastAlert("Método de autorização de transação atualizado com sucesso")
      }),catchError(err => {
        return of(err)
      })
    ).subscribe();
  }


  async showConfirmDisable() {
    Swal.fire({
      title: 'Desativar Biometria',
      text: `Tem certeza que deseja desativar a proteção da sua conta?`,
      icon: 'warning',
      heightAuto: false,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      customClass: {
        cancelButton: 'order-1',
        confirmButton: 'order-2'
      }
    }
    ).then(async res => {

      if (res.isConfirmed == true) {
        this.loginBiometricActive = false;
        this.storage.set('activeBiometric', false);
        const fingerData: any = await this.requireFinger();
        const data = JSON.parse(fingerData)
        
        this.tokenService.deleteBiometric(data.bioauthUuid, [])
        .pipe(
          tap(res => {
            this.toastAlert('Biometria desativada com sucesso');
          }),catchError(err => {
            console.log("Erro", err)
            return of(err)
          })
        ).subscribe()
      }

      if (res.isDismissed === true) {
        this.loginBiometricActive = true
        this.forCancelButton = true
      }

    })
  }

  async toastAlert(message: string) {
    const toast = await this.toast.create({
      message: message,
      duration: 2500,
      cssClass: 'customToastColor',
      mode: 'ios'
    })

    await toast.present();
  }


  async requireFinger(type?: string) {
    // return new Promise((resolve, reject) => {
    //   this.faio.loadBiometricSecret({
    //     title: "Validar sua identidade",
    //     description: "Use sua biometria para validar sua identidade",
    //     cancelButtonTitle: "Cancelar"
    //   })
    //   .then(res => {
    //     resolve(res);
    //   })
    //   .catch(err => {
    //     this.cancelPressed = true
    //     setTimeout(() => {
    //       this.cancelPressed = false
    //     }, 500);
    //     if(type == 'email'){
    //       this.emailValidationTransaction = !this.emailValidationTransaction
    //     }
    //     if(type == 'sms'){
    //       this.smsValidationTransaction = !this.smsValidationTransaction
    //     }
    //     if(type == 'card'){
    //       this.passValidationTransaction = !this.passValidationTransaction
    //     }
    //     reject(err);
    //   });
    // });
  }



}
