import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { Component } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { QuizService} from '@wlp/ui-bs-signup'
import { Router } from '@angular/router';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'ui-t-ocr-scanner-data',
  templateUrl: './ocr-scanner-data.page.html',
  styleUrls: ['./ocr-scanner-data.page.scss'],
})
export class OcrScannerDataPage {
  brand: string;
  navigationBackground: string;
  birthDate: string;
  cpf: string = '';
  fullName: string = '';
  rg: string = '';
  motherFullName: string = '';
  okbirthDate = false
  private ocrData: any
  public quizData: any
  public preAccountValue

  private cpfValueStartProcess
  private birthDateStartProcess

  private haveBirthDate: boolean = true

  constructor(
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private quizService: QuizService,
    private router: Router,
    private storage: Storage,
    private alertController: AlertController,
  ) {
    this.brand = this.layoutConfigService.getConfig('wlTheme.header.brand.logo.secondary');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
  }

  goBack() {
    let typeFlow = sessionStorage.getItem('typeFlow')
    if(typeFlow === 'LEGAL_PERSON'){
      this.navCtrl.navigateForward('documents-partner');
    } else {
    this.navCtrl.navigateForward('document-type');
    }
  }

  formataCPF(cpf){
    //retira os caracteres indesejados...
    cpf = cpf.replace(/[^\d]/g, "");
    
    //realizar a formatação...
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  async ionViewWillEnter() {

    this.ocrData =  JSON.parse(sessionStorage.getItem('ocrData'))

    this.preAccountValue =  sessionStorage.getItem('keyForm')

    this.cpfValueStartProcess = sessionStorage.getItem('cpfValue')

    this.birthDateStartProcess = sessionStorage.getItem('birthDateValue')
    if (this.birthDateStartProcess === null) {
      this.haveBirthDate = false
    }

    if(this.ocrData){
      const data: any = this.ocrData
      this.cpf = this.formataCPF(data.cpf)
      this.fullName = data.name
      this.rg = data.rg
      this.birthDate = data.birthDate
      this.motherFullName = data.motherName
    } else {
      this.cpf = this.cpfValueStartProcess
      this.birthDate = this.birthDateStartProcess
    }
  }

  async continue() {

    this.attNamePerson(this.fullName, this.rg, this.preAccountValue)

    let typeFlow

    typeFlow = sessionStorage.getItem('typeFlow')

    if(
      this.cpfValueStartProcess === this.cpf
      ) {
        if(this.birthDate === this.birthDateStartProcess && this.haveBirthDate === true){
          if(typeFlow === 'MEI'){
            this.router.navigate(['company-data'])
          } else if(typeFlow === 'LIBERAL_PROFESSIONAL') {
            this.router.navigate(['personal-address'])
          } else if(typeFlow === 'LEGAL_PERSON'){
            this.router.navigate(['personal-address'])
          }
           else {
          this.router.navigate(['personal-address'])
          }
        } else if(this.haveBirthDate === false){
          if(typeFlow === 'MEI'){
            this.router.navigate(['company-data'])
          } else if(typeFlow === 'LIBERAL_PROFESSIONAL') {
            this.router.navigate(['personal-address'])
          }  else if(typeFlow === 'LEGAL_PERSON'){
            this.router.navigate(['personal-address'])
          }
           else {
          this.router.navigate(['personal-address'])
          }
        } else {
      this.showAlertError('Por favor, verifique a data de nascimento digitada e tente novamente.')
    }
      } else {
        this.showAlertError('Por favor, verifique o CPF digitado e tente novamente.');
      }

  }

  async showAlertError(message) {
    const alert = await this.alertController.create({
      header: 'Atenção!',
      message: message,
      cssClass: 'custom-alert',
      buttons: ['OK'],
    });

    await alert.present();
  }

  checkDate() {
    if(
      this.cpf === '' &&
      this.fullName === '' &&
      this.rg === '' &&
      this.motherFullName === '' &&
      this.birthDate.length < 10
      ) {
      this.okbirthDate = false
    } else {
      this.okbirthDate = true
    }
  }

  async attNamePerson(name, rg, onboardingId) {

    let uuidWhiteLabel: any
    let email


    if(sessionStorage.getItem('uuidWhiteLabel') !== null){
      uuidWhiteLabel = sessionStorage.getItem('uuidWhiteLabel')
    } else {
      uuidWhiteLabel = sessionStorage.getItem('preAccountData')
    }

    let phone

    email = sessionStorage.getItem('emailValue')

    phone = sessionStorage.getItem('phoneValue')

    let postDataPreAccount = {
      "_id": onboardingId,
      "uuidWhiteLabel": uuidWhiteLabel,
      "name": name,
      "cpf": this.cpf, 
      "email": email,
      "rg": rg,
      "phone": phone
    }

    let postDataPersonalData = {
      "_id": onboardingId,
      "personalData": {
        "motherName": this.motherFullName,
        "birthDay": this.birthDate.replace('/','-').replace('/','-'),
        "monthlyIncome": "1,00",
        "marital": ""
      }
    }

    this.quizService.attPreAccount(onboardingId, postDataPreAccount)
    .pipe(
      tap((res) => {
        
      }),
      catchError((err) => {
        //console.log('Erro', err)
        return of(null);
      })
    )
    .subscribe();

    this.quizService.attPersonalData(onboardingId, postDataPersonalData)
    .pipe(
      tap((res) => {
        
      }),
      catchError((err) => {
        //console.log('Erro', err)
        return of(null);
      })
    )
    .subscribe();
  }

}
