import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { CompanyAddressService } from '@wlp/ui-bs-signup';

@Component({
  selector: 'ui-t-company-address',
  templateUrl: './company-address.page.html',
  styleUrls: ['./company-address.page.scss'],
})
export class CompanyAddressPage {
  companyAddressForm: FormGroup;
  errorMessages: any;
  listUf: Array<any>;
  brand: string;
  navigationBackground: string;
  constructor(
    private companyAddressService: CompanyAddressService,
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService
  ) {
    this.companyAddressForm = this.companyAddressService.getCompanyAddressForm();
    this.errorMessages = this.companyAddressService.getErrorMessage();
    this.companyAddressService.updateForm();
    this.listUf = this.companyAddressService.getUf();
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
  }

  continue() {
    this.companyAddressService.sendCompanyAddress().subscribe(
      (data) => this.navCtrl.navigateRoot('create-password'),
      (error) => this.companyAddressService.getErrorCompanyAddress(error)
    );
  }

  searchPostalCode(data: any) {
    this.companyAddressService.searchPostaCode(data.target.value);
  }

  goBack() {
    this.navCtrl.navigateRoot('company-data');
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }
}
