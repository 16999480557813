import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { LayoutConfigService } from './../../../../../core/layout/services/layout-config.service';

@Component({
  selector: 'app-modal-pix',
  templateUrl: './modal-pix.component.html',
  styleUrls: ['./modal-pix.component.scss'],
})
export class ModalPixComponent {
  @Input() status: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() textButton?: string;

  public translator: boolean;
  @Input() set translate(value) {
    if (value !== undefined && value !== null) {
      this.translator = value;
    }
  }

  private confirmatedIcon: any;
  private infoIcon: any;
  private warningIcon: any;
  private recusadedIcon: any;

  constructor(public modalController: ModalController, private layoutConfigService: LayoutConfigService) {
    this.confirmatedIcon = this.layoutConfigService.getImagesPages('confirmated');
    this.recusadedIcon = this.layoutConfigService.getImagesPages('recused');
    this.infoIcon = this.layoutConfigService.getImagesPages('info-icon');
    this.warningIcon = this.layoutConfigService.getImagesPages('warning-icon');

    this.textButton = this.textButton ? this.textButton : 'Cancelar';
  }

  async dismiss() {
    if (this.status === 'success') {
      this.modalController.dismiss(true);
    } else {
      this.modalController.dismiss(false);
    }
  }

  classNotification() {
    if (this.status === 'success') {
      return 'success-text';
    } else if (this.status === 'info') {
      return 'info-text';
    } else if (this.status === 'warning') {
      return 'warning-text';
    } else {
      return 'failed-text';
    }
  }

  modalImage() {
    if (this.status === 'success') {
      return this.confirmatedIcon.path;
    } else if (this.status === 'warning') {
      return this.warningIcon.path;
    } else if (this.status === 'info') {
      return this.infoIcon.path;
    } else {
      return this.recusadedIcon.path;
    }
  }
}
