import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { StatusAccountECService } from '@wlp/ui-bs-ec';
import { Status } from '@wlp/ui-px-ec';

@Component({
  selector: 'app-status-account-ec',
  templateUrl: './status-account-ec-page.html',
  styleUrls: ['./status-account-ec-page.scss'],
})
export class StatusAccountEcPage implements OnInit {
  status: any;
  private analiseConclutedIcon: any;
  private devolvedDocumentIcon: any;
  private recusedIcon: any;
  private successIcon: any;

  constructor(
    private navCtrl: NavController,
    private statusAccountService: StatusAccountECService,
    private cdr: ChangeDetectorRef,
    private layoutConfigService: LayoutConfigService
  ) {
    this.analiseConclutedIcon = this.layoutConfigService.getImagesPages('analise-concluded');
    this.devolvedDocumentIcon = this.layoutConfigService.getImagesPages('devolved-document');
    this.recusedIcon = this.layoutConfigService.getImagesPages('recused');
    this.successIcon = this.layoutConfigService.getImagesPages('success');
  }

  public title: string;
  public description: string;
  public button: string;
  public pathIcon: string;
  private preAccount: any;

  ngOnInit() {
    /*
    this.cdr.detach();
    */
    this.load();
  }

  ionViewWillEnter() {
    // this.cdr.detectChanges();
  }

  load() {
    this.statusAccountService
      .loadStatus()
      .then((res) => {
        this.preAccount = res.REPROVED;
        this.status = res.REPROVED;
        this.title = this.statusAccountService.getTitle(this.status);
        this.description = this.statusAccountService.getDescription(this.status);
        this.button = this.statusAccountService.getTextButton(this.status);
        this.definedIcon(this.status);
      })
      .finally(() => {
        this.cdr.detectChanges();
      });
  }

  continue() {
    if (
      this.status === Status.IN_ANALYSIS_COMPLIANCE ||
      this.status === Status.APPROVED_COMPLIANCE ||
      this.status === Status.IN_ANALYSIS_TOPAZIO ||
      this.status === Status.APPROVED_TOPAZIO
    ) {
      this.navCtrl.navigateRoot('/');
    } else if (this.status === Status.DOCUMENT_PHYSICAL_PERSON_REPROVED) {
      this.navCtrl.navigateRoot('document-type?s=invalid');
    } else if (this.status === Status.DOCUMENT_LEGAL_PERSON_REPROVED) {
      this.navCtrl.navigateRoot('company-documents?s=invalid');
    } else if (this.status === Status.REGISTRATION_DATA_LEGAL_PERSON_INVALID) {
      this.navCtrl.navigateRoot('company-data?s=invalid');
    } else if (this.status === Status.REGISTRATION_DATA_PHYSICAL_PERSON_INVALID) {
      this.navCtrl.navigateRoot('personal-data?s=invalid');
    } else if (this.status === Status.FINISHED || this.status === Status.REPROVED) {
      this.navCtrl.navigateRoot('/');
    }
  }

  definedIcon(status: Status) {
    if (
      status === Status.IN_ANALYSIS_COMPLIANCE ||
      status === Status.APPROVED_COMPLIANCE ||
      status === Status.IN_ANALYSIS_TOPAZIO ||
      status === Status.APPROVED_TOPAZIO
    ) {
      this.pathIcon = this.analiseConclutedIcon.path;
    } else if (
      status === Status.DOCUMENT_PHYSICAL_PERSON_REPROVED ||
      status === Status.DOCUMENT_LEGAL_PERSON_REPROVED
    ) {
      this.pathIcon = this.devolvedDocumentIcon.path;
    } else if (
      status === Status.REGISTRATION_DATA_LEGAL_PERSON_INVALID ||
      status === Status.REGISTRATION_DATA_PHYSICAL_PERSON_INVALID
    ) {
      this.pathIcon = this.devolvedDocumentIcon.path;
    } else if (status === Status.REPROVED) {
      this.pathIcon = this.recusedIcon.path;
    } else if (status === Status.FINISHED) {
      this.pathIcon = this.successIcon.path;
    }
  }
}
