import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { CardsService } from '@wlp/ui-bs-cards';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

import { ModalCardNotificationComponent } from './../../../../modals/modal-card-notification/modal-card-notification.component';

@Component({
  selector: 'ui-t-card-active',
  templateUrl: './card-active.page.html',
  styleUrls: ['./card-active.page.scss'],
})
export class CardActivePage implements OnInit {
  public activeCardForm: FormGroup;
  public errorMessages: any;
  private userProfile: UserInfoProfile;

  constructor(
    private cardService: CardsService,
    private layoutConfigService: LayoutConfigService,
    protected userInfoService: UserInfoService,
    private loadingService: LoadingService,
    private modalController: ModalController,
    private navCtrl: NavController
  ) {
    this.errorMessages = this.cardService.getFormMessageActiveCard();
    this.activeCardForm = this.cardService.createFormActiveCard();
  }

  ngOnInit() {
    this.loadUser();
  }

  public getImageTag(tag: string) {
    const item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }

  private loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  private async modalCardNotification(status: string, message: string) {
    const modal = await this.modalController.create({
      component: ModalCardNotificationComponent,
      cssClass: 'modal-custom-class',
      componentProps: {
        status: status,
        message: message,
      },
    });
    return await modal.present();
  }

  public async activeCard() {
    this.loadingService.show();
    await this.cardService
      .sendActivateCardNoName(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, this.activeCardForm)
      .then((res) => {
        this.loadingService.hide();
        if (res) {
          if (res.statusCode === 400) {
            this.modalCardNotification('solicitation-card', 'CARDS.CARD_MANAGER.CARDS_ACTIVE.MESSAGES.FAILED');
          } else {
            this.modalCardNotification('success', 'CARDS.CARD_MANAGER.CARDS_ACTIVE.MESSAGES.SUCCESS');
          }
        } else {
          throw new Error('Falha ao ativar cartão');
        }
      })
      .catch((err) => {
        this.loadingService.hide();
        this.modalCardNotification('solicitation-card', 'CARDS.CARD_MANAGER.CARDS_ACTIVE.MESSAGES.FAILED');
      });
  }

  public goBackManagerCard() {
    this.navCtrl.navigateRoot('/app/cards/card-manager');
  }
}
