import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { FileAccount, PartnersService, QuizService, UploadService } from '@wlp/ui-bs-signup';
import { ModalCombateAFraudeComponent } from '../../../../modals/modal-combate-a-fraude/modal-combate-a-fraudecomponent';
import { Storage } from '@ionic/storage';
import { catchError, tap } from 'rxjs/operators';
import { Subscription, of } from 'rxjs';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

@Component({
  selector: 'ui-t-documents-partner.page.html',
  templateUrl: './documents-partner.page.html.page.html',
  styleUrls: ['./documents-partner.page.html.page.scss'],
})
export class DocumentsPartnerPage {
  public partnerType: string;
  public titleHeader: string;
  public arrBtn: Array<boolean>;
  public fileAccount: FileAccount;
  public brand: string;
  public navigationBackground: string;

  private selfieUrl: string = ''
  private rgFrontUrl: string
  private rgBackUrl: string
  private cnhFrontUrl: string
  private cnhBackUrl: string
  private rneFrontUrl: string
  private rneBackUrl: string
  private ocrData: any
  private cpfValue: string
  private preAccountValue: string

  public selfToCombateAFraude = false
  public documentsFrontAndBack = false
  public residentialCertificate = false
  private residentialCertificateUrl: string

  constructor(
    private navCtrl: NavController,
    private uploadService: UploadService,
    private activateRoute: ActivatedRoute,
    private layoutConfigService: LayoutConfigService,
    private partnersService: PartnersService,
    private modalController: ModalController,
    private alertController: AlertController,
    private storage: Storage,
    private quizService: QuizService,
    private loadingService: LoadingService,
  ) {
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
  }

  async ngAfterContentInit() {
    this.titleHeader = '';
    this.partnerType = null;
    this.arrBtn = [true, true, false, false];

    this.activateRoute.queryParams.subscribe((res) => this.showBtn(res.type));

    this.uploadService.loadDocumentuploaded();

    this.documentsFrontAndBack = sessionStorage.getItem('documentsFrontAndBack') === 'true' ? true : false

    this.selfToCombateAFraude = sessionStorage.getItem('selfToCombateAFraude') === 'true' ? true : false

    this.selfieUrl = sessionStorage.getItem('selfieUrl') !== null ? sessionStorage.getItem('selfieUrl') : ''

    this.rgFrontUrl = sessionStorage.getItem('rgFrontUrl') !== null ? sessionStorage.getItem('rgFrontUrl') : ''

    this.rgBackUrl = sessionStorage.getItem('rgBackUrl') !== null ? sessionStorage.getItem('rgBackUrl') : ''

    this.cnhFrontUrl = sessionStorage.getItem('cnhFrontUrl') !== null ? sessionStorage.getItem('cnhFrontUrl') : ''

    this.cnhBackUrl = sessionStorage.getItem('cnhBackUrl') !== null ? sessionStorage.getItem('cnhBackUrl') : ''

    this.rneFrontUrl = sessionStorage.getItem('rneFrontUrl') !== null ? sessionStorage.getItem('rneFrontUrl') : ''

    this.rneBackUrl =  sessionStorage.getItem('rneBackUrl') !== null ? sessionStorage.getItem('rneBackUrl') : ''

    this.ocrData = sessionStorage.getItem('ocrData') !== null ? sessionStorage.getItem('ocrData') : ''

    /* this.partnersService.initializeBehaviourList(); */
  }

  showBtn(type) {
    this.partnerType = type;

    if (this.partnerType === 'legalRepresentative') this.arrBtn = [true, true, true, false, true];
    else if (this.partnerType === 'partner') this.arrBtn = [true, true, true, false, false];
    else if (this.partnerType === 'attorney') this.arrBtn = [true, true, true, true, false];
    // else if (this.partnerType === undefined || this.partnerType === null || this.partnerType === "") this.navCtrl.navigateRoot(['/partners']);
  }

  isDisabled() {

    // checa se todos os arquivos foram enviados (baseado no tipo de socio)
    if (
      this.selfToCombateAFraude === true &&
      this.documentsFrontAndBack === true &&
      this.isLoaded('RESIDENCIAL_CERTIFICATE')
    ) {
      return false;
    } else {
      return true;
    }
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }

  private isLoaded(type: string): boolean {
    return this.uploadService.isDocumentUploaded(type);
  }

  markButton(type: string): string {
    let isLoaded = this.isLoaded(type);
    if (type === 'RG') {
      isLoaded = this.documentsFrontAndBack;
    }
    if (type === 'SELF_PHOTO') {
      isLoaded = this.selfToCombateAFraude
    }
    if (type === 'RESIDENCIAL_CERTIFICATE' && !isLoaded) {
      isLoaded = this.isLoaded('RESIDENCIAL_CERTIFICATE');
    }
    if (type === 'ATTORNEY_LETTER' && !isLoaded) {
      isLoaded = this.isLoaded('ATTORNEY_LETTER');
    }
    if (type === 'MINUTES_MEETING' && !isLoaded) {
      isLoaded = this.isLoaded('MINUTES_MEETING');
    }
    this.isDisabled();
    return isLoaded ? 'checkmark-circle' : 'ellipse-outline';
  }

  photoSelf() {
    this.GoToModalCombateAFraude('passive')
    // this.fileAccount = new FileAccount();
    // this.fileAccount.uploadType = 'SELF_PHOTO';
    // this.fileAccount.positionPhoto = 'FRONT';
    // this.fileAccount.title = 'Selfie';
    // this.fileAccount.groupDocument = 'LEGAL_PERSON_MASTER';
    // this.fileAccount.optionParam = this.partnerType;
    // this.uploadService.createFileAccount(this.fileAccount);
    // this.redirectToUpload();
  }

  async RGOrCPF() {
    const alert = await this.alertController.create({
      header: 'Você irá enviar qual documento?',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'RG',
          cssClass: 'alert-button-cancel',
          handler: () => {
            this.GoToModalCombateAFraude('rg')
          }
        },
        {
          text: 'CNH',
          cssClass: 'alert-button-confirm',
          handler: () => {
            this.GoToModalCombateAFraude('cnh')
          }
        },
        {
          text: 'RNE',
          cssClass: 'alert-button-confirm',
          handler: () => {
            this.GoToModalCombateAFraude('rne')
          }
        },
      ],
    });

    await alert.present();
    // this.fileAccount = new FileAccount();
    // this.fileAccount.optionParam = this.partnerType;
    // this.fileAccount.groupDocument = 'LEGAL_PERSON_MASTER';
    // this.uploadService.createFileAccount(this.fileAccount);
    // this.redirectToUpload(true);
  }

  proofAddress() {
    this.fileAccount = new FileAccount();
    this.fileAccount.uploadType = 'RESIDENCIAL_CERTIFICATE';
    this.fileAccount.groupDocument = 'LEGAL_PERSON_MASTER';
    this.fileAccount.title = 'Comprovante';
    this.fileAccount.optionParam = this.partnerType;
    this.uploadService.createFileAccount(this.fileAccount);
    this.redirectToUpload();
  }

  procuration() {
    this.fileAccount = new FileAccount();
    this.fileAccount.positionPhoto = 'VERSE';
    this.fileAccount.uploadType = 'ATTORNEY_LETTER';
    this.fileAccount.title = 'Procuração';
    this.fileAccount.groupDocument = 'LEGAL_PERSON_MASTER';
    this.fileAccount.optionParam = this.partnerType;
    this.uploadService.createFileAccount(this.fileAccount);
    this.redirectToUpload();
  }

  meeting() {
    this.fileAccount = new FileAccount();
    this.fileAccount.positionPhoto = 'VERSE';
    this.fileAccount.uploadType = 'MINUTES_MEETING';
    this.fileAccount.title = 'Ata Assembléia';
    this.fileAccount.groupDocument = 'LEGAL_PERSON_MASTER';
    this.fileAccount.optionParam = this.partnerType;
    this.uploadService.createFileAccount(this.fileAccount);
    this.redirectToUpload();
  }

  continue() {
    // this.navCtrl.navigateRoot('partners');
    if (this.ocrData === '') {
      this.sendDocumentCaf();
    } else {
      let onboarding_light = sessionStorage.getItem('onboarding_light')


      if (onboarding_light == 'true') {
        this.navCtrl.navigateRoot('company-documents')
      } else {
        // if (this.platform.is('ios')) {
        this.navCtrl.navigateRoot('ocr-scanner')
        // }
      }

    }
  }

  goBack() {
    this.navCtrl.navigateRoot('birth-date');
  }

  private redirectToUpload(isRGOrCnh?: boolean): void {
    this.uploadService
      .loadFileTypeUpload()
      .then((res) => {
        if (isRGOrCnh) {
          this.activateRoute.queryParams.subscribe((res) => {
            this.navCtrl.navigateRoot(['choose-document'], { queryParams: { type: res.type } });
          });
          return;
        }
        this.navCtrl.navigateRoot('upload-documents');
      })
      .catch((err) => this.uploadService.getErroUpload(err));
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }

  async GoToModalCombateAFraude(type: string) {
    this.loadingService.hide();

    const modal = await this.modalController.create({
      component: ModalCombateAFraudeComponent,
      swipeToClose: false,
      backdropDismiss: false,
      componentProps: {
        type: type
      },
    });
    modal.onDidDismiss().then(async (data) => {
      if (type === 'passive') {
        if (data.data && data.role === 'ok') {
          this.selfToCombateAFraude = true;
          sessionStorage.setItem('selfToCombateAFraude', this.selfToCombateAFraude.toString())
          this.selfieUrl = data.data.signedResponse;
          sessionStorage.setItem('selfieUrl', this.selfieUrl);
        } else if (data.role === 'fail') {
          this.showAlertError();
        }
      }
      if (type === 'rg' || type === 'cnh') {

        if (data.data && data.role === 'ok') {
          this.documentsFrontAndBack = true
          sessionStorage.setItem('documentsFrontAndBack', this.documentsFrontAndBack.toString())
          if (type === 'rg') {
            this.rgFrontUrl = data.data[0].imageUrl
            this.rgBackUrl = data.data[1].imageUrl
            sessionStorage.setItem('rgFrontUrl', this.rgFrontUrl)
            sessionStorage.setItem('rgBackUrl', this.rgBackUrl)
          } else if (type === 'cnh') {
            this.cnhFrontUrl = data.data[0].imageUrl
            this.cnhBackUrl = data.data[1].imageUrl
            sessionStorage.setItem('cnhFrontUrl', this.cnhFrontUrl)
            sessionStorage.setItem('cnhBackUrl', this.cnhBackUrl)
          }
          else if (type === 'rne') {
            this.rneFrontUrl = data.data[0].imageUrl
            this.rneBackUrl  = data.data[1].imageUrl
            sessionStorage.setItem('rneFrontUrl', this.rneFrontUrl)
            sessionStorage.setItem('rneBackUrl', this.rneBackUrl)
          }
        } else if (data.role === 'fail') {
          this.showAlertError();
        }
      }
      if (type === 'any') {
        if (data.data && data.role === 'ok') {
          this.residentialCertificate = true
          this.residentialCertificateUrl = data.data.imageUrl
        } else if (data.role === 'fail') {
          this.showAlertError();
        }
      }
    });

    this.sendDocumentCaf();

    return await modal.present();
  }

  sendDocumentCaf() {
    if (
      this.selfieUrl && this.rgFrontUrl && this.rgBackUrl
    ) {
      this.loadingService.show();
      this.analyseDocumentOCR(this.rgFrontUrl, this.rgBackUrl, 'RG_FRONT', 'RG_BACK', this.selfieUrl)
    }
    if (
      this.selfieUrl && this.cnhFrontUrl && this.cnhBackUrl
    ) {
      this.loadingService.show();
      this.analyseDocumentOCR(this.cnhFrontUrl, this.cnhBackUrl, 'CNH_FRONT', 'CNH_BACK', this.selfieUrl)
    }
    if(
      this.selfieUrl &&  this.rneFrontUrl && this.rneBackUrl
      ){
        this.loadingService.show();
        this.analyseDocumentOCR(this.rneFrontUrl, this.rneBackUrl, 'RNE_FRONT', 'RNE_BACK', this.selfieUrl)
    }
  }

  async analyseDocumentOCR(frontUrl, backUrl, typeFront, typeBack, selfieUrl) {

    let onboarding_light = sessionStorage.getItem('onboarding_light')

    this.cpfValue = sessionStorage.getItem('cpfValue')

    this.preAccountValue = sessionStorage.getItem('keyForm')

    let postData = {
      "onboardingId": this.preAccountValue,
      "attributes": {
        "cpf": this.cpfValue.replace(/\D/g, '')
      },
      "files": [
        {
          "type": typeFront,
          "data": frontUrl
        },
        {
          "type": typeBack,
          "data": backUrl
        },
        {
          "type": "SELFIE",
          "data": selfieUrl
        }
      ]
    }

    this.quizService.checkOcr(postData)
      .pipe(
        tap(async (res) => {
          this.ocrData = res
          sessionStorage.setItem('ocrData', JSON.stringify(res))
          this.loadingService.hide();
          if (onboarding_light == 'true') {
            this.navCtrl.navigateRoot('company-documents')
          } else {
            // if (this.platform.is('ios')) {
            this.navCtrl.navigateRoot('ocr-scanner')
            // }
          }

        }),
        catchError((err) => {
          this.loadingService.hide();
          return of(null);
        })
      )
      .subscribe();
  }

  async showAlertError() {
    const alert = await this.alertController.create({
      header: 'Tente novamente!',
      message: 'Algo deu errado durante a validação, por favor tente novamente.',
      cssClass: 'custom-alert',
      buttons: ['OK'],
    });

    await alert.present();
  }

  async showAlertErrorCamera() {
    const alert = await this.alertController.create({
      header: 'Tente novamente!',
      message: 'Algo deu errado ao carregar sua câmera, por favor tente novamente.',
      cssClass: 'custom-alert',
      buttons: ['OK'],
    });

    await alert.present();
  }
}
