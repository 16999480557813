import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-alert-card.component.html',
  styleUrls: ['./modal-alert-card.component.scss'],
})
export class ModalAlertCardComponent {

  @Input() public titleBtnConfirm: any;
  @Input() public message: any;
  @Input() public alertRequest: boolean;
  @Input() public param?: string;

  constructor(
    public modalController: ModalController,
  ) {
  }

  dismiss() {
    this.modalController.dismiss(true);
  }

  confirm() {
    this.modalController.dismiss();
  }
}
