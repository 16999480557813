import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StepServiceResolver } from './../functions/resolver/step-service-resolver';
import { StatusResponsePage } from './status-response/status-response.page';
import { StatusAccountPage } from './status-account/status-account.page';

const routes: Routes = [

  {
    path: 'status-response',
    component: StatusResponsePage,
    resolve: { route: StepServiceResolver },
  },
  {
    path: 'status-account',
    component: StatusAccountPage,
    resolve: { route: StepServiceResolver },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotificationsRoutingModule { }
