import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { NavController, ToastController, ModalController, AlertController } from '@ionic/angular';
import { FormGroup } from '@angular/forms';
import { UserProfile } from 'src/app/core/dto/user-profile';
import { AccountBalanceService } from '@wlp/ui-bs-cashin';
import { PixService } from '@wlp/ui-bs-cashout';
import { UserInfoService } from '@wlp/ui-bs-login';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { Router } from '@angular/router';
import { ModalPixComponent } from '../../../../../modals/modal-pix/modal-pix.component';
import { TokenCodeService } from '@wlp/ui-bs-token';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ValidSmsPage } from '../../../cash-out/valid-sms/valid-sms.page';
import { ValidSmsUserPage } from '../../../cash-out/valid-sms/valid-sms-user.page';

@Component({
  selector: 'app-devolution-resume',
  templateUrl: './devolution-resume.page.html',
  styleUrls: ['./devolution-resume.page.scss'],
})
export class DevolutionResumePage implements OnInit {
  public backgroundImage: string;
  public formPixTransfer: FormGroup;
  public recipientAccoundData: any = [];
  public paymentValue: number;
  public balance;
  public userProfile: any;

  constructor(
    private layoutConfigService: LayoutConfigService,
    private navCtrl: NavController,
    private accountBalanceService: AccountBalanceService,
    private transferService: PixService,
    private cdr: ChangeDetectorRef,
    private authService: UserInfoService,
    private loadingService: LoadingService,
    private modalController: ModalController,
    private router: Router,
    private tokenCodeService: TokenCodeService,
    private toastController: ToastController,
    public alertController: AlertController,
  ) {}

  ngOnInit() {
    this.loadUser();
    this.backgroundImage = this.layoutConfigService.getConfig('wlTheme.header.background.image');

    this.formPixTransfer = this.transferService.getFormTransfer();

    if (this.router.getCurrentNavigation().extras.state === undefined) {
      this.goBack();
    } else {
      this.recipientAccoundData = this.router.getCurrentNavigation().extras.state.recipientAccoundData;
    }

    const paymentData = this.formPixTransfer.getRawValue();

    this.paymentValue = paymentData.operationValue;
  }

  public goBack() {
    this.navCtrl.back();
  }

  private loadUser() {
    const userDetails = this.authService.getInfoProfile();
    userDetails.then(user => {
      this.userProfile = user;
      this.loadBalance(this.userProfile);
    });
  }

  private loadBalance(userProfile: UserProfile): void {
    this.accountBalanceService
      .getBalance(userProfile.uuidWhiteLabel, userProfile.uuidAccount)
      .then((res) => {
        this.loadingService.hide();
        this.balance = res.balance;
      })
      .catch((err) => {
        this.loadingService.hide();
        this.accountBalanceService.getErroMessage(err);
      });
  }

  public continue() {
    // this.token();
    this.validSms();
  }

  public async token() {
    this.tokenCodeService
      .getToken(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount)
      .pipe(
        tap((res) => {
          this.sendPixTransaction(res.code);
        }),
        catchError((_) => {
          return of(null);
        })
      )
      .subscribe();
  }

  async validSms() {
    const modal = await this.modalController.create({
      component: ValidSmsUserPage,
      cssClass: 'my-custom-class',
      swipeToClose: false,
      backdropDismiss: false
    });

      modal.onDidDismiss()
      .then(data => {
        let dataResponse:any = data
        if ( dataResponse.data[0].validToken == true) {
          this.sendPixTransaction(dataResponse.data[0].token);
          return;
        } else {
          this.alertError('Código SMS não validado, tente novamente!')
          return;
        }
      })
     this.loadingService.hide();
     return await modal.present();

  }

  async alertError(message: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Atenção',
      message,
      buttons: ['OK'],
      backdropDismiss: false,
    });
    await alert.present();
  }

  public async sendPixTransaction(token: string) {
    this.loadingService.show();
    await this.transferService
      .sendTransaction(token)
      .then((res) => {
        this.loadingService.hide();
        const response = res;
        if (response !== null) {
          if (response.isExecuted === true) {
            this.statusTransaction('success', 'DEVOLUÇÃO EFETUADA!', response.message, 'FECHAR', false);
            return;
          }
          if (response.isExecuted === false) {
            if (String(response.message).includes('em processamento')) {
              this.transferService.unsubscribe();
              this.statusTransaction(
                'success',
                'PAGAMENTO EM PROCESSAMENTO',
                response.message,
                'FECHAR',
                false,
                '/app/dashboard'
              );
            } else {
              this.statusTransaction('failed', 'FALHA AO PROCESSAR DEVOLUÇÃO', response.message, 'FECHAR', false);
            }
            return;
          } else {
            this.statusTransaction(
              'failed',
              'PIX.PIX_DEVOLUTION.RESUME_PAYMENT.MESSAGES.NOT_PAID',
              'PIX.PIX_DEVOLUTION.RESUME_PAYMENT.MESSAGES.FAILED',
              'FECHAR',
              true
            );
            return;
          }
        } else {
          this.statusTransaction(
            'failed',
            'PIX.PIX_DEVOLUTION.RESUME_PAYMENT.MESSAGES.NOT_PAID',
            'PIX.PIX_DEVOLUTION.RESUME_PAYMENT.MESSAGES.FAILED',
            'FECHAR',
            true
          );
        }
      })
      .catch((err) => {
        this.loadingService.hide();
        this.transferService.getErrorTransfer(err);
        this.statusTransaction(
          'failed',
          'PIX.PIX_DEVOLUTION.RESUME_PAYMENT.MESSAGES.NOT_PAID',
          'PIX.PIX_DEVOLUTION.RESUME_PAYMENT..MESSAGES.FAILED',
          'FECHAR',
          true
        );
      });
  }

  public async statusTransaction(
    status: string,
    title: string,
    subtitle: string,
    textButton: string = 'Fechar',
    translate: boolean = false,
    redirect: string = '/app/pix/receipt-payment'
  ) {
    this.loadingService.hide();
    const modalStatus = await this.modalController.create({
      component: ModalPixComponent,
      cssClass: 'modal-pix-small',
      componentProps: {
        status,
        title,
        subtitle,
        textButton,
        translate,
      },
      backdropDismiss: false,
    });
    modalStatus.onDidDismiss().then((data) => {
      if (data.data === true) {
        this.loadingService.show();

        // load receipt
        this.transferService.getTransferConfirmation().subscribe(
          (res) => {
            this.loadingService.hide();
            this.router.navigate(['/app/dashboard'])
            // this.router.navigate([redirect], {
            //   state: {
            //     transferDataReceipt: res,
            //   },
            // });
          },
          (err) => {
            console.log(err);
            this.loadingService.hide();

            this.toastPresent('Falha ao carregar comprovante', 5000, 'bottom');
          }
        );
      }
    });
    return await modalStatus.present();
  }

  async toastPresent(message, duration, position) {
    const toast = await this.toastController.create({
      message,
      duration,
      position,
    });
    toast.present();
  }

  ngOnDestroy() {
    this.transferService.unsubscribe();
  }
}
