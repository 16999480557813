import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import {LayoutConfigService} from '../../../../../../../../core/layout/services/layout-config.service';

@Component({
  selector: 'ui-t-privacy-policy-ec',
  templateUrl: './privacy-policy-ec.page.html',
  styleUrls: ['./privacy-policy-ec.page.scss'],
})
export class PrivacyPolicyEcPage {

  wlName: string;
  wlCompany: any;

  constructor(private navCtrl: NavController, private layoutConfigService: LayoutConfigService) {
    this.wlName = this.layoutConfigService.getConfig('wlTheme.wlName');
    this.wlCompany = this.layoutConfigService.getConfig('wlTheme.company');
  }

  goBack() {
    this.navCtrl.back();
  }
  continue() {
    this.navCtrl.navigateRoot('onboarding-ec/contract-ec');
  }
}
