// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../../../../environments/environment';

interface IPermissionModel {
  permission: string;
  params: any;
}

interface IUserModel {
  name: string;
  phone: string;
  email: string;
  permissions: IPermissionModel[];
  [key: string]: any;
}

interface IDeleteUserModel {
  userId: string;
}

interface IResetPasswordModel {
  email: string;
}

interface IBlockUserPayload {
  email: string;
  enabled: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private url = `${environment.apiUri}/spe-n-user-permissions/v1`;

  constructor(private httpClient: HttpClient) {}

  async getUserPermissions(userProfileInfo, userId?): Promise<IUserModel[]> {
    const options = {
      params: {
        uuidAccountPayment: userProfileInfo.uuidAccount,
        uuidWhitelabel: userProfileInfo.uuidWhiteLabel,
      },
    };

    if (userId) {
      (options.params as any).userId = userId;
    }

    return new Promise((resolve) => {
      this.httpClient
        .get(this.url, options)
        .pipe(catchError((error) => of(error)))
        .subscribe(resolve);
    });
  }

  async createUser(user: IUserModel): Promise<any> {
    const response = await new Promise((resolve) => {
      this.httpClient
        .post(this.url, user)
        .pipe(catchError((error) => of(error)))
        .subscribe(resolve);
    });
    return response;
  }

  async updateUser(user: IUserModel): Promise<any> {
    const response = await new Promise((resolve) => {
      this.httpClient
        .put(this.url, user)
        .pipe(catchError((error) => of(error)))
        .subscribe(resolve);
    });
    return response;
  }

  async deleteUser(payload: IDeleteUserModel): Promise<IUserModel[]> {
    const options = {
      params: {
        userId: payload.userId,
      },
    };

    return new Promise((resolve) => {
      this.httpClient
        .delete(this.url, options)
        .pipe(catchError((error) => of(error)))
        .subscribe(resolve);
    });
  }

  async resetPassword(user: IResetPasswordModel): Promise<any> {
    const response = await new Promise((resolve, reject) => {
      this.httpClient
        .post(`${this.url}/reset-password`, user)
        .pipe(catchError((error) => of(error)))
        .subscribe(resolve);
    });
    return response;
  }

  async blockUser(user: IBlockUserPayload): Promise<any> {
    const response = await new Promise((resolve) => {
      this.httpClient
        .post(`${this.url}/block-user`, user)
        .pipe(catchError((error) => of(error)))
        .subscribe(resolve);
    });
    return response;
  }

  async checkPermission(permission: string): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient
        .post(`${this.url}/check-permission`, { permission })
        .pipe(
          map((_) => true),
          catchError((err) => {
            console.log(err);
            return of(false);
          })
        )
        .subscribe(resolve);
    });
  }

  async getPermissions(): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient
        .get(`${this.url}/permissions?full=true`)
        .pipe(catchError(() => of([])))
        .subscribe(resolve);
    });
  }
}
