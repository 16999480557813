import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { AccountRechargeService, RechargeBilletResponse } from '@wlp/ui-bs-cashin';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import {of, Subject} from 'rxjs';
import {catchError, retry, takeUntil, tap} from 'rxjs/operators';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import {DashboardService} from "@wlp/ui-bs-prospera";

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-prospera-billet.component.html',
  styleUrls: ['./modal-prospera-billet.component.scss'],
})
export class ModalProsperaBilletComponent implements OnDestroy, OnInit {

  @Input() public value: number;
  @Input() public barCodeBillet: number;
  @Input() public urlBillet: number;

  @Input() public data: any;

  public billetData: any;
  public billetValue: any;
  public userProfile: UserInfoProfile;

  private destroy$: Subject<null> = new Subject();

  constructor(
    public modalController: ModalController,
    private service: DashboardService,
    private loadingService: LoadingService,
    @Inject(DOCUMENT) private document: Document,
    protected userInfoService: UserInfoService,
  ) {
  }
  public ngOnInit() {
    this.loadingService.show();
    const userDetails = this.userInfoService.getInfoProfile();
    userDetails.then((user) => {
      this.userProfile = user;
      this.billetData = {
        isExecuted: null,
        linkRedirect: String(this.urlBillet),
        message: null,
        typeableLine: String(this.barCodeBillet),
      };
      this.loadingService.hide();
    });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public dismiss() {
    this.modalController.dismiss().then((res) => { }, (err) => {
      console.error(err);
    });
  }

  public printBilletOnPage(msg: string) {
    if (this.billetData.linkRedirect) {
      window.open(this.billetData.linkRedirect, '_blank');
    } else {
      window.open(this.data.linkBillet, '_blank');
    }
  }

  public shareBilletOnPage(msg: string) {
  }

  public copyContent() {
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = this.billetData.typeableLine;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  }
}
