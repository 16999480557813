import * as CryptoJS from 'crypto-js'
import { environment } from "src/environments/environment"
const Forge = require("node-forge");

export class encryptDataService {

constructor() {
    
}

public codeEncrypt(code:string, publicKey: string): Promise<any> {
	const publicKeyData = publicKey;

	const codeData = code
	const plainText = codeData+'$'+environment.saltTransaction
	
	const publicKeyForge = Forge.pki.publicKeyFromPem(publicKeyData);
	const dataBytes = Forge.util.encodeUtf8(plainText);
	
	const encryptedBytes = publicKeyForge.encrypt(dataBytes, "RSA-OAEP", {
	md: Forge.md.sha256.create(),
	});
	
	return Forge.util.encode64(encryptedBytes);
}

}