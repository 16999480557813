import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-pix-conclusion',
  templateUrl: './pix-conclusion.component.html',
  styleUrls: ['./pix-conclusion.component.scss'],
})
export class PixConclusionComponent implements OnInit {
  params: boolean;
  constructor(
    private activateRoute: ActivatedRoute,
    private navCtrl: NavController  
  ) {
     // this.activateRoute.queryParams.subscribe((res) => (res.type ? (this.params = res.type) : null));
   }

  ngOnInit() {}

  continue() {
    this.navCtrl.navigateRoot(['app/dashboard']);
  }

}
