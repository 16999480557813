import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Storage } from '@ionic/storage';
import { UserInfoService } from '@wlp/ui-bs-login';


@Injectable({
  providedIn: 'root'
})
export class QrCodeAuthenticationGuard implements CanActivate {

  constructor(
    private router: Router,
    private storage: Storage,
    protected userInfoService: UserInfoService,
    ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {

    const qrValid = await this.getItem('validaQrCode')

    if (qrValid === true){
      return true
    }

    this.onLogout()
    return false

  }

  async setItem(key: string, value){
    await this.storage.set(key, value)
  }

  async getItem(key: string) {
    await this.storage.get(key)
    return this.storage.get(key)
  }

  async clearStorage() {
    await this.storage.clear();
  }

  public onLogout() {
      
    let redirectUrl = document.location.protocol + '//' + document.location.hostname;

    if (document.location.hostname.includes('localhost')) {
      redirectUrl += ':' + document.location.port;
    }

    this.userInfoService.onLogout(redirectUrl);
  
}
  
}
