export class NotificationsController {
  /**
   * apresenta a notificacao na tela do usuario
   * @param title
   * @param body
   */
  static notify(title: string, body: string) {
    try {
      if (!NotificationsController.isPermited()) throw 'Usuario ainda precisa autorizar!';

      new Notification(title, {
        icon: './assets/images/whitelabel/icon/favicon.png',
        body: body,
      });
    } catch (err) {
      // console.warn(err);
    }
  }

  /**
   * metodo usado para pedir a permissao de notificacoes no navegador
   */
  static requestNotification(): Promise<string> {
    const prom: Promise<string> = new Promise((resolve, reject) => {
      try {
        if (!Notification) throw 'Api Notification nao funciona neste navegador!';

        Notification.requestPermission().then((result) => {
          if (result === 'granted') {
            NotificationsController.notifyExample();

            resolve(result);
          }
        });
      } catch (err) {
        reject(err);
      }
    });

    return prom;
  }

  /**
   * checa se o usuario ja permitiu as notificacoes
   */
  static isPermited(): boolean {
    let flag: boolean = false;
    if (Notification.permission === 'granted') {
      flag = true;
    }

    return flag;
  }

  /**
   * TODO: remover este metodo quando as notificacoes forem usadas nas demais paginas!
   * usado apenas para uma apresentacao de exemplo
   */
  static notifyExample() {
    NotificationsController.notify('Notificacao Payshopx', 'Uma notificacao de exemplo');
  }
}
