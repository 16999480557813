export enum StatusEnum {
    "" = "",
    WAITING = "Aguardando",
    WAITING_APPROVE = "Aguardando aprovação",
    REPROVED = "Reprovado",
    IMED_PROCESSING = "Em processamento",
    PROCESSING = "Processando",
    PROCESSED = "Processado",
    FAIL = "Falhou",
    DUPLICATE = "Duplicado",
    CANCELED = "Cancelado",
}