import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';

import { routesPrizeDraw } from './routes/routes-prize-draw';
import { routesCP } from './routes/routes-cp';

let routes: Routes = routesCP;

if (environment.wlThemeParameterization.wlTheme.app === 'prize-draw') {
  routes = routesPrizeDraw;
}

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ThemesRoutingModule {}
