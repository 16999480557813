import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UiBsCashOutModule } from '@wlp/ui-bs-cashout';

import { BankAgencyPipe } from './pipe/bankAgency.pipe';
import { BankAccountPipe } from './pipe/bankAccount.pipe';
import { BankPipe } from './pipe/bank.pipe';
import { CpfCnpjPipe } from './pipe/cpfCnpj.pipe';

@NgModule({
  declarations: [BankAgencyPipe, BankAccountPipe, BankPipe, CpfCnpjPipe],
  exports: [BankAgencyPipe, BankAccountPipe, BankPipe, CpfCnpjPipe],
  imports: [CommonModule, UiBsCashOutModule],
})
export class SharedModule {}
