import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { StatusAccountService } from '@wlp/ui-bs-signup';
import { Status } from '@wlp/ui-px-signup';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

import { LayoutConfigService } from '../../../../../core/layout/services/layout-config.service';

@Component({
  selector: 'app-status-account',
  templateUrl: './status-account-page.html',
  styleUrls: ['./status-account-page.scss'],
})
export class StatusAccountPage implements OnInit {
  title: string;
  description: string;
  button: string;
  pathIcon: string;
  preAccount: any;
  navigationBackground: string;
  brand: string;
  descriptionSidebar: string;
  private analiseConclutedIcon: any;
  private devolvedDocumentIcon: any;
  private recusedIcon: any;
  private successIcon: any;

  constructor(
    private navCtrl: NavController,
    private statusAccountService: StatusAccountService,
    private cdr: ChangeDetectorRef,
    private layoutConfigService: LayoutConfigService,
    private loadingService: LoadingService
  ) {
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
    this.analiseConclutedIcon = this.layoutConfigService.getImagesPages('analise-concluded');
    this.devolvedDocumentIcon = this.layoutConfigService.getImagesPages('devolved-document');
    this.recusedIcon = this.layoutConfigService.getImagesPages('recused-onboarding')
      ? this.layoutConfigService.getImagesPages('recused-onboarding')
      : this.layoutConfigService.getImagesPages('recused');
    this.successIcon = this.layoutConfigService.getImagesPages('success');
    this.descriptionSidebar = 'ONBORDING.STATUS_ACCOUNT.DESCRIPTION';
  }

  ngOnInit() {
    // console.log(new Date() + ' - ngOnInit');
    this.cdr.detach();
    this.load();
  }

  ionViewWillEnter() {
    // this.cdr.detectChanges();
  }

  load() {
    this.loadingService.show();
    this.statusAccountService
      .loadStatus()
      .then((res) => {
        this.preAccount = res;
        this.descriptionSidebar =
          res.status === 'REPROVED' ? 'ONBORDING.STATUS_ACCOUNT.DESCRIPTION_REPROVED' : this.descriptionSidebar;
        this.title = this.statusAccountService.getTitle(res.status);
        this.description = this.statusAccountService.getDescription(res.status);
        this.button = this.statusAccountService.getTextButton(res.status);
        this.definedIcon(res.status);
        this.loadingService.hide();
      })
      .finally(() => {
        this.cdr.detectChanges();
        this.loadingService.hide();
      });
  }

  continue() {
    if (
      this.preAccount.status === Status.IN_ANALYSIS_COMPLIANCE ||
      this.preAccount.status === Status.APPROVED_COMPLIANCE ||
      this.preAccount.status === Status.IN_ANALYSIS_BACKOFFICE ||
      this.preAccount.status === Status.APPROVED_BACKOFFICE ||
      this.preAccount.status === Status.IN_ANALYSIS_TOPAZIO ||
      this.preAccount.status === Status.APPROVED_TOPAZIO ||
      this.preAccount.status === Status.PENDENT
    ) {
      this.navCtrl.navigateRoot('/');
    } else if (this.preAccount.status === Status.DOCUMENT_PHYSICAL_PERSON_REPROVED) {
      this.navCtrl.navigateRoot('document-type?s=invalid');
    } else if (this.preAccount.status === Status.DOCUMENT_LEGAL_PERSON_REPROVED) {
      this.navCtrl.navigateRoot('company-documents?s=invalid');
    } else if (this.preAccount.status === Status.REGISTRATION_DATA_LEGAL_PERSON_INVALID) {
      this.navCtrl.navigateRoot('company-data?s=invalid');
    } else if (this.preAccount.status === Status.REGISTRATION_DATA_PHYSICAL_PERSON_INVALID) {
      this.navCtrl.navigateRoot('create-account?s=invalid');
    } else if (this.preAccount.status === Status.FINISHED || this.preAccount.status === Status.REPROVED) {
      this.navCtrl.navigateRoot('/');
    }
  }

  definedIcon(status: Status) {
    if (
      status === Status.IN_ANALYSIS_COMPLIANCE ||
      status === Status.APPROVED_COMPLIANCE ||
      status === Status.IN_ANALYSIS_BACKOFFICE ||
      status === Status.APPROVED_BACKOFFICE ||
      status === Status.IN_ANALYSIS_TOPAZIO ||
      status === Status.APPROVED_TOPAZIO ||
      status === Status.VALIDATION_ANALYSIS ||
      status === Status.VALIDATION_PENDENT
    ) {
      this.pathIcon = this.analiseConclutedIcon.path;
    } else if (
      status === Status.DOCUMENT_PHYSICAL_PERSON_REPROVED ||
      status === Status.DOCUMENT_LEGAL_PERSON_REPROVED
    ) {
      this.pathIcon = this.devolvedDocumentIcon.path;
    } else if (
      status === Status.REGISTRATION_DATA_LEGAL_PERSON_INVALID ||
      status === Status.REGISTRATION_DATA_PHYSICAL_PERSON_INVALID
    ) {
      this.pathIcon = this.devolvedDocumentIcon.path;
    } else if (status === Status.REPROVED || status === Status.VALIDATION_REPROVED) {
      this.pathIcon = this.recusedIcon.path;
    } else if (status === Status.FINISHED) {
      this.pathIcon = this.successIcon.path;
    }
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }
}
