import { NavController, IonSlides, ModalController } from '@ionic/angular';
import { Component, OnInit, ViewChild } from '@angular/core';


@Component({
  selector: 'ui-t-card-intro',
  templateUrl: './card-intro.page.html',
  styleUrls: ['./card-intro.page.scss'],
})
export class CardIntroPage implements OnInit {

  mode?: string;
  options?: object;
  bullets?: boolean;
  dataSource?: object;
  slideNumber?: any;
  showButtons?: boolean;
  isDisabledNext: boolean;
  isDisabledBack: boolean;
  @ViewChild('ionSlider', { static: false }) slides: IonSlides;

  slideOpts = {
    autoplay: false,
    loop: false,
  };

  objImage: object = [
    {
      path: 'assets/images/card-manager/ilustracao-cartao-1.svg',
      caption:
        'CARDS.CARD_SHOWCASE.SHOWCASE_1',
    },
    {
      path: 'assets/images/card-manager/ilustracao-cartao-2.svg',
      caption:
        'CARDS.CARD_SHOWCASE.SHOWCASE_2',
    },
    {
      path: 'assets/images/card-manager/ilustracao-cartao-3.svg',
      caption:
        'CARDS.CARD_SHOWCASE.SHOWCASE_3',
    },
  ];

  constructor(
    private navCtrl: NavController,
  ) { }

  ngOnInit() {
    this.isDisabledNext = true;
    this.isDisabledBack = false;
    this.bullets = true;
    this.mode = 'image';
    this.showButtons = false;
    this.options = this.slideOpts;
    this.dataSource = this.objImage;
  }

  async continue() {
    this.isDisabledBack = true;
    await this.slides.isEnd().then((res) => {
      if (res === true) {
        this.showButtons = true;
        this.isDisabledNext = false;
      }
      this.slides.slideNext();
    });
  }

  async skip() {
    const slide = await this.slideNumber;
    this.slides.slideTo(slide, 0);
  }

  async goBack() {
    this.showButtons = false;
    this.isDisabledNext = true;
    await this.slides.isBeginning().then((res) => {
      if (res === true) {
        this.isDisabledBack = false;
      }
      this.slides.slidePrev();
    });
  }

  ionSlidePrevEnd() {
    this.showButtons = false;
  }

  ionSlideReachEnd() {
    this.showButtons = true;
    this.isDisabledNext = false;
  }

  ionSlideReachStart() {
    this.isDisabledBack = false;
  }

  goToCardManager() {
    this.navCtrl.navigateRoot('app/cards/card-manager');
  }
}
