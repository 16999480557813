import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QrCodeAuthenticationGuard } from 'src/app/core/auth/qrcode-authentication-guard';
import { CanAuthenticationGuard } from '../../../../../core/auth/can-authentication-guard';

import { UserInfoService } from '@wlp/ui-bs-login';
import { BilletsListPage } from './cash-in/billets-list/billets-list.page';
import { MySalesPage } from './cash-in/my-sales/my-sales.page';
import { RechargePage } from './cash-in/recharge/recharge.page';
import { RechargeMobilePage } from './cash-out/mobile-recharge/mobile-recharge.page';
import { ValidSmsUserPage } from './cash-out/valid-sms/valid-sms-user.page';
import { ValidSmsPage } from './cash-out/valid-sms/valid-sms.page';
import { CpManagerPage } from './cp-manager.page';
import { DashboardPage } from './dashboard/dashboard.page';
import { DetectappPage } from './detectapp/detectapp.page';
import { ImportFilesListPage } from './import-files/import-files-list/import-files-list.page';
import { ImportFilesLotePage } from './import-files/import-files-lote/import-files-lote.page';
import { ImportFilesLotePageList } from './import-files/import-files-lote-list/import-files-lote-list.page';
import { InsurancePage } from './insurance/insurance.page';
import { MyDataPage } from './my-data/my-data.page';
import { NotificationsPage } from './notifications/notifications.page';
import { FormPermissionsPage } from './permissions/permissions-form/form-permissions.page';
import { PermissionsPage } from './permissions/permissions-list/permissions.page';
import { TransactionApprovalListPage } from './transaction-approval/transaction-approval-list/transaction-approval-list.page';
import { ValidateQrcodePage } from './validate-qrcode/validate-qrcode.page';
import { AccountClosurePage } from './account-closure/account-closure.page';
import { SecurityPage } from './account-security/security/security.page';
import { SecurityValidatePage } from './account-security/validade-security-operation/security-validate/security-validate.page';
import { TransferLimitPage } from './transfer-limit/transfer-limit.page';
import { LimitManagement } from './limit-management/limit-management.page';
import { TransfersPage } from './transfer-limit/tabs/transfers/transfers.page';
import { HistoryPage } from './transfer-limit/tabs/history/history.page';

const userInfoService = UserInfoService;

const routes: Routes = [
  {
    path: '',
    component: CpManagerPage,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: DashboardPage,
      },
      {
        path: 'my-data',
        component: MyDataPage,
      },
      {
        path: 'my-sales',
        component: MySalesPage,
      },
      {
        path: 'notification',
        component: NotificationsPage,
      },
      {
        path: 'recharge',
        component: RechargePage,
      },
      {
        path: 'security',
        component: SecurityPage,
      },
      {
        path: 'security-validate',
        component: SecurityValidatePage,
      },
      {
        path: 'list-billets',
        component: BilletsListPage,
      },
      {
        path: 'permissions',
        component: PermissionsPage,
      },
      {
        path: 'transaction-authorization',
        component: TransactionApprovalListPage,
      },
      {
        path: 'limit-management',
        component: LimitManagement,
      },
      {
        path: 'transfer-limit',
        component: TransferLimitPage,
        children: [
          {
            path: 'tab-transfers',
            component: TransfersPage,
          },
          {
            path: 'tab-history',
            component: HistoryPage,
          },
        ]
      },
      {
        path: 'permissions/form',
        component: FormPermissionsPage,
      },
      {
        path: 'payment',
        loadChildren: './cash-out/payment/payment.module#PaymentModule',
      },
      {
        path: 'cards',
        loadChildren: './cards/cards.module#CardsModule',
      },
      {
        path: 'mobile-recharge',
        component: RechargeMobilePage,
      },
      {
        path: 'transfer',
        loadChildren: './cash-out/transfer/transfer.module#TransferModule',
      },
      {
        path: 'extract',
        loadChildren: './cash-out/extract/extract.module#ExtractModule',
      },
      {
        path: 'charging',
        loadChildren: './cash-out/charging/charging.module#ChargingModule',
      },
      {
        path: 'onboarding-ec',
        loadChildren: './onboarding-ec/onboarding-ec.module#OnboardingEcPageModule',
      },
      {
        path: 'prosperaenergy',
        loadChildren: './prosperaenergy/prosperaenergy.module#ProsperaenergyModule',
      },
      {
        path: 'cashback',
        loadChildren: './cashback/cashback.module#CashbackPageModule',
      },
      {
        path: 'pix',
        loadChildren: './pix/pix.module#PixModule',
      },
      {
        path: 'fees-tariffs',
        loadChildren: () => import('./fees-tariffs/fees-tariffs.module').then((m) => m.FeesTariffsPageModule),
      },
      {
        path: 'insurance',
        component: InsurancePage,
      },
       {
        path: 'import-files',
         component: ImportFilesListPage,
       },
      {
     path: 'batch/:batchType',
      component: ImportFilesLotePage,
      },
      
      {
        path: 'batch/payment/detail/:uniqueBatchIdentifier/:batchType',
        component: ImportFilesLotePageList,
        },
  
      {
        path: 'valid-sms',
        component: ValidSmsPage,
      },
      {
        path: 'valid-sms-user',
        component: ValidSmsUserPage,
      },
      {
        path: 'wallet',
        loadChildren: () => import('./wallet/wallet.module').then((m) => m.WalletModule),
      },
    ],
    canActivate: [CanAuthenticationGuard, QrCodeAuthenticationGuard],
  },
  {
    path: 'credit',
    loadChildren: () => import('./credit/credit.module').then((m) => m.CreditPageModule),
  },
  {
    path: 'validate-qrcode',
    component: ValidateQrcodePage,
    canActivate: [CanAuthenticationGuard],
  },
  {
    path: 'account-closure',
    component: AccountClosurePage,
  },
  {
    path: 'detectapp',
    component: DetectappPage,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CpManagerRoutingModule {}
