import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { StepFlowService } from '@wlp/ui-bs-signup';

@Injectable({
  providedIn: 'root',
})
export class StepServiceResolver implements Resolve<any> {
  constructor(private stepService: StepFlowService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let params = '?a=a';
    if (route.url[0].path.includes('documents-partner')) {
      params = '';
      route.queryParamMap.keys.forEach((key) => {
        params += '?' + key + '=' + route.queryParamMap.get(key);
      });
    } else {
      route.queryParamMap.keys.forEach((key) => {
        params += '&' + key + '=' + route.queryParamMap.get(key);
      });
    }
    // console.log(route.url[0].path + params);
    this.stepService.updateStep(route.url[0].path + params);
    return 'teste';
  }
}

/* export class StepServiceResolver implements Resolve<any> {
  constructor(private stepService: StepFlowService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let params = '?a=a';
    route.queryParamMap.keys.forEach((key) => {
      params += '&' + key + '=' + route.queryParamMap.get(key);
    });
    // console.log('teste ', params);
    this.stepService.updateStep(route.url[0].path + params);
    return 'teste';
  }
} */
