import { Component, OnInit } from '@angular/core';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import {environment} from 'src/environments/environment';


@Component({
  selector: 'app-detectapp',
  templateUrl: './detectapp.page.html',
  styleUrls: ['./detectapp.page.scss'],
})
export class DetectappPage implements OnInit {

  public brand: string;
  public background: string;
  public chrome = false
  public realm: string;

  constructor(
    private layoutConfigService: LayoutConfigService,
  ) {

    this.brand = this.layoutConfigService.getConfig('wlTheme.intro.brand.logo');
    this.background = this.layoutConfigService.getConfig('wlTheme.intro.background.image');
    this.realm = environment.wlThemeParameterization.wlTheme.realm.toUpperCase();

  }

  ngOnInit() {

    this.chromeDetect()

  }

  chromeDetect() {
    
    let ua = navigator.userAgent.toLowerCase();
    let isAndroid = ua.indexOf("android") > -1; // android check
    let isIphone = ua.indexOf("iphone") > -1; // ios check

    let agente = window.navigator.userAgent
    if (
      navigator.userAgent.indexOf('Chrome') > -1 && agente.length < 123 && isIphone === true && isAndroid === false ||
      navigator.userAgent.indexOf('Chrome') > -1 && agente.length < 123 && isIphone === false && isAndroid === true
      ) {
      this.chrome = true
    } else {
      this.deeplink()
    }

  }

  continueBrowser(){
    window.location.replace("/welcome");
  }

  deeplink() {
    let ua = navigator.userAgent.toLowerCase();
    let isAndroid = ua.indexOf("android") > -1; // android check
    let isIphone = ua.indexOf("iphone") > -1; // ios check
    if (isIphone == true) {
      let app = {
        launchApp: function () {
          window.location.href = "wlplataforms://app.secure.srv.br"; //Abrir o app Android caso esteja instalado.
          setTimeout(this.openWebApp, 200);
        },
        openWebApp: function () {
          window.location.replace("/welcome"); //Caso não tenha o app instalado, ir para o site.
        }
      };
      app.launchApp();
    } else if (isAndroid == true) {
      let app = {
        launchApp: function () {
          window.location.href = "wlplataforms://app.secure.srv.br"; //Abrir o app Android caso esteja instalado.
          setTimeout(this.openWebApp, 200);
        },
        openWebApp: function () {
          window.location.replace("/welcome"); //Caso não tenha o app instalado, ir para o site.
        }
      };
      app.launchApp();
    } else {
      window.location.replace("/welcome"); // Se estiver em desktop ir para o site
    }
  }

}
