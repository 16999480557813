import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { FileAccount, PreAccountResponse, UploadService } from '@wlp/ui-bs-signup';

@Component({
  selector: 'ui-t-choose-document',
  templateUrl: './choose-document.page.html',
  styleUrls: ['./choose-document.page.scss'],
})
export class ChooseDocumentPage {
  public brand: string;
  private fileAccount: FileAccount;
  private preAccount: PreAccountResponse;
  public navigationBackground: string;

  constructor(
    private navCtrl: NavController,
    private uploadService: UploadService,
    private activateRoute: ActivatedRoute,
    private layoutConfigService: LayoutConfigService
  ) {
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
  }

  /**
   * AO ENTRAR DA PAGINA
   */
  ionViewWillEnter(): void {
    this.loadFileAccount();
  }

  uploadRG() {
    let fileAccount = new FileAccount();
    fileAccount.title = 'Rg';
    fileAccount.uploadType = 'RG';
    fileAccount.positionPhoto = 'FRONT';
    fileAccount.groupDocument = this.fileAccount.groupDocument;
    fileAccount.optionParam = this.fileAccount.optionParam;
    this.uploadService.createFileAccount(fileAccount);
    this.navCtrl.navigateRoot('upload-documents');
  }

  uploadCNH() {
    let fileAccount = new FileAccount();
    fileAccount.title = 'CNH';
    fileAccount.uploadType = 'CNH';
    fileAccount.positionPhoto = 'FRONT';
    fileAccount.groupDocument = this.fileAccount.groupDocument;
    fileAccount.optionParam = this.fileAccount.optionParam;
    this.uploadService.createFileAccount(fileAccount);
    this.navCtrl.navigateRoot('upload-documents');
  }

  goBack() {
    this.uploadService
      .getAccountType()
      .then((res: PreAccountResponse) => {
        this.redirectToFlow(res);
      })
      .catch((err) => this.uploadService.getErroUpload(err));
  }

  private redirectToFlow(preAccountService: PreAccountResponse): void {
    if (
      preAccountService.accountType.typeFlow.toString() === 'PHHYSICAL_PERSON' ||
      preAccountService.accountType.typeFlow.toString() === 'PHYSICAL_PERSON'
    ) {
      this.navCtrl.navigateRoot('document-type');
      return;
    }

    if (preAccountService.accountType.typeFlow.toString() === 'LEGAL_PERSON') {
      this.activateRoute.queryParams.subscribe((res) => {
        this.navCtrl.navigateRoot(['documents-partner'], { queryParams: { type: res.type } });
      });
      return;
    }

    if (preAccountService.accountType.typeFlow.toString() === 'MEI') {
      this.navCtrl.navigateRoot('document-type');
      return;
    }

    if (preAccountService.accountType.typeFlow.toString() === 'LIBERAL_PROFESSIONAL') {
      this.navCtrl.navigateRoot('choose-document');
      return;
    }
  }

  // private defineGroupDocument(): string {
  //     console.log('this.preAccount', this.preAccount);
  //     if(this.preAccount.accountType.typeFlow.toString() === 'PHHYSICAL_PERSON' || this.preAccount.accountType.typeFlow.toString() === 'PHYSICAL_PERSON') {
  //         return 'PHYSICAL_PERSON';
  //     }
  //
  //     if(this.preAccount.accountType.typeFlow.toString() === 'LEGAL_PERSON') {
  //         return 'LEGAL_PERSON_MASTER';
  //     }
  //
  //     if(this.preAccount.accountType.typeFlow.toString() === 'MEI') {
  //         return 'MEI';
  //     }
  //
  //     if(this.preAccount.accountType.typeFlow.toString() === 'LIBERAL_PROFESSIONAL') {
  //         return 'LIBERAL_PROFESSIONAL';
  //     }
  // }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }

  private loadFileAccount(): void {
    this.uploadService.loadFileTypeUpload().then((res) => {
      this.fileAccount = res;
      if (!this.fileAccount) {
        this.goBack();
      }
    });
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }
}
