import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import * as moment from 'moment';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { FormImportFilesModalPage } from '../../../../modals/import-files-form-modal/import-files-form-modal.page';
import { ImportFilesService } from '../import-files.service';

@Component({
  selector: 'ui-t-import-files-list',
  templateUrl: './import-files-list.page.html',
  styleUrls: ['./import-files-list.page.scss'],
})
export class ImportFilesListPage implements OnInit {
  public formTransfer: FormGroup;
  public errorMessages: any;
  public filesList: Array<any> = [];
  public filteredFilesList: Array<any> = [];
  userProfile: UserInfoProfile;
  public countUsers = 0;
  public showModal = false;
  protected nav;

  constructor(
    private importFilesService: ImportFilesService,
    protected userInfoService: UserInfoService,
    private loadingService: LoadingService,
    private router: Router,
    private modalController: ModalController
  ) {
    this.nav = this.router.getCurrentNavigation();
  }

  ngOnInit() {}

  ionViewWillEnter() {
    this.loadUser();
  }

  loadUser() {
    this.userInfoService
      .getInfoProfile()
      .then((user) => {
        this.userProfile = user;
        this.refresh();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async refresh() {
    if (!this.loadingService.isLoading) {
      await this.loadingService.show();
    }
    const filter = {};
    const enumStatus = {
      IN_PROGRESS: 'Em Processamento',
      PROCESSING_SUCCESSFUL: 'Processado com Sucesso',
      PROCESSING_WITH_ERRORS: 'Processado com Erros',
      INVALID_FILE: 'Arquivo Inválido',
    };
    try {
      const res = await this.importFilesService.getImportFiles(this.userProfile, filter);
      this.filesList = res
        .map((it) => ({
          ...it,
          parsedStatus: enumStatus[it.status],
          isValid: it.status !== 'INVALID_FILE',
        }))
        .sort((a, b) => new Date(b.dateUpdated).getTime() - new Date(a.dateUpdated).getTime());
      this.filteredFilesList = this.filesList;
      this.countUsers = this.filesList.length;
    } finally {
      await this.loadingService.hide();
    }
  }

  async uploadFile() {
    this.handleShowModal();
  }

  infiniteScrollEvent(event: Event) {
    console.log(event);
  }

  filter(event) {
    const val = event.toLowerCase() || '';

    this.filteredFilesList = this.filesList.filter((d) => {
      return (
        `
        ${d.name}
        ${d.type}
        ${d.parsedStatus}
      `
          .toLowerCase()
          .indexOf(val) !== -1
      );
    });
  }

  async downloadFileToCorrect(importFile) {
    const response: any = await this.importFilesService.getDownloadResumeFile(importFile.uuid, 'errors');
    const objectUrl = window.URL.createObjectURL(response);
    const anchor = document.createElement('a');
    anchor.href = objectUrl;
    anchor.download = `${importFile.name}_errors.csv`;
    anchor.click();
    window.URL.revokeObjectURL(objectUrl);
  }

  async downloadErrorsFile(importFile) {
    const response: any = await this.importFilesService.getDownloadResumeFile(importFile.uuid, 'errors');
    const objectUrl = window.URL.createObjectURL(response);
    const anchor = document.createElement('a');
    anchor.href = objectUrl;
    anchor.download = `${importFile.name}_errors.csv`;
    anchor.click();
    window.URL.revokeObjectURL(objectUrl);
  }

  async downloadResumeFile(importFile) {
    const response: any = await this.importFilesService.getDownloadResumeFile(importFile.uuid, 'complete');
    const objectUrl = window.URL.createObjectURL(response);
    const anchor = document.createElement('a');
    anchor.href = objectUrl;
    anchor.download = `${importFile.name}_complete.csv`;
    anchor.click();
    window.URL.revokeObjectURL(objectUrl);
  }

  public async handleShowModal() {
    const modal = await this.modalController.create({
      component: FormImportFilesModalPage,
      cssClass: 'modal-custom-class',
      backdropDismiss: false,
    });

    modal.onDidDismiss().then((event) => {
      if (event.data.success === true) {
        this.refresh();
      }
    });
    return await modal.present();
  }

  public dateControl(date: any): string {
    try {
      return `${moment(date).format('DD/MM/YYYY')}`;
    } catch (err) {
      return '';
    }
  }

  public getHourDate(date: any): string {
    try {
      return `${moment(date).format('HH:mm:ss')}`;
    } catch (err) {
      return '';
    }
  }
}
