import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'walletCurrency' })
export class WalletCurrencyPipe implements PipeTransform {
    transform(value: string): string {
        const costPosition = value.search(/\d/); // posicao do valor dentro da string
        const cost = value.substring(costPosition); // Valor
        const currency = value.substring(0, costPosition); // Simbolo monetario

        return `<span class="wallet-currency">${currency}</span>${cost}`;
    }
}
