import {Component, OnInit, ViewChild} from '@angular/core';
import {IonSlides, ModalController, NavController, Platform} from '@ionic/angular';
import {UICMenuAppService} from '@wlp/ui-c-menu-app';
import {OnboardingEcService} from '../../onboarding-ec.service';
import {OnboardingEcInterface} from '../../onboarding-ec.interface';
import {FormBuilder, FormGroup} from '@angular/forms';
import {
  PreProductAccountProduct,
  PreProductAccountResponse,
  PreProductWithProductRequest,
  ProductWhiteLabelResponse,
  ShowcaseService,
  StatusAnalysisEnum
} from '@wlp/ui-bs-ec';
import {UserInfoService} from '@wlp/ui-bs-login';
import {PreProductCustom, ProductSlideCustomDto} from "./product-slide-custom.dto";
import {ModalSearchMccComponent} from "../../../../../modals/modal-search-mcc/modal-search-mcc.component";
import {LoadingService} from "../../../../../../../../core/layout/services/loading.service";


@Component({
  selector: 'ui-t-showcase-ec',
  templateUrl: './showcase-ec.page.html',
  styleUrls: ['./showcase-ec.page.scss'],
})
export class ShowcaseEcPage implements OnInit {
  public mode?: string;
  public options?: object;
  public bullets?: boolean;
  public dataSource?: object;
  public slideNumber?: any;
  public showButtons?: boolean;
  public showStatusRequest?: boolean;
  public isDisabledNext: boolean;
  public isDisabledBack: boolean;
  private productAccountResponse: PreProductAccountResponse;
  private slideActive: number = 0;

  @ViewChild('ionSlider', { static: false }) slides: IonSlides;
  public titleData: OnboardingEcInterface;

  public slideOpts = {
    autoplay: false,
    loop: false,
  };

  public objImage: object = [
    {
      path: 'assets/images/showcase/image-showcase-1.svg',
      caption:
        'ONBOARDING_EC.SHOWCASE.POS_01.DESCRIPTION',
    },
    {
      path: 'assets/images/showcase/image-showcase-2.svg',
      caption:
        'ONBOARDING_EC.SHOWCASE.POS_02.DESCRIPTION',
    },
    {
      path: 'assets/images/showcase/billet.svg',
      caption:
        'ONBOARDING_EC.SHOWCASE.POS_03.DESCRIPTION',
    },
  ];

  public arrayStatus: object = [
    {
      path: 'assets/images/showcase/approved.svg',
      caption:
        'ONBOARDING_EC.SHOWCASE.POS_04.DESCRIPTION',
    },
    {
      path: 'assets/images/showcase/disapproved.svg',
      caption:
        'ONBOARDING_EC.SHOWCASE.POS_05.DESCRIPTION',
    },
  ];


  public statusApproved: object = {
      path: 'assets/images/showcase/approved.svg',
      caption: 'ONBOARDING_EC.SHOWCASE.POS_04.DESCRIPTION',
      button: 'ONBOARDING_EC.SHOWCASE.POS_04.BUTTON',
    }
  ;

  public formProducts: FormGroup;
  public productsWhiteLabel: Array<ProductWhiteLabelResponse>;
  public itensSelected = [];
  public userProfile: any;
  public productSlide: PreProductCustom;

  constructor(private platform: Platform, private navCtrl: NavController, private uicMenuApp: UICMenuAppService,
    private onboardingEcService: OnboardingEcService, private fb: FormBuilder,
    private showcaseService: ShowcaseService, private userInfoService: UserInfoService,
    private modalController: ModalController,
    private loadingService: LoadingService,
  ) {
    this.loadUser();
  }

  ngOnInit() {
    this.isDisabledNext = true;
    this.isDisabledBack = false;
    this.bullets = true;
    this.mode = 'image';
    this.showButtons = false;
    this.options = this.slideOpts;
    this.dataSource = this.objImage;
    this.uicMenuApp.applyStatus(false);
    this.platform.ready().then(() => {
      this.length();
    });
    this.titleData = {
      isTitle: false,
      title: '',
      subtitle: '',
    };
    this.onboardingEcService.setTitleSubject(this.titleData);
  }

  private loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
        this.getListProducts();
        // console.log(this.userProfile);
      });
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }

  public async continue() {
    this.isDisabledBack = true;
    await this.slides.isEnd().then((res) => {
      if (res === true) {
        this.showButtons = true;
        this.isDisabledNext = false;
      }
      this.slides.slideNext();
    });
  }

  public async skip() {
    const slide = await this.slideNumber;
    this.slides.slideTo(slide, 0);
  }

  public async goBack() {
    this.showButtons = false;
    this.isDisabledNext = true;
    await this.slides.isBeginning().then((res) => {
      if (res === true) {
        this.isDisabledBack = false;
      }
      this.slides.slidePrev();
    });
  }

  public length() {
    if (this.slides) {
      this.slideNumber = this.slides.length();
    }
  }

  public ionSlideDidChange(event) {
    // console.log('ionSlideDidChange', event);
    this.slides.getActiveIndex().then(active => {
      // console.log('ionSlideDidChange:active', active);
      this.slideActive = active;
    });
  }

  public ionSlideNextEnd() {
  }

  public ionSlidePrevEnd() {
    this.showButtons = false;
    this.slideActive -= 1;
  }

  public ionSlideReachEnd() {
    this.showButtons = true;
    this.isDisabledNext = false;
  }

  public ionSlideReachStart() {
    this.isDisabledBack = false;
  }

  public async onSubmitRequest() {
    // console.log(this.itensSelected);

    if (this.productSlide.id) {
      const requestUpdate = this.prepare();
      for ( const product of requestUpdate.products ) {
          await this.showcaseService.updateProductRequest({
            uuid: product.uuid,
            uuidProduct: product.uuidProduct,
            name: product.name,
            status: product.status,
          }, this.productSlide.id, product.uuid).subscribe( res => {
            // console.log(res);
          });
       }
        this.showStatusRequest = true;
        this.getListProducts();
      // }
    } else {
      await this.showcaseService.saveRequest(this.prepare()).subscribe(res => {
        // console.log(res);
        this.showStatusRequest = true;
        this.getListProducts();
      });
    }
  }

  private prepare(): PreProductWithProductRequest {
    return {
      uuidWhiteLabel: this.productSlide.uuidWhiteLabel,
      uuidAccountPayment: this.productSlide.uuidAccountPayment,
      activityBranch: this.productSlide.activityBranch,
      products: this.prepareProduct(),
    };
  }

  private prepareProduct() {
    let products = new Array<PreProductAccountProduct>();
    for (const productCustom of this.productSlide.preProductsCustom) {
      productCustom.preProduct.status = productCustom.preProduct.status === StatusAnalysisEnum.REQUESTED ?
        StatusAnalysisEnum.IN_ANALYSIS : productCustom.preProduct.status;
      products.push(productCustom.preProduct);
    }

    return products;
  }

  public getListProducts() {
     this.loadingService.show();
    this.showcaseService.getAllProductsVisible(  this.userProfile.uuidWhiteLabel, 1)
      .subscribe( res => {
        this.productsWhiteLabel = res;
        if (res) {
          this.productSlide = new PreProductCustom();
          this.productSlide.uuidAccountPayment = this.userProfile.uuidAccount;
          this.productSlide.uuidWhiteLabel = this.userProfile.uuidWhiteLabel;
          this.productSlide.preProductsCustom = new Array<ProductSlideCustomDto>();

          for (const productWL of res) {
            this.productSlide.preProductsCustom.push({
              productWhiteLabel: productWL,
            });
          }

          this.loadRequestProduct();
          this.loadingService.hide();
        }
        // console.log(this.productsWhiteLabel);
      }, error => this.loadingService.hide());

  }

  private loadRequestProduct() {
     this.showcaseService.findUuidAccountPayment(this.userProfile.uuidAccount).subscribe( res => {
      if ( res ) {
        this.productAccountResponse = res;
        // console.log(this.productAccountResponse);
        this.productSlide.activityBranch = res.activityBranch;
        this.productSlide.id = res._id;
        for (const preProd of res.products) {
          const filterProducts = this.productSlide.preProductsCustom.filter(product => {
            return product.productWhiteLabel.uuid === preProd.uuid && product.productWhiteLabel.uuidProduct === preProd.uuidProduct;
          });

          filterProducts[0].preProduct = preProd;
          filterProducts[0].checked = this.isProductCheckSelected(preProd);
          filterProducts[0].disabled = this.isProductCheckSelected(preProd);
        }

        const productsNotRequested = this.productAccountResponse.products.filter(product => {
          return product.status === StatusAnalysisEnum.NOT_REQUESTED;
        });

        if (productsNotRequested.length === 0) {
          this.showStatusRequest = true;
        }

        // this.loadingService.hide();
      } else {
        console.log('Não localizamos produto');
        for ( const prod of this.productSlide.preProductsCustom) {
          prod.checked = false;
          prod.disabled = false;
          prod.preProduct = {
            uuid: prod.productWhiteLabel.uuid,
            uuidProduct:  prod.productWhiteLabel.uuidProduct,
            name: prod.productWhiteLabel.name,
            status: StatusAnalysisEnum.NOT_REQUESTED,
          };
        }

        // this.loadingService.hide();
      }
    });
  }

  public selectedProduct(e, item: ProductSlideCustomDto) {
    if (this.slideActive === 3 ) {
        if (item.preProduct.status === StatusAnalysisEnum.REQUESTED || item.preProduct.status === StatusAnalysisEnum.NOT_REQUESTED) {
          if (e.detail.checked && item.preProduct.status === StatusAnalysisEnum.NOT_REQUESTED) {
            // console.log('checked 1');
            item.preProduct.status = StatusAnalysisEnum.REQUESTED;
            item.checked = true;
          } else if (e.detail.checked && item.preProduct.status === StatusAnalysisEnum.REQUESTED) {
            // console.log('checked 2');
            item.preProduct.status = StatusAnalysisEnum.NOT_REQUESTED;
            item.checked = false;
          } else {
            // console.log('checked 3');
            item.preProduct.status = StatusAnalysisEnum.NOT_REQUESTED;
            item.checked = false;
          }
          // console.log('productsSlide:depois ', this.productSlide);
      }
    }
  }

  public isValid() {
    const filterProducts = this.productSlide.preProductsCustom.filter( product => {
      if (product.preProduct && product.preProduct.status) {
        return product.preProduct.status === StatusAnalysisEnum.REQUESTED;
      }
      return false;
    });
    return filterProducts.length > 0 && this.productSlide.activityBranch;
  }

  public isProductCheckSelected(preProd: PreProductAccountProduct) {
    // console.log('isProductSelected', this.slideActive, item.name);
      switch (preProd.status) {
        case StatusAnalysisEnum.NOT_REQUESTED:
        // case StatusAnalysisEnum.REQUESTED:
          // console.log('isProductSelected', false, preProd.name);
          return false;
        case StatusAnalysisEnum.IN_ANALYSIS:
        case StatusAnalysisEnum.APPROVED:
        case StatusAnalysisEnum.REPROVED:
          // case StatusAnalysisEnum.REQUESTED:
          // console.log('isProductSelected', true, preProd.name);
          return true;
      }
  }

  public async searchMcc() {
    this.loadingService.show();
    let modalBank = null;

    await this.modalController.create({
      component: ModalSearchMccComponent,
      cssClass: 'modal-search-mcc',
      id: 'modal-search-mcc',
    }).then( modal => {
      modalBank = modal;
      this.loadingService.hide();
    }).catch( e => {
      this.loadingService.hide();
    });

    modalBank.onDidDismiss()
      .then((data) => {
        if (data['data']) {
          this.productSlide.activityBranch = {
            activityBranch: data.data.activityBranch,
            cnae: data.data.cnae,
            departamentCode: data.data.departamentCode,
            description: data.data.description,
            mcc: data.data.mcc,
          };

          // console.log(this.productSlide);
          // this.formTransfer.controls.bank.setValue(data['data'].name);
          // this.formTransfer.controls.bankCode.setValue(data['data'].code);
        }
      });
    return await modalBank.present();
  }

  public redirectDashboard() {
    this.navCtrl.navigateForward('/app/dashboard');
  }

}
