import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenCodeService } from '@wlp/ui-bs-token';
import { catchError, tap } from 'rxjs/operators';


import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LimitManagementService {

    endpointBase = "https://api-sandbox.secure.srv.br";

    constructor(
        private httpClient: HttpClient,
    ) { }

    async getDataByTypes(payload: any) {
        const endPoint = `${this.endpointBase}/spe-j-limit-manager/v1/my-limits/by-types`;

        const params = {
            uuidWhiteLabel: payload.uuidWhiteLabel,
            uuidAccountPayment: payload.uuidAccountPayment,
            type: payload.type,
            period: payload.period,
        };

        const promise: any = new Promise(async (resolve: any) => {
            try {
                this.httpClient.get(endPoint, {
                    params,
                })
                    .subscribe(async (res: any) => {
                        resolve(res);
                    }, async (res: HttpErrorResponse) => {
                        if (res.status === 401) {
                            console.log(res.status, res.error);
                        }

                        resolve(res.error);
                    });
            } catch (error) {
                console.log('Ocorreu um erro', error)
                resolve({ status: 'error' });
            }
        });
        return promise
    }

    async getDataHistoric(payload: any) {
        const endPoint = `${this.endpointBase}/spe-j-limit-manager/v1/my-limits/history`;

        const params = {
            uuidWhiteLabel: payload.uuidWhiteLabel,
            uuidAccountPayment: payload.uuidAccountPayment,
            type: payload.type,
            period: payload.period,
        };

        const promise: any = new Promise(async (resolve: any) => {
            try {
                this.httpClient.get(endPoint, {
                    params,
                })
                    .subscribe(async (res: any) => {
                        resolve(res);
                    }, async (res: HttpErrorResponse) => {
                        if (res.status === 401) {
                            console.log(res.status, res.error);
                        }

                        resolve(res.error);
                    });
            } catch (error) {
                console.log('Ocorreu um erro', error)
                resolve({ status: 'error' });
            }
        });
        return promise
    }

}