import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DevolutionResumePage } from './resume/devolution-resume.page';
import { DevolutionPage } from './devolution.page';

const routes: Routes = [
  {
    path: '',
    component: DevolutionPage,
  },
  {
    path: 'resume',
    component: DevolutionResumePage,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DevolutionRoutingModule {}
