import { Component } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';

import { NotificationsController } from '../../notifications/notifications.controller';

@Component({
  selector: 'ui-t-popover',
  templateUrl: './popover-favorites.component.html',
  styleUrls: ['./popover-favorites.component.scss'],
})
export class PopoverFavoritesComponent {
  constructor(  public popoverController: PopoverController) {}

  dismissPopover() {
    this.popoverController.dismiss();
  }

  exclude() {
    this.popoverController.dismiss("exclude");
  }
  edit() {
    this.popoverController.dismiss("edit");
  }
}
