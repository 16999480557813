import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PixRoutingModule } from './pix-routing.module';
import { PreRegisterModule } from './pre-register/pre-register.module';
import { UiBsPrePixModule } from '@wlp/ui-bs-pre-pix'
import { UiBsPixModule } from '@wlp/ui-bs-pix'
import { InterceptService } from "../../../../../../core/intercept/intercept.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { DevolutionModule } from './devolution/devolution.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PixRoutingModule,
    PreRegisterModule,
    UiBsPrePixModule,
    UiBsPixModule,
    DevolutionModule
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
})
export class PixModule { }
