import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {UserInfoService} from '@wlp/ui-bs-login';
import {UserInfoProfile} from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import {AttendanceService} from '@wlp/ui-bs-prospera';
import {of, Subject} from 'rxjs';
import {catchError, takeUntil, tap} from 'rxjs/operators';
@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-prospera-attendance.component.html',
  styleUrls: ['./modal-prospera-attendance.component.scss'],
})
export class ModalProsperaAttendanceComponent implements OnInit, OnDestroy {

  public succeed: boolean;

  public form: FormGroup;
  public errorMessages: any;
  public id: string;
  public listSubject: any[];

  public userProfile: UserInfoProfile;

  private destroy$: Subject<null> = new Subject();

  constructor(
    public modalController: ModalController,
    private service: AttendanceService,
    protected userInfoService: UserInfoService) {
    this.succeed = true;
  }

  public ngOnInit() {
    this.form = this.service.createForm();
    this.errorMessages = this.service.getFormError();
    this.loadUser();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public f(control: string): AbstractControl {
    return this.form.get(control);
  }

  public toggleSucceed(): void {
    this.succeed = !this.succeed;
  }

  public async dismiss(el) {
    this.modalController.dismiss(el);
  }

  private loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();

      userDetails.then( (user) => {
        this.userProfile = user;
        this.getListAttendance();
      });

    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }

  private getListAttendance() {
    this.service.getListSubject(this.userProfile.uuidWhiteLabel)
      .pipe(
        takeUntil(this.destroy$),
        tap((data) => {
          this.listSubject = data;
        }),
        catchError((_) => {
          return of(null);
        }),
      )
      .subscribe();
  }
}
