import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UiBsTranslateModule } from '@wlp/ui-bs-translate';
import { UiCIntroPixModule } from '@wlp/ui-c-intro-pix';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ComponentsModule } from 'src/app/core/shared/modules/components.module';

import { IntroPixComponent } from './intro-pix/intro-pix.component';
import { PreRegisterRoutingModule } from './pre-register-routing.module';
import { ChooseKeyComponent } from './choose-key/choose-key.component';
import { UiCPixChooseKeyModule } from '@wlp/ui-c-pix-choose-key';
import { UiCPixConclusionModule } from '@wlp/ui-c-pix-conclusion';
import { UiCAcceptContentPixModule } from '@wlp/ui-c-accept-content-pix';
import { PixConclusionComponent } from './pix-conclusion/pix-conclusion.component';
import { ConfirmationPixComponent } from './confirmation-pix/confirmation-pix.component';
import { UiCPixConfirmationNotificationModalComponent, UiCPixConfirmationNotificationModalModule } from '@wlp/ui-c-pix-confirmation-notification-modal'
import { ContractPixComponent } from './contract-pix/contract-pix.component';
import { UiCConfirmationPixModule } from '@wlp/ui-c-confirmation-pix';
import {InterceptService} from "../../../../../../../core/intercept/intercept.service";
import {HTTP_INTERCEPTORS} from "@angular/common/http";



@NgModule({
  imports: [AngularSvgIconModule,
    ComponentsModule,
    CommonModule,
    FormsModule,
    IonicModule,
    PreRegisterRoutingModule,
    UiBsTranslateModule,
    UiCIntroPixModule,
    UiCPixChooseKeyModule,
    UiCPixConclusionModule,
    UiCPixConfirmationNotificationModalModule,
    UiCAcceptContentPixModule,
    UiCConfirmationPixModule,
  ],
  declarations: [
    IntroPixComponent,
    ChooseKeyComponent,
    PixConclusionComponent,
    ContractPixComponent,
    ConfirmationPixComponent,
  ],
  entryComponents: [
    UiCPixConfirmationNotificationModalComponent,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
})

export class PreRegisterModule { }
