import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { IPageBase, PersonalDataService } from '@wlp/ui-bs-signup';

@Component({
  selector: 'ui-t-personal-data',
  templateUrl: './personal-data.page.html',
  styleUrls: ['./personal-data.page.scss'],
})
export class PersonalDataPage implements IPageBase {
  formPersonalData: FormGroup;
  errorFormMessages: any;
  public customPatterns = { '0': { pattern: new RegExp('/(\\d)(?=(\\d{3})+(?!\\d))/g') } };
  public brand: string;
  public navigationBackground: string;
  constructor(
    private personalDataService: PersonalDataService,
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService
  ) {
    this.errorFormMessages = this.personalDataService.getFormErrorMessage();
    this.formPersonalData = this.personalDataService.getPersonalData();
    this.personalDataService.updateForm();
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
  }

  continue(): void {
    this.personalDataService.sendPersonalData(this.formPersonalData, 'personal-address').subscribe(
      (data) => this.navCtrl.navigateRoot('personal-address'),
      (error) => this.personalDataService.getErrorPersonalData(error)
    );
  }

  applyMask(data: any): void {
    this.personalDataService.applyMaskCurrency(data.controlName, data.form);
  }

  goBack() {
    this.navCtrl.navigateForward('create-account');
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }
}
