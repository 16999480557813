import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ExtractDigitalAccountPage } from './digital-account/extract-digital-account.page';
import { ExtractReceiptPage } from './receipt/extract-receipt.page';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'digital-account',
        pathMatch: 'full',
      },
      {
        path: 'receipt/:type',
        component: ExtractReceiptPage,
      },
      {
        path: 'digital-account',
        component: ExtractDigitalAccountPage,
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class ExtractRoutingModule { }
