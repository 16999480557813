import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { AfterContentChecked, AfterContentInit, Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { UploadEcService } from '@wlp/ui-bs-ec';
import { AccountTypeService, FileAccount, PreAccountResponse, UploadService } from '@wlp/ui-bs-signup';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ui-t-document-type-ec',
  templateUrl: './document-type.page.html',
  styleUrls: ['./document-type.page.scss'],
})
export class DocumentTypePageEC {
  private fileAccount: FileAccount;
  private isResidencial: boolean;
  private isSelfiePhoto: boolean;
  private isRgOrCnh: boolean;
  private isLoadedResidencial: boolean;
  private preAccount: PreAccountResponse;
  private groupDocument: string;
  private layoutConfigService: LayoutConfigService;
  private optionalPages: boolean;

  constructor(
    private navCtrl: NavController,
    private uploadService: UploadEcService,
    private accountTypeService: AccountTypeService
  ) {}

  ionViewWillEnter(): void {
    this.isResidencial = false;
    this.isSelfiePhoto = false;
    this.isRgOrCnh = false;
    this.showResidencial();
    /* this.uploadService
      .loadDocumentuploaded(); */
    this.optionalPages = environment.wlThemeParameterization.wlTheme.optionalPages;
  }

  goBack() {
    this.navCtrl.navigateRoot('account-type');
  }

  markButton(type: string): string {
    let isLoaded = this.isLoaded(type);
    if (type === 'RG' && !isLoaded) {
      isLoaded = this.isLoaded('CNH');
    }
    this.isDisabled();
    return isLoaded ? 'checkmark-circle' : 'ellipse-outline';
  }

  isDisabled(): boolean {
    this.isRgOrCnh = this.isLoaded('RG');
    if (!this.isRgOrCnh) {
      this.isRgOrCnh = this.isLoaded('CNH');
    }
    this.isLoadedResidencial = false;
    this.isSelfiePhoto = this.isLoaded('SELF_PHOTO');
    if (this.isResidencial) {
      this.isLoadedResidencial = this.isLoaded('RESIDENCIAL_CERTIFICATE');
    }

    const disabled = this.isSelfiePhoto && this.isRgOrCnh && (this.isResidencial ? this.isLoadedResidencial : true);
    return !disabled;
  }

  private isLoaded(type: string): boolean {
    /*  return this.uploadService.isDocumentUploaded(type); */
    return false;
  }

  rGOrCPF() {
    this.fileAccount = new FileAccount();
    this.fileAccount.groupDocument = this.groupDocument;
    /* this.uploadService.createFileAccount(this.fileAccount); */
    this.navCtrl.navigateRoot('onboarding-ec/choose-document-ec');
  }

  proofAddress() {
    this.fileAccount = new FileAccount();
    this.fileAccount.uploadType = 'RESIDENCIAL_CERTIFICATE';
    this.fileAccount.positionPhoto = 'FRONT';
    this.fileAccount.title = 'Foto do Comprovante';
    /* this.uploadService.createFileAccount(this.fileAccount); */
    this.navCtrl.navigateRoot('/onboarding-ec/upload-documents-ec');
  }

  photoSelf() {
    this.fileAccount = new FileAccount();
    this.fileAccount.uploadType = 'SELF_PHOTO';
    this.fileAccount.positionPhoto = 'FRONT';
    this.fileAccount.title = 'Foto da Selfie';
    /* this.uploadService.createFileAccount(this.fileAccount); */
    this.navCtrl.navigateRoot('onboarding-ec/upload-documents');
  }

  continue(): void {
    if (
      this.preAccount.accountType.typeFlow.toString() === 'PHHYSICAL_PERSON' ||
      this.preAccount.accountType.typeFlow.toString() === 'PHYSICAL_PERSON'
    ) {
      if (this.optionalPages) this.navCtrl.navigateRoot('comercial-agent');
      else this.navCtrl.navigateRoot('contract');
      return;
    }

    if (this.preAccount.accountType.typeFlow.toString() === 'MEI') {
      this.navCtrl.navigateRoot('company-data');
      return;
    }
  }

  private showResidencial() {
    /*  this.uploadService
       .getAccountType()
       .then((preAccount: PreAccountResponse) => this.defineResidecial(preAccount))
       .catch(err => this.uploadService.getErroUpload(err)); */
  }

  private defineResidecial(preAccount: PreAccountResponse): void {
    this.preAccount = preAccount;
    if (
      preAccount.accountType.typeFlow.toString() === 'PHHYSICAL_PERSON' ||
      preAccount.accountType.typeFlow.toString() === 'PHYSICAL_PERSON'
    ) {
      this.groupDocument = 'PHYSICAL_PERSON';
      this.isMoreFiveK();
      return;
    }

    if (preAccount.accountType.typeFlow.toString() === 'MEI') {
      this.groupDocument = 'MEI';
      this.isResidencial = true;
      return;
    }
  }

  private isMoreFiveK() {
    this.accountTypeService
      .accountIsMoreFiveK()
      .then((res) => {
        if (res.isMore) {
          this.isResidencial = true;
        }
      })
      .catch((err) => this.accountTypeService.getErrorMessages(err));
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }
}
