import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { UICMenuAppService } from '@wlp/ui-c-menu-app';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { WalletService } from '@wlp/ui-bs-prizedraw';
import { environment } from '../../../../../../../../environments/environment';
@Component({
  selector: 'app-transactions.page',
  templateUrl: './transactions.page.html',
  styleUrls: ['./transactions.page.scss'],
})
export class TransactionsPage implements OnInit {
  public backgroundImage: string;
  public cards: Array<any>;
  public transactions: Array<any> = [];
  public cardSliderOption: any;
  public userProfile: UserInfoProfile;
  constructor(
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private uicMenuApp: UICMenuAppService,
    private walletService: WalletService,
    protected userInfoService: UserInfoService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loadUser();

    // this.transactions = [
    //   { store: 'Hamburgueria QDelícia', value: 35.20, date: 1611697860225, cashback: 3.52, type: 'gastronomia' },
    //   { store: 'Cinema Trianplex', value: 20.00, date: 1611697860225, cashback: 4.00, type: 'cinema' },
    //   { store: 'Farmácia', value: 52.00, date: 1611697860225, cashback: null, type: 'lojas' },
    //   { store: 'Hamburgueria QDelícia', value: 35.20, date: 1611697860225, cashback: 3.52, type: 'gastronomia' },
    //   { store: 'Cinema Trianplex', value: 20.00, date: 1611697860225, cashback: 4.00, type: 'cinema' },
    //   { store: 'Farmácia', value: 52.00, date: 1611697860225, cashback: null, type: 'lojas' }
    // ];

    // this.transactions = [];

    // this.cardSliderOption = {
    //   slidesPerView: 3,
    //   centeredSlides: false
    // }
    this.cardSliderOption = {
      slidesPerView: 1,
      slidesPerColumn: 1,
      slidesPerGroup: 1,
      spaceBetween: 20,
      centeredSlides: true,
    };

    this.backgroundImage = this.layoutConfigService.getConfig('wlTheme.header.background.image');
  }

  redirectUrl(link: string): void {
    if (link !== '') {
      this.navCtrl.navigateRoot([link]);
    }
  }

  ionViewDidEnter() {
    this.uicMenuApp.applyStatus(true);
  }

  goBack() {
    this.navCtrl.navigateRoot('/app/dashboard');
  }
  sliderOption() {
    if (window.outerWidth <= 500) {
      return (this.cardSliderOption = {
        slidesPerView: 1,
        slidesPerColumn: 1,
        slidesPerGroup: 1,
        spaceBetween: 20,
        centeredSlides: true,
      });
    } else {
      return (this.cardSliderOption = {
        slidesPerView: 3,
        centeredSlides: false,
      });
    }
  }
  async getWallets() {
    await this.walletService
      .getWallets(environment.wlp, this.userProfile.uuidAccount)
      .then((res) => {
        if (res) {
          this.cards = res;
          this.cdr.detectChanges();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  async findTransactions(uuidAccount: string) {
    await this.walletService
      .findTransactions(uuidAccount)
      .then((res) => {
        console.log('transactions', res);
        if (res) {
          this.transactions = res;
          this.cdr.detectChanges();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  formatDate(date) {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (day.toString().length == 1) {
      day = '0' + day;
    }

    if (month.toString().length == 1) {
      month = '0' + month;
    }

    return year + '-' + month + '-' + day;
  }
  firstLastDay(date: Date) {
    let year = date.getFullYear();
    let month = date.getMonth();

    let dStart = new Date(year, month, 1);
    let dEnd = new Date(year, month + 1, 0);

    return {
      startDate: this.formatDate(dStart),
      endDate: this.formatDate(dEnd),
    };
  }
  async findTransactionsByPeriod(uuidAccount: string, date) {
    const startDate = this.firstLastDay(new Date(date)).startDate;
    const endDate = this.firstLastDay(new Date(date)).endDate;

    await this.walletService
      .findTransactionsByPeriod(uuidAccount, startDate, endDate)
      .then((res) => {
        //console.log('transactions', res);
        if (res) {
          this.transactions = res;
          this.cdr.detectChanges();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  private loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();

      userDetails.then((user) => {
        this.userProfile = user;
        this.getWallets();
        this.findTransactions(user.uuidAccount);
      });
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }
  selectedPeriod(evt) {
    if (evt == 'All') {
      this.findTransactions(this.userProfile.uuidAccount);
    } else {
      this.findTransactionsByPeriod(this.userProfile.uuidAccount, evt);
    }
  }
}
