import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CardsService } from '@wlp/ui-bs-cards';

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-unlock-card.component.html',
  styleUrls: ['./modal-unlock-card.component.scss'],
})
export class ModalUnlockCardComponent {

  public numberCardForm: FormGroup;
  public errorMessages: any;
  public disableBtn = true;

  constructor(
    public modalController: ModalController,
    private cardService: CardsService,
  ) {
    this.errorMessages = this.cardService.getFormNumberCardModal();
    this.numberCardForm = this.cardService.createNumberCardForm();
  }

  public checkInput() {
    if (this.numberCardForm.status === 'VALID') {
      return false;
    } else {
      return true;
    }
  }

  dismiss() {
    this.modalController.dismiss();
  }

  confirm() {
    const numbercard = this.numberCardForm.get("cardNumber").value;
    this.modalController.dismiss(numbercard);
  }

}
