import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TransferDocumentPage } from './document/transfer-document.page';
import { TransferLotPage } from './lot/transfer-lot.page';
import { TransferOnUsPage } from './on-us/transfer-on-us.page';
import { TransferOtherAccountsPage } from './other-accounts/transfer-other-accounts.page';
import { TransferReceiptPage } from './receipt/transfer-receipt.page';
import { TransferPage } from './transfer.page';

const routes: Routes = [
  {
    path: '',
    component: TransferPage,
    children: [
      {
        path: '',
        redirectTo: 'document',
        pathMatch: 'full',
      },
      {
        path: 'document',
        component: TransferDocumentPage,
      },
      {
        path: 'on-us',
        component: TransferOnUsPage,
      },
      {
        path: 'other-accounts',
        component: TransferOtherAccountsPage,
      },
      {
        path: 'lot',
        component: TransferLotPage,
      },
      {
        path: 'receipt',
        component: TransferReceiptPage,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TransferRoutingModule {}
