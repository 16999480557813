import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'faqEcFilter',
})
export class FaqEcFilterPipe implements PipeTransform {
  transform(names: any[], terms: string, label: string): any[] {
    if (!names) return [];
    if (!terms) return names;
    terms = terms.toLowerCase();
    return names.filter((it) => {
      return it[label].toLowerCase().includes(terms);
    });
  }
}
