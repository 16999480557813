import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { CompanyDataService } from '@wlp/ui-bs-signup';

@Component({
  selector: 'ui-t-company-data',
  templateUrl: './company-data.page.html',
  styleUrls: ['./company-data.page.scss'],
})
export class CompanyDataPage {
  companyDataForm: FormGroup;
  errorMessages: any;
  brand: string;
  navigationBackground: string;

  constructor(
    private companyDataService: CompanyDataService,
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService
  ) {
    this.companyDataForm = this.companyDataService.getCompanyDataForm();
    this.errorMessages = this.companyDataService.getErrorMessage();
    this.companyDataService.updateForm();
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
  }

  continue(): void {
    this.companyDataService.sendCompanyData().subscribe(
      (res) => this.navCtrl.navigateRoot('company-documents'),
      (err) => this.companyDataService.getErrorCompanyData(err)
    );
  }

  goBack() {
    this.navCtrl.navigateRoot('documents-partner?type=partner');
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }
}
