import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { PermissionsService } from '../permissions.service';

@Component({
  selector: 'ui-t-permissions',
  templateUrl: './permissions.page.html',
  styleUrls: ['./permissions.page.scss'],
})
export class PermissionsPage implements OnInit {

  public formTransfer: FormGroup;
  public errorMessages: any;
  public userList: Array<any> = [];
  public filteredUserList: Array<any> = [];
  userProfile: UserInfoProfile;
  public countUsers: number = 0;
  protected nav;

  constructor(
    private permissionService: PermissionsService,
    protected userInfoService: UserInfoService,
    private loadingService: LoadingService,
    private router: Router,
    private alertController: AlertController
  ) {
    this.nav = this.router.getCurrentNavigation();
  }

  ngOnInit() { }

  ionViewWillEnter() {
    this.loadUser();
  }

  loadUser() {
    this.userInfoService.getInfoProfile()
      .then(user => {
      this.userProfile = user;
      this.refresh();
    })
      .catch(error => {
      console.error(error);
    });
  }

  async refresh() {
    await this.loadingService.show();
    try {
      const res = await this.permissionService.getUserPermissions(this.userProfile)
      this.userList = res.map(it => ({
        ...it,
        parsedStatus: it.enabled ? "ATIVO" : "BLOQUEADO",
        canEdit: (it.email !== this.userProfile.email && it.enabled) && !it.isMaster,
        canBlock: (it.email !== this.userProfile.email) && !it.isMaster,
        canDelete: (it.email !== this.userProfile.email) && !it.isMaster,
      }));
      this.filteredUserList = this.userList;
      this.countUsers = this.userList.length;
    } finally {
      await this.loadingService.hide();
    }
  }

  createUser() {
    this.router.navigateByUrl('/app/permissions/form');
  }

  infiniteScrollEvent(event: Event) {
    console.log(event);
  }

  filter(event) {
    const val = event.toLowerCase() || "";

    this.filteredUserList = this.userList.filter((d => {
      return `
        ${d.fullName}
        ${d.email}
        ${d.parsedStatus}
      `.toLowerCase().indexOf(val) !== -1
    }))
  }

  async confirmDelete(user) {
    const alert = await this.alertController.create({
      header: 'Atenção',
      message: 'Confirma a exclusao deste usuário?',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
          handler: () => {
            return null
          },
        },
        {
          text: 'Sim',
          role: 'confirm',
          handler: () => {
            this.deleteUser(user)
          },
        },
      ],
    });

    await alert.present();
  }

  async deleteUser(user) {
    await this.loadingService.show();
    try {
      const payload = {
        userId: user.userId,
      }
      await this.permissionService.deleteUser(payload);
    } finally {
      await this.loadingService.hide();
    }
    await this.refresh();
  }

  async resetPassword(user) {
    await this.loadingService.show();
    try {
      const payload = {
        email: user.email
      }
      await this.permissionService.resetPassword(payload);
    } finally {
      await this.loadingService.hide();
    }
  }

  async blockUser(user) {
    await this.loadingService.show();
    try {
      const payload = {
        email: user.email,
        enabled: false,
      }
      await this.permissionService.blockUser(payload);
    } finally {
      await this.loadingService.hide();
    }
    await this.refresh();
  }

  async unBlockUser(user) {
    await this.loadingService.show();
    try {
      const payload = {
        email: user.email,
        enabled: true,
      }
      await this.permissionService.blockUser(payload);
    } finally {
      await this.loadingService.hide();
    }
    await this.refresh();
  }

  editUser(user) {
    this.router.navigateByUrl(`/app/permissions/form?userId=${user.userId}`);
  }

}
