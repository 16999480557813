import { AngularSvgIconModule } from 'angular-svg-icon';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { IntroService } from '@wlp/ui-bs-intro';
import { UiBsTranslateModule } from '@wlp/ui-bs-translate';
import { UiCContainerBrandModule } from '@wlp/ui-c-container-brand';

import { ComponentsModule } from '../../../../core/shared/modules/components.module';
import { NotificationsRoutingModule } from './notifications-routing.module';
import { StatusAccountPage } from './status-account/status-account.page';
import { StatusResponsePage } from './status-response/status-response.page';
import {StepServiceResolver} from "../functions/resolver/step-service-resolver";

@NgModule({
  declarations: [StatusResponsePage, StatusAccountPage],
  imports: [
    IonicModule,
    CommonModule,
    ComponentsModule,
    NotificationsRoutingModule,
    UiCContainerBrandModule,
    UiBsTranslateModule,
    AngularSvgIconModule.forRoot(),
  ],
  providers: [IntroService, StepServiceResolver],
})
export class NotificationsModule {}
