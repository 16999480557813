import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ApiImportFilesLote } from '../import-files-lotes.service';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { TokenCodeResponse, TokenCodeService } from '@wlp/ui-bs-token';
import { AlertAccountService } from '@wlp/ui-bs-alert';
import { DeviceService, SendCodeService } from '@wlp/ui-bs-device-validation';
import { catchError, tap } from 'rxjs/operators';
import { interval, Subscription } from 'rxjs';
import { CountdownComponent, CountdownEvent } from 'ngx-countdown';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';


@Component({
  selector: 'modal-card-notification',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})




export class ModalComponent implements OnInit{
  form!: FormGroup;
  formFile!: FormGroup;


  userProfile: any;
  values:any [] = []
  valuesSelected:any [] = []
  type:any = null;
  typeChosen:any;
  file:any;
  number:any
  numberAccount:any;
  name:any;
  sendToken:any
  status:any
  responseData:any
  data:any = null
  formSendCode: FormGroup;
  token = '';
  sub: Subscription;

  progressbarValue: number;
  typeChose: Boolean
  submitted:Boolean = false;
  submitting:Boolean = false;
  resError:any[] = []
  functionChild:any;
  batchType:String
  statement:String
  load:any;
  transfer:String;
  typeAccount: any[] = [];
  fileType;
  mask:string;
  verifiCnpjCpf: Boolean = false
  verifyInput: Boolean = false
  verifyButtonLote: Boolean = false
  uniqueBatchIdentifier:any
  alertAnalyse:Boolean
  alertError: any[]
  

  valueStatus: any[] =  ['ONUS', 'PIXKEY','PIX','TED','RECHARGE', 'BILLET','PIX_CHARGE']

  constructor(public modalController: ModalController,
    protected userInfoService: UserInfoService, 
    public apiLotes: ApiImportFilesLote,
    private tokenCodeService: TokenCodeService,
    private formBuilder: FormBuilder,
    private sendCodeService: SendCodeService,
    private alertController: AlertController,
    private alertAccountService: AlertAccountService,
    private loadingService : LoadingService ,
    private route: Router,
    private layoutConfigService: LayoutConfigService) {

      this.formSendCode = this.sendCodeService.getForm();
   
  }
  ngOnInit() {

    if(this.data != null){
      this.data.status == "INCONSISTENT" ? (this.alertAnalyse=true, this.alertError = this.data.metadaPayload.errors) : this.alertAnalyse =false
    }

    if(this.type != null){
      this.typeChose = this.type <= 2 ?  false : true 
    }
 
    
    
    this.fileType == 'TED_INTERNAL' ?  this.fileType = 'ONUS' : null
    this.fileType == 'TED_EXTERNAL' ? this.fileType = 'TED' : null

    if (this.type <= 3){
      let position = this.valueStatus.indexOf(this.fileType)
      this.typeChosen = position + 1

      if(this.typeChosen == 4){
       this.typeAccount= [
          {id: 1, value: 'CP', label: 'Conta de Pagamento'},
          {id: 2, value: 'CC', label: 'Conta Corrente'},
          {id: 3, value: 'PP', label: 'Poupança'}
        ]
      }else if(this.typeChosen == 3){
        this.typeAccount= [
          {id: 1, value: 'TRAN', label: 'Conta de Pagamento'},
          {id: 2, value: 'CACC', label: 'Conta Corrente'},
          {id: 3, value: 'SVGS', label: 'Poupança'}
        ]
      }

   
      
      this.form = this.formBuilder.group({
        typeTransaction: [this.valueStatus[position], Validators.required],
        name: ['', Validators.required],
        accountNumber: ['', Validators.required],
        ispb: ['', Validators.required],
        branch: ['', Validators.required],
        personType: ['', Validators.required],
        description: ['', Validators.required],
        valueTransaction: ['0,00', Validators.required],
        transactionDate: ['', Validators.required],
        document: ['', Validators.required],
        phoneNumber: ['', Validators.required],
        pixKey: ['', Validators.required],
        pixKeyType: ['', Validators.required],
        codeBank: ['', Validators.required],
        agency: ['', Validators.required],
        accountType: ['', Validators.required],
        uuid: ['', Validators.required],
    });

    this.formFile = this.formBuilder.group({
      batchName: ['', Validators.required],
      statement: ['', Validators.required],
      statusRadio: ['', Validators.required],
    })
    // this.form.get('batchName').valueChanges.subscribe(() => {
    //   this.onBatchNameChange();
    // });
    }else{

      this.typeChosen = this.valueStatus.indexOf(this.data.typeTransaction) + 1

      

      this.form = this.formBuilder.group({
        typeTransaction: [this.data.typeTransaction, Validators.required],
        name: [this.data.metadaPayload.name, Validators.required],
        accountNumber: [this.data.metadaPayload.accountNumber, Validators.required],
        ispb: [this.data.metadaPayload.ispb, Validators.required],
        branch: [this.data.metadaPayload.branch, Validators.required],
        personType: [this.data.metadaPayload.personType, Validators.required],
        description: [this.data.metadaPayload.description, Validators.required],
        valueTransaction: [this.formatMoney(this.data.metadaPayload.valueTransaction), Validators.required],
        transactionDate: [this.data.metadaPayload.transactionDate, Validators.required],
        document: [this.formatarCPFCNPJ(this.data.documentOrigin), Validators.required],
        pixKey: [this.data.pixKey, Validators.required],
        phoneNumber: [this.data.phoneNumber, Validators.required],
        pixKeyType: [this.data.pixKeyType, Validators.required],
        codeBank: [this.data.metadaPayload.codeBank, Validators.required],
        agency: [this.data.metadaPayload.agency, Validators.required],
        accountType: [this.data.metadaPayload.accountType, Validators.required],
        uuid: [this.data.uuid, Validators.required],

   
    });


    }
    

    this.loadStatus()
    this.loadUser()





  

  //  let vals = [
  //   {value: "FIX_INCONSISTENCIES", name: "Inconsistências"},
  //   {value: "READY_FOR_PROCESSING", name: "Pronto para processar"},
  //   {value: "TRANSACTION_NOT_COMPLETED", name: "Aguardando processamento"},
  //   {value: "TRANSACTION_IN_PROCESSING", name: "Em processamento"},
  //   {value: "TRANSACTION_COMPLETED", name: "Processado"}
  //  ]

  }

  startInput(){
    if(this.verifyButtonLote == false){
   
      this.verifyInput = true

      let batchName = this.formFile.get('batchName').value;
  
      this.formFile.patchValue({statement: `${batchName}`})
    }
   
  }

  verifyInputLote(){
    this.verifyButtonLote = true
  }
  

  onSubmit() {
    this.submitted = true;
}


formatData(){
  let value  = this.form.get('transactionDate').value.replace(/\D/g,'').slice(0, 10);;

  if (value.length >= 5) {
    value = `${value.slice(0,2)}/${value.slice(2,4)}/${value.slice(4)}`;
    }
    else if (value.length >= 3 && value.length < 5) {
    value = `${value.slice(0,2)}/${value.slice(2)}`;
    }

    this.form.patchValue({transactionDate: value})

}

formatMoney(money = null){
  if(money == null){
    let value = this.form.get('valueTransaction').value;
    if(value == 'Na' || value==''){
      value = '000'
    }else{
      value = value.replace('.', '').replace(',', '').replace(/\D/g, '')
      
    }
    const options = { minimumFractionDigits: 2 }
    const result = new Intl.NumberFormat('pt-BR', options).format(
      parseFloat(value) / 100
    )
    

  this.form.patchValue({valueTransaction: result})

  }else{
    let value = money.replace('.', '').replace(',', '').replace(/\D/g, '')
    const options = { minimumFractionDigits: 2 }
    const result = new Intl.NumberFormat('pt-BR', options).format(
      parseFloat(value) / 100
    )
  
  return result
  }
  

}

async handleChangeStatus(e) {

  this.form.patchValue({accountType: e.detail.value})

 
}

cpfcnpjmask(){
  
  let value = this.form.get('document').value.replace(/\D/g,'').slice(0, 18);
  if (value.length <= 11) {
    value = this.maskCpf(value);
  } else {
    value = this.maskCnpj(value);
  }



  this.form.patchValue({document: value})

}


 maskCpf(value) {
  value=value.replace(/\D/g,"")                    
  value=value.replace(/(\d{3})(\d)/,"$1.$2")      
  value=value.replace(/(\d{3})(\d)/,"$1.$2")      
                                           
  value=value.replace(/(\d{3})(\d{1,2})$/,"$1-$2") 
  return value
}
 maskCnpj(value) {
  value=value.replace(/\D/g,"")                        
  value=value.replace(/^(\d{2})(\d)/,"$1.$2")             
  value=value.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
  value=value.replace(/\.(\d{3})(\d)/,".$1/$2")          
  value=value.replace(/(\d{4})(\d)/,"$1-$2")            
  return value
}




formatarCPFCNPJ(cpfCnpj)
{   
  if (cpfCnpj.length === 11) {       
     return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');   
    } else if (cpfCnpj.length === 14) {     
      return cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');   
    } else {    
      return cpfCnpj;   
    } 
  }

onSelectedDate(dateSelected: moment.Moment, status: string) {
  const date = dateSelected.toDate();

    let data = date.toISOString().substring(0, 10);

    const [year, month, day] = data.split('-');

    this.form.value.transactionDate = `${day}/${month}/${year}`;

  

}

onBatchNameChange() {
  const batchNameValue = this.form.get('batchName').value;
  const statementValue = this.form.get('statement').value;

  // Verifica se o statement está vazio ou o batchName começa com o valor do statement
  if (!statementValue || batchNameValue.startsWith(statementValue)) {
    // Atualiza o statement com o valor de batchName
    this.form.get('statement').setValue(batchNameValue);
  }
}

get f() { return this.form.controls; }


  loadUser() {
    this.userInfoService
      .getInfoProfile()
      .then((user) => {
        this.userProfile = user;


      })
      .catch((error) => {
        alert(error);
      });
  }

  restartProgress() {
    this.sub.unsubscribe();
    this.startProgress();
  }

  resendCode(data): void {
    this.loadTokenCode(this.userProfile);
    this.restartProgress();
    this.sendCodeService.resend();
    this.sendToken.countdown.restart();
    this.sendToken.countdown.begin();
  }

  startProgress() {
    this.sendToken.countdown.config.leftTime = 180;
    const timer$ = interval(180);
    this.sub = timer$.subscribe((sec) => {
      this.progressbarValue = 1 - (sec * 0.37) / 240;
      if (this.progressbarValue === 0) {
        this.sub.unsubscribe();
      }
    });
  }

  loadTokenCode(userProfile) {
    this.tokenCodeService
      .getTokenSms(userProfile.uuidWhiteLabel, userProfile.uuidAccount, userProfile.userId)
      .pipe(
        tap((res) => {
          // //console.log(res)
        }),
        catchError((error) => {
          return error;
        })
      )
      .subscribe();
  }


  onKeyDown(evt) {
    const numKeyCode = [48,49,50,51,52,53,54,55,56,57,96,97,98,99,100,101,102,103,104,105,8,46]
    const iKeyCode = (evt.which) ? evt.which : evt.keyCode;
    // return !(iKeyCode !== 46 && iKeyCode > 31 && (iKeyCode < 48 || iKeyCode > 57));
    return numKeyCode.includes(iKeyCode)
  }

  nextElement(previusElement, currentElement, nextElement, event) {
    if (event.keyCode === 8) {
      if (!currentElement.value) {
        previusElement.focus();
        return;
      }
      return;
    }
    // console.log('onKeyUp', event);
    if (currentElement.value) {
      nextElement.focus();
    }
    if (currentElement === nextElement) {
      nextElement.blur();
    }
  }

  handleEvent($event: CountdownEvent) {
    // this.actionCountDown.emit($event);
  }

  async continueSendCode() {
    this.token =
      this.formSendCode.get('codeNumberOne').value +
      this.formSendCode.get('codeNumberTwo').value +
      this.formSendCode.get('codeNumberThree').value +
      this.formSendCode.get('codeNumberFour').value +
      this.formSendCode.get('codeNumberFive').value;



     const validToken = await this.tokenCodeService.confirmToken(
       this.userProfile.uuidWhiteLabel,
       this.userProfile.uuidAccount,
       this.token,
       this.userProfile.userId
     );

   

     if (validToken === true) {
       this.apiLotes.postProcess(this.token, this.uniqueBatchIdentifier, this.numberAccount).subscribe((res)=>{

        this.modalController.dismiss();
       })
      
       
       return;
     } else {
       if (!this.sendToken.countdown.left) {
         this.alertAccountService.expiredCode();
         this.formSendCode.reset();
         return;
       }
       this.alertAccountService.invalidCode();
     }
  }

  loadStatus(){

      if(this.batchType == 'payment'){
        this.values = [
              {id: 3,label: 'Pix (Conta Bancária)', type: 'radio', value: 'PIX_PAYMENT', checked: false},
              {id: 2,label: 'Pix (Chave)', type: 'radio', value: 'PIX_PAYMENT_INITIATE', checked: false},
              {id: 5,label: 'Recarga de Celular', type: 'radio', value: 'MOBILE_RECHARGE', checked: false},
        ]

      }else if(this.batchType == 'transfer'){
        this.values = [
          {id: 4,label: 'TED', type: 'radio', value: 'TED_EXTERNAL', checked: false},
          {id: 1,label: 'TEF (OnUs)', type: 'radio', value: 'TED_INTERNAL', checked: false},
        ]

      }else if(this.batchType == 'charge'){
        this.values = [
          {id: 6,label: 'Boleto de Cobrança', type: 'radio', value: 'BILLET', checked: false},
          {id: 7,label: 'Pix Cobrança', type: 'radio', value: 'PIX_CHARGE', checked: false},
        ]

      }else{
        
      }
     
    
    

  
    
  }

  handleChange(e){
this.typeChose = true
    this.typeChosen = e.detail.value

  }

  chosen(value){


    
    let answer = this.valuesSelected.filter(e => e == value);

    answer.length == 0 ? this.valuesSelected.push(value) : this.valuesSelected = this.valuesSelected.filter(name=> name != value)

  }


  save(){
    this.loadingService.show()
    this.modalController.dismiss()

    
         this.apiLotes.postFile(this.formFile.value, this.file, this.numberAccount, this.formFile.value.statusRadio ).subscribe(
           data => (

            setTimeout(() => {

              this.loadingService.hide(),
         
              this.route.navigateByUrl(`/app/batch/payment/detail/${data.generateUniqueBatchIdentifier}/${this.batchType}`)
             }, 5000)
          
           ),
           err => (this.callAlertError(err)),

      
         )
      

  }


 async callAlertError(error){
  
    const alert = await this.alertController.create({
      header: 'Atenção',
      message: `${error.message}`,
      buttons: ['Fechar'],
    });

    await alert.present();
  }

 
 

  confirm() {

    this.valuesSelected.forEach((value, index)=>{
      setTimeout(() => {
      if(value == 1){
        window.open('/assets/filesLotes/ONUS.xlsx', '_blank');
     }
      if(value == 2){
         window.open('/assets/filesLotes/PIXKEY.xlsx', '_blank');
      }
      else if(value == 3){
         window.open('/assets/filesLotes/PIX.xlsx', '_blank');
      }
      else if(value == 4){
         window.open('/assets/filesLotes/TED.xlsx', '_blank');
      }
      else if(value == 5){
         window.open('/assets/filesLotes/RECHARGE.xlsx', '_blank');
      }
      else if(value == 6){
        window.open('/assets/filesLotes/BILLET.xlsx', '_blank');
      }
     else if(value == 7){
      window.open('/assets/filesLotes/FULL.xlsx', '_blank');
     }

    }, index * 2000);
    })

    this.modalController.dismiss();
  }

  closeModal(){

    this.modalController.dismiss();
  }

  public getImageTag(tag: string) {
    const item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }

  removeNullProperties(payload: any): any {
    const cleanedPayload: any = {};
 
    for (const key in payload) {
        if (payload[key] !== null) {
            cleanedPayload[key] = payload[key];
        }
    }
 
    return cleanedPayload;
}


  updateTransaction(){

    this.form.value.typeTransaction = this.valueStatus[this.typeChosen -1]

    this.form.get('valueTransaction').setValue(this.form.value.valueTransaction.replace(',', '.'));

    let payload = this.removeNullProperties(this.form.value)

    delete payload.uuid;



    this.apiLotes.editTtansaction(payload, this.form.value.uuid, this.userProfile.numberAccountBank).subscribe(async(res:any)=>{


       if (res.errors){
         this.resError = res.errors
         const alert = await this.alertController.create({
           header: 'Atenção',
           message: `${this.resError[0]}`,
           buttons: ['Fechar'],
         });
    
         await alert.present();
       }else{
         this.resError = []
         this.closeModal()
       }
    })
  }

  saveTransaction(){
    
    this.form.value.typeTransaction = this.valueStatus[this.typeChosen -1]
    this.form.get('valueTransaction').setValue(this.form.value.valueTransaction.replace(',', '.'));
    this.form.get('ispb').setValue(10000);
    this.form.get('branch').setValue(1000);
    this.form.get('personType').setValue("NATURAL_PERSON");

    this.apiLotes.saveTransaction(this.form.value, this.uniqueBatchIdentifier).subscribe(async(res:any)=>{
      if (res.errors.length > 0){
        this.resError = res.errors
        const alert = await this.alertController.create({
          header: 'Atenção',
          message: `${this.resError[0]}`,
          buttons: ['Fechar'],
        });
    
        await alert.present();
      }else{
        this.resError = []
        this.closeModal()
      }
    })
  }
}



 

