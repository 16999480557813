import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { ModalOperationNotificationComponent } from '../../../../modals/modal-operation-notification/modal-operation-notification.component';
import { ValidSmsUserPage } from '../../cash-out/valid-sms/valid-sms-user.page';
import { PermissionsService } from '../permissions.service';

@Component({
  selector: 'app-form-permissions',
  templateUrl: './form-permissions.page.html',
  styleUrls: ['./form-permissions.page.scss'],
})
export class FormPermissionsPage implements OnInit {
  public permissionsForm: FormGroup;
  public errorMessages: any;
  public userList: Array<any> = [];
  userProfile: UserInfoProfile;
  public countUsers = 0;
  public user: any = {};
  private codeAuthorization = '';
  protected nav;

  constructor(
    private _formBuilder: FormBuilder,
    private permissionService: PermissionsService,
    protected userInfoService: UserInfoService,
    private loadingService: LoadingService,
    private router: Router,
    public modalController: ModalController,
    private dataRoute: ActivatedRoute
  ) {
    this.nav = this.router.getCurrentNavigation();
  }

  ngOnInit() {
    this.permissionsForm = this._formBuilder.group({
      userId: '',

      fullName: '',
      phone: '',
      email: '',

      tedAccess: false,
      tedApproval: false,
      tedLimit: 0,
      tedLimitAccess: 0,

      pixAccess: false,
      pixApproval: false,
      pixLimit: 0,
      pixLimitAccess: 0,

      billPaymentAccess: false,
      billPaymentApproval: false,
      billPaymentLimit: 0,
      billPaymentLimitAccess: 0,

      transferAccess: false,
      transferApproval: false,
      transferLimit: 0,
      transferLimitAccess: 0,

      viewBalanceAccess: false,
      manageUsersAccess: false,
      chargeAccountAccess: false,
    });
  }

  ionViewWillEnter() {
    this.loadUser();
  }

  loadUser() {
    this.userInfoService
      .getInfoProfile()
      .then((user) => {
        this.userProfile = user;
        this.dataRoute.queryParams.subscribe(async ({ userId }) => {
          if (userId) {
            await this.loadingService.show();
            try {
              this.user = (await this.permissionService.getUserPermissions(this.userProfile, userId))[0];
              this.parseUserState();
            } finally {
              await this.loadingService.hide();
            }
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async handleSubmit() {
    const modalToken = await this.modalController.create({
      component: ValidSmsUserPage,
      cssClass: 'modal-resume',
    });

    modalToken.onDidDismiss().then((data) => {
      if (data.data[0].validToken === true) {
        this.codeAuthorization = data.data[0].token;
        this.submit();
      }
    });

    return await modalToken.present();
  }

  async submit() {
    await this.loadingService.show();
    try {
      const parsedPayload = {
        userId: this.fv.userId,
        name: this.fv.fullName,
        phone: this.fv.phone,
        email: this.fv.email,
        permissions: this.parsePermissions(),
        codeAuthorization: this.codeAuthorization,
      };

      let response;

      response = this.fv.userId
        ? await this.permissionService.updateUser(parsedPayload as any)
        : await this.permissionService.createUser(parsedPayload as any);

      if (response.error) {
        this.statusTransaction('failed', response.error.message, false);
      } else if (response._id) {
        this.router.navigateByUrl('/app/permissions');
      }
    } finally {
      await this.loadingService.hide();
    }
  }

  public async statusTransaction(status: string, message: string, translateFlag: boolean) {
    this.loadingService.hide();
    const translate = translateFlag ? true : false;
    const modalSuccess = await this.modalController.create({
      component: ModalOperationNotificationComponent,
      cssClass: 'modal-operation-notification',
      componentProps: {
        status,
        message,
        translate,
      },
      backdropDismiss: false,
    });
    return await modalSuccess.present();
  }

  parsePermissions() {
    const payload = this.fv;
    const permissions = [];

    if (payload.tedAccess) {
      permissions.push({
        permission: 'tedAccess',
        params: {
          maxAmount: this.parseAmount(payload.tedLimitAccess),
        },
      });
    }

    if (payload.tedApproval) {
      permissions.push({
        permission: 'tedApproval',
        params: {
          maxAmount: this.parseAmount(payload.tedLimit),
        },
      });
    }

    if (payload.pixAccess) {
      permissions.push({
        permission: 'pixAccess',
        params: {
          maxAmount: this.parseAmount(payload.pixLimitAccess),
        },
      });
    }

    if (payload.pixApproval) {
      permissions.push({
        permission: 'pixApproval',
        params: {
          maxAmount: this.parseAmount(payload.pixLimit),
        },
      });
    }

    if (payload.billPaymentAccess) {
      permissions.push({
        permission: 'billPaymentAccess',
        params: {
          maxAmount: this.parseAmount(payload.billPaymentLimitAccess),
        },
      });
    }

    if (payload.billPaymentApproval) {
      permissions.push({
        permission: 'billPaymentApproval',
        params: {
          maxAmount: this.parseAmount(payload.billPaymentLimit),
        },
      });
    }

    if (payload.transferAccess) {
      permissions.push({
        permission: 'transferAccess',
        params: {
          maxAmount: this.parseAmount(payload.transferLimitAccess),
        },
      });
    }

    if (payload.transferApproval) {
      permissions.push({
        permission: 'transferApproval',
        params: {
          maxAmount: this.parseAmount(payload.transferLimit),
        },
      });
    }

    if (payload.viewBalanceAccess) {
      permissions.push({
        permission: 'viewBalanceAccess',
      });
    }

    if (payload.manageUsersAccess) {
      permissions.push({
        permission: 'manageUsersAccess',
      });
    }

    if (payload.chargeAccountAccess) {
      permissions.push({
        permission: 'chargeAccountAccess',
      });
    }

    return permissions;
  }

  formatPhone(numero){
    const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
    var numero = numero.replace(/[^0-9]/g, "").slice(0, 11);
  return numero.replace(regex, "($1)$2-$3")
  }

  parseUserState() {
    if (this.user.userId) {
      this.permissionsForm.controls.userId.setValue(this.user.userId);
    }

    this.permissionsForm.controls.fullName.setValue(this.user.fullName);
    this.permissionsForm.controls.phone.setValue(this.formatPhone(this.user.phone));
    this.permissionsForm.controls.email.setValue(this.user.email);

    const parsedPermissions = this.user.permissions.reduce((acc, cur) => {
      if (!acc[cur.permission]) {
        acc[cur.permission] = cur;
      }
      return acc;
    }, {});

    if (parsedPermissions.tedAccess) {
      this.permissionsForm.controls.tedAccess.setValue(true);
            this.permissionsForm.controls.tedLimitAccess.setValue(
        this.formatAmount(parsedPermissions.tedAccess.params.maxAmount)
      );
    }

    if (parsedPermissions.tedApproval) {
      this.permissionsForm.controls.tedApproval.setValue(true);
      this.permissionsForm.controls.tedLimit.setValue(
        this.formatAmount(parsedPermissions.tedApproval.params.maxAmount)
      );
    }

    if (parsedPermissions.pixAccess) {
      this.permissionsForm.controls.pixAccess.setValue(true);
      this.permissionsForm.controls.pixLimitAccess.setValue(
        this.formatAmount(parsedPermissions.pixAccess.params.maxAmount)
      );
    }

    if (parsedPermissions.pixApproval) {
      this.permissionsForm.controls.pixApproval.setValue(true);
      this.permissionsForm.controls.pixLimit.setValue(
        this.formatAmount(parsedPermissions.pixApproval.params.maxAmount)
      );
    }

    if (parsedPermissions.billPaymentAccess) {
      this.permissionsForm.controls.billPaymentAccess.setValue(true);
      this.permissionsForm.controls.billPaymentLimitAccess.setValue(
        this.formatAmount(parsedPermissions.billPaymentAccess.params.maxAmount)
      );
    }

    if (parsedPermissions.billPaymentApproval) {
      this.permissionsForm.controls.billPaymentApproval.setValue(true);
      this.permissionsForm.controls.billPaymentLimit.setValue(
        this.formatAmount(parsedPermissions.billPaymentApproval.params.maxAmount)
      );
    }

    if (parsedPermissions.transferAccess) {
      this.permissionsForm.controls.transferAccess.setValue(true);
      this.permissionsForm.controls.transferLimitAccess.setValue(
        this.formatAmount(parsedPermissions.transferAccess.params.maxAmount)
      );
    }

    if (parsedPermissions.transferApproval) {
      this.permissionsForm.controls.transferApproval.setValue(true);
      this.permissionsForm.controls.transferLimit.setValue(
        this.formatAmount(parsedPermissions.transferApproval.params.maxAmount)
      );
    }

    if (parsedPermissions.viewBalanceAccess) {
      this.permissionsForm.controls.viewBalanceAccess.setValue(true);
    }

    if (parsedPermissions.manageUsersAccess) {
      this.permissionsForm.controls.manageUsersAccess.setValue(true);
    }

    if (parsedPermissions.chargeAccountAccess) {
      this.permissionsForm.controls.chargeAccountAccess.setValue(true);
    }
  }

  setField(field, value) {
    this.permissionsForm.controls[field].setValue(value);
  }

  formatAmount(int) {
    let numeroFormatado = int.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).replace(/^(\D+)/, '');
        return numeroFormatado
  }

  parseAmount(val) {
    return parseFloat(val.toString().replace('.', '').replace(',', '.')) || 0;
  }

  checkPermissionForm(){
    if (
      this.permissionsForm.value.tedAccess === true || 
      this.permissionsForm.value.pixAccess === true || 
      this.permissionsForm.value.billPaymentAccess === true || 
      this.permissionsForm.value.transferAccess === true || 
      this.permissionsForm.value.viewBalanceAccess === true || 
      this.permissionsForm.value.chargeAccountAccess === true
    ) {
      return true
    }
  }

  goBack() {
    this.router.navigateByUrl('/app/permissions');
  }

  get fv() {
    return this.permissionsForm.getRawValue();
  }
}
