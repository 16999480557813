import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'manage-keys',
    pathMatch: 'full',
  },
  {
    path: 'devolution',
    loadChildren: () => import('./devolution/devolution.module').then((m) => m.DevolutionModule),
  },
  {
    path: 'pre-register',
    loadChildren: () => import('./pre-register/pre-register.module').then((m) => m.PreRegisterModule),
  },
  {
    path: 'manage-keys',
    loadChildren: () => import('./manage-keys/manage-keys.module').then((m) => m.ManageKeysPageModule),
  },
  {
    path: 'register-key',
    loadChildren: () => import('./register-key/register-key.module').then((m) => m.RegisterKeyPageModule),
  },
  {
    path: 'code-validation',
    loadChildren: () =>
      import('./portability-keys/code-validation/code-validation.module').then((m) => m.CodeValidationPageModule),
  },
  {
    path: 'claim-keys',
    loadChildren: () => import('./claim-keys/claim-keys.module').then((m) => m.ClaimKeysPageModule),
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then((m) => m.PaymentPageModule),
  },
  {
    path: 'addressing-key-pix',
    loadChildren: () => import('./addressing-key-pix/addressing-key-pix.module').then((m) => m.AddressingKeyPixModule),
  },
  {
    path: 'manual-transfer',
    loadChildren: () => import('./manual-transfer/manual-transfer.module').then((m) => m.ManualTransferPageModule),
  },
  {
    path: 'qr-code',
    loadChildren: () => import('./qr-code/qr-code.module').then((m) => m.QrCodePageModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PixRoutingModule {}
