import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { CreatePasswordService } from '@wlp/ui-bs-device-validation';

@Component({
  selector: 'ui-t-create-password',
  templateUrl: './create-password.page.html',
  styleUrls: ['./create-password.page.scss'],
})
export class CreatePasswordPage {
  brand: string;
  errorMessages: any;
  formCreatePassword: FormGroup;
  navigationBackground: string;
  constructor(
    private createPasswordService: CreatePasswordService,
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService
  ) {
    this.formCreatePassword = this.createPasswordService.getForm();
    this.errorMessages = this.createPasswordService.getErrorFormMessage();
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
  }

  continue() {
    this.createPasswordService.createPassword('comercial-agent').subscribe(
      (res) => this.navCtrl.navigateRoot('comercial-agent'),
      (err) => this.createPasswordService.getErrorPassword(err)
    );
  }

  goBack() {
    this.navCtrl.navigateForward('personal-address');
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }
}
