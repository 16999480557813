import { TransactionType } from './../../../../../../core/layout/models/transfer-limit.model';
import { UserInfoService } from '@wlp/ui-bs-login';
import { TransferLimitService } from '@wlp/ui-bs-transfer-limit';
import { LoadingController, ModalController, NavController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-limit-management',
  templateUrl: './limit-management.page.html',
  styleUrls: ['./limit-management.page.scss'],
})
export class LimitManagement implements OnInit {

  public userProfile: any;

  public transferSegment = "transferencia";

  public TransactionTypePIX: TransactionType[] = [];
  public filteredTransactionTypePIX: TransactionType[] = [];

  constructor(
    public navCtrl: NavController,
    public modalController: ModalController,
    private loadingCtrl: LoadingController,
    protected userInfoService: UserInfoService,
    private transferLimitService: TransferLimitService
  ) { }

  async ngOnInit() {
    await this.loadUser();
    //this.GetTransactionTypesPix();
  }

  public loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then(async (user) => {
        /*console.log("USER PROFILE: ", user)  */
        this.userProfile = user;
        console.log('Profile TRANSFER', this.userProfile)
      });
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }

  /*
  GetTransactionTypesPix() {
    this.transferLimitService.getType('transaction-type').subscribe(
      async (response: TransactionType[]) => {
        console.log("RESPONSE: ", response)
        this.TransactionTypePIX = await response.filter(i => {
          return i.displayName == "PIX";
        });
       // await this.filterTransactionTypesPix();
       // await this.getByTypeData();
      });
  }
  */

  async changeSegment(segment) {
    this.transferSegment = segment
  }

}