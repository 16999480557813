import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { NotificationsController } from '../../notifications/notifications.controller';

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss'],
})
export class ModalAlertComponent {
  constructor(public modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss().then(
      (res) => {},
      (err) => {
        // console.error(err);
      }
    );
  }

  confirm() {
    NotificationsController.requestNotification()
      .then((res) => this.dismiss())
      .catch((err) => {
        // console.warn(err)
      });
  }
}
