import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { environment } from 'src/environments/environment';

import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { ComercialAgentService } from '@wlp/ui-bs-signup';

@Component({
  selector: 'comercial-agent-ec',
  templateUrl: './comercial-agent-ec.page.html',
  styleUrls: ['./comercial-agent-ec.page.scss'],
})
export class ComercialAgentEcPage {
  formComercialAgent: FormGroup;
  errorMessages: any;
  param: object;

  constructor(
    private navCtrl: NavController,
    private comercialAgentService: ComercialAgentService,
    private layoutConfigService: LayoutConfigService
  ) {
    this.errorMessages = this.comercialAgentService.getMessageError();
    this.formComercialAgent = this.comercialAgentService.getFormComercialAgent();
    this.comercialAgentService.updateForm();
    this.param = { wlName: this.layoutConfigService.getConfig('wlTheme.wlName') };
  }

  continue(): void {
    // this.comercialAgentService
    //   .sendComercialAgentData(this.formComercialAgent)
    //   .subscribe(
    //     data =>
    this.navCtrl.navigateRoot('/app/onboarding-ec/contract-ec');
    //   error => this.comercialAgentService.getErrorComercialAgentData(error)
    // );
  }
}
