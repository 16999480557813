import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import {
  AccountPaymentTypeResponse,
  AccountTypeService,
  CreateAccountService,
  IPageBase,
  PreAccountResponse,
  QuizService,
} from '@wlp/ui-bs-signup';

import { environment } from '../../../../../../../../environments/environment';
import { ModalFlowComponent } from '../../../../modals/modal-flow/modal-flow.component';
import { Storage } from '@ionic/storage';
import Swal from 'sweetalert2'
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'email',
  templateUrl: './email.page.html',
  styleUrls: ['./email.page.scss'],
})
export class EmailPage implements IPageBase, OnInit {
  formCreateAccount: FormGroup;
  errorMessages: any;
  brand: string;
  navigationBackground: string;
  param: object;
  envWlp: any;
  public listAccountType: AccountPaymentTypeResponse[];
  public amountDailyLimit: number;
  public amountThirtyLimit: number;
  public valueDailyLimit: number;
  public valueThirtyLimit: number;

  constructor(
    private navCtrl: NavController,
    public alertController: AlertController,
    private modalController: ModalController,
    public layoutConfigService: LayoutConfigService,
    private createAccountService: CreateAccountService,
    private accountTypeService: AccountTypeService,
    private storage: Storage,
    private quizService: QuizService,
  ) {
    this.errorMessages = this.createAccountService.getFormMessageError();
    this.formCreateAccount = this.createAccountService.createForm();
    this.createAccountService.updateForm();
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
    this.param = { wlName: this.layoutConfigService.getConfig('wlTheme.wlName') };
    this.envWlp = environment;
  }

  ngOnInit() {
    this.loadAccountType();
  }

  ionViewWillEnter() {
    this.formCreateAccount.reset();
  }

  async continue(route?: string): Promise<void> {

    const emailValue = this.formCreateAccount.get('email').value;
    sessionStorage.setItem('emailValue', emailValue)

    this.createAccountService.verifyEmailByIdwl(emailValue, environment.wlp).subscribe(async (resp: any) => {
      if (resp && resp.length > 0) {
          this.warningEmail();
          this.formCreateAccount.get('email').setErrors({ required: true });
          this.formCreateAccount.get('emailConfirm').setErrors({ mustMatch: true });
        } else {
          this.attNamePerson();
        }
    });
  }

  async attNamePerson() {

    let uuidWhiteLabel: any
    let email
    let onboardingId
    let cpf

    if(sessionStorage.getItem('uuidWhiteLabel') !== null){
      uuidWhiteLabel = sessionStorage.getItem('uuidWhiteLabel')
    }

    email = sessionStorage.getItem('emailValue')
    cpf = sessionStorage.getItem('cpfValue')
    onboardingId = sessionStorage.getItem('keyForm')

    let postDataPreAccount = {
      "_id": onboardingId,
      "uuidWhiteLabel": uuidWhiteLabel,
      "cpf": cpf, 
      "email": email,
    }

    this.quizService.attPreAccount(onboardingId, postDataPreAccount)
    .pipe(
      tap((res) => {
        this.navCtrl.navigateRoot('account-type')
      }),
      catchError((err) => {
        //console.log('Erro', err)
        return of(null);
      })
    )
    .subscribe();
    
  }

  goBack() {
    this.navCtrl.back();
  }

  checkCpf(data: any) {
    this.createAccountService.checkAccountExists(environment.wlp, data.target.value).subscribe(async (resp) => {
      sessionStorage.setItem('accounts', JSON.stringify(resp))
      if (resp && resp.length > 0) {
        if (resp.length === 1 && resp[0].status === 'REPROVED') {
          this.createAccountService.processPreAccount(resp[0]);
          this.navCtrl.navigateRoot('status-account');
        } else {
          await this.modalFlow(resp);
        }
      }
    });
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }

  checkValues() {
    let emailValue = this.formCreateAccount.get('email').value;
    let emailConfirm = this.formCreateAccount.get('emailConfirm').value
    
    if(emailValue === emailConfirm && emailValue){
      return false
    } else {
      return true
    }
   
  }

  async modalFlow(response: any) {
    const modal = await this.modalController.create({
      component: ModalFlowComponent,
      cssClass: 'modal-flow-container',
      componentProps: { response },
      backdropDismiss: false,
    });
    await modal.present();
  }

  public getImageTag(tag: string) {
    const item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }

  private loadAccountType(): void {
    this.accountTypeService
      .loadTypeAccount(environment.wlp)
      .then((list) => (this.listAccountType = list))
      .catch((err) => this.accountTypeService.getErrorMessages(err));
  }

  async warningEmail() {
    const alert = await this.alertController.create({
      cssClass: 'alert-warning-email',
      header: 'Atenção',
      message: 'Esse email já foi associado com outra conta, por favor, informe outro email válido.',
      buttons: ['OK'],
      backdropDismiss: false,
    });

    await alert.present();
  }
}
