import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EditCardPage } from './edit-card/edit-card-page.component';
import { ManagerCardsPage } from './manager-cards/manager-cards-page.component';
import { TransactionsPage } from './transactions/transactions.page';

const routes: Routes = [
  {
    path: '',
    component: TransactionsPage
  },
  {
    path: 'edit-cards',
    component: ManagerCardsPage
  },
  {
    path: 'add-cards',
    component: EditCardPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class WalletRoutingModule { }
