import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import * as moment from 'moment';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { FormImportFilesModalPage } from '../../../../modals/import-files-form-modal/import-files-form-modal.page';
import { ImportFilesService } from '../import-files.service';
import { ApiImportFilesLote } from '../import-files-lotes.service';
import { ModalComponent } from '../modal/modal.component';
import { AlertController, NavController } from '@ionic/angular';
import { AccountPaymentRechargeBilletResponse, AccountRechargeService } from '@wlp/ui-bs-cashin';
import { DataService } from '../shared';

import { ActivatedRoute, Router } from '@angular/router';


import { Subscription } from 'rxjs';

@Component({
  selector: 'ui-t-import-files-lote',
  templateUrl: './import-files-lote.page.html',
  styleUrls: ['./import-files-lote.page.scss'],
})
export class ImportFilesLotePage implements OnInit {
  
  public formTransfer: FormGroup;
  public errorMessages: any;
  public filesList: Array<any> = [];
  public filteredFilesList: Array<any> = [];
  userProfile: UserInfoProfile;
  public countUsers = 0;
  public showModal = false;
  protected nav;
  public sizeFile: number = 5242880;
  fileName = '';
  public filterInitialDate: Date;
  public filterEndlDate: Date;
  fileVersion:any;
  status:any;
  error: string;
  dragAreaClass: string;
  draggedFiles: any;
  statusValue:any [] = []
  typeStatus: any = null;
  batchType: String;

   allFiles: File[] = [];
   fileChosen:any[] = [];
   files: any[] = [];
   filesOrigin: any[] = [];
   filesError: any[] = [];
   values:any [] = []
   showModalBox: boolean = true;
   public listReceipts: Array<AccountPaymentRechargeBilletResponse>;
   public listReceiptsShow: Array<AccountPaymentRechargeBilletResponse>;
   public scroillTimes: number;

   public currentPage: number;
  public dataPerPage: number;
   public maxData: number;
   pageCount: any;
   dataEnd:String  | undefined;
   dataStart:String='';
   @Input()
   showDialog : boolean;
   title:String;

   message: string;


  private subscription: Subscription;
   


  constructor(
    public apiLotes: ApiImportFilesLote,
    private importFilesService: ImportFilesService,
    protected userInfoService: UserInfoService,
    private loadingService: LoadingService,
    private router: ActivatedRoute ,
    private route: Router,
    private modalController: ModalController,
    public alertController: AlertController,
    private dataService: DataService
  
  ) {
    this.subscription = this.dataService.message$.subscribe(message => {
      this.message = message;
      console.log(this.message)
      this.loadUser()
    });
    this.listReceipts = [];
    this.listReceiptsShow = [];


    this.currentPage = 1;
    this.dataPerPage = 10;
    this.maxData = 0;


    this.filterInitialDate = null;
    this.filterEndlDate= null;

    

    moment.locale('pt-br');
  }
  

  async ngOnInit() {
  

    this.batchType = this.router.snapshot.params.batchType;

    this.title = this.getTitle(this.batchType)

 
    // try {
      this.loadUser();

      let status = [{value: "", name: "Nenhum"},
    {value: "INCONSISTENT", name: "Inconsistências"},
    {value: "CONSISTENT", name: "Pronto para processar"},
    {value: "WAITING_PROCESSING", name: "Aguardando processamento"},
    {value: "PROCESSING", name: "Em processamento"},
    {value: "PROCESSED_SPREADSHEET", name: "Processado"}]
    
      this.statusValue = status

      if(this.batchType == 'payment'){
        this.values = [
              {id: 3,label: 'Pix (Conta Bancária)', type: 'radio', value: 'PIX_PAYMENT', checked: false},
              {id: 4,label: 'Pix (Chave)', type: 'radio', value: 'PIX_PAYMENT_INITIATE', checked: false},
              {id: 5,label: 'Recarga de Celular', type: 'radio', value: 'MOBILE_RECHARGE', checked: false},
        ]

      }else if(this.batchType == 'transfer'){
        this.values = [
          {id: 1,label: 'TED', type: 'radio', value: 'TED_EXTERNAL', checked: false},
          {id: 2,label: 'TEF (OnUs)', type: 'radio', value: 'TED_INTERNAL', checked: false},
        ]

      }else if(this.batchType == 'charge'){
        this.values = [
          {id: 6,label: 'Boleto de Cobrança', type: 'radio', value: 'BILLET', checked: false},
          {id: 7,label: 'Pix Cobrança', type: 'radio', value: 'PIX_CHARGE', checked: false},
        ]

      }
   
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
 

  getTitle(batch){
    if(batch == "payment"){
      return "Pagamento em lote"
    }else if(batch == "transfer"){
      return "Transferência em lote"
    }else if(batch == "charge"){
      return "Cobrança em lote"
    } 

  }




  getLotePage(page){
    const filesTypes = this.values.map(item => item.value).join(',');
    this.apiLotes.getLoteList(this.userProfile.uuidAccount,filesTypes, page).subscribe((res)=>{


 
      this.files = res.data



      this.maxData = res.meta.itemCount
      this.currentPage = res.meta.page
      this.pageCount= res.meta.pageCount

      this.filesOrigin = res.data

    })
  }


  onSearchChange(searchValue: string): void {  
 
    this.files = this.filesOrigin
    if(searchValue != ''){
      this.files = this.files.filter((res) => res.fileVersion.indexOf(searchValue) != -1)
 
    }
   

  }

  async downloadLote(uniqueBatchIdentifier): Promise<void> {
    try {
      const csvContent:any = await this.apiLotes.downloadLote(uniqueBatchIdentifier, this.userProfile.numberAccountBank).toPromise();
  

        const blob = new Blob([csvContent], { type: 'text/csv' });
  
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'batch.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
     
    } catch (error) {
      console.error('Erro ao baixar o CSV:', error);
    }
  }

  sendPage(data){

    this.route.navigateByUrl(`/app/batch/payment/detail/${data.uniqueBatchIdentifier}/${this.batchType}`);
  }


  reloadLote(){


    this.dataStart == null
    this.dataEnd == null



    const filesTypes = this.values.map(item => item.value).join(',');
    

    this.apiLotes.getLoteList(this.userProfile.uuidAccount, filesTypes).subscribe((res)=>{

  
      this.files = res.data

      this.maxData = res.meta.itemCount
      this.currentPage = res.meta.page
      this.pageCount= res.meta.pageCount
      this.filesOrigin = res.data

    })
  }

  open() {

       this.showModalBox = true;
    

  }


  

  onFileDropped($event) {
    this.prepareFilesList($event);


  }

  async downloadCSV() {
    
    // await this.loadingService.show();
    // let dataFormatada = ((this.today.getDate())) + "-" + ((this.today.getMonth() + 1)) + "-" + this.today.getFullYear();

    // const a = document.createElement('a');
    // const blob = new Blob([this.csvPreview], { type: 'text/csv' });
    // const url = window.URL.createObjectURL(blob);

    // a.href = url;
    // a.download = `extrato_${(this.userProfile.nameFirst as string).toLowerCase()}_${dataFormatada}.csv`;
    // a.click();
    // window.URL.revokeObjectURL(url);
    // a.remove();
    // await this.loadingService.hide();
    // await this.dismiss();
  }

  async dismiss() {
    await this.modalController.dismiss();
  }

  deleteLote(lote){

    this.apiLotes.deleteLote(lote.uniqueBatchIdentifier).subscribe( async result => {


      const alert =  await this.alertController.create({
        header: `${result.message} `,
        buttons: ['OK'],
      });
  
      (await alert).present();
      await this.reloadLote()

    },
    error => {
     alert("algo deu errado, consulte o administrador!")
    },)
  
  }

  async alertDeleteLote(lote) {
    const alert = await this.alertController.create({
      header: 'Atenção',
      message: `Deseja realmente excluir esse lote?`,
      buttons: [
        {
          text: 'Não',
          role: 'cancelar',
          handler: () => {
            return null
          },
        },
        {
          text: 'Sim',
          role: 'confirmar',
          handler: () => {
            this.deleteLote(lote)
          },
        },
      ],
    });

    await alert.present();
  }

  deleteUser(){

  }

    async presentAlert() {

      const modal = await this.modalController.create({
        component: ModalComponent,
        cssClass: 'modal-custom-class',
        backdropDismiss: false,
        componentProps: {
          type: 2, 
          file: this.fileChosen,
          numberAccount: this.userProfile.numberAccountBank,
          functionChild: this.reloadLote(),
          batchType: this.batchType,
          uniqueBatchIdentifier: this.userProfile
        }
     
      });

      
  
      modal.onDidDismiss().then((event) => {

        if (event.data.success === true) {
          this.reloadLote()
        }
      });
      this.reloadLote()
      return await modal.present();

    
    

      // const alert = await this.alertController.create({
      //   header: 'Escolha o tipo de documento',
      //   buttons: ['OK'],
      //   inputs: this.values
      // });
  
      // await alert.present();

      // const result = await alert.onDidDismiss();

      // let prompt = await this.alertController.create({
      //   header: 'Digite o nome do lote',
      //   message: '',
      //   inputs: [ 
      //     {
      //       name: 'lote',
      //       placeholder: 'lote'
      //     },
      //   ],
      //   buttons: [
      //     {
      //       text: 'Save',
      //       handler: data => {

      //                     if (data.value == '') {
                            
      //                         return false;
      //                     } else {
                     
      //                     }
      //                 }
      //     }
      //   ]
      // });

      // await prompt.present();
      // const results = await prompt.onDidDismiss();

      // this.apiLotes.postFile(result, this.fileChosen, this.userProfile.numberAccountBank ).subscribe((res)=>{
      //   this.reloadLote()
      //   console.log(res)
      // })


    
  }



 

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.filesError = []
    this.fileChosen = []
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  async uploadFilesSimulator(index: number) {
   
    if(this.filesError.length >0 ){
      const alert = await this.alertController.create({
        cssClass: 'account-not-found',
        header: 'Error',
        subHeader: 'O documento não pode ser importado!',
        message: `Desculpe, este arquivo não é compatível com essa funcionalidade. Por favor, revise e tente novamente..`,
        buttons: ['OK'],
      });

      await alert.present();
    }else{
      this.presentAlert()
    }

  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
 async prepareFilesList(filesAll: Array<any>) {

    if(filesAll.length == 1){
      for (const item of filesAll) {
        item.progress = 0;
        item.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'?this.fileChosen.push(item) : this.filesError.push(item)
      }
      this.uploadFilesSimulator(0);
    }else{
      const alert = await this.alertController.create({
        cssClass: 'account-not-found',
        header: 'Atenção',
        subHeader: 'O documento não pode ser importado!',
        message: `Lamentamos, apenas um arquivo é permitido por vez. Por favor, verifique e tente novamente.`,
        buttons: ['OK'],
      });
      await alert.present();
    }


  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }







  async modalCardNotification() {
    const modal = await this.modalController.create({
      component: ModalComponent,
      cssClass: 'modal-custom-class',
      backdropDismiss: false,
      componentProps: {
        batchType: this.batchType, 
        type: 1,
      }
   
    });

    modal.onDidDismiss().then((event) => {
      if (event.data.success === true) {
        this.refresh();
      }
    });
    return await modal.present();
  }

  modalCardNotificatio(){
    this.modalCardNotification();
  }


 async searchLote(){


     if((this.dataStart != null && this.dataEnd == null)||(this.dataStart != null && this.dataEnd == null)){
       const alert = await this.alertController.create({
         header: `Por favor, preencher todos os campos de data!`,
         buttons: ['OK'],
       });
  
       (await alert).present();
     }else{
     
   
       if(this.dataStart != null && this.dataEnd != null && this.dataStart > this.dataEnd) {
         const alert = await this.alertController.create({
           header: `Por favor, preencher data inicial menor que data final!`,
           buttons: ['OK'],
         });
    
         (await alert).present();
       }else{
       


  this.apiLotes.getLoteDate(this.userProfile.uuidAccount, this.dataStart, this.dataEnd, this.status).subscribe((res:any)=>{
         this.files = res.data
         this.maxData = res.meta.itemCount
       this.currentPage = res.meta.page
       this.pageCount= res.meta.pageCount
       this.filesOrigin = res.data

       })

       }

   
     
     }
  }




  // ionViewWillEnter() {
  //   this.loadUser();
  // }

   onSelectedStart(dateSelected: moment.Moment = null) {

    this.dataStart = dateSelected != null ?  dateSelected.toDate().toISOString().substring(0, 10) : null
    

   }

   onSelectedEnd(dateSelected: moment.Moment = null){
  
    this.dataEnd = dateSelected!= null ? dateSelected.toDate().toISOString().substring(0, 10) : null

   }
  public filterReceipt(value) {
  }

   loadUser() {

     this.userInfoService
       .getInfoProfile()
       .then((user) => {
         this.userProfile = user;
  
         this.reloadLote()
//      
  
       })
       .catch((error) => {
         alert(error);
       });
   }

 
  onFileSelected(event) {

    const file:File = event.target.files[0];

    if (file) {

        this.fileName = file.name;

        const formData = new FormData();

        formData.append("thumbnail", file);



        // const upload$ = this.http.post("/api/thumbnail-upload", formData);

        // upload$.subscribe();
    }
}

handleDismiss(e) {

}

  async handleChange(e) {


  if (e.detail.value == 'CLEAR'){

    this.typeStatus = null

  }
  else{
    this.typeStatus = e.detail.value


  }
 

}

  async refresh() {
    if (!this.loadingService.isLoading) {
      await this.loadingService.show();
    }
    const filter = {};
    const enumStatus = {
      IN_PROGRESS: 'Em Processamento',
      PROCESSING_SUCCESSFUL: 'Processado com Sucesso',
      PROCESSING_WITH_ERRORS: 'Processado com Erros',
      INVALID_FILE: 'Arquivo Inválido',
    };
    try {
      const res = await this.importFilesService.getImportFiles(this.userProfile, filter);

      this.filesList = res
        .map((it) => ({
          ...it,
          parsedStatus: enumStatus[it.status],
          isValid: it.status !== 'INVALID_FILE',
        }))
        .sort((a, b) => new Date(b.dateUpdated).getTime() - new Date(a.dateUpdated).getTime());
      this.filteredFilesList = [{
        name: 'teste',
        type: 'teste',
        dataSend :'20/10/2023',
        dataProcess :'21/10/2023',
        value:'45.20',
        quant: 5
      }]
      this.countUsers = this.filesList.length;
    } finally {
      await this.loadingService.hide();
    }
  }

  async uploadFile() {
    this.handleShowModal();
  }


  filter(event) {
    const val = event.toLowerCase() || '';

    this.filteredFilesList = this.filesList.filter((d) => {
      return (
        `
        ${d.name}
        ${d.type}
        ${d.parsedStatus}
      `
          .toLowerCase()
          .indexOf(val) !== -1
      );
    });
  }

 
  public async handleShowModal() {
    const modal = await this.modalController.create({
      component: FormImportFilesModalPage,
      cssClass: 'modal-custom-class',
      backdropDismiss: false,
    });

    modal.onDidDismiss().then((event) => {
      if (event.data.success === true) {
        this.refresh();
      }
    });
    return await modal.present();
  }

  public dateControl(date: any): string {
    try {
      return `${moment(date).format('DD/MM/YYYY')}`;
    } catch (err) {
      return '';
    }
  }

  public getHourDate(date: any): string {
    try {
      return `${moment(date).format('HH:mm:ss')}`;
    } catch (err) {
      return '';
    }
  }
  
  infiniteScrollEvent(event: Event) {
    console.log(event);
  }

  chosen(id){


    const position = this.values.map(e => e.id).indexOf(id);

    // console.log(this.values[position].checked)
     this.values[position].checked == false ? (
      
      this.values[position].checked = true
     ):(
       this.values[position].checked = false 
     )


  }



 
 



  

}
