import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UserInfoService } from '@wlp/ui-bs-login';

@Injectable({
  providedIn: 'root'
})
export class ApiImportFilesLote {

  base_path = environment.apiUri;
  userProfile: UserInfoProfile;

  constructor(private http: HttpClient, protected userInfoService: UserInfoService,) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  };



  postFile(value ,fileToUpload, numberAccount, name): Observable<any> {

    console.log(value ,fileToUpload, numberAccount, name)

    const formData: FormData = new FormData();
    formData.append('file', fileToUpload[0]);
    formData.append('fileVersion', value.batchName);
    formData.append('groupName', value.statement);
    formData.append('template', name);

    const headers = { 'x-request-uac': numberAccount};



     return this.http
       .post<any>(this.base_path + '/spe-n-batch-transactions/v1/read-file/batch-payment', formData, { headers })
       .pipe(
         retry(2),
         catchError(this.handleError)
       )
  }

  getLoteList(number, filesTypes, page = 1):Observable<any>{
    return this.http.get<any>(this.base_path + `/spe-n-batch-transactions/v1/read-file/consult-processing/${number}?page=${page}&fileType=${filesTypes}&limit=10&sortOrder=desc&sortBy=dateRegister`).pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

  getLoteListPage(number, page = 1):Observable<any>{
    return this.http.get<any>(this.base_path + `/spe-n-batch-transactions/v1/read-file/consult-processing/${number}?page=${page}&limit=10&sortOrder=desc&sortBy=dateRegister`).pipe(
      retry(2),
      catchError(this.handleError)
    )
  }




  deleteLote(uniqueBatchIdentifier):Observable<any>{
    return this.http.delete(this.base_path + `/spe-n-batch-transactions/v1/post-processing/delete-by-unique-batch-identifier/${uniqueBatchIdentifier}`)
  }

  getTransferDate(number, dataInitial, dataEnd, status){


    let createResponse: String = ''
    if(dataInitial != null && dataEnd != null){
      createResponse =  createResponse + `&startDate=${dataInitial}&endDate=${dataEnd}`
    }
    if(status != undefined && status != 'Nenhum' ){
      createResponse = createResponse + `&status=${status}`
    }
  
   
        return this.http.get(this.base_path + `/spe-n-batch-transactions/v1/read-file/consult-batch-transactions/${number}?page=1&limit=10${createResponse}`)
    
  }



  getLoteDate(number, dataInitial, dataEnd, status){

    let createResponse: String = ''
    if(dataInitial != null && dataEnd != null){
      createResponse =  createResponse + `&startDate=${dataInitial}&endDate=${dataEnd}`
    }
    if(status != undefined){
      createResponse = createResponse + `&status=${status}`
    }


  
 

      return this.http.get(this.base_path + `/spe-n-batch-transactions/v1/read-file/consult-processing/${number}?page=1&limit=10${createResponse}`)

  }

  getLoteFileVersion(file, page = 1, limit = 10){
    return this.http.get(this.base_path + `/spe-n-batch-transactions/v1/read-file/consult-batch-transactions/${file}?page=${page}&limit=${limit}`)
  }


  getStatusLote(number, uniqueBatchIdentifier){
    return this.http.get(this.base_path + `/spe-n-batch-transactions/v1/read-file/consult-processing/${number}?uniqueBatchIdentifier=${uniqueBatchIdentifier}`)
  }

  putAgroupLote(fileVersion, uuid){
    const headers = {
      "uuids": [
          {
              "uuid": uuid
          }
      ],
      "fileVersion": fileVersion
  }
    return this.http.put(this.base_path + '/spe-n-batch-transactions/v1/post-processing/group-an-item-individually',  headers )
  }

  putUngroupLote(fileVersion, uuid){

     const headers = {
       "uuids": [
           {
               "uuid": uuid
           }
       ],
       "fileVersion": fileVersion
   }
    return this.http.put(this.base_path + '/spe-n-batch-transactions/v1/post-processing/ungroup-an-item-individually',  headers )
  }

  postProcess(codeAuthorization, fileVersion, numberAccount){
    const headers = { 'x-request-uac': numberAccount};
  
    return this.http.post(this.base_path + `/spe-n-batch-transactions/v1/processing-payment/${fileVersion}/code/${codeAuthorization}`, null, {headers} )
    // return this.http.post(this.base_path + `/spe-n-batch-transactions/v1/processing-payment/${fileVersion}/code/${codeAuthorization}`)
  }

  editTtansaction(data, uuid, numberAccount){

    const headers = { 'x-request-uac': numberAccount};

    return this.http.put(this.base_path + `/spe-n-batch-transactions/v1/post-processing/update-transaction/${uuid}`,  data, {headers}) 
  }
  saveTransaction(data, fileVersion){
    
     return this.http.post(this.base_path + `/spe-n-batch-transactions/v1/post-processing/add-transaction-to-processing/${fileVersion}`,  data) 
  }



  downloadLote(uniqueBatchIdentifier: string, numberAccount: any){
    const headers = new HttpHeaders().set('x-request-uac', numberAccount);
  
    return this.http.get(this.base_path + `/spe-n-batch-transactions/v1/download/file/${uniqueBatchIdentifier}`, {
      headers,
      responseType: 'text' as 'json'
    })
      .pipe(
        catchError(error => {
          console.error('Erro na solicitação da API:', error);
          throw error;
        })
      );
  }


  deleteTransaction(uuid){
    return this.http.delete(this.base_path + `/spe-n-batch-transactions/v1/post-processing/delete-transaction/${uuid}`)
  }

  

}