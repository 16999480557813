import { Component, OnInit } from '@angular/core';
import { OnboardingEcService } from './onboarding-ec.service';
import { OnboardingEcInterface } from './onboarding-ec.interface';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-onboarding-ec',
  templateUrl: './onboarding-ec.page.html',
  styleUrls: ['./onboarding-ec.page.scss'],
})
export class OnboardingEcPage implements OnInit {

  isCreate: boolean;
  titleData: OnboardingEcInterface;
  constructor(
    private OnboardingEcService: OnboardingEcService
  ) {

  }

  ngOnInit() {

    // 

    // this.OnboardingEcService.getTitleSubject().pipe(tap(data => {
    //   this.titleData = data;
    // })).subscribe();

  }
  getData(){
    this.titleData = this.OnboardingEcService.getTitleSubject();
    return this.titleData.isTitle;
  }

}
