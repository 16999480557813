import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AccountBalanceService } from '@wlp/ui-bs-cashin';
import { UICHeaderUserInfoService } from '@wlp/ui-c-header-user-info';
import * as   html2pdf from 'html2pdf.js';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UiBsMyDataService } from '@wlp/ui-bs-my-data';
import { log } from 'util';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


@Component({
  selector: 'app-modal-pdf-generate',
  templateUrl: './modal-pdf-generate.component.html',
  styleUrls: ['./modal-pdf-generate.component.scss'],
})
export class ModalPdfGenerateComponent implements OnInit {

  datePeriod: string;
  dataFile: string;

  @Input() listStatmentShow: Array<any>
  @Input() userProfile: any;
  @Input() printPeriodPdfShow: any;
  @Input() banknameShow: any;

  company: any;
  public balance: string;
  public balanceSubject$: any;
  private today = new Date();
  companyName:any;

  dayName = new Array(
    'domingo',
    'Segunda-Feira',
    'Terça-Feira',
    'Quarta-Feira',
    'Quinta-Feira',
    'Sexta-Feira',
    'sábado'
  );
  monName = new Array(
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'julho',
    'agosto',
    'setembro',
    'outubro',
    'novembro',
    'dezembro'
  );

  now = new Date();

  constructor(
    private myDataService: UiBsMyDataService,
    protected userInfoService: UserInfoService,
    private loadingService: LoadingService,
    private accountBalanceService: AccountBalanceService,
    private headerInfoService: UICHeaderUserInfoService,
    private modal: ModalController,
    private layoutConfigService: LayoutConfigService
    
    
  ) {
    this.company = this.layoutConfigService.getConfig('wlTheme.company');

    const userDetails = this.userInfoService.getInfoProfile();

    userDetails.then((user) => {        
      this.userProfile = user;
      this.myDataService
          .getPersonalData(this.userProfile.uuidAccount)
          .pipe(
            tap((data) => {
              this.companyName = data
            }),
          )
          .subscribe();

  }) 
  }

  ngOnInit() {
    this.balance = '';

    this.loadInitialData();
    this.loadValueUpdate();

    let hours;
    let minute;
    let seconds;

    if (this.now.getHours() < 10) {
      hours = '0' + this.now.getHours();
    } else {
      hours = this.now.getHours();
    }

    if (this.now.getMinutes() < 10) {
      minute = '0' + this.now.getMinutes();
    } else {
      minute = this.now.getMinutes();
    }

    if (this.now.getSeconds() < 10) {
      seconds = '0' + this.now.getSeconds();
    } else {
      seconds = this.now.getSeconds();
    }

    this.datePeriod =
    this.dayName[this.now.getDay()] +
    ', ' +
    this.now.getDate() +
    ' de ' +
    this.monName[this.now.getMonth()] +
    ' de ' +
    this.now.getFullYear() +
    ' às ' +
    hours +
    ':' +
    minute 
    // ':' 
    // seconds;

  this.dataFile =
    this.now.getFullYear().toString() + '_' + this.now.getMonth().toString() + '_' + this.now.getDate().toString();
    
  }

  loadInitialData(): void {
    this.headerInfoService.loadUser();
    this.accountBalanceService.getBalanceSubject().pipe(tap(data => {
      this.balanceSubject$ = String(data);
    })).subscribe();
  }

  loadValueUpdate(): void {
    this.headerInfoService.getValueObservable().subscribe(res => this.loadInitialData());
  }

  public dateControl(date: any): string {
    try {
      return `${moment(this.formattedDate(date)).format('DD/MM/YYYY')}`;
    } catch (err) {
      return '';
    }
  }

  /**
 * apresentacao da data na tabela
 * @param date
 */
  public getHourDate(date: any): string {
    try {
      return `${moment(this.formattedDate(date)).format('HH:mm')}`;
    } catch (err) {
      return '';
    }
  }

  public formattedDate(date) {
    if (date && typeof date === 'object' && date.constructor === Array) {
      if (date.length >= 6) {
        return new Date(date[0], (date[1] - 1), date[2], date[3], date[4], date[5]);
      } else if (date.length === 5) {
        return new Date(date[0], (date[1] - 1), date[2], date[3], date[4]);
      }
      return new Date(date[0], date[1], date[2]);
    }

    return date;
  }
  async generatePrint() {
    await this.loadingService.show();
  
    const data = new Date();
    const dataFormatada = `${data.getDate()}-${data.getMonth() + 1}-${data.getFullYear()}`;
  
    const element = document.getElementById('table-extract') as HTMLElement;
  
    if (!element) {
      console.error('Elemento não encontrado!');
      await this.loadingService.hide();
      return;
    }
  
    const styles = `
      <style>
        /* Estilos CSS para o documento de impressão */
        body {
          font-family: Arial, sans-serif;
          background-color: white;
          font-size: 12px; /* Tamanho da fonte reduzido para a impressão */
          margin: 0; /* Remover margens padrão */
          padding: 0; /* Remover padding padrão */
        }
  
        #table-extract {
          overflow-x: auto;
          background: var(--ui-t-color-primary-contrast);
          box-shadow: var(--ui-t-box-shadow);
          border-radius: var(--ui-t-box-radius);
          padding: 10px; /* Reduzir o padding para economizar espaço na impressão */
          font-size: 10px; /* Ajustar o tamanho da fonte da tabela */
          width: 100%; /* Ajustar largura para ocupar toda a largura disponível */
          margin-bottom: 10px; /* Espaçamento inferior reduzido */
        }
  
        .infos-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex: 0.8;
        }
  
        .logo-infos-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
        }
  
        .logo {
          border-radius: 3.125rem;
          height: 5rem;
          width: 5rem;
          background: var(--ui-t-aside-background-color);
          color: var(--ui-t-aside-text-color);
          font-size: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
  
        .infos {
          margin-left: 1rem; /* Ajuste de margem reduzida */
        }
  
        .saldo {
          text-align: center;
          margin-top: 1rem; /* Adicionar margem superior */
        }
  
        table {
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 10px; /* Reduzir espaço entre a tabela e o conteúdo acima */
        }
  
        th, td {
          border: 1px solid #dddddd;
          text-align: left;
          padding: 6px; /* Reduzir o padding das células */
        }
  
        th {
          background-color: #f2f2f2;
          color: var(--ui-t-color-primary);
        }
  
        tbody tr:nth-child(odd) {
          background: var(--ui-t-color-medium);
        }
  
        .bank {
          font-size: 10px; /* Ajustar o tamanho da fonte para as informações bancárias */
          font-weight: bold;
          text-align: center;
          margin-top: 1rem; /* Adicionar margem superior */
        }
      </style>
    `;
  
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write('<html><head><title>Extrato de Transações</title>');
    printWindow.document.write(styles);  // Inclui os estilos CSS no documento
    printWindow.document.write('</head><body style="margin: 0; padding: 0;">');
    printWindow.document.write('<h2 style="text-align:center;">Extrato de Transações</h2>');
    printWindow.document.write('<p style="text-align:center;">Aqui está o conteúdo do seu extrato de transações:</p>');
  
    // Clonar o elemento table-extract para evitar modificações na página original
    const clonedElement = element.cloneNode(true) as HTMLElement;
    printWindow.document.body.appendChild(clonedElement);
  
    printWindow.document.write('</body></html>');
  
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  
    await this.loadingService.hide();
    await this.dismiss();
  }
  
  
  
  
  
  async dismiss() {
    // Adicione a lógica para fechar a tela ou modal aqui.
  }
  
}
