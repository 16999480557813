import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-transfer-notification-accepted.component.html',
  styleUrls: ['./modal-transfer-notification-accepted.component.scss'],
})
export class ModalTransferNotificationAcceptedComponent {
  constructor(public modalController: ModalController, private layoutConfigService: LayoutConfigService) {}

  async dismiss() {
    this.modalController.dismiss();
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }
}
