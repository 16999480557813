import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-transfer-notification-completed.component.html',
  styleUrls: ['./modal-transfer-notification-completed.component.scss'],
})
export class ModalTransferNotificationCompletedComponent {
  constructor(public modalController: ModalController, private layoutConfigService: LayoutConfigService) {}

  public async dismiss() {
    this.modalController.dismiss().then(
      (res) => {},
      (err) => {
        // console.error(err);
      }
    );
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }
}
