import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccountBalanceService } from '@wlp/ui-bs-cashin';
import { ExtractService, TransferService } from '@wlp/ui-bs-cashout';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UICHeaderUserInfoService } from '@wlp/ui-c-header-user-info';

@Component({
  selector: 'ui-t-transfer-receipt',
  templateUrl: './transfer-receipt.page.html',
  styleUrls: ['./transfer-receipt.page.scss'],
})
export class TransferReceiptPage implements OnInit, OnDestroy {
  public transferDataReceipt: any;
  private userProfile: UserInfoProfile;

  public brand: string;

  constructor(
    private accountBalanceService: AccountBalanceService,
    private transferService: TransferService,
    protected userInfoService: UserInfoService,
    protected extractService: ExtractService,
    private headerService: UICHeaderUserInfoService,
    private layoutConfigService: LayoutConfigService
  ) {
    this.brand = this.layoutConfigService.getConfig('wlTheme.header.billet.brand.logo');
    this.loadDataReceipt();
  }

  ngOnInit() {
    this.loadUser();
  }

  private async loadDataReceipt() {
    this.transferService.getTransferConfirmation().subscribe((res) => (this.transferDataReceipt = res));
  }

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
        this.loadBalance(user.uuidWhiteLabel, user.uuidAccount);
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  print() {
    // let printContents: string;
    // let css: string;
    // printContents = document.getElementById('print-section').innerHTML;
    // css = '<style> font-family: sans-serif; </style>';
    // window.print();
    // /* this.transferService.print(printContents, css); */
  }

  private loadBalance(uuidWL: string, uuidAccount: string): void {
    this.accountBalanceService
      .getBalance(uuidWL, uuidAccount)
      .then((res) => {
        this.accountBalanceService.setBalanceSubject(res.balance);
        this.headerService.updateValue(true); // atualiza os valores
      })
      .catch((err) => this.accountBalanceService.getErroMessage(err));
  }

  ngOnDestroy() {
    this.transferService.unsubscribe();
    this.transferDataReceipt = undefined;
  }
}
