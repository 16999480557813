import {Component, Input} from '@angular/core';
import {ModalController} from '@ionic/angular';
@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-prospera-notification.component.html',
  styleUrls: ['./modal-prospera-notification.component.scss'],
})
export class ModalProsperaNotificationComponent {

  public succeed: boolean;

  @Input() status;

  constructor(public modalController: ModalController) {
    this.succeed = true;
  }

  public toggleSucceed(): void {
    this.succeed = !this.succeed;
  }

  async dismiss() {
    this.modalController.dismiss().then((res) => {
    }, (err) => {
    });
  }

  // TO-DO
  // Método para chamar o modal de notificação do prospera

  // async notificationProspera() {
  //   const modal = await this.modalController.create({
  //     component: ModalProsperaNotificationComponent,
  //     cssClass: 'modal-prospera-custom-class',
  //     componentProps: {
  //       status: 'MAIL | IN_ANALYSIS | APROVED | RETURNED | REPROVED'
  //     }
  //   });
  //   return await modal.present();
  // }
}
