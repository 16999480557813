import { tap } from 'rxjs/operators';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { AlertController, NavController } from '@ionic/angular';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { UiBsMyDataService, UserAccountInfoResponse } from '@wlp/ui-bs-my-data';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../../environments/environment';



import axios from 'axios'

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.page.html',
  styleUrls: ['./insurance.page.scss'],
})
export class InsurancePage implements OnInit {

  backgroundHeaderLogon: string;
  dataForm: UserAccountInfoResponse;
  userProfile: UserInfoProfile;
  formMyData: FormGroup;
  errorMessages: any;
  typeAccount: string;
  successChange: boolean;
  backgroundImage: string;
  teste: any
  postData: any
  realm: string;

  email: string
  cpf: string
  nome: string

  constructor(
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private myDataService: UiBsMyDataService,
    //private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private loadingService: LoadingService,
    public httpClient: HttpClient,
    protected userInfoService: UserInfoService,
  ) { 
    this.backgroundHeaderLogon = this.layoutConfigService.getConfig('wlTheme.header.background.image');

    this.formMyData = this.myDataService.getFormMyData();
    this.errorMessages = this.myDataService.getFormMessageError();
    this.typeAccount = 'MEI';
    this.successChange = false;
    this.backgroundImage = this.layoutConfigService.getConfig('wlTheme.header.background.image');
    this.realm = environment.wlThemeParameterization.wlTheme.realm;
  }

  ngOnInit() {
    this.loadUser();
  }

  goBack() {
    this.navCtrl.navigateBack('app/dashboard');
  }

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {        
        this.userProfile = user;
        this.myDataService
          .getPersonalData(this.userProfile.uuidAccount)
          .pipe(
            tap((data) => {        
              //console.log(data)      
              this.dataForm = data;
              this.email = data.email
              this.cpf   = data.document
              this.nome  = data.name
            }),
          )
          .subscribe();
      });
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }

  // loadMyData(userProfile: UserProfile) {
  //   this.myDataService
  //     .getPersonalData(userProfile.uuidAccount)
  //     .pipe(
  //       tap((data) => {

  //         this.email = data.email
  //         this.cpf   = data.document
  //         this.nome  = data.name

  //       })
  //     )
  //     .subscribe();
  // }

  public getImageTag(tag: string) {
    return this.layoutConfigService.getImagesPages(tag);
  }

  formataCPF(cpf){
    //retira os caracteres indesejados...
    cpf = cpf.replace(/[^\d]/g, "");
  
    //realizar a formatação...
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  getLink(){

    this.loadingService.show()

    const headers = {
      'x-api-key': null,
    };

    const postData = {
      campaignId: null,
      customer: {
        email: this.email,
        cellphone: '',
        cpf: this.formataCPF(this.cpf),
        name: this.nome,
        lastName: '',
        birthdate: '',
        address: {
          zipCode: '',
          street: '',
          number: '',
          complement: '',
          district: '',
          state: '',
          city: '',
        },
      },
    };     


    if (this.realm === 'logbank') {
      postData.campaignId = 'U2FsdGVkX19x2H3Miq0yZU7SgF4oWRnxwsWgp2nITNE=';
      headers['x-api-key'] = 'Hx8cjCLQeH52ZJmewdyIK9BspE5WQl1Z18c6AfPp';
    }

    if (this.realm === 'bancomin') {
      postData.campaignId = 'U2FsdGVkX18rly4AahZUJSy1pszPXz2gPbnzkugJE8U=';
      headers['x-api-key'] = 'Hx8cjCLQeH52ZJmewdyIK9BspE5WQl1Z18c6AfPp'
    }

    if (this.realm === 'autocred'){
      postData.campaignId = 'U2FsdGVkX19Vf0h0gJsIfthJicnktqQK75jS6HU73YI=';
      headers['x-api-key'] = 'Hx8cjCLQeH52ZJmewdyIK9BspE5WQl1Z18c6AfPp'
    }

    if (this.realm === 'primorbank'){
      postData.campaignId = 'U2FsdGVkX18kLHJYrOWDjYKkXRFgKmzgi6zwrUQ3DR0=';
      headers['x-api-key'] = 'Hx8cjCLQeH52ZJmewdyIK9BspE5WQl1Z18c6AfPp'
    }

    if (this.realm === 'spfcbank'){
      postData.campaignId = 'U2FsdGVkX19JaXH5AZrADVvbfWWnbC8jDUspbKf7apE=';
      headers['x-api-key'] = 'Hx8cjCLQeH52ZJmewdyIK9BspE5WQl1Z18c6AfPp'
    }

    if (this.realm === 'auguricredbank'){
      postData.campaignId = 'UU2FsdGVkX19x2H3Miq0yZU7SgF4oWRnxwsWgp2nITNE=';
      headers['x-api-key'] = 'Hx8cjCLQeH52ZJmewdyIK9BspE5WQl1Z18c6AfPp'
    }

    axios.post('https://api-gateway.88i.io/common/customer-integration', postData, {headers: headers}).then(result => {
      this.loadingService.hide()
      window.open(result.data.urls[2].url)
    })

    }
}
