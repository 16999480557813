import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { UiBsCashInModule } from '@wlp/ui-bs-cashin';
import { UiBsCashOutModule } from '@wlp/ui-bs-cashout';
import { UiBsTranslateModule } from '@wlp/ui-bs-translate';
import { UiCAccountBalanceModule } from '@wlp/ui-c-account-balance';
import { UiCAsideMenuLeftModule } from '@wlp/ui-c-aside-menu-left';
import { UiCBarInfoModule } from '@wlp/ui-c-bar-info';
import { UiCCardInfoModule } from '@wlp/ui-c-card-info';
import { HeaderUserInfoModule } from '@wlp/ui-c-header-user-info';
import { UiCNotificationsModule } from '@wlp/ui-c-notifications';
import { UiCOtherAccountModule } from '@wlp/ui-c-other-account';
import { UiCPdfGeneratorModule } from '@wlp/ui-c-pdf-generator';
import { UiCTransferDataModule } from '@wlp/ui-c-transfer-data';
import { UiCTransferDocumentModule } from '@wlp/ui-c-transfer-document';
import { UiCTransferReceiptModule } from '@wlp/ui-c-transfer-receipt';
import { UiCTransferValueModule } from '@wlp/ui-c-transfer-value';
import { BrMaskerModule } from 'br-mask';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgxMaskIonicModule } from 'ngx-mask-ionic';
import { ComponentsModule } from 'src/app/core/shared/modules/components.module';
import { environment } from 'src/environments/environment';

import { TransferDocumentPage } from './document/transfer-document.page';
import { TransferLotPage } from './lot/transfer-lot.page';
import { TransferOnUsPage } from './on-us/transfer-on-us.page';
import { TransferOtherAccountsPage } from './other-accounts/transfer-other-accounts.page';
import { TransferReceiptPage } from './receipt/transfer-receipt.page';
import { TransferRoutingModule } from './transfer-routing.module';
import { TransferPage } from './transfer.page';
import {InterceptService} from "../../../../../../../core/intercept/intercept.service";
import {HTTP_INTERCEPTORS} from "@angular/common/http";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TooltipModule,
    ComponentsModule,
    ReactiveFormsModule,
    TransferRoutingModule,
    NgxMaskIonicModule.forRoot(),
    RouterModule,
    BrMaskerModule,
    UiCAccountBalanceModule,
    UiBsCashInModule.forRoot({ device: 'WEB', config: environment }),
    UiBsCashOutModule.forRoot({ device: 'WEB', config: environment }),
    UiCAsideMenuLeftModule,
    UiCCardInfoModule,
    UiCNotificationsModule,
    UiBsTranslateModule,
    UiCTransferDataModule,
    UiCTransferDocumentModule,
    UiCTransferValueModule,
    UiCOtherAccountModule,
    UiCTransferReceiptModule,
    UiCBarInfoModule,
    HeaderUserInfoModule,
    UiCPdfGeneratorModule,
  ],
  declarations: [
    TransferPage,
    TransferDocumentPage,
    TransferOnUsPage,
    TransferOtherAccountsPage,
    TransferLotPage,
    TransferReceiptPage,
  ],
  bootstrap: [],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
})
export class TransferModule { }
