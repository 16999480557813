import {ActivityBranch, ProductWhiteLabelResponse, PreProductAccountProduct} from "@wlp/ui-bs-ec";


export class PreProductCustom {
  public id?: string;
  public uuidWhiteLabel: string;
  public uuidAccountPayment: string;
  public activityBranch?: ActivityBranch;
  public preProductsCustom: Array<ProductSlideCustomDto>;
}

export class ProductSlideCustomDto {
  public productWhiteLabel?: ProductWhiteLabelResponse;
  public preProduct?: PreProductAccountProduct;
  public checked?: boolean;
  public disabled?: boolean;
}
