import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';

import {UiCHeaderModule} from '@wlp/ui-c-header';
import {UiCButtonModule} from '@wlp/ui-c-button';


@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    UiCButtonModule,
    UiCHeaderModule
  ],
  exports: [
    UiCButtonModule,
    UiCHeaderModule
  ],
})
export class ComponentsModule {}
