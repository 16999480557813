import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { UICMenuAppService } from '@wlp/ui-c-menu-app';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { CardMessagePage } from '../modals/card-message/card-message.page';
import { CardLoadingPage } from '../modals/card-loading/card-loading.page';
import { WalletService as WalletServiceNsc } from '@wlp/ui-bs-nsc';
import { WalletService } from '@wlp/ui-bs-prizedraw';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { environment } from '../../../../../../../../environments/environment';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

@Component({
  selector: 'manager-cards-page',
  templateUrl: './manager-cards-page.component.html',
  styleUrls: ['./manager-cards-page.component.scss'],
})
export class ManagerCardsPage implements OnInit {
  public showValueFlag: boolean;
  public showValueIcon: string;
  public backgroundImage: string;
  public userProfile: UserInfoProfile;

  public cards: Array<any>;

  constructor(
    private uicMenuApp: UICMenuAppService,
    private layoutConfigService: LayoutConfigService,
    private router: Router,
    public modalController: ModalController,
    protected userInfoService: UserInfoService,
    private walletService: WalletService,
    private walletServiceNsc: WalletServiceNsc,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.loadUser();
    this.backgroundImage = this.layoutConfigService.getConfig('wlTheme.header.background.image');
  }

  showValue(): void {
    this.showValueFlag = this.showValueFlag ? false : true;
    this.showValueIcon = this.showValueFlag ? this.showIcon('ocultarValor') : this.showIcon('verValor');
  }

  showIcon(icon: string, extension?: string): string {
    let iconUrl = `assets/images/prize-draw/wallet/${icon}.svg`;
    if (extension) {
      iconUrl = iconUrl.replace(/\.svg$/, `.${extension}`);
    }

    return iconUrl;
  }

  redirectUrl(link: string): void {
    if (link !== '') {
      this.router.navigate([link]);
    }
  }

  ionViewDidEnter() {
    this.loadUser();
  }

  async callCardManagerPage(link, id?: string) {
    if (link && id) {
      this.router.navigate([link, id]);
    } else {
      this.router.navigate([link]);
    }
  }

  async callRemoveCardPage(card: any) {
    const modal = await this.modalController.create({
      component: CardMessagePage,
      cssClass: 'cardMessagePage',
      componentProps: {
        card: card,
      },
    });

    modal.onDidDismiss().then(async (modalData) => {
      if (modalData.data.option === 'exclude') {
        this.loadingService.show();
        this.walletServiceNsc.removeCard(card.uuid).subscribe((res) => {
          this.getWallets().then((_) => this.loadingService.hide());
        });
      }
    });

    return await modal.present();
  }

  goBack() {
    this.router.navigate(['/wallet']);
  }
  async getWallets() {
    await this.walletService
      .getWallets(environment.wlp, this.userProfile.uuidAccount)
      .then((res) => {
        if (res) {
          this.cards = res;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  private loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();

      userDetails.then((user) => {
        this.userProfile = user;
        this.getWallets();
      });
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }
  async callModalLoading() {
    return await this.modalController.create({
      component: CardLoadingPage,
      cssClass: 'cardMessagePage',
    });
  }
}
