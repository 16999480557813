import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-share',
  templateUrl: './modal-share.page.html',
  styleUrls: ['./modal-share.page.scss'],
})
export class ModalSharePage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
