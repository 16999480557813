import { Component, Renderer2, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

import { environment } from '../environments/environment';
import { LayoutConfigService } from './core/layout/services/layout-config.service';
import { QrCodeAuthenticationGuard } from 'src/app/core/auth/qrcode-authentication-guard';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  dark = false;
  wlName: string;
  googleTagManagerId: string;

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private splashScreen: SplashScreen,
    private translate: TranslateService,
    private layoutConfigService: LayoutConfigService,
    private titleService: Title,
    private renderer: Renderer2,
    private storage: QrCodeAuthenticationGuard,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.translate.setDefaultLang('pt-br');
    this.wlName = this.layoutConfigService.getConfig('wlTheme.wlName');
    this.googleTagManagerId =
      environment.wlThemeParameterization.googleTagManager && environment.wlThemeParameterization.googleTagManager.web
        ? environment.wlThemeParameterization.googleTagManager.web
        : null;
    this.initializeApp();
    this.clearValidaQrcode();
  }

  initializeApp() {
    this.titleService.setTitle(this.wlName);

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    this.setPropertyStyles();

    if (this.googleTagManagerId) {
      this.addGoogleTagManager(this.googleTagManagerId);
    }
  }

  clearValidaQrcode() {
    window.addEventListener("beforeunload", () => {
  // Remove saved data from sessionStorage
  this.storage.setItem('validaQrCode', false); 
  // Remove all saved data from sessionStorage
  this.storage.clearStorage();
  });
}


  addGoogleTagManager(id: string) {
    //ADD HEAD SCRIPT
    const script = this.renderer.createElement('script');
    this.renderer.setProperty(
      script,
      'innerHTML',
      `
        window.dataLayer = window.dataLayer || [];
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${id}');
      `
    );
    this.renderer.appendChild(this.document.head, script);

    // ADD BODY NOSCRIPT
    const noScript = this.renderer.createElement('noscript');
    this.renderer.setProperty(
      noScript,
      'innerHTML',
      `
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=${id}"
          height="0"
          width="0"
          style="display: none; visibility: hidden"
        ></iframe>
    `
    );
    this.renderer.appendChild(this.document.body, noScript);
  }

  setPropertyStyles() {
    // Intro Vars
    let introBgColor = this.layoutConfigService.getConfig('wlTheme.intro.background.color');
    let introTxtColor = this.layoutConfigService.getConfig('wlTheme.intro.text.color');
    let introBtnBgColorPrimary = this.layoutConfigService.getConfig('wlTheme.intro.button.background.color.primary');
    let introBtnBgColorSecondary = this.layoutConfigService.getConfig(
      'wlTheme.intro.button.background.color.secondary'
    );
    let introBtnBgHoverPrimary = this.layoutConfigService.getConfig('wlTheme.intro.button.background.hover.primary');
    let introBtnBgHoverSecondary = this.layoutConfigService.getConfig(
      'wlTheme.intro.button.background.hover.secondary'
    );
    let introBtnTxtColorPrimary = this.layoutConfigService.getConfig('wlTheme.intro.button.text.color.primary');
    let introBtnTxtColorSecondary = this.layoutConfigService.getConfig('wlTheme.intro.button.text.color.secondary');
    let introBtnBorderColorPrimary = this.layoutConfigService.getConfig('wlTheme.intro.button.border.color.primary');
    let introBtnBorderColorSecondary = this.layoutConfigService.getConfig(
      'wlTheme.intro.button.border.color.secondary'
    );
    let introSocialColor = this.layoutConfigService.getConfig('wlTheme.intro.social.color');
    let introFooterBgColor = this.layoutConfigService.getConfig('wlTheme.intro.footer.background.color');
    let introFooterBorderColor = this.layoutConfigService.getConfig('wlTheme.intro.footer.border.color');

    // Timiline Vars
    let timelineBgColor = this.layoutConfigService.getConfig('wlTheme.timeline.background.color');
    let timelineTxtColorPrimary = this.layoutConfigService.getConfig('wlTheme.timeline.text.color.primary');
    let timelineTxtColorSecondary = this.layoutConfigService.getConfig('wlTheme.timeline.text.color.secondary');
    let timelineBulletColorPrimary = this.layoutConfigService.getConfig('wlTheme.timeline.bullet.color.primary');
    let timelineBulletColorSecondary = this.layoutConfigService.getConfig('wlTheme.timeline.bullet.color.secondary');
    let timelineBulletColorTertiary = this.layoutConfigService.getConfig('wlTheme.timeline.bullet.color.tertiary');
    let timelineBulletBorderColor = this.layoutConfigService.getConfig('wlTheme.timeline.bullet.border.color');

    // Aside Vars
    let asideBgColor = this.layoutConfigService.getConfig('wlTheme.aside.background.color');
    let asideTxtColor = this.layoutConfigService.getConfig('wlTheme.aside.text.color');
    let asideTxtHover = this.layoutConfigService.getConfig('wlTheme.aside.text.hover');

    // Header Vars
    let headerBgColorPrimary = this.layoutConfigService.getConfig('wlTheme.header.background.color.primary');
    let headerBgColorSecondary = this.layoutConfigService.getConfig('wlTheme.header.background.color.secondary');
    let headerTxtColorPrimary = this.layoutConfigService.getConfig('wlTheme.header.text.color.primary');
    let headerTxtColorSecondary = this.layoutConfigService.getConfig('wlTheme.header.text.color.secondary');
    let headerTxtColorTertiary = this.layoutConfigService.getConfig('wlTheme.header.text.color.tertiary');
    let headerBtnBgColorPrimary = this.layoutConfigService.getConfig('wlTheme.header.button.background.color.primary');
    let headerBtnBgColorSecondary = this.layoutConfigService.getConfig(
      'wlTheme.header.button.background.color.secondary'
    );
    let headerBtnTxtColorPrimary = this.layoutConfigService.getConfig('wlTheme.header.button.text.color.primary');
    let headerBtnTxtColorSecondary = this.layoutConfigService.getConfig('wlTheme.header.button.text.color.secondary');
    let headerProfileBgColor = this.layoutConfigService.getConfig('wlTheme.header.profile.background.color');

    // Font Vars
    let fontSize = this.layoutConfigService.getConfig('wlTheme.font.size');
    let fontFamily = this.layoutConfigService.getConfig('wlTheme.font.family');

    // Global Vars
    let globalBtnTxtColor = this.layoutConfigService.getConfig('wlTheme.global.button.text.color');

    // Custom Header Vars
    let customHeaderTextColorPrimary = this.layoutConfigService.getConfig('wlTheme.custom.header.text.color.primary');
    let customHeaderTextColorSecondary = this.layoutConfigService.getConfig(
      'wlTheme.custom.header.text.color.secondary'
    );
    let customHeaderButtonColorPrimary = this.layoutConfigService.getConfig(
      'wlTheme.custom.header.button.color.primary'
    );
    let customHeaderButtonColorSecondary = this.layoutConfigService.getConfig(
      'wlTheme.custom.header.button.color.secondary'
    );
    let customHeaderTextButtonColorPrimary = this.layoutConfigService.getConfig(
      'wlTheme.custom.header.text.button.color.primary'
    );
    let customHeaderTextButtonColorSecondary = this.layoutConfigService.getConfig(
      'wlTheme.custom.header.text.button.color.secondary'
    );
    let customHeaderColorPrimary = this.layoutConfigService.getConfig('wlTheme.custom.header.color.primary');
    let customHeaderColorSecondary = this.layoutConfigService.getConfig('wlTheme.custom.header.color.secondary');

    // Color Vars
    let colorPrimary = this.layoutConfigService.getConfig('wlTheme.color.primary');
    let colorSecondary = this.layoutConfigService.getConfig('wlTheme.color.secondary');

    // CUstom height Vars
    let vh = window.innerHeight * 0.01;

    // Custom height Sets
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // Color Sets
    document.documentElement.style.setProperty('--ui-t-color-primary', colorPrimary);
    document.documentElement.style.setProperty('--ui-t-color-secondary', colorSecondary);

    // Font Sets
    document.documentElement.style.setProperty('--ui-t-font-size', fontSize);
    document.documentElement.style.setProperty('--ui-t-font-family', fontFamily);

    // Global Sets
    document.documentElement.style.setProperty('--ui-t-global-button-text-color', globalBtnTxtColor);

    // Intro Sets
    document.documentElement.style.setProperty('--ui-t-intro-background-color', introBgColor);
    document.documentElement.style.setProperty('--ui-t-intro-text-color', introTxtColor);
    document.documentElement.style.setProperty('--ui-t-intro-button-background-color-primary', introBtnBgColorPrimary);
    document.documentElement.style.setProperty(
      '--ui-t-intro-button-background-color-secondary',
      introBtnBgColorSecondary
    );
    document.documentElement.style.setProperty('--ui-t-intro-button-background-hover-primary', introBtnBgHoverPrimary);
    document.documentElement.style.setProperty(
      '--ui-t-intro-button-background-hover-secondary',
      introBtnBgHoverSecondary
    );
    document.documentElement.style.setProperty('--ui-t-intro-button-text-color-primary', introBtnTxtColorPrimary);
    document.documentElement.style.setProperty('--ui-t-intro-button-text-color-secondary', introBtnTxtColorSecondary);
    document.documentElement.style.setProperty('--ui-t-intro-button-border-color-primary', introBtnBorderColorPrimary);
    document.documentElement.style.setProperty(
      '--ui-t-intro-button-border-color-secondary',
      introBtnBorderColorSecondary
    );
    document.documentElement.style.setProperty('--ui-t-intro-social-color', introSocialColor);
    document.documentElement.style.setProperty('--ui-t-intro-footer-background-color', introFooterBgColor);
    document.documentElement.style.setProperty('--ui-t-intro-footer-border-color', introFooterBorderColor);

    // Timeline Stes
    document.documentElement.style.setProperty('--ui-t-timeline-background-color', timelineBgColor);
    document.documentElement.style.setProperty('--ui-t-timeline-text-color-primary', timelineTxtColorPrimary);
    document.documentElement.style.setProperty('--ui-t-timeline-text-color-secondary', timelineTxtColorSecondary);
    document.documentElement.style.setProperty('--ui-t-timeline-bullet-color-primary', timelineBulletColorPrimary);
    document.documentElement.style.setProperty('--ui-t-timeline-bullet-color-secondary', timelineBulletColorSecondary);
    document.documentElement.style.setProperty('--ui-t-timeline-bullet-color-tertiary', timelineBulletColorTertiary);
    document.documentElement.style.setProperty('--ui-t-timeline-bullet-border-color', timelineBulletBorderColor);

    // Aside Sets
    document.documentElement.style.setProperty('--ui-t-aside-background-color', asideBgColor);
    document.documentElement.style.setProperty('--ui-t-aside-text-color', asideTxtColor);
    document.documentElement.style.setProperty('--ui-t-aside-text-hover', asideTxtHover);

    // Header Sets
    document.documentElement.style.setProperty('--ui-t-header-background-color-primary', headerBgColorPrimary);
    document.documentElement.style.setProperty('--ui-t-header-background-color-secondary', headerBgColorSecondary);
    document.documentElement.style.setProperty('--ui-t-header-text-color-primary', headerTxtColorPrimary);
    document.documentElement.style.setProperty('--ui-t-header-text-color-secondary', headerTxtColorSecondary);
    document.documentElement.style.setProperty('--ui-t-header-text-color-tertiary', headerTxtColorTertiary);
    document.documentElement.style.setProperty(
      '--ui-t-header-button-background-color-primary',
      headerBtnBgColorPrimary
    );
    document.documentElement.style.setProperty(
      '--ui-t-header-button-background-color-secondary',
      headerBtnBgColorSecondary
    );
    document.documentElement.style.setProperty('--ui-t-header-button-text-color-primary', headerBtnTxtColorPrimary);
    document.documentElement.style.setProperty('--ui-t-header-button-text-color-secondary', headerBtnTxtColorSecondary);
    document.documentElement.style.setProperty('--ui-t-header-profile-background-color', headerProfileBgColor);
    document.documentElement.style.setProperty('--ui-t-color-custom-header-color-primary', customHeaderColorPrimary);
    document.documentElement.style.setProperty(
      '--ui-t-color-custom-header-color-secondary',
      customHeaderColorSecondary
    );
  }
}
