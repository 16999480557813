import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { TransferLimitService } from '@wlp/ui-bs-transfer-limit';
import { BrMaskModel } from 'br-mask';
import { UserInfoService } from '@wlp/ui-bs-login';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

@Component({
  selector: 'app-modal-edit-transfer-limit',
  templateUrl: './modal-edit-transfer-limit.component.html',
  styleUrls: ['./modal-edit-transfer-limit.component.scss'],
})
export class ModalEditTransferLimitComponent implements OnInit {

  @Input() reponseTransfer: any;
  @Input() currentValuePerTransaction: number;
  @Input() maximumValuePerTransaction: number;

  private responseApprove: any
  private valueLimit: number = 1000;

  editing: boolean = false;

  // EDIT
  brMaskModel: BrMaskModel = new BrMaskModel();

  intValueByTransfer: number;
  inputValueByTransfer: any;

  public valorLimitUser: number;

  //REFACTOR

  public userProfile: any;

  public valorlimiteDiurno;
  public valorLimiteNoturno;
  public valorMaximolimiteDiurno;
  public valorMaximoLimiteNoturno;

  constructor(
    public modalController: ModalController,
    public toastController: ToastController,
    private transferLimitService: TransferLimitService,
    protected userInfoService: UserInfoService,
    private loadingService: LoadingService,
  ) { }

  async ngOnInit() {
    this.loadUser();
    this.inputValueByTransfer = await this.currentValuePerTransaction
    console.log("Limite Atual: ", this.currentValuePerTransaction)
    console.log("Limite Maximo: ", this.maximumValuePerTransaction)
    console.log("Info : ", this.reponseTransfer)
    this.brMaskModel.money = true;
    this.brMaskModel.thousand = '.';

    this.valorlimiteDiurno = this.currentValuePerTransaction;
    this.valorLimiteNoturno = this.currentValuePerTransaction;
    this.valorMaximolimiteDiurno = this.maximumValuePerTransaction
    this.valorMaximoLimiteNoturno = this.maximumValuePerTransaction
  }

  public loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then(async (user) => {
        this.userProfile = user;
        console.log('Profile TRANSFER', this.userProfile)
      });
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }

  changeValueInput(event) {
    const inputValue = event.detail.value;
    const numericValue = parseFloat(inputValue);

    if (!isNaN(numericValue)) {
      this.valorlimiteDiurno = numericValue;
    } else {
      this.valorlimiteDiurno = 0;
    }
  }

  changeValueRange(event) {
    console.log(event.detail.value);
    console.log("Valor: ", this.valorlimiteDiurno)
    this.valorlimiteDiurno = event.detail.value
  }

  changeValueInputNoturno(event) {
    console.log(event.detail.value);
    this.valorLimiteNoturno = event.detail.value
  }

  changeValueRangeNoturno(event) {
    let value = event.detail.value;
    let increment;

    if (value > 500) {
      increment = 500;
    } else if (value > 100) {
      increment = 50;
    } else if (value > 0) {
      increment = 10;
    } else {
      increment = 0;
    }

    if (increment !== 0) {
      this.valorLimiteNoturno = Math.round(value / increment) * increment;
    } else {
      this.valorLimiteNoturno = 0;
    }
  }

  /*
  requestLimit() {
    this.loadingService.show();
    let reponseLimit = {
      uuidWhiteLabel: this.userProfile.uuidWhiteLabel,
      uuidAccountPayment: this.userProfile.uuidAccount,
      valuePerTransaction: this.valorlimiteDiurno,
      valuePerPeriod: this.valorLimiteNoturno,
      typeId: 0,
      transactionTypeId: 0
    }
    console.log("BODY: ", reponseLimit)
    this.transferLimitService.resquestMyLimitsPost(reponseLimit).subscribe(
      (response => {
        console.log("Response: ", response)
        this.loadingService.hide();
      })),
      (err) => {
        console.log("Err: ", err)
        this.loadingService.hide();
      }
      this.loadingService.hide();
  }

  //REFACTOR

  GetTransactionTypesPix() {
    this.transferLimitService.requestApprove('transaction-type', this.responseApprove).subscribe(
      async (response) => {
        console.log("RESPONSE: ", response)
      });
  }
  */

  setValueByTransfer(event) {
    let data = event.target.value.toString().replace('.', '').replace(',', '.');
    this.intValueByTransfer = parseFloat(data);

    let valorFormatado = this.formatarValorMonetario(this.intValueByTransfer);
    this.inputValueByTransfer = valorFormatado;

    console.log(this.intValueByTransfer, this.inputValueByTransfer);
  }

  setValueByTransferInputMode(event) {
    let data = event.target.value.toString().replace('.', '').replace(',', '.');
    this.intValueByTransfer = parseFloat(data);

    let valorFormatado = this.formatarValorMonetario(this.intValueByTransfer);
    this.inputValueByTransfer = valorFormatado;

    console.log(this.intValueByTransfer, this.inputValueByTransfer, event);
  }

  formatarValorMonetario(valor: number): string {
    let valorString = valor.toFixed(2);
    let partes = valorString.split('.');

    partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return partes.join('.');
  }

  dismiss() {
    this.modalController.dismiss();
  }

  //RANGE ->

  async solicitacaoEnviada() {
    const toast = await this.toastController.create({
      message: 'Solicitação enviada com sucesso!',
      duration: 1500,
      color: 'success',
      position: 'bottom',
    });

    await toast.present();
    this.dismiss()
  }

  updateValueLimit(event: any) {
    const newValue = event.detail.value;

    if (!isNaN(newValue)) {
      if (newValue > 0 && newValue <= 100) {
        this.valueLimit = Math.round(newValue / 10) * 10;
      } else if (newValue > 100 && newValue <= 500) {
        this.valueLimit = Math.round(newValue / 50) * 50;
      } else if (newValue > 500) {
        this.valueLimit = Math.round(newValue / 500) * 500;
      }
    } else {
      this.valueLimit = 0;
    }

    console.log('Novo valor do ion-range:', this.valueLimit);
  }
}
