import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BaseService, ErrorMessageService } from '@wlp/ui-bs-util';
import { ConfigService, SpeJFundsProxy } from '@wlp/ui-px-cashin';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { OnboardingEcInterface } from './onboarding-ec.interface';




@Injectable()
export class OnboardingEcService extends BaseService {

  private page$: Subject<OnboardingEcInterface> = new Subject<OnboardingEcInterface>();
  private page:OnboardingEcInterface;
  constructor(
    protected storage: Storage,
    protected configService: ConfigService,
  ) {
    super(storage, configService);
    this.initialObservable();
    this.page = { 
      isTitle: false,
      title: "",
      subtitle :""
    }
  }


  getTitleSubject(){
    return this.page;
  }
  setTitleSubject(data:OnboardingEcInterface){
    this.page$.next(data);
  }
  private initialObservable():void{
    this.page$.subscribe(res => {
      this.page = res
    })
  }
}
