import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.page.html',
  styleUrls: ['./notifications.page.scss'],
})
export class NotificationsPage {

  notifications = [];
  class = '.collapse';

  constructor() {
    this.notifications = [
      {
        title: 'TED Recebida de Joana...',
        message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry is simply dummy text of the printing, Lorem Ipsum is simply dummy text of the printing and typesetting industry is simply dummy text of the printing, Lorem Ipsum is simply dummy text of the printing and typesetting industry is simply dummy text of the printing, Lorem Ipsum is simply dummy text of the printing and typesetting industry is simply dummy text of the printing',
        class: 'notification collapse' ,
        date: '2020-06-02 16:29:26',
        read: false,
      },
      {
        title: 'TED Recebida de Italo...',
        message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry is simply dummy text of the printing, Lorem Ipsum is simply dummy text of the printing and typesetting industry is simply dummy text of the printing, Lorem Ipsum is simply dummy text of the printing and typesetting industry is simply dummy text of the printing, Lorem Ipsum is simply dummy text of the printing and typesetting industry is simply dummy text of the printing',
        class: 'notification collapse',
        date: '2020-06-02 17:01:06',
        read: false,
      },
      {
        title: 'TED Recebida de Danilo...',
        message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry is simply dummy text of the printing, Lorem Ipsum is simply dummy text of the printing and typesetting industry is simply dummy text of the printing, Lorem Ipsum is simply dummy text of the printing and typesetting industry is simply dummy text of the printing, Lorem Ipsum is simply dummy text of the printing and typesetting industry is simply dummy text of the printing',
        class: 'notification collapse',
        date: '2020-06-02 17:02:21',
        read: false,
      }, {
        title: 'TED Recebida de Lucas...',
        message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry is simply dummy text of the printing, Lorem Ipsum is simply dummy text of the printing and typesetting industry is simply dummy text of the printing, Lorem Ipsum is simply dummy text of the printing and typesetting industry is simply dummy text of the printing, Lorem Ipsum is simply dummy text of the printing and typesetting industry is simply dummy text of the printing',
        class: 'notification collapse',
        date: '2020-06-02 17:02:26',
        read: false,
      },
      {
        title: 'TED Recebida de Guilherme...',
        message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry is simply dummy text of the printing, Lorem Ipsum is simply dummy text of the printing and typesetting industry is simply dummy text of the printing, Lorem Ipsum is simply dummy text of the printing and typesetting industry is simply dummy text of the printing, Lorem Ipsum is simply dummy text of the printing and typesetting industry is simply dummy text of the printing',
        class: 'notification collapse',
        date: '2020-06-02 17:20:15',
        read: false,
      },
    ];
  }

  changeClass(index: number) {
    if (this.notifications[index].class === 'notification collapse') {
      this.notifications[index].class = 'notification expand';
    } else {
      this.notifications[index].class = 'notification collapse';
    }
  }

}
