import { AfterContentInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { IntroService } from '@wlp/ui-bs-intro';

import { LayoutConfigService } from '../../../../../core/layout/services/layout-config.service';

@Component({
  selector: 'app-success',
  templateUrl: './status-response.page.html',
  styleUrls: ['./status-response.page.scss'],
})
export class StatusResponsePage implements AfterContentInit {
  navigationBackground: string;
  brand: string;
  @Input() statusAnalysis;

  constructor(
    private navCtrl: NavController,
    private introService: IntroService,
    private layoutConfigService: LayoutConfigService,
    private router: Router,
  ) {
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
    this.statusAnalysis = this.router.getCurrentNavigation().extras.state.status;
  }

  ngAfterContentInit(): void {
    console.log(this.statusAnalysis);
    this.introService.cleanStorage();
    sessionStorage.clear();
  }

  goBack() {
    this.navCtrl.back();
  }
  continue() {
    this.navCtrl.navigateRoot('');
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }
}
