import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AccountBalanceService } from '@wlp/ui-bs-cashin';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UiBsMyDataService, UserAccountInfoResponse } from '@wlp/ui-bs-my-data';
import { catchError, tap } from 'rxjs/operators';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import Swal from "sweetalert2"
import { accountClosureInterface, accountClusureService } from '@wlp/ui-bs-account-closure'
import { of } from 'rxjs';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

@Component({
  selector: 'ui-t-my-data',
  templateUrl: './my-data.page.html',
  styleUrls: ['./my-data.page.scss'],
})
export class MyDataPage implements OnInit {
  formMyData: FormGroup;
  errorMessages: any;
  typeAccount: string;
  successChange: boolean;
  dataForm: UserAccountInfoResponse;
  userProfile: UserInfoProfile;
  numberAccountBank: string
  balanceAmount: string

  constructor(
    private myDataService: UiBsMyDataService,
    private layoutConfigService: LayoutConfigService,
    protected userInfoService: UserInfoService,
    private accountBalanceService: AccountBalanceService,
    private accountClosures: accountClusureService,
    private load: LoadingService
    ) {
    this.formMyData = this.myDataService.getFormMyData();
    this.errorMessages = this.myDataService.getFormMessageError();
    this.typeAccount = 'MEI';
    this.successChange = false;
  }

  ngOnInit() {
    this.loadUser();
  }

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {        
        this.userProfile = user;
        this.loadBalance(user)
        this.numberAccountBank = user.numberAccountBank
        this.myDataService
          .getPersonalData(this.userProfile.uuidAccount)
          .pipe(
            tap((data) => {              
              this.dataForm = data;
            }),
          )
          .subscribe();
      });
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }

  accountClosure() {
    Swal.fire({
      title: 'Encerramento de conta',
      html: `
      <div>
      <div class="padrao">
        <h2>Leia atentamente</h2>
      </div>
      <div class="padrao">
        <p>Você está prestes a encerrar a sua conta bancária conosco. Por favor, tenha em mente que essa ação é irreversível e resultará no encerramento permanente da sua conta e na perda de acesso aos serviços e benefícios associados.</p>
        <p>Certifique-se de considerar cuidadosamente todas as informações a seguir antes de prosseguir:</p>
      </div>

      <div class="padrao">
        <div>
          <i class="checkmark-outline"></i>
        </div>
        <div>
          Todas as chaves PIX cadastradas serão perdidas, caso deseje, realize a portabilidade antes de prosseguir com o encerramento.
        </div>
      </div>
<br>
      <div class="padrao">
        <div>
          <i class="checkmark-outline"></i>
        </div>
        <div>
          Recomendamos que você retire todo o saldo disponível em sua conta antes de prosseguir com o encerramento.
        </div>
      </div>
<br>
      <div class="padrao">
        <div>
          <i class="checkmark-outline"></i>
        </div>
        <div>
          Tenha em mente que essa ação é irreversível e resultará no encerramento permanente da sua conta e na perda de acesso aos serviços e benefícios associados.
        </div>
      </div>
      <br>
       Ao clicar em <b>Encerrar</b> você esta de acordo com o encerramento da conta <b>${this.numberAccountBank}</b>
      </div>
    </div>
    </div>
      `,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Encerrar',
      heightAuto: false,
      focusConfirm: false,
    }).then((result) => {
      if (result.isConfirmed) {
        this.closeUserAccount();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Ação quando o usuário cancela o encerramento
      }
    });
    
  }

  closeUserAccount() {

    this.load.show();

    if(parseFloat(this.balanceAmount) > 0) {
      this.load.hide();
      Swal.fire({
        title: 'Atenção',
        text: 'Infelizmente, não é possível encerrar sua conta enquanto houver saldo remanescente. Pedimos gentilmente que realize a transferência do saldo restante para outra conta antes de solicitar o encerramento.',
        icon: 'warning',
        heightAuto: false
      })

    } else {

      const body: accountClosureInterface = {
        accountNumber: this.numberAccountBank,
        uuidReason: 'd31e03c4-00e8-47e9-8066-0dc4b4ddf745',
        comment: 'Encerramento de conta realizada pelo app.',
        requestOrigin: 'APP'
      }
  
      this.accountClosures.closeAccount(body)
      .pipe(
        tap(res => {
          // this.loading.hide()
          Swal.fire({
            title: 'Conta encerrada',
            text: `Seguindo os critérios internos, encerramos a conta de sua titularidade número ${this.numberAccountBank}. Neste momento você será desconectado da plataforma. O encerramento, porém, não significa o cancelamento automático de outros serviços e compromissos que estejam transitando pela conta.`,
            icon: 'success',
            heightAuto: false
          }
          ).then(res => {
            this.onLogout()
          })
        }),catchError(err => {
          // this.loading.hide()
          Swal.fire({
            title: 'Conta não encerrada',
            text: 'Lamentamos informar que, no momento, não conseguimos encerrar sua conta. Por gentileza, entre em contato com nossa central de atendimento para obter assistência.',
            icon: 'error',
            heightAuto: false
          }
          )
          return of(null)
        })
      )
      .subscribe()
    }

  }

  public onLogout() {
    let redirectUrl = document.location.protocol + '//' + document.location.hostname;

    if (document.location.hostname.includes('localhost')) {
      redirectUrl += ':' + document.location.port;
    }

    this.userInfoService.onLogout(redirectUrl);
  }

  private loadBalance(userProfile: any): void {
    this.accountBalanceService
      .getBalance(userProfile.uuidWhiteLabel, userProfile.uuidAccount)
      .then((res) => {
        // this.loading.hide()
        this.balanceAmount = res.balance
      })
      .catch((err) => {
        // this.loading.hide()
        this.accountBalanceService.getErroMessage(err);
      });
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }
}
