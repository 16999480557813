import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { Component, ViewChild } from '@angular/core';
import { AlertController, IonSlides, NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { QuizService } from '@wlp/ui-bs-signup'
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'ui-t-quiz-answer',
  templateUrl: './quiz-answer.page.html',
  styleUrls: ['./quiz-answer.page.scss'],
})
export class QuizAnswerPage {
  brand: string;
  birthDate: string;
  okbirthDate = false
  quizData: any
  typeFlow: string
  navigationBackground: string;
  answersQuiz = []
  values = []
  private preAccountValue: string
  @ViewChild('questionsSlider', null) slides: IonSlides;

  constructor(
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private router: Router,
    private quizService: QuizService,
    private loading: LoadingService,
    private storage: Storage,
    private alertController: AlertController,
  ) {
    this.brand = this.layoutConfigService.getConfig('wlTheme.header.brand.logo.secondary');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
    this.quizData = this.router.getCurrentNavigation().extras.state.quizData;
    this.typeFlow = this.router.getCurrentNavigation().extras.state.typeFlow;
  }


  goBack() {
    this.navCtrl.back();
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }

  async continue() {

    this.loading.show();
    let onboarding_light = sessionStorage.getItem('onboarding_light')

    this.preAccountValue = sessionStorage.getItem('keyForm') === null ? sessionStorage.getItem('preAccountValue') : sessionStorage.getItem('keyForm')

    for (let rows in this.answersQuiz) {
      this.answersQuiz[rows].value = JSON.stringify(this.answersQuiz[rows].value)
    }

    let body = {
      "parameters": {
        "input": {
          "questions":
            this.answersQuiz

        },
        "password": null,
        "workflowQueueId": this.quizData.workflowQueueId
      },
      "onboardingId": this.preAccountValue
    }

    this.quizService.answerQuiz(body)
      .pipe(
        tap((res) => {
          this.loading.hide();
          //console.log('Resposta post', res)
          if (res.status === 'Negative') {
            this.showAlertError(res.executionMessage)
          } else {

            if(this.typeFlow === 'PHHYSICAL_PERSON' && onboarding_light == 'true' || this.typeFlow === 'PHYSICAL_PERSON' && onboarding_light == 'true'){
              this.navCtrl.navigateRoot('document-type')
            }

            if(this.typeFlow === 'LEGAL_PERSON' && onboarding_light == 'true'){
              this.navCtrl.navigateRoot('documents-partner')
            }

            if (this.typeFlow === 'PHHYSICAL_PERSON' || this.typeFlow === 'PHYSICAL_PERSON' || this.typeFlow === 'MEI') {
              this.router.navigate(['document-type'])
            }
            if (this.typeFlow === 'LEGAL_PERSON') {
              this.router.navigate(['documents-partner'])
            }
            if (this.typeFlow === 'LIBERAL_PROFESSIONAL') {
              this.router.navigate(['documents-liberal'])
            }
            // }this.navCtrl.navigateForward(['documents-partner'], { queryParams: { type: type[0].type } });
          }
        }),
        catchError((err) => {
          this.loading.hide();
          //console.log('Erro', err)
          return of(null);
        })
      )
      .subscribe();
  }

  async changeRadioValue(id: number, value: any) {

    this.slides.lockSwipeToNext(false)
    let index
    await this.slides.getActiveIndex()
      .then(res => {
        index = res
      })

    if (this.answersQuiz.length === 0) {
      let values = []
      values.push(parseInt(value.detail.value))
      this.answersQuiz.push(
        { id: id, value: values }
      )
    }

    if (this.answersQuiz[index] === undefined) {
      let values = []
      values.push(parseInt(value.detail.value))
      this.answersQuiz.push(
        { id: id, value: values }
      )
    }

    for (let rows in this.answersQuiz) {
      if (this.answersQuiz[rows].id === id) {
        this.answersQuiz[rows].value = []
        this.answersQuiz[rows].value.push(
          parseInt(value.detail.value)
        )
      }
    }

    this.slides.slideNext();

    //console.log(this.answersQuiz)
  }

  async changeCheckBox(id: number, value: any, index: any) {

    // this.slides.lockSwipeToNext(false)
    // this.values.sort();

    // let indexSlide
    // await this.slides.getActiveIndex()
    //   .then(res => {
    //     indexSlide = res
    //   })

    if (value.detail.checked === true) {
      this.values.push(
        parseInt(value.detail.value)
      )
    } else {
      this.values.splice(index, 1);
    }

    if (this.answersQuiz.length === 0) {
      let values = this.values
      this.answersQuiz.push(
        { id: id, value: values }
      )
    }

    // if (this.answersQuiz[indexSlide] === undefined) {
    //   let values = this.values
    //   this.answersQuiz.push(
    //     { id: id, value: values }
    //   )
    // }

    for (let rows in this.answersQuiz) {
      if (this.answersQuiz[rows].id === id) {
        this.answersQuiz[rows].value = this.values
      }
    }

  }

  checkAllAnswers() {
    if (this.quizData.questions.length !== this.answersQuiz.length) {
      return true
    } else {
      return false
    }
  }

  ionViewWillEnter() {
    //console.log('Quiz data', this.quizData)
    // this.slides.lockSwipeToNext(true)
  }

  checkDate() {
    if (this.birthDate.length < 10) {
      this.okbirthDate = false
    } else {
      this.okbirthDate = true
    }
  }

  async showAlertError(message) {
    const alert = await this.alertController.create({
      header: 'Atenção!',
      message: message,
      cssClass: 'custom-alert',
      buttons: ['OK'],
    });

    await alert.present();
  }

}
