import { NgModule } from '@angular/core';
import { ThemesRoutingModule } from './themes-routing.module';
import { ThemesComponent } from './themes.component';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { SentinelModule } from './sentinel/sentinel.module';
import { environment } from '../../../environments/environment';
import { InterceptService } from '../../core/intercept/intercept.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  declarations: [ThemesComponent],
  imports: [
    IonicModule,
    CommonModule,
    ThemesRoutingModule,
    !environment.wlThemeParameterization.wlTheme.realm.includes('premiadao') &&
    environment.wlThemeParameterization.wlTheme.realm !== 'clubensc'
      ? SentinelModule
      : CommonModule,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
})
export class ThemesModule {}
