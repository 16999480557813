import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import {
  ComercialAgentService,
  PartnersEcService,
  ProductsService,
  StatusAccountECService,
  UiBsEcModule,
} from '@wlp/ui-bs-ec';
import { UiBsLoginModule } from '@wlp/ui-bs-login';
import { UploadService } from '@wlp/ui-bs-signup';
import { UiBsTranslateModule } from '@wlp/ui-bs-translate';
import { UiCAcceptContentEcModule } from '@wlp/ui-c-accept-content-ec';
import { UiCAddPartnersModule } from '@wlp/ui-c-add-partners';
import { UiCAddPartnersEcModule } from '@wlp/ui-c-add-partners-ec';
import { UiCBoxRadiusModule } from '@wlp/ui-c-box-radius';
import { UiCButtonModule } from '@wlp/ui-c-button';
import { UiCComercialAgentModule } from '@wlp/ui-c-comercial-agent';
import { UiCContainerBrandModule } from '@wlp/ui-c-container-brand';
import { UiCDashboardHeaderModule } from '@wlp/ui-c-dashboard-header';
import { UiCFaqDetailModule } from '@wlp/ui-c-faq-detail';
import { UiCHeaderModule } from '@wlp/ui-c-header';
import { UICMenuAppModule } from '@wlp/ui-c-menu-app';
import { UiCPartnersModule } from '@wlp/ui-c-partners';
import { UiCSwiperModule } from '@wlp/ui-c-swiper';
import { UiCUploadFileWebEcModule } from '@wlp/ui-c-upload-file-web-ec';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ComponentsModule } from 'src/app/core/shared/modules/components.module';
import { environment } from 'src/environments/environment';

import { FaqEcFilterPipe } from './faq/faq.filter.pipe';
import { FaqPage } from './faq/faq.page';
import { StatusAccountEcPage } from './notifications/status-account-ec/status-account-ec.page';
import { StatusResponseEcPage } from './notifications/status-response-ec/status-response-ec.page';
import { OnboardingEcPageRoutingModule } from './onboarding-ec-routing.module';
import { OnboardingEcPage } from './onboarding-ec.page';
import { OnboardingEcService } from './onboarding-ec.service';
import { ChooseDocumentEcPage } from './sign-up/choose-document-ec/choose-document-ec.page';
import { ComercialAgentEcPage } from './sign-up/comercial-agent-ec/comercial-agent-ec.page';
import { ContractEcPage } from './sign-up/contract-ec/contract-ec.page';
import { DocumentTypePageEC } from './sign-up/document-type/document-type.page';
import { PartnersECPage } from './sign-up/partners-ec/partners-ec.page';
import { PrivacyPolicyEcPage } from './sign-up/privacy-policy-ec/privacy-policy-ec.page';
import { ProductsAndServicesEcPage } from './sign-up/products-and-services-ec/products-and-services-ec.page';
import { ShowcaseEcPage } from './sign-up/showcase-ec/showcase-ec.page';
import { UploadDocumentsEcPage } from './sign-up/upload-documents-ec/upload-documents-ec.page';

/* import { UiBsEcModule, ComercialAgentService, PartnersEcService } from '@wlp/ui-bs-ec'; */
import { ReactiveFormsModule } from '@angular/forms';
import {ModalsModule} from "../../../modals/modals.module";
import {InterceptService} from "../../../../../../core/intercept/intercept.service";
import {HTTP_INTERCEPTORS} from "@angular/common/http";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    ComponentsModule,
    OnboardingEcPageRoutingModule,
    UiBsTranslateModule,
    UiBsEcModule.forRoot({ device: 'WEB', config: environment }),
    UiCSwiperModule,
    UiCButtonModule,
    UiCDashboardHeaderModule,
    UiCAddPartnersModule,
    UiCPartnersModule,
    UiCContainerBrandModule,
    UiCHeaderModule,
    UiCAddPartnersEcModule,
    UiCUploadFileWebEcModule,
    UiCComercialAgentModule,
    UiBsLoginModule,
    UiCAcceptContentEcModule,
    UiBsEcModule,
    UiCBoxRadiusModule,
    UICMenuAppModule,
    UiCFaqDetailModule,
    ModalsModule,
    AngularSvgIconModule.forRoot(),
  ],
  declarations: [
    ContractEcPage,
    OnboardingEcPage,
    ShowcaseEcPage,
    FaqPage,
    FaqEcFilterPipe,
    ComercialAgentEcPage,
    PartnersECPage,
    UploadDocumentsEcPage,
    ContractEcPage,
    ProductsAndServicesEcPage,
    PrivacyPolicyEcPage,
    ChooseDocumentEcPage,
    StatusResponseEcPage,
    StatusAccountEcPage,
    DocumentTypePageEC,
  ],
  providers: [
    StatusAccountECService,
    ComercialAgentService,
    UploadService,
    PartnersEcService,
    UiCAcceptContentEcModule,
    ProductsService,
    OnboardingEcService,
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
})
export class OnboardingEcPageModule {}
