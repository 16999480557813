import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TransferService } from '@wlp/ui-bs-cashout';

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-search-bank.component.html',
  styleUrls: ['./modal-search-bank.component.scss'],
})
export class ModalSearchBankComponent {
  @Input() withIspb: boolean;

  public bankList: Array<any>;
  public banksFilter: Array<any>;

  constructor(public modalController: ModalController, private transferService: TransferService) {
    this.withIspb = this.withIspb ? true : false;
    this.listBanks();
  }

  async listBanks() {
    if (this.withIspb) {
      this.bankList = await this.transferService.getBankListWithIspb();
    } else {
      this.bankList = await this.transferService.getBankList();
    }
    this.banksFilter = await this.bankList;
  }

  filterListBank(value) {
    if (value === undefined || value === '') {
      this.banksFilter = this.bankList;
    } else {
      this.banksFilter = this.bankList.filter(
        (unit) => (unit.code + ' - ' + unit.name.toUpperCase()).indexOf(value.toUpperCase()) > -1
      );
    }
  }

  async chooseBank(item) {
    this.modalController.dismiss(item);
  }
}
