import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertController, NavController } from '@ionic/angular';
import { AccountDataService, TransferResponse, TransferService } from '@wlp/ui-bs-cashout';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';

@Component({
  selector: 'ui-t-transfer-document',
  templateUrl: './transfer-document.page.html',
  styleUrls: ['./transfer-document.page.scss'],
})
export class TransferDocumentPage implements OnInit {

  public formTransfer: FormGroup;
  public errorMessages: any;
  public transferOnUs = false;

  public transferData: any;

  userProfile: UserInfoProfile;
  @Output() transferType = new EventEmitter;

  private documentAccount: string;

  constructor(
    private transferService: TransferService,
    private navCtrl: NavController,
    private accountDataService: AccountDataService,
    public alertController: AlertController,
    protected userInfoService: UserInfoService,
  ) {
  }

  ngOnInit() {
    this.errorMessages = this.transferService.getFormMessageError();
    this.formTransfer = this.transferService.createFormTransfer();
    this.loadUser();
  }

  ionViewWillEnter() {
    this.transferService.setTransferFlow('On-Us');
  }

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then(user => {
        this.userProfile = user;
        if (this.userProfile) {
          this.sameOwnership();
        }
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  public formIsValid() {
    if (this.formTransfer.controls.document.status === 'VALID') {
      return true;
    } else {
      return false;
    }
  }

  public async continue() {
    const data = this.formTransfer.getRawValue();
    const document = data.document;

    if (this.documentAccount === document.replace(/[^\w\s]/gi, '')) {
      this.sameDocumentError();
    } else {
      this.transferService
        .documentConsult(this.userProfile.uuidWhiteLabel, document)
        .then((res) => {
          if (res) {
            this.loadData();
            return;
          }
          this.transferOnUs = false;
          this.accountNotFound();
          this.formTransfer.controls.typeTransfer.setValue('TED');
          this.transferService.setTransfer(this.formTransfer);

          return;
        })
        .catch((err) => this.transferService.getErrorTransfer(err));
    }
  }

  sameOwnership() {
    this.accountDataService.getAccountPaymentPerson(this.userProfile.uuidAccount).subscribe(
      (res) => {
        this.documentAccount = res.documentDefault;
      },
      (error) => {
        console.error(error);
      },
    );
  }

  async accountNotFound() {
    const alert = await this.alertController.create({
      cssClass: 'account-not-found',
      header: 'Atenção',
      subHeader: 'O documento não foi encontrado!',
      message: 'Verifique se foi digitado corretamente.',
      buttons: ['OK'],
    });
    await alert.present();
  }

  async sameDocumentError() {
    const alert = await this.alertController.create({
      cssClass: 'account-not-found',
      header: 'Atenção',
      subHeader: 'O documento não pode ser utilizado!',
      message: 'O mesmo é atribuído a conta atual.',
      buttons: ['OK'],
    });
    await alert.present();
  }

  public loadData() {
    this.transferService
      .getTransfer()
      .subscribe((res: TransferResponse) => {
        this.transferData = res;
        this.transferOnUs = true;
      });

    this.formTransfer = this.transferService.getFormTransfer();
  }

  public onUsTransfer() {
    this.formTransfer.controls.typeTransfer.setValue('On-Us');
    this.transferService.setTransfer(this.formTransfer);
    this.navCtrl.navigateRoot('app/transfer/on-us');
  }

}
