import {Routes} from '@angular/router';
import {CanAuthenticationGuard} from '../../../core/auth/can-authentication-guard';
import {ThemesComponent} from '../themes.component';

export const routesCP: Routes = [
  {
    path: '',
    component: ThemesComponent,
    children: [
      {
        path: '',
        redirectTo: 'welcome',
        pathMatch: 'full',
      },
      {
        path: 'welcome',
        loadChildren: () => import('../sentinel/pages/intro/intro.module').then(m => m.IntroPageModule),
      },
      {
        path: 'app',
        loadChildren: () => import('../sentinel/pages/cp-manager/cp-manager.module').then(m => m.CpManagerModule),
        canActivate: [CanAuthenticationGuard],
        data: { roles: ['cp-client'] },
      },
      {
        path: 'login',
        redirectTo: 'app/dashboard',
      },
      {
        path: 'account-activation',
        loadChildren: () => import('../sentinel/pages/account-ativation/account-ativation.module').then(m => m.AccountAtivationPageModule),
      },

    ],
  },
];
