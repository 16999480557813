import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { FileAccount, QuizService, UploadService } from '@wlp/ui-bs-signup';
import { environment } from 'src/environments/environment';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { Storage } from '@ionic/storage';
import { ModalCombateAFraudeComponent } from '../../../../modals/modal-combate-a-fraude/modal-combate-a-fraudecomponent';
import { catchError, tap } from 'rxjs/operators';
import { Subscription, of } from 'rxjs';

@Component({
  selector: 'ui-t-documents-liberal',
  templateUrl: './documents-liberal.page.html',
  styleUrls: ['./documents-liberal.page.scss'],
})
export class DocumentsLiberalPage {
  public brand: string;
  private fileAccount: FileAccount;
  public navigationBackground: string;
  private optionalPages: boolean;

  private selfieUrl: string = ''
  private rgFrontUrl: string
  private rgBackUrl: string
  private cnhFrontUrl: string
  private cnhBackUrl: string
  private ocrData: any
  private cpfValue: string
  private preAccountValue: string

  public selfToCombateAFraude = false
  public documentsFrontAndBack = false
  public residentialCertificate = false
  private residentialCertificateUrl: string

  constructor(
    private navCtrl: NavController,
    private uploadService: UploadService,
    private layoutConfigService: LayoutConfigService,
    private modalController: ModalController,
    private alertController: AlertController,
    private storage: Storage,
    private quizService: QuizService,
    private loadingService: LoadingService,
  ) {
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
  }

  async ionViewWillEnter() {
    this.uploadService.loadDocumentuploaded();
    this.optionalPages = environment.wlThemeParameterization.wlTheme.optionalPages;

    this.documentsFrontAndBack = sessionStorage.getItem('documentsFrontAndBack') === 'true' ? true : false

    this.selfToCombateAFraude = sessionStorage.getItem('selfToCombateAFraude') === 'true' ? true : false

    this.selfieUrl = sessionStorage.getItem('selfieUrl') !== null ? sessionStorage.getItem('selfieUrl') : ''

    this.rgFrontUrl = sessionStorage.getItem('rgFrontUrl') !== null ? sessionStorage.getItem('rgFrontUrl') : ''

    this.rgBackUrl =  sessionStorage.getItem('rgBackUrl') !== null ? sessionStorage.getItem('rgBackUrl') : ''
    
    this.cnhFrontUrl = sessionStorage.getItem('cnhFrontUrl') !== null ? sessionStorage.getItem('cnhFrontUrl') : ''

    this.cnhBackUrl =  sessionStorage.getItem('cnhBackUrl') !== null ? sessionStorage.getItem('cnhBackUrl') : ''

    this.ocrData =  sessionStorage.getItem('ocrData') !== null ? sessionStorage.getItem('ocrData') : ''
  }

  goBack() {
    this.navCtrl.navigateForward('professional-type');
  }

  async rGOrCPF() {

    const alert = await this.alertController.create({
      header: 'Você irá enviar qual documento?',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'RG',
          cssClass: 'alert-button-cancel',
          handler: () => {
            this.GoToModalCombateAFraude('rg')
          }
        },
        {
          text: 'CNH',
          cssClass: 'alert-button-confirm',
          handler: () => {
            this.GoToModalCombateAFraude('cnh')
          }
        },
      ],
    });

    await alert.present();
    // this.fileAccount = new FileAccount();
    // this.fileAccount.groupDocument = 'LIBERAL_PROFESSIONAL';
    // this.uploadService.createFileAccount(this.fileAccount);
    // this.navCtrl.navigateRoot('choose-document');
  }

  professionalCertification() {
    this.fileAccount = new FileAccount();
    this.fileAccount.uploadType = 'PROFESSIONAL_CERTIFICATE';
    this.fileAccount.positionPhoto = 'FRONT';
    this.fileAccount.title = 'Certificado Profissional';
    this.uploadService.createFileAccount(this.fileAccount);
    this.redirectToUpload();
  }

  proofAddress() {
    this.fileAccount = new FileAccount();
    this.fileAccount.uploadType = 'RESIDENCIAL_CERTIFICATE';
    this.fileAccount.positionPhoto = 'FRONT';
    this.fileAccount.title = 'Comprovante';
    this.uploadService.createFileAccount(this.fileAccount);
    this.redirectToUpload();
  }

  photoSelf() {
    this.GoToModalCombateAFraude('passive')
    // this.fileAccount = new FileAccount();
    // this.fileAccount.uploadType = 'SELF_PHOTO';
    // this.fileAccount.positionPhoto = 'FRONT';
    // this.fileAccount.title = 'Selfie';
    // this.uploadService.createFileAccount(this.fileAccount);
    // this.navCtrl.navigateRoot('upload-documents');
  }

  markButton(type: string): string {
    let isLoaded = this.isLoaded(type);
    if (type === 'RG') {
      isLoaded = this.documentsFrontAndBack;
    }
    if (type === 'SELF_PHOTO') {
      isLoaded = this.selfToCombateAFraude
    }
    this.isDisabled();
    return isLoaded ? 'checkmark-circle' : 'ellipse-outline';
  }

  isDisabled(): boolean {
    let isRgOrCnh = this.documentsFrontAndBack
    if (!isRgOrCnh) {
      isRgOrCnh = this.documentsFrontAndBack
    }
    let isProfessionalCertificate = this.isLoaded('PROFESSIONAL_CERTIFICATE');
    let isResidencial = this.isLoaded('RESIDENCIAL_CERTIFICATE');
    let isSelfie = this.selfToCombateAFraude;
    const disabled = isRgOrCnh && isProfessionalCertificate && isResidencial && isSelfie;
    return !disabled;
  }

  async continue() {
    sessionStorage.setItem('ocrData', JSON.stringify(this.ocrData))
    this.navCtrl.navigateRoot('professional-type');
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }

  private redirectToUpload(isRGOrCnh?: boolean): void {
    this.uploadService
      .loadFileTypeUpload()
      .then((res) => this.navCtrl.navigateRoot('upload-documents'))
      .catch((err) => this.uploadService.getErroUpload(err));
  }

  private isLoaded(type: string): boolean {
    return this.uploadService.isDocumentUploaded(type);
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }

  public async GoToModalCombateAFraude(type: string) {

    const modal = await this.modalController.create({
      component: ModalCombateAFraudeComponent,
      componentProps: {
        type: type
      },
    });

    modal.onDidDismiss().then(async (data) => {
      if (type === 'passive') {
        if (data.data && data.role === 'ok') {
          this.selfToCombateAFraude = true
          sessionStorage.setItem('selfToCombateAFraude', this.selfToCombateAFraude.toString())
          this.selfieUrl = data.data.signedResponse;
          sessionStorage.setItem('selfieUrl', this.selfieUrl);
        } else if (data.role === 'fail') {
          this.showAlertError();
        }
      }
      if (type === 'rg' || type === 'cnh') {

        if (data.data && data.role === 'ok') {
          this.documentsFrontAndBack = true
          sessionStorage.setItem('documentsFrontAndBack', this.documentsFrontAndBack.toString())
          if (type === 'rg') {
            this.rgFrontUrl = data.data[0].imageUrl
            this.rgBackUrl  = data.data[1].imageUrl
            sessionStorage.setItem('rgFrontUrl', this.rgFrontUrl)
            sessionStorage.setItem('rgBackUrl', this.rgBackUrl)
          } else if (type === 'cnh') {
            this.cnhFrontUrl = data.data[0].imageUrl
            this.cnhBackUrl  = data.data[1].imageUrl
            sessionStorage.setItem('cnhFrontUrl', this.cnhFrontUrl)
            sessionStorage.setItem('cnhBackUrl', this.cnhBackUrl)
          }
        } else if (data.role === 'fail') {
          this.showAlertError();
        }
      }

      if(
        this.selfieUrl && this.rgFrontUrl && this.rgBackUrl
        ){
          this.loadingService.show();
        this.analyseDocumentOCR(this.rgFrontUrl, this.rgBackUrl, 'RG_FRONT', 'RG_BACK', this.selfieUrl)
      }
      if(
        this.selfieUrl &&  this.cnhFrontUrl && this.cnhBackUrl
        ){
          this.loadingService.show();
        this.analyseDocumentOCR(this.cnhFrontUrl, this.cnhBackUrl, 'CNH_FRONT', 'CNH_BACK', this.selfieUrl)
      }
    });

    return await modal.present();

  }

  async showAlertError() {
    const alert = await this.alertController.create({
      header: 'Tente novamente!',
      message: 'Algo deu errado durante a validação, por favor tente novamente.',
      cssClass: 'custom-alert',
      buttons: ['OK'],
    });

    await alert.present();
  }

  async showAlertErrorCamera() {
    const alert = await this.alertController.create({
      header: 'Tente novamente!',
      message: 'Algo deu errado ao carregar sua câmera, por favor tente novamente.',
      cssClass: 'custom-alert',
      buttons: ['OK'],
    });

    await alert.present();
  }


  async analyseDocumentOCR(frontUrl, backUrl, typeFront, typeBack, selfieUrl?) {

    this.cpfValue = sessionStorage.getItem('cpfValue')

    this.preAccountValue =  sessionStorage.getItem('keyForm')

    let postData = {
      "onboardingId": this.preAccountValue,
      "attributes": {
        "cpf": this.cpfValue.replace(/\D/g, '')
      },
      "files": [
        {
          "type": typeFront,
          "data": frontUrl
        },
        {
          "type": typeBack,
          "data": backUrl
        },
        {
          "type": "SELFIE",
          "data": selfieUrl || this.fileAccount.fileBase64.photo
        }
      ]
    }

    this.quizService.checkOcr(postData)
      .pipe(
        tap((res) => {
          this.ocrData = res
          this.loadingService.hide();
        }),
        catchError((err) => {
          this.loadingService.hide();
          return of(null);
        })
      )
      .subscribe();
  }
}
