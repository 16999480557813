import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { LayoutConfigService } from './../../../../../core/layout/services/layout-config.service';

@Component({
  selector: 'app-modal-pix-management-key',
  templateUrl: './modal-pix-management-key.component.html',
  styleUrls: ['./modal-pix-management-key.component.scss'],
})
export class ModalPixManagementKeyComponent {
  public succeed: boolean;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() dismissText: string;
  @Input() confirmText: string;
  @Input() doubleButton = true;
  @Input() clearButton: boolean;
  @Input() imageModal: boolean;
  @Input() imageTag: string;

  constructor(public modalController: ModalController, private layoutConfigService: LayoutConfigService) {
    this.succeed = true;
  }

  public toggleSucceed(): void {
    this.succeed = !this.succeed;
  }

  async dismiss() {
    this.modalController.dismiss({
      dismissed: false,
    });
  }

  async confirm() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  showImage() {
    if (this.imageModal) {
      return this.getImageTag(this.imageTag);
    } else {
      return '';
    }
  }

  public getImageTag(tag: string) {
    const item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }

  // EXEMPLO DE USO

  /*   async confirmationNotificationModal() {
      const modal = await this.modalController.create({
        component: ModalPixManagementKeyComponent,
        cssClass: 'modal-pix-management-key-class-small',
        componentProps: {
          title: '',
          subtitle: 'PIX.MANAGEMENT_KEY_NOTIFICATIONS_PIX.TRANSACTION_NOT_COMPLETED',
          dismissText: 'ENTENDI',
          confirmText: '',
          imageModal: true,
          imageTag: 'confirmated',
          doubleButton: false,
          clearButton: false
        }
      });
      return await modal.present();
    } */
}
