import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TransferService } from '@wlp/ui-bs-cashout';
import {ShowcaseService} from "@wlp/ui-bs-ec";
import {LoadingService} from "../../../../../core/layout/services/loading.service";

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-search-mcc.component.html',
  styleUrls: ['./modal-search-mcc.component.scss'],
})
export class ModalSearchMccComponent implements OnInit {

  public mccList: Array<any>;
  public mccsFilter: Array<any>;

  constructor(
    public modalController: ModalController,
    private showcaseService: ShowcaseService,
    private cdr: ChangeDetectorRef,
  ) {
    this.listMccs();
  }

  ngOnInit() {
  }

  private listMccs() {
     this.showcaseService.findActivityBranch().subscribe( res => {
      // console.log('findActivityBranch', res);
      this.mccList = res;
      this.mccsFilter = res;
    }, error => {
       console.log('Erro ao carrecar MCC ', error);
     });
  }

  public filterListMcc(value) {
    if (value === undefined || value === '') {
      this.mccsFilter = this.mccList;
    } else {
      // this.banksFilter = this.mccList.filter((unit) => (unit.code + ' - ' + unit.name.toUpperCase()).indexOf(value.toUpperCase()) > -1);
      this.mccsFilter = this.mccList.filter((unit) => (unit.mcc.code + ' - ' + unit.mcc.description.normalize("NFD").replace(/[^a-zA-Zs]/g, "").toUpperCase()).indexOf(value.normalize("NFD").replace(/[^a-zA-Zs]/g, "").toUpperCase()) > -1);
    }
  }

  public async choose(item) {
    this.modalController.dismiss(item);
  }
}
