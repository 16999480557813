import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'bankAgency' })
export class BankAgencyPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';

    value = String(value).padStart(4, '0');

    return value;
  }
}
