import { Component, Input, OnInit } from '@angular/core';
import { NavController, AlertController, LoadingController } from '@ionic/angular';
import { TransferLimitService } from '@wlp/ui-bs-transfer-limit';
import { tap } from 'rxjs/operators';
import { StatusEnum } from '../enum/status.enum.component';

@Component({
  selector: 'tab-history',
  templateUrl: './history.page.html',
  styleUrls: ['./history.page.scss'],
})
export class HistoryPage implements OnInit {
  @Input() uData: any;
  transferHistory: any[];
  billetHistory: any[];
  withdrawHistory: any[];
  loading: boolean = true;
  loadingObject: HTMLIonLoadingElement;
  currentPageTransfer: number = 0;
  currentPageBillet: number = 0;
  currentPageWithdraw: number = 0;
  statusEnum = StatusEnum;
  statusTransfer: string = '';
  statusBillet: string = '';
  statusWithdraw: string = '';
  statusList: any[];

  constructor(
    private transferLimitService: TransferLimitService,
    private navCtrl: NavController,
    private alertController: AlertController,
    public loadingController: LoadingController,
  ) { }

  async ngOnInit() {
    this.loadingObject = await this.loadingController.create({
      message: 'Carregando...',
    });
    this.loadingObject.present();
    this.statusList = Object.keys(this.statusEnum).map((key) => {
      return {
        key: key,
        value: this.statusEnum[key].toUpperCase(),
      };
    });
    this.transferLimitService.getLimitsHistory(this.uData['uuidWhiteLabel'], this.uData['uuidAccount'], this.currentPageTransfer, '', '', 'TRANSFER', '', this.statusTransfer, '', '', '', 10).pipe(tap((response) => {
      this.transferHistory = response['content'];
      this.transferHistory.forEach((limit) => {
        limit['valuePerTransaction'] = parseFloat(limit['valuePerTransaction']).toFixed(2);
        limit['valuePerPeriod'] = parseFloat(limit['valuePerPeriod']).toFixed(2);
        limit['status'] = this.statusEnum[limit['status']].toUpperCase();
      });
      this.transferLimitService.getLimitsHistory(this.uData['uuidWhiteLabel'], this.uData['uuidAccount'], this.currentPageBillet, '', '', 'BILLETS', '', this.statusBillet, '', '', '', 10).pipe(tap((response) => {
        this.billetHistory = response['content'];
        this.billetHistory.forEach((limit) => {
          limit['valuePerTransaction'] = parseFloat(limit['valuePerTransaction']).toFixed(2);
          limit['valuePerPeriod'] = parseFloat(limit['valuePerPeriod']).toFixed(2);
          limit['status'] = this.statusEnum[limit['status']].toUpperCase();
        });
        this.transferLimitService.getLimitsHistory(this.uData['uuidWhiteLabel'], this.uData['uuidAccount'], this.currentPageWithdraw, '', '', 'WITHDRAW', '', this.statusWithdraw, '', '', '', 10).pipe(tap((response) => {
          this.withdrawHistory = response['content'];
          this.withdrawHistory.forEach((limit) => {
            limit['valuePerTransaction'] = parseFloat(limit['valuePerTransaction']).toFixed(2);
            limit['valuePerPeriod'] = parseFloat(limit['valuePerPeriod']).toFixed(2);
            limit['status'] = this.statusEnum[limit['status']].toUpperCase();
          });
          this.loading = false;
          this.loadingObject.dismiss();
        }, (error) => {
          this.showError(error.status.toString() + " - Ocorreu um erro ao processar sua solicitação");
        })).subscribe();
      }, (error) => {
        this.showError(error.status.toString() + " - Ocorreu um erro ao processar sua solicitação");
      })).subscribe();
    }, (error) => {
      this.showError(error.status.toString() + " - Ocorreu um erro ao processar sua solicitação");
    })).subscribe();
  }

  async showError(message) {
    const alert = await this.alertController.create({
      header: 'Ocorreu um erro',
      message: message,
      buttons: [
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {
            this.navCtrl.back();
          },
        },
      ],
    });

    await alert.present();
  }

  getTransferHistory() {
    this.loadingObject.present();
    this.transferLimitService.getLimitsHistory(this.uData['uuidWhiteLabel'], this.uData['uuidAccount'], this.currentPageTransfer, '', '', 'TRANSFER', '', this.statusTransfer, '', '', '', 10).pipe(tap((response) => {
      this.transferHistory = response['content'];
      this.transferHistory.forEach((limit) => {
        limit['valuePerTransaction'] = parseFloat(limit['valuePerTransaction']).toFixed(2);
        limit['valuePerPeriod'] = parseFloat(limit['valuePerPeriod']).toFixed(2);
        limit['status'] = this.statusEnum[limit['status']].toUpperCase();
      });
      this.loading = false;
      this.loadingObject.dismiss();
    }, (error) => {
      this.showError(error.status.toString() + " - Ocorreu um erro ao processar sua solicitação");
    })).subscribe();
  }

  getBilletHistory() {
    this.loadingObject.present();
    this.transferLimitService.getLimitsHistory(this.uData['uuidWhiteLabel'], this.uData['uuidAccount'], this.currentPageBillet, '', '', 'BILLETS', '', this.statusBillet, '', '', '', 10).pipe(tap((response) => {
      this.billetHistory = response['content'];
      this.billetHistory.forEach((limit) => {
        limit['valuePerTransaction'] = parseFloat(limit['valuePerTransaction']).toFixed(2);
        limit['valuePerPeriod'] = parseFloat(limit['valuePerPeriod']).toFixed(2);
        limit['status'] = this.statusEnum[limit['status']].toUpperCase();
      });
      this.loading = false;
      this.loadingObject.dismiss();
    }, (error) => {
      this.showError(error.status.toString() + " - Ocorreu um erro ao processar sua solicitação");
    })).subscribe();
  }

  getWithdrawHistory() {
    this.loadingObject.present();
    this.transferLimitService.getLimitsHistory(this.uData['uuidWhiteLabel'], this.uData['uuidAccount'], this.currentPageWithdraw, '', '', 'WITHDRAW', '', this.statusWithdraw, '', '', '', 10).pipe(tap((response) => {
      this.withdrawHistory = response['content'];
      this.withdrawHistory.forEach((limit) => {
        limit['valuePerTransaction'] = parseFloat(limit['valuePerTransaction']).toFixed(2);
        limit['valuePerPeriod'] = parseFloat(limit['valuePerPeriod']).toFixed(2);
        limit['status'] = this.statusEnum[limit['status']].toUpperCase();
      });
      this.loading = false;
      this.loadingObject.dismiss();
    }, (error) => {
      this.showError(error.status.toString() + " - Ocorreu um erro ao processar sua solicitação");
    })).subscribe();
  }

  async filterTransferHistory() {
    const alert = await this.alertController.create({
      header: 'Selecione o status desejado',
      inputs: this.statusList.map((status) => {
        return {
          name: status.key,
          type: 'radio',
          label: status.value,
          value: status.key,
          handler: () => {
            this.statusTransfer = status.key;
          },
          checked: status.key === this.statusTransfer,
        };
      }),
      buttons: [
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {
            this.getTransferHistory();
          },
        },
      ],
    });

    await alert.present();
  }

  async filterBilletHistory() {
    const alert = await this.alertController.create({
      header: 'Selecione o status desejado',
      inputs: this.statusList.map((status) => {
        return {
          name: status.key,
          type: 'radio',
          label: status.value,
          value: status.key,
          handler: () => {
            this.statusBillet = status.key;
          },
          checked: status.key === this.statusBillet,
        };
      }),
      buttons: [
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {
            this.getBilletHistory();
          },
        },
      ],
    });

    await alert.present();
  }

  async filterWithdrawHistory() {
    const alert = await this.alertController.create({
      header: 'Selecione o status desejado',
      inputs: this.statusList.map((status) => {
        return {
          name: status.key,
          type: 'radio',
          label: status.value,
          value: status.key,
          handler: () => {
            this.statusWithdraw = status.key;
          },
          checked: status.key === this.statusWithdraw,
        };
      }),
      buttons: [
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {
            this.getWithdrawHistory();
          },
        },
      ],
    });

    await alert.present();
  }

}
