import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AccountTypeService, FileAccount, UploadService } from '@wlp/ui-bs-signup';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'ui-t-company-documents',
  templateUrl: './company-documents.page.html',
  styleUrls: ['./company-documents.page.scss'],
})
export class CompanyDocumentsPage implements OnInit {
  public brand: string;
  public navigationBackground: string;
  public typeFlow: string;
  private fileAccount: FileAccount;
  private optionalPages: boolean;

  constructor(
    private navCtrl: NavController,
    private uploadService: UploadService,
    private accountTypeService: AccountTypeService,
    private layoutConfigService: LayoutConfigService
  ) {
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
  }

  ngOnInit(): void {
    this.accountTypeService.loadRegisteredAccountType().then((res) => {
      this.typeFlow = res.accountType.typeFlow;
    });
    this.optionalPages = environment.wlThemeParameterization.wlTheme.optionalPages;
  }

  ionViewWillEnter(): void {
    this.uploadService.loadDocumentuploaded();
  }

  CNPJCard() {
    this.fileAccount = new FileAccount();
    this.fileAccount.uploadType = 'CNPJ';
    this.fileAccount.positionPhoto = 'FRONT';
    this.fileAccount.title = 'Cnpj';
    this.fileAccount.groupDocument = this.typeFlow === 'MEI' ? 'MEI' : 'LEGAL_PERSON_COMPANY';
    console.log('File account', this.fileAccount)
    this.uploadService.createFileAccount(this.fileAccount);
    this.redirectToUpload();
  }

  socialContract() {
    this.fileAccount = new FileAccount();
    this.fileAccount.uploadType = 'SOCIAL_CONTRACT';
    this.fileAccount.positionPhoto = 'FRONT';
    this.fileAccount.title = 'Contrato Social';
    this.fileAccount.groupDocument = 'LEGAL_PERSON_COMPANY';
    this.uploadService.createFileAccount(this.fileAccount);
    this.redirectToUpload();
  }

  meiOpeningTerms() {
    this.fileAccount = new FileAccount();
    this.fileAccount.uploadType = 'MEI_OPENING_TERMS';
    this.fileAccount.positionPhoto = 'FRONT';
    this.fileAccount.title = 'Termos de abertura do MEI';
    this.fileAccount.groupDocument = 'MEI';
    this.uploadService.createFileAccount(this.fileAccount);
    this.redirectToUpload();
  }

  companyAddress() {
    this.fileAccount = new FileAccount();
    this.fileAccount.uploadType = 'RESIDENCIAL_CERTIFICATE_LEGAL_PERSON';
    this.fileAccount.positionPhoto = 'FRONT';
    this.fileAccount.title = 'Comprovante de Endereço';
    this.fileAccount.groupDocument = 'LEGAL_PERSON_COMPANY';
    this.uploadService.createFileAccount(this.fileAccount);
    this.redirectToUpload();
  }

  minutesMeeting() {
    this.fileAccount = new FileAccount();
    this.fileAccount.uploadType = 'MINUTES_MEETING';
    this.fileAccount.positionPhoto = 'FRONT';
    this.fileAccount.title = 'Ata';
    this.fileAccount.groupDocument = 'LEGAL_PERSON_COMPANY';
    this.uploadService.createFileAccount(this.fileAccount);
    this.redirectToUpload();
  }

  markButton(type: string): string {
    let isLoaded = this.isLoaded(type, 'MarkButton');
    if (type === 'RG' && !isLoaded) {
      isLoaded = this.isLoaded('CNH', 'MarkButton2');
    }
    this.isDisabled();
    return isLoaded ? 'checkmark-circle' : 'ellipse-outline';
  }

  isDisabled(): boolean {
    let hasCNPJ = this.isLoaded('CNPJ', 'IsDisabled1');
    let hasSocialContract = this.isLoaded('SOCIAL_CONTRACT', 'IsDisabled2');
    let hasMeiOpeningTerms = this.isLoaded('MEI_OPENING_TERMS', 'IsDisabled3');
    let hasCompanyAddress = this.isLoaded('RESIDENCIAL_CERTIFICATE_LEGAL_PERSON', 'IsDisabled4');

    const hasAllMeiDocuments = hasCNPJ && hasMeiOpeningTerms;
    const hasAllLegalPersonDocuments = hasCNPJ && hasCompanyAddress && hasSocialContract;

    const disabled = !(this.typeFlow === 'MEI' ? hasAllMeiDocuments : hasAllLegalPersonDocuments);
    return disabled;
  }

  continue() {
    let onboarding_light = sessionStorage.getItem('onboarding_light')
    if(onboarding_light == 'true') {
      this.navCtrl.navigateRoot('create-password')
     } else {
      if (this.optionalPages) this.navCtrl.navigateRoot('company-address');
      else this.navCtrl.navigateRoot('company-address');
     }

  }

  goBack() {
    this.navCtrl.back();
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }

  private isLoaded(type: string, location? : string): boolean {
    console.log('Type do doc', type, location)
    return this.uploadService.isDocumentUploaded(type);
  }

  private redirectToUpload(): void {
    this.uploadService
      .loadFileTypeUpload()
      .then((res) => this.navCtrl.navigateRoot('upload-documents'))
      .catch((err) => this.uploadService.getErroUpload(err));
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }
}
