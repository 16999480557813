import {LayoutConfigService} from 'src/app/core/layout/services/layout-config.service';
import {environment} from 'src/environments/environment';

import {Component, OnInit} from '@angular/core';
import {ModalController, NavController, Platform} from '@ionic/angular';
import {IntroService} from '@wlp/ui-bs-intro';

import {ModalAlertComponent} from '../../modals/modal-alert/modal-alert.component';
import {KeycloakService} from '@wlp/ui-bs-login';
import { QrCodeAuthenticationGuard } from 'src/app/core/auth/qrcode-authentication-guard';
import { CreateAccountService } from '@wlp/ui-bs-signup';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'ui-t-intro',
  templateUrl: './intro.page.html',
  styleUrls: ['./intro.page.scss'],
})
export class IntroPage implements OnInit {
  public brand: string;
  public background: string;
  public brandFooter: string;
  public showSocial: boolean;
  public socialYouTube: string;
  public socialFacebook: string;
  public socialLinkedin: string;
  public socialInstagram: string;
  public appleStoreImageLink: string;
  public googleStoreImageLink: string;
  public appleStoreImage: boolean;
  public googleStoreImage: boolean;
  public isMobile: boolean;
  public env: any;
  public realm: string;
  enableButton: boolean

  constructor(
    private navCtrl: NavController,
    private modalController: ModalController,
    private introService: IntroService,
    private layoutConfigService: LayoutConfigService,
    private platform: Platform,
    private keycloakService: KeycloakService,
    private storage: QrCodeAuthenticationGuard,
    private accountService: CreateAccountService
  ) {
    this.realm = environment.wlThemeParameterization.wlTheme.realm;
    this.brand = this.layoutConfigService.getConfig('wlTheme.intro.brand.logo');
    this.showSocial = this.layoutConfigService.getConfig('wlTheme.intro.social.show');
    this.brandFooter = this.layoutConfigService.getConfig('wlTheme.intro.footer.brand');
    this.background = this.layoutConfigService.getConfig('wlTheme.intro.background.image');

    this.socialYouTube = this.layoutConfigService.getConfig('wlTheme.custom.social.youtube');
    this.socialFacebook = this.layoutConfigService.getConfig('wlTheme.custom.social.facebook');
    this.socialLinkedin = this.layoutConfigService.getConfig('wlTheme.custom.social.linkedin');
    this.socialInstagram = this.layoutConfigService.getConfig('wlTheme.custom.social.instagram');

    this.appleStoreImage = this.layoutConfigService.getConfig('wlTheme.storeImage.apple.visible');
    this.appleStoreImageLink = this.layoutConfigService.getConfig('wlTheme.storeImage.apple.link');

    this.googleStoreImage = this.layoutConfigService.getConfig('wlTheme.storeImage.google.visible');
    this.googleStoreImageLink = this.layoutConfigService.getConfig('wlTheme.storeImage.google.link');

    this.env = environment.production;
  }

  ngOnInit() {
    this.introService.cleanStorage();
    this.isMobile = this.platform.is('mobile');
    this.verifyCreateAccountButton();
  }

  async ionViewWillEnter() {
    await this.storage.setItem('validaQrCode', false)
  }

  public login(): void {
    let redirectUrl = document.location.protocol + '//' + document.location.hostname;

    if (document.location.hostname.includes('localhost')) {
      redirectUrl += ':' + document.location.port;
    }

    this.keycloakService.login({redirectUri: redirectUrl + '/app/validate-qrcode'}).then( () => {
    });
  }

  public createAccount(): void {
    this.navCtrl.navigateRoot('create-account');
  }

  public async modalAlert() {
    const modal = await this.modalController.create({
      component: ModalAlertComponent,
      cssClass: 'modal-custom-class',
    });
    return await modal.present();
  }

  public facebookLink() {
    window.open(this.socialFacebook, '_blank');
  }

  public instagramLink() {
    window.open(this.socialInstagram, '_blank');
  }

  public linkedinLink() {
    window.open(this.socialLinkedin, '_blank');
  }

  public youtubeLink() {
    window.open(this.socialYouTube, '_blank');
  }

  public enabledCreateAccount() {
    if ( environment.production ) {
      if (this.realm !== 'urbanobank' &&
        this.realm !== 'psxbank' &&
        this.realm !== 'tchenetbank') {
        return true;
      }

      return false;
    } else {
      return true;
    }
  }

  verifyCreateAccountButton() {
    this.accountService.verifyCreateAccount(environment.wlp)
    .pipe(
      tap((res) => {
        this.enableButton = res.isAllowed
      }),
      catchError((err) => {
        console.log('Erro', err)
        this.enableButton = true
        return err
      })
    )
    .subscribe();
  }
}
