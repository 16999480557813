import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import {
  AccountPaymentTypeResponse,
  AccountTypeService,
  CreateAccountService,
  IPageBase,
  PreAccountResponse,
} from '@wlp/ui-bs-signup';

import { environment } from '../../../../../../../../environments/environment';
import { ModalFlowComponent } from '../../../../modals/modal-flow/modal-flow.component';
import { Storage } from '@ionic/storage';
import Swal from 'sweetalert2'
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Geolocation } from '@ionic-native/geolocation/ngx';

@Component({
  selector: 'ui-t-create-account',
  templateUrl: './create-account.page.html',
  styleUrls: ['./create-account.page.scss'],
})
export class CreateAccountPage implements IPageBase, OnInit {
  formCreateAccount: FormGroup;
  errorMessages: any;
  brand: string;
  navigationBackground: string;
  param: object;
  envWlp: any;
  public listAccountType: AccountPaymentTypeResponse[];
  public amountDailyLimit: number;
  public amountThirtyLimit: number;
  public valueDailyLimit: number;
  public valueThirtyLimit: number;

  constructor(
    private navCtrl: NavController,
    public alertController: AlertController,
    private modalController: ModalController,
    public layoutConfigService: LayoutConfigService,
    private createAccountService: CreateAccountService,
    private accountTypeService: AccountTypeService,
    private storage: Storage,
    private geolocation: Geolocation
  ) {
    this.errorMessages = this.createAccountService.getFormMessageError();
    this.formCreateAccount = this.createAccountService.createForm();
    this.createAccountService.updateForm();
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
    this.param = { wlName: this.layoutConfigService.getConfig('wlTheme.wlName') };
    this.envWlp = environment;
  }

  ngOnInit() {
    this.loadAccountType();
  }

  ionViewWillEnter() {
    this.formCreateAccount.reset();
    this.getLocation();
  }

  async continue(route?: string): Promise<void> {
    const cpfValue = this.formCreateAccount.get('cpf').value;
    const emailValue = this.formCreateAccount.get('email').value;
    const idValue = await this.createAccountService.getLoadKeyPreAccount().then((keyID) => {
      return keyID;
    });

    sessionStorage.setItem('cpfValue', cpfValue)
    sessionStorage.setItem('emailValue', emailValue)

    this.createAccountService.verifyEmailByIdwl(emailValue, environment.wlp).subscribe(async (resp: any) => {
      if (resp && resp.length > 0) {
        if (resp.addressData && resp.addressData.cep) {
          sessionStorage.setItem('zipCodeValue', resp.addressData.cep);
        }
          if (resp.personData && resp.personData.birthDay) {
            sessionStorage.setItem('birthDateValue', resp.personData.birthDay.replace(/-/g, ""));
          }

        if (
          resp.length === 1 &&
          emailValue === resp[0].email &&
          cpfValue === resp[0].cpf &&
          this.createAccountService.getLoadKeyPreAccount() &&
          idValue === resp[0]._id
        ) {
          this.saveAccount(route);
        } else {
          this.warningEmail();
          this.formCreateAccount.get('email').setErrors({ required: true });
          this.formCreateAccount.get('emailConfirm').setErrors({ mustMatch: true });
        }
      } else {
        this.saveAccount(route);
      }
    });
  }

  saveAccount(route?: string) {
    this.createAccountService
      .createPreAccount(this.formCreateAccount, environment.wlp, route || 'email')
      .subscribe(
        async (res: PreAccountResponse) => {
          if(res){
            sessionStorage.setItem('preAccountData', res.uuidWhiteLabel)
            sessionStorage.setItem('preAccountValue', res._id)
            this.createAccountService.processPreAccount(res)
          }
          this.createAccountService.processPreAccount(res);
          this.accountTypeService.getTemporaryTypeAccount().then((accountTypeTemp) => {
            const accountType = this.listAccountType.filter((type) => {
              return type.typeFlow.toString() === accountTypeTemp;
            })[0];

            this.accountTypeService.defineTypeAccount(accountType, 'email');
          });
        },
        (err) => 
        {
          if(err.status === 400) {
            Swal.fire(
              {
              title: 'Atenção', 
              text: err.error.message, 
              icon: 'warning',
              heightAuto: false
              }
              );
          }
          this.createAccountService.getErrorPreAccount(err)
        },
        () => this.navCtrl.navigateRoot(route || 'email')
      );
  }

  goBack() {
    this.navCtrl.navigateForward('/');
  }

  checkCpf(data: any) {
    let cpfValue = this.formCreateAccount.get('cpf').value;

    this.createAccountService.checkAccountExists(environment.wlp, cpfValue)
    .pipe(
      tap(async (resp) => {
        sessionStorage.setItem('accounts', JSON.stringify(resp))
        let allowMoreAccount = sessionStorage.getItem('allowMoreAccount') != null ? sessionStorage.getItem('allowMoreAccount') : "false"
        if (resp && resp.length > 0 && allowMoreAccount == "false") {
          if (resp.length === 1 && resp[0].status === 'REPROVED') {
            this.createAccountService.processPreAccount(resp[0]);
            this.navCtrl.navigateRoot('status-account');
          } else {
            const hasOnboardingNotAllowed = resp.some((obj:any) => obj.status == "ONBOARDING_NOT_ALLOWED");
            
            if (hasOnboardingNotAllowed) {
              Swal.fire(
                {
                title: 'Atenção', 
                text: 'Infelizmente não podemos seguir com o seu cadastro, entre em contato com a central de atendimento para mais informações.', 
                icon: 'warning',
                heightAuto: false
                }
                ).then(_ => {
                  this.goBack();
                })
              return;
          }

          if(hasOnboardingNotAllowed && resp.length > 1){
            const filteredResponse = resp.filter((obj:any) => obj.status !== "ONBOARDING_NOT_ALLOWED");
            await this.modalFlow(filteredResponse);
          } else {
            await this.modalFlow(resp)
          }
          }
        } else {
          this.continue();
        }
      }),catchError(err => {
        if(err.status === 406){
          Swal.fire(
            {
            title: 'Atenção', 
            text: err.error.message, 
            icon: 'warning',
            heightAuto: false
            }
            ).then(_ => {
              this.goBack();
            })
        }
        return of(null)
      })
    ).subscribe()
    
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }

  checkValues() {
    let cpfValue = this.formCreateAccount.get('cpf').value;
    
    if (cpfValue != null){
    if(cpfValue.length > 0){

     let value = cpfValue.replace(/[^\d]+/g, '');
  
     if (value.length !== 11 || !!value.match(/(\d)\1{10}/)) {
       return true;
     }
  
     const values = value.split('').map(el => +el);
     const rest = (count) => (values.slice(0, count-12).reduce( (soma, el, index) => (soma + el * (count-index)), 0 )*10) % 11 % 10;
  
     cpfValue = rest(10) === values[9] && rest(11) === values[10];
        }
     }

    if(cpfValue){
      return false
    } else {
      return true
    }
   
  }

  async modalFlow(response: any) {
    const modal = await this.modalController.create({
      component: ModalFlowComponent,
      cssClass: 'modal-flow-container',
      componentProps: { response },
      backdropDismiss: false,
    });
    await modal.present();
  }

  public getImageTag(tag: string) {
    const item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }

  private loadAccountType(): void {
    this.accountTypeService
      .loadTypeAccount(environment.wlp)
      .then((list) => (this.listAccountType = list))
      .catch((err) => this.accountTypeService.getErrorMessages(err));
  }

  async warningEmail() {
    const alert = await this.alertController.create({
      cssClass: 'alert-warning-email',
      header: 'Atenção',
      message: 'Esse email já foi associado com outra conta, por favor, informe outro email válido.',
      buttons: ['OK'],
      backdropDismiss: false,
    });

    await alert.present();
  }

  async getLocation() {
    await this.geolocation
      .getCurrentPosition()
      .then((resp) => {
        if(resp) {
          const location = resp.coords
          sessionStorage.setItem('lastLatitude', location.latitude.toString())
          sessionStorage.setItem('lastLongitude', location.longitude.toString())
        }
      })
      .catch((error) => {
        //console.log('Erro ao pegar localização: ', error);
      });
  }
}
