import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'billetCreateDataFilter',
})
export class BilletCreateDataFilterPipe implements PipeTransform {
  transform(data: any[], search) {
    if (!search) return data;

    return data.filter(info => {
      let requestDescription = info.requestDescription.toLowerCase()
      let namePayer = info.namePayer.toLowerCase();
      let documentPayer = info.documentPayer.toLowerCase();
      let requestNumber = info.requestNumber.toLowerCase();
      return requestDescription.indexOf(search.toLowerCase()) > -1 || namePayer.indexOf(search.toLowerCase()) > -1
        || documentPayer.indexOf(search.toLowerCase()) > -1
        || requestNumber.indexOf(search.toLowerCase()) > -1;
    });
  }

  transformReceipts(data: any[], search) {
    if (!search) return data;

    return data.filter(info => {
      let requestDescription = info.requestDescription.toLowerCase()
      let namePayer = info.namePayer.toLowerCase();
      let documentPayer = info.documentPayer.toLowerCase();
      let requestNumber = info.requestNumber.toLowerCase();
      return requestDescription.indexOf(search.toLowerCase()) > -1 || namePayer.indexOf(search.toLowerCase()) > -1
        || documentPayer.indexOf(search.toLowerCase()) > -1
        || requestNumber.indexOf(search.toLowerCase()) > -1;
    });
  }
}
