import { ModalEditTransferLimitComponent } from './../../../modals/modal-edit-transfer-limit/modal-edit-transfer-limit.component';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LoadingController, ModalController, NavController } from '@ionic/angular';
import { UserInfoService } from '@wlp/ui-bs-login';
import { TransactionType, TransferLimit } from 'src/app/core/layout/models/transfer-limit.model';
import { TransferLimitService } from '@wlp/ui-bs-transfer-limit';
import { LimitManagementService } from './limit-management.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-transfer-limit',
  templateUrl: './transfer-limit.page.html',
  styleUrls: ['./transfer-limit.page.scss'],
})
export class TransferLimitPage implements OnInit {

  public userProfile: any;
  public transferSegment = "limites";

  constructor(
    public navCtrl: NavController,
    public modalController: ModalController,
    protected userInfoService: UserInfoService,
    private cdr: ChangeDetectorRef,
    private loadingService: LoadingService,
    private transferLimitService: TransferLimitService,
  ) { }

  async ngOnInit() {
    this.loadingService.show();
    this.loadUser();
  }

  public loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then(async (user) => {
        this.userProfile = user;
        this.loadingService.hide();
      });
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }

  async changeSegment(segment) {
    this.transferSegment = segment;
  }

  /*
  async openEditLimitTransferModal(response) {
    console.log("By Types: ", this.transferMyAccountsByTypes)
    const modal = await this.modalController.create({
      component: ModalEditTransferLimitComponent,
      cssClass: 'modal-review-notification',
      componentProps: {
        reponseTransfer: response,
        currentValuePerTransaction: this.transferMyAccountsByTypes[0].currentValuePerTransaction,
        maximumValuePerTransaction: this.transferMyAccountsByTypes[0].maximumValuePerTransaction,
      },
      backdropDismiss: false,
    });
    return await modal.present();
  }
  */

}
