import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CardActivePage } from './card-active/card-active.page';
import { CardIntroPage } from './card-intro/card-intro.page';
import { CardManagerPage } from './card-manager/card-manager.page';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'card-intro',
        component: CardIntroPage,
      },
      {
        path: 'card-manager',
        component: CardManagerPage,
      },
      {
        path: 'card-active',
        component: CardActivePage,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CardsRoutingModule { }
