import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TransferService } from '@wlp/ui-bs-cashout';

export interface ListData {
  name: string;
  value: string;
}

@Component({
  selector: 'app-modal-transfer-resume',
  templateUrl: './modal-transfer-resume.component.html',
  styleUrls: ['./modal-transfer-resume.component.scss'],
})
export class ModalTransferResumeComponent implements OnInit {
  formTransfer: FormGroup;
  listData1: ListData[];
  listData2: ListData[];

  transferData: any;

  private wlName: string;

  constructor(
    public modalController: ModalController,
    private transferService: TransferService,
    private layoutConfigService: LayoutConfigService,
  ) {
    this.wlName = this.layoutConfigService.getConfig('wlTheme.wlName');
    this.loadData();
    this.formTransfer = this.transferService.getFormTransfer();
  }

  ngOnInit() {}

  private typeDocument(document) {
    if (document.length > 14) {
      return 'CNPJ:';
    } else {
      return 'CPF:';
    }
  }

  loadData() {
    this.transferService.getTransfer().subscribe((res) => {
      this.transferData = res;
      this.listData1 = [
        { name: 'CASH_OUT.TRANSFER_RESUME.RESUME_DESCRIPTION.DATE', value: this.transferData.date },
        { name: 'CASH_OUT.TRANSFER_RESUME.RESUME_DESCRIPTION.SENDING_BANK', value: this.wlName },
        { name: 'CASH_OUT.TRANSFER_RESUME.RESUME_DESCRIPTION.BENEFICIARY_BANK', value: this.transferData.bank },
      ];

      this.listData2 = [
        { name: this.typeDocument(this.transferData.document), value: this.transferData.document },
        { name: 'CASH_OUT.TRANSFER_RESUME.RESUME_DESCRIPTION.NAME', value: this.transferData.name },
        { name: 'CASH_OUT.TRANSFER_RESUME.RESUME_DESCRIPTION.AGENCY', value: this.transferData.branch },
        { name: 'CASH_OUT.TRANSFER_RESUME.RESUME_DESCRIPTION.ACCOUNT', value: this.transferData.accountNumber },
      ];
    });
  }

  async continue() {
    await this.transferService.setTransfer(this.formTransfer);
    this.dismiss(true);
  }

  async dismiss(confirm: boolean) {
    this.modalController.dismiss(confirm);
  }
}
