import { AccountBalanceService, AccountRechargeService } from '@wlp/ui-bs-cashin';
import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController, NavController } from '@ionic/angular';

import { FormGroup } from '@angular/forms';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { ModalBilletReviewComponent } from '../../../../modals/modal-billet-review/modal-billet-review.component';
import {
  ModalOperationNotificationComponent,
} from './../../../../modals/modal-operation-notification/modal-operation-notification.component';
import { NotificationsPage } from '../../notifications/notifications.page';
import { UICHeaderUserInfoService } from '@wlp/ui-c-header-user-info';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UserInfoService } from '@wlp/ui-bs-login';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'ui-t-recharge',
  templateUrl: './recharge.page.html',
  styleUrls: [
    './recharge.page.scss',
  ],
})
export class RechargePage implements OnInit {

  @ViewChild(IonSlides, { static: true }) slides: IonSlides;
  iconName: string | 'eye-off-outline' | 'eye-outline';
  showBLC = false;
  balance: string;
  balanceResponse: any;
  userResponse: any;
  nameInitials: string;
  flagDisplayCard: boolean;
  flagDisplayNotification: boolean;
  messages = [];
  balanceSubject$: string;
  formRecharge: FormGroup
  errorFormMessages: any;

  userProfile: UserInfoProfile;

  secundary: boolean

  constructor(
    private navCtrl: NavController,
    private modalController: ModalController,
    private accountBalanceService: AccountBalanceService,
    private accountRechargeService: AccountRechargeService,
    private headerUserInfoService: UICHeaderUserInfoService,
    protected userInfoService: UserInfoService,
    private loadingService: LoadingService,
    private storage: Storage
  ) {
    this.iconName = 'eye-off-outline';
    this.balance = '';
    this.flagDisplayCard = false;
    this.flagDisplayNotification = false;
  }

  ngOnInit() {
    this.loadUser();
    this.errorFormMessages = this.accountRechargeService.getFormErrorMessage();
    this.formRecharge = this.accountRechargeService.getRechargeData();

    // atualiza o saldo da conta
    this.headerUserInfoService.updateValue(true);
  }

  ionViewWillEnter() {
    this.checkViewBalanceSecundaryUser();
  }

    async checkViewBalanceSecundaryUser(){
    await this.storage.get('permissions')
    .then(res => {
      if(res){
        const viewBalance = res.includes('viewBalanceAccess')
        if(res[0] === '*' || viewBalance == true){
          this.secundary = !true
        } else {
          this.secundary = !false
        }
        
        // this.secundary = res[0] === '*' ? false : true
      }
    })
  }

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then(user => {
        this.userProfile = user;
        this.loadBalance(user.uuidWhiteLabel, user.uuidAccount);
      });
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }

  onFlagActive() {
    this.flagDisplayNotification = false;
    this.flagDisplayCard = (this.flagDisplayCard) ? false : true;
  }

  onFlagActiveNotification() {
    this.flagDisplayCard = false;
    this.flagDisplayNotification = (this.flagDisplayNotification) ? false : true;
  }

  iconGrid() {
    this.navCtrl.navigateRoot('#');
  }

  async showNotifications() {
    const modal = await this.modalController.create({
      component: NotificationsPage,
      cssClass: 'modal-custom-class',
    });
    return await modal.present();
  }

  showBalance() {
    if (this.iconName === 'eye-off-outline') {
      this.iconName = 'eye-outline';
      this.showBLC = true;
    } else {
      this.iconName = 'eye-off-outline';
      this.showBLC = false;
    }
  }

  getInitial(name) {
    const parts = name.split(' ');
    let initials = '';
    for (let i = 0; i < 2; i++) {
      if (parts[i].length > 0 && parts[i] !== '') {
        initials += parts[i][0];
      }
    }
    return initials;
  }

  slideBack() {
    this.slides.slidePrev();
  }

  slideNext() {
    this.slides.slideNext();
  }

  private loadBalance(uuidWL: string, uuidAccount: string): void {
    this.balanceResponse = this.accountBalanceService
      .getBalance(uuidWL, uuidAccount)
      .then(res => this.accountBalanceService.setBalanceSubject(res.balance))
      .catch(err => this.accountBalanceService.getErroMessage(err));
  }

  async showBilletRechargeReviewModal() {
    const modal = await this.modalController.create({
      component: ModalBilletReviewComponent,
      cssClass: 'modal-custom-class',
      componentProps: {
        billetRequest: true,
      },
      backdropDismiss: false,
    });

    modal.onDidDismiss().then(({data}) => {
      if (!data) {
        this.navCtrl.navigateRoot('app');
      }
    });
    return await modal.present();
  }

  getBilletValue(): number {
    const { valueField } = this.formRecharge.getRawValue();
    const billetValue = parseFloat(valueField.replace('.', '').replace(',', '.'));
    return billetValue;
  }

  checkValue(value) {
    if (/^0/.test(value)) {
      this.formRecharge.controls.valueField.setValue('');
      return;
    }

    value = value.replace(/[^\w\s]/gi, '');
    value = parseFloat(value);

    if (value < 2000) {
      this.formRecharge.controls.valueField.setErrors({ 'min': true });
    }
  }

  checkButtonStatus(): boolean {
    return this.formRecharge.invalid || this.getBilletValue() < 20;
  }

  public validateOperation() {
    this.loadingService.show();
    this.accountRechargeService.saveValue(this.formRecharge.value);

    this.accountRechargeService
      .validateOperation(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, this.formRecharge.value.valueField)
      .then((res) => {
        this.loadingService.hide();

        if (!res) {
          this.statusTransaction('failed', 'CASH_IN.ACCOUNT_RECHARGE_REVIEW.MESSAGES.FAILED', true);
          return;
        } else {
          if (!res.isAllowed) {
            this.statusTransaction('failed', 'CASH_IN.ACCOUNT_RECHARGE_REVIEW.MESSAGES.FAILED_NOT_FUNDS', true);
            return;
          } else {
            this.showBilletRechargeReviewModal();
            return;
          }
        }
      })
      .catch((err) => {
        this.loadingService.hide();
        this.accountRechargeService.getErrorAccountRecharge(err);
        this.statusTransaction('failed', 'CASH_IN.ACCOUNT_RECHARGE_REVIEW.MESSAGES.FAILED', true);
      });
  }

  public async statusTransaction(status: string, message: string, translateFlag: boolean) {
    this.loadingService.hide();
    const translate = (translateFlag) ? true : false;
    const modalSuccess = await this.modalController.create({
      component: ModalOperationNotificationComponent,
      cssClass: 'modal-operation-notification',
      componentProps: {
        status: status,
        message: message,
        translate: translate
      },
      backdropDismiss: false,
    });
    modalSuccess.onDidDismiss().then((data) => data);
    return await modalSuccess.present();
  }
}
