import { CurrencyPipe, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ExtractService } from '@wlp/ui-bs-cashout';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { TransactionConsult } from '@wlp/ui-px-cashout';
import { ModalExtractReviewComponent } from 'src/app/views/themes/sentinel/modals/modal-extract-review/modal-extract-review.component';

import { ExtractDataFilterPipe } from '../extract-data-filter.pipe';
import { ExtractUtil } from '../extract-util';
import { Storage } from '@ionic/storage';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginatorIntl } from '@angular/material';

export interface tableInterface {
  description: string;
  data: string;
  value: string;
  icon: string;
}

@Component({
  selector: 'ui-t-extract-receipt',
  templateUrl: './extract-receipt.page.html',
  styleUrls: ['./extract-receipt.page.scss'],
  providers: [ExtractDataFilterPipe, CurrencyPipe],
})
export class ExtractReceiptPage extends ExtractUtil implements OnInit {
  public userProfile: UserInfoProfile;
  secundary: boolean
  displayedColumns: string[] = ['description', 'data', 'value', 'icon'];
  dataSource: MatTableDataSource<tableInterface>;
  pageSize = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    public pipeFilter: ExtractDataFilterPipe,
    public pipeCurency: CurrencyPipe,
    public detection: ChangeDetectorRef,
    protected userInfoService: UserInfoService,
    protected extractService: ExtractService,
    public modalController: ModalController,
    public activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private storage: Storage
  ) {
    super(pipeFilter, detection, modalController);
  }

  ngOnInit() {
    this.loadUser();
  }

 

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  ionViewWillEnter() {
    this.listReceipts = [];
    this.activatedRoute.params
      .subscribe((params) => {
        this.tpVoucher = params.type;
        this.getReceipts(this.tpVoucher);
      })
      .unsubscribe();

      this.checkViewBalanceSecundaryUser();
  }

    async checkViewBalanceSecundaryUser(){
    await this.storage.get('permissions')
    .then(res => {
      if(res){
        const viewBalance = res.includes('viewBalanceAccess')
        if(res[0] === '*' || viewBalance == true){
          this.secundary = !true
        } else {
          this.secundary = !false
        }
        
        // this.secundary = res[0] === '*' ? false : true
      }
    })
  }

  ionViewWillLeave() {
    this.listReceipts = [];
    this.listReceiptsShow = [];
  }

  public getReceipts(type: string, initial?: Date) {
    if (initial === undefined) {
      initial = this.calInitialDate;
    }

    let typeVoucher;

    switch (type) {
      case 'full':
        typeVoucher = type;
        break;
      case 'ted':
        typeVoucher = '120';
        break;
      case 'on-us':
        typeVoucher = '901';
        break;
      case 'payment':
        typeVoucher = '112-902';
        break;
      case 'pix':
        typeVoucher = '1016';
        break;
    }

    if (type === 'full') {
      this.extractService
        .getTransactionsByPeriod(
          this.userProfile.uuidAccount,
          new DatePipe('en-US').transform(initial, 'yyyy-MM-dd'),
          new DatePipe('en-US').transform(this.calEndDate, 'yyyy-MM-dd')
        )
        .subscribe(
          (res) => {
            this.listReceipts = res;

            this.dataSource = new MatTableDataSource(this.listReceipts);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            // atualiza o front da tabela
            this.setArrayForShowReceipts(true);
            this.calcPaginationReceipts();
          },
          (err) => console.error(err),
          () => this.cdr.detectChanges()
        );
    } else {
      this.extractService
        .getTransactionsByPeriodAndTypeVoucher(
          this.userProfile.uuidAccount,
          typeVoucher,
          new DatePipe('en-US').transform(initial, 'yyyy-MM-dd'),
          new DatePipe('en-US').transform(this.calEndDate, 'yyyy-MM-dd')
        )
        .subscribe(
          (res) => {
            this.listReceipts = res;
            this.dataSource = new MatTableDataSource(this.listReceipts);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            // atualiza o front da tabela
            this.setArrayForShowReceipts(true);
            this.calcPaginationReceipts();
          },
          (err) => console.error(err),
          () => this.cdr.detectChanges()
        );
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getTransaction(transactionId: string) {
    this.extractService.getTransaction(transactionId).subscribe((res) => {
      this.showExtract(res);
    });
  }

  async showExtract(data: TransactionConsult) {
    const modal = await this.modalController.create({
      component: ModalExtractReviewComponent,
      cssClass: 'extract-review',
      componentProps: {
        confirmationData: data,
      },
      backdropDismiss: false,
    });
    return await modal.present();
  }

  typeVoucher(code) {
    switch (code) {
      case '112':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_112';
      case '902':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_902';
      case '903':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_903';
      case '904':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_904';
      case '905':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_905';
      case '901':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_901';
      case '117':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_117';
      case '213':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_213';
      case '805':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_805';
      case '120':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_120';
      case '209':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_209';
      case '1016':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_1016';
      case '1017':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_1017';
      default:
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_DEFAULT';
    }
  }
}

export class CustomPaginatorIntl extends MatPaginatorIntl {
  itemsPerPageLabel = 'Itens por página:';
  nextPageLabel = 'Próxima';
  previousPageLabel = 'Anterior';

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 de ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} de ${length}`;
  };
}