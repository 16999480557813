import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { AfterContentInit, Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { IntroService } from '@wlp/ui-bs-intro';

@Component({
  selector: 'app-success-ec',
  templateUrl: './status-response-ec.page.html',
  styleUrls: ['./status-response-ec.page.scss'],
})
export class StatusResponseEcPage implements AfterContentInit {
  constructor(
    private navCtrl: NavController,
    private introService: IntroService,
    private layoutConfigService: LayoutConfigService,
  ) {}

  public ngAfterContentInit(): void {
    this.introService.cleanStorage();
  }

  public goBack() {
    this.navCtrl.back();
  }
  public continue() {
    this.navCtrl.navigateRoot('');
  }

  public getImageTag(tag: string) {
    const item: any = this.layoutConfigService.getImagesPages(tag);

    if (isNaN(item)) {
      return '';
    }

    return item.path;
  }
}
