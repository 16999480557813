import { BrMaskerModule } from 'br-mask';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgxMaskIonicModule } from 'ngx-mask-ionic';
import { ComponentsModule } from 'src/app/core/shared/modules/components.module';
import { environment } from 'src/environments/environment';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UiBsTranslateModule } from '@wlp/ui-bs-translate';
import { WalletRoutingModule } from './wallet-routing.module';
import { TransactionsPage } from './transactions/transactions.page';
import { UiCWalletSlideCardsModule } from '@wlp/ui-c-wallet-slide-cards';
import { UiCWalletTransactionsModule } from '@wlp/ui-c-wallet-transactions';
import { CardMessagePage } from './modals/card-message/card-message.page';
import { CardManagerPage } from './modals/card-manager/card-manager.page';
import { WalletCurrencyPipe } from './pipe/wallet-currency.pipe';
import { InterceptService } from '../../../../../../core/intercept/intercept.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ManagerCardsPage } from './manager-cards/manager-cards-page.component';
import { CardLoadingPage } from './modals/card-loading/card-loading.page';
import { UiBsPrizeDrawModule } from '@wlp/ui-bs-prizedraw';
import { UiBsNscModule } from '@wlp/ui-bs-nsc';
import { UiBsCardsModule } from '@wlp/ui-bs-cards';
import { EditCardPage } from './edit-card/edit-card-page.component';
import { UiCPrizeDrawMessageComponent, UiCPrizeDrawMessageModule } from '@wlp/ui-c-prizedraw-message';
@NgModule({
  imports: [
    FormsModule,
    IonicModule,
    CommonModule,
    TooltipModule,
    ComponentsModule,
    ReactiveFormsModule,
    WalletRoutingModule,
    NgxMaskIonicModule.forRoot(),
    WalletRoutingModule,
    BrMaskerModule,
    UiBsTranslateModule.forRoot({ device: 'WEB', config: environment }),
    UiCWalletSlideCardsModule,
    UiCWalletTransactionsModule.forRoot({ device: 'WEB', config: environment }),
    UiBsPrizeDrawModule,
    UiBsNscModule,
    UiBsCardsModule,
    UiCPrizeDrawMessageModule,
  ],
  declarations: [
    TransactionsPage,
    WalletCurrencyPipe,
    CardManagerPage,
    CardMessagePage,
    ManagerCardsPage,
    CardLoadingPage,
    EditCardPage,
  ],
  entryComponents: [CardLoadingPage, CardMessagePage, UiCPrizeDrawMessageComponent],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
})
export class WalletModule {}
