import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { celCoinService } from '@wlp/ui-bs-celcoin';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UiBsMyDataService, UserAccountInfoResponse } from '@wlp/ui-bs-my-data';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import * as moment_ from 'moment';
import { ValidSmsUserPage } from '../valid-sms/valid-sms-user.page';
const moment = moment_;

const uri = 'https://api-sandbox.secure.srv.br/px-celcoin';

@Component({
  selector: 'ui-t-mobile-recharge',
  templateUrl: './mobile-recharge.page.html',
  styleUrls: ['./mobile-recharge.page.scss'],
})
export class RechargeMobilePage implements OnInit {
  numeroRecarga: FormGroup;
  page = 1;
  operadoras: any;
  numero: string;
  ddd: string;
  operadora = 0;
  valores: any;
  nameProvider: string;
  valorRecarga = '0';
  valorRecarga2: number;
  transactionId: string;
  token: string;
  userProfile: UserInfoProfile;
  userData: any;

  isProcessing = false;
  valorSegment: any;

  maxYear: string
  minYear: string
  now: string
  selectedDate: string
  showRepeatOptions = false
  frequency = null
  occurrences = null

  monthly: number[] = Array.from({length:12}, (_, i) => i + 1);
  weekly: number[] = Array.from({length: 48}, (_, i) => i + 1);;
  monthlyOrNo: string
  dataMinima = moment().format('DD/MM/YYYY')

  constructor(
    private celcoin: celCoinService,
    private loadingService: LoadingService,
    private modalController: ModalController,
    protected userInfoService: UserInfoService,
    private myDataService: UiBsMyDataService,
    private navCtrl: NavController
  ) {
    this.numeroRecarga = new FormGroup({
      numero: new FormControl('', [Validators.required, Validators.minLength(15)]),
    });

  }

  selecionaOperadora(operadora) {
    this.operadora = operadora.detail.value;
  }
  

  setOperadora(nomeOperadora) {
    this.nameProvider = nomeOperadora;
  }

  valueChange(value) {
    this.valorRecarga = value.detail.value + ',00';
    this.valorRecarga2 = value.detail.value;
  }

  editarNumero() {
    this.page = 1;
  }

  editarOperadora() {
    this.page = 2;
  }

  editarvalor() {
    this.page = 3;
  }

  geraStringAleatoria() {
    let sequencia1 = '';
    let sequencia2 = '';
    let sequencia3 = '';
    let sequencia4 = '';
    let sequencia5 = '';
    let stringAleatoria;
    const caracteres = 'abcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 9; i++) {
      sequencia1 += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    }

    for (let i = 0; i < 4; i++) {
      sequencia2 += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    }

    for (let i = 0; i < 4; i++) {
      sequencia3 += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    }

    for (let i = 0; i < 4; i++) {
      sequencia4 += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    }

    for (let i = 0; i < 12; i++) {
      sequencia5 += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    }

    stringAleatoria = sequencia1 + '-' + sequencia2 + '-' + sequencia3 + '-' + sequencia4 + '-' + sequencia5;

    this.transactionId = stringAleatoria;
  }

  ngOnInit() {}

  ionViewWillEnter() {
    this.maxYear = (moment().year() + 5).toString()
    this.minYear = moment().format('YYYY-MM-DD').toString();
    this.selectedDate = moment().format("YYYY-MM-DD").toString();
    this.now = this.selectedDate
    // this.loadUser();
    this.geraStringAleatoria();
    this.loadUser();
  }

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
        this.myDataService
          .getPersonalData(this.userProfile.uuidAccount)
          .pipe(
            tap((data) => {
              this.userData = data;
            })
          )
          .subscribe();
      });
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }

  continue() {
    const headers = {
      headers: {
        'client-secret': 'e9d15cde33024c1494de7480e69b7a18c09d7cd25a8446839b3be82a56a044a3',
        'client-id': '41b44ab9a56440.teste.celcoinapi.v5',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    if (this.page === 1) {
      this.loadingService.show();
      this.numero = this.numeroRecarga.controls['numero'].value;
      this.ddd = this.numero.substring(1, 3);

      const stateCode = this.ddd;
      const type = '2';
      const category = '1';

      this.celcoin
        .consultProviders(stateCode, type, category)
        .pipe(
          tap((res) => {
            this.operadoras = res;
            this.loadingService.hide();
          }),
          catchError((err) => {
            this.loadingService.hide();
            return of(null);
          })
        )
        .subscribe();
    }

    if (this.page === 2) {
      this.loadingService.show();
      const stateCode = this.ddd;
      const providerId = this.operadora;
      const url = `${uri}/v1/provider-values?stateCode=${stateCode}&providerId=${providerId}`;

      this.celcoin
        .providerValues(stateCode, providerId)
        .pipe(
          tap((res) => {
            this.valorRecarga2 = res.value[0].maxValue;
            this.valorRecarga = res.value[0].maxValue;
            this.valorSegment = res.value[0].maxValue;
            this.valores = res.value;
            this.loadingService.hide();
          }),
          catchError((err) => {
            this.loadingService.hide();
            return of(null);
          })
        )
        .subscribe();
    }

    this.page += 1;
  }
  
  OnDateTimeChange(event) {
    console.log('Event', event)
    this.selectedDate = moment(event).format("YYYY-MM-DD").toString()
    console.log('Data selecionada', this.selectedDate)
  }

  public async validSms() {
    const modalToken = await this.modalController.create({
      component: ValidSmsUserPage,
      cssClass: 'modal-resume',
    });
    modalToken.onDidDismiss().then((data) => {
      if (data.data[0].validToken === true) {
        this.token = data.data[0].token;
        this.loadingService.show();
        this.continueSaldo();
      }
    });
    return await modalToken.present();
  }

  confirmaRecarga() {
    this.validSms();
  }

  async continueSaldo() {
    const data = {
      accountType: '',
      codeAuthorization: this.token.toString(),
      codeOperation: '',
      countryCode: 55,
      description: 'Recarga de celular web',
      externalNsu: 123456,
      externalTerminal: this.userData.document,
      isCharged: false,
      number: this.numero.substring(5, 15).replace(/[^0-9]/g, ''),
      operationCode: '',
      operationValue: this.valorRecarga2,
      providerId: this.operadora,
      signerCode: '',
      stateCode: this.ddd,
      transactionId: this.transactionId,
      uuidAccountPayment: this.userProfile.uuidAccount,
      uuidWhiteLabel: this.userProfile.uuidWhiteLabel,
      value: this.valorRecarga2,
      dateExecution: this.selectedDate,
      frequency: this.frequency,
      occurrences: this.occurrences
    };

    this.celcoin
      .rechargeMobile(data, this.userProfile.uuidAccount, this.transactionId)
      .pipe(
        tap((res) => {
          if (
            res.message ===
            'Operação em processamento, verifique o seu extrato dentro de alguns minutos para confirmar a transação'
          ) {
            this.isProcessing = true;
            this.page = 9;
          } else if (res.isExecuted === false && this.isProcessing === false) {
            this.page = 7;
          } else {
            this.page = 8;
          }
          this.loadingService.hide();
        }),
        catchError((err) => {
          this.loadingService.hide();
          return of(null);
        })
      )
      .subscribe();
  }

  successRecharge() {
    this.navCtrl.navigateBack('app/dashboard');
  }

  failRecharge() {
    this.navCtrl.navigateBack('app/dashboard');
  }

  editTextWeekly(index) {
    if(index > 1) {
    return `${index} semanas`
  } else {
    return `${index} semana`
  }
  }

  OnTransactionRepeatChange(event){

    this.showRepeatOptions = event.detail.checked
  
    if(this.showRepeatOptions == true){
      this.frequency = 'WEEKLY'
      this.monthlyOrNo = 'Toda semana'
      this.frequency = 'WEEKLY'
      this.occurrences = '1'
    } else {
      this.frequency = null
      this.occurrences = null
    }
  }
  
  
  onFrequencyChange(){
    
    if(this.monthlyOrNo == 'Toda semana'){
      this.monthlyOrNo = 'Todo mês'
      this.frequency = 'MONTHLY'
      this.occurrences = '1'
    } else {
      this.monthlyOrNo = 'Toda semana'
      this.frequency = 'WEEKLY'
      this.occurrences = '1'
    }
  }
  
  onOccurrencesChange(event){
      this.occurrences = event.detail.value
  }
}
