import { FileAccount, IPageBase, PreAccountResponse, UploadService } from '@wlp/ui-bs-signup';

import { AlertDocumentService } from '@wlp/ui-bs-alert';
import { Component } from '@angular/core';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from '../../../../../../../core/layout/services/loading.service';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'ui-t-upload-documents',
  templateUrl: './upload-documents.page.html',
  styleUrls: ['./upload-documents.page.scss'],
})
export class UploadDocumentsPage implements IPageBase {
  public isFile: boolean;
  public fileAccount: FileAccount;
  public description: string;
  public warningsTitle: string;
  public subTitle: string;
  public warnings: string[];
  public brand: string;
  public navigationBackground: string;
  public isRgOrCnh: boolean;
  public sizeFile: number;

  private file: any;

  constructor(
    private navCtrl: NavController,
    private uploadService: UploadService,
    private loadingService: LoadingService,
    private alertDocumentService: AlertDocumentService,
    private layoutConfigService: LayoutConfigService,
    private storage: Storage
  ) {
    this.fileAccount = new FileAccount();
    this.fileAccount.title = '';
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
  }

  /**
   * AO ENTRAR DA PAGINA
   */
  async ionViewWillEnter() {
    this.fileAccount = new FileAccount();
    this.fileAccount.title = '';
    this.isFile = true;
    // await this.storage.set('typeUpload', JSON.parse(sessionStorage.getItem('typeUpload')))
    await this.storage.set('keyForm', sessionStorage.getItem('keyForm'))
    /* this.warnings = []; */
    this.loadFileAccount();
  }

  async continue() {
    this.fileAccount.fileBase64 = this.file.base64;
    this.fileAccount.title = this.file.file.name;
    this.fileAccount.positionPhoto = 'FRONT';
    this.loadingService.show();
    this.uploadService
      .uploadFile(this.fileAccount)
      .then((res: PreAccountResponse) => {
        this.loadingService.hide();
        this.redirectToFlow(res);
        //console.log(res)
      })
      .catch((error) => {
        this.loadingService.hide();
        this.uploadService.getErroUpload(error);
      });
  }

  goBack() {
    this.redirectFLowRgCnh();
    this.uploadService
      .getAccountType()
      .then((res: PreAccountResponse) => {
        //console.log(res)
        this.redirectToFlow(res);
      })
      .catch((err) => this.uploadService.getErroUpload(err));
  }

  public getUploadFile(data: any) {
    this.file = data;
    this.isFile = false;
  }

  public removeFile() {
    this.isFile = true;
    this.file = null;
  }

  public isOwnership() {
    return this.fileAccount.uploadType === 'RESIDENCIAL_CERTIFICATE';
  }

  public ownership(event) {
    this.fileAccount.ownership = event.isOwnership;
  }

  /**
   * AO SAIR DA PAGINA
   */
  public ionViewWillLeave() {
    if (!this.isRgOrCnh) {
      this.uploadService.removeFileTypeUpload();
    }
  }

  private loadFileAccount(): void {
    this.uploadService.loadFileTypeUpload().then((res) => {
      this.fileAccount = res;
      this.verifyTypeDcoument();
      if (!this.fileAccount) {
        this.goBack();
      }
    });
  }

  burstFile() {
    if (this.fileAccount.uploadType === 'SOCIAL_CONTRACT') {
      this.alertDocumentService.documentLarge(50);
      return;
    }
    this.alertDocumentService.documentLarge();
  }

  private redirectToFlow(preAccountService: PreAccountResponse): void {
    if (
      preAccountService.accountType.typeFlow.toString() === 'PHHYSICAL_PERSON' ||
      preAccountService.accountType.typeFlow.toString() === 'PHYSICAL_PERSON'
    ) {
      this.navCtrl.navigateRoot('document-type');
      return;
    }

    if (preAccountService.accountType.typeFlow.toString() === 'LEGAL_PERSON') {
      this.redirectFlowPJ();
      return;
    }

    if (preAccountService.accountType.typeFlow.toString() === 'MEI') {
      if (this.fileAccount.uploadType === 'CNPJ' || this.fileAccount.uploadType === 'MEI_OPENING_TERMS') {
        this.navCtrl.navigateRoot('company-documents');
      } else {
        this.navCtrl.navigateRoot('document-type');
      }
      return;
    }

    if (preAccountService.accountType.typeFlow.toString() === 'LIBERAL_PROFESSIONAL') {
      this.navCtrl.navigateRoot('documents-liberal');
      return;
    }
  }

  private redirectFLowRgCnh() {
    if (this.fileAccount.uploadType === 'RG' || this.fileAccount.uploadType === 'CNH') {
      this.isRgOrCnh = true;
      this.navCtrl.back();
      return;
    }
  }

  private redirectFlowPJ(): void {
    //console.log("dentro do redirectFlowPj")
    //console.log(this.fileAccount.groupDocument)
    if (this.fileAccount.groupDocument === 'LEGAL_PERSON_MASTER') {
      this.navCtrl.navigateRoot('documents-partner', { queryParams: { type: this.fileAccount.optionParam } });
    }

    if (this.fileAccount.groupDocument === 'LEGAL_PERSON_COMPANY') {
      //console.log("AQUI!")
      this.navCtrl.navigateRoot('company-documents');
    }
  }

  verifyTypeDcoument() {
    let documentType = this.fileAccount.uploadType.toLocaleUpperCase();
    this.warningsTitle = 'ONBORDING.UPLOAD_DOCUMENTS.WARNING.WARNING_1';
    this.warnings = [];

    // //console.log(documentType);

    switch (documentType) {
      case 'RG': {
        this.subTitle = 'ONBORDING.UPLOAD_DOCUMENTS_WEB.SUBTITLE.RG.MESSAGE';
        this.description = 'ONBORDING.UPLOAD_DOCUMENTS.RG_DESCRIPTION';
        this.warnings.push('ONBORDING.UPLOAD_DOCUMENTS.WARNING.WARNING_2_RG');
        this.warnings.push('ONBORDING.UPLOAD_DOCUMENTS.WARNING.WARNING_3_RG');
        this.sizeFile = 5242880;
        break;
      }
      case 'CNH': {
        this.subTitle = 'ONBORDING.UPLOAD_DOCUMENTS_WEB.SUBTITLE.CNH.MESSAGE';
        this.description = 'ONBORDING.UPLOAD_DOCUMENTS.CNH_DESCRIPTION';
        this.warnings.push('ONBORDING.UPLOAD_DOCUMENTS.WARNING.WARNING_2_CNH');
        this.warnings.push('ONBORDING.UPLOAD_DOCUMENTS.WARNING.WARNING_3_CNH');
        this.sizeFile = 5242880;
        break;
      }
      case 'SELF_PHOTO': {
        this.subTitle = 'ONBORDING.UPLOAD_DOCUMENTS_WEB.SUBTITLE.SELFIE.MESSAGE';
        this.description = 'ONBORDING.UPLOAD_DOCUMENTS.SELF_DESCRIPTION';
        this.warnings.push('ONBORDING.UPLOAD_DOCUMENTS.WARNING.WARNING_2_SELF');
        this.warnings.push('ONBORDING.UPLOAD_DOCUMENTS.WARNING.WARNING_3_SELF');
        this.sizeFile = 5242880;
        break;
      }
      case 'RESIDENCIAL_CERTIFICATE': {
        this.subTitle = 'ONBORDING.UPLOAD_DOCUMENTS_WEB.SUBTITLE.RESIDENTIAL_CERTIFICATE.MESSAGE';
        this.description = 'ONBORDING.UPLOAD_DOCUMENTS.SELF_DESCRIPTION';
        this.warnings.push('ONBORDING.UPLOAD_DOCUMENTS.WARNING.WARNING_2_RESIDENCIAL_CERTIFICATE');
        this.warnings.push('ONBORDING.UPLOAD_DOCUMENTS.WARNING.WARNING_3_RESIDENCIAL_CERTIFICATE');
        this.warnings.push('ONBORDING.UPLOAD_DOCUMENTS.WARNING.WARNING_4_RESIDENCIAL_CERTIFICATE');
        this.sizeFile = 5242880;
        break;
      }
      case 'PROFESSIONAL_CERTIFICATE': {
        this.subTitle = 'ONBORDING.UPLOAD_DOCUMENTS_WEB.SUBTITLE.PROFESSIONAL_CERTIFICATE.MESSAGE';
        this.description = 'ONBORDING.UPLOAD_DOCUMENTS.PROFESSIONAL_CERTIFICATE_DESCRIPTION';
        this.warnings.push('ONBORDING.UPLOAD_DOCUMENTS.WARNING.WARNING_2_PROFESSIONAL_CERTIFICATE');
        this.sizeFile = 5242880;
        break;
      }
      case 'CNPJ': {
        this.subTitle = 'ONBORDING.UPLOAD_DOCUMENTS_WEB.SUBTITLE.CNPJ.MESSAGE';
        this.description = 'ONBORDING.UPLOAD_DOCUMENTS.CNPJ_DESCRIPTION';
        this.warnings.push('ONBORDING.UPLOAD_DOCUMENTS.WARNING.WARNING_2_CNPJ');
        this.warnings.push('ONBORDING.UPLOAD_DOCUMENTS.WARNING.WARNING_3_CNPJ');
        this.sizeFile = 5242880;
        break;
      }
      case 'SOCIAL_CONTRACT': {
        this.subTitle = 'ONBORDING.UPLOAD_DOCUMENTS_WEB.SUBTITLE.SOCIAL_CONTRACT.MESSAGE';
        this.description = 'ONBORDING.UPLOAD_DOCUMENTS.SOCIAL_CONTRACT_DESCRIPTION';
        this.warnings.push('ONBORDING.UPLOAD_DOCUMENTS.WARNING.WARNING_2_SOCIAL_CONTRACT');
        this.warnings.push('ONBORDING.UPLOAD_DOCUMENTS.WARNING.WARNING_3_SOCIAL_CONTRACT');
        this.sizeFile = 50000000;
        break;
      }
      case 'ATTORNEY_LETTER': {
        this.subTitle = 'ONBORDING.UPLOAD_DOCUMENTS_WEB.SUBTITLE.ATTORNEY_LETTER.MESSAGE';
        this.description = 'ONBORDING.UPLOAD_DOCUMENTS.ATTORNEY_LETTER_DESCRIPTION';
        this.warnings.push('ONBORDING.UPLOAD_DOCUMENTS.WARNING.WARNING_1_ATTORNEY_LETTER');
        this.sizeFile = 5242880;
        break;
      }
      case 'MINUTES_MEETING': {
        this.subTitle = 'ONBORDING.UPLOAD_DOCUMENTS_WEB.SUBTITLE.MINUTES_MEETING.MESSAGE';
        this.description = 'ONBORDING.UPLOAD_DOCUMENTS.MINUTES_MEETING_DESCRIPTION';
        this.warnings.push('ONBORDING.UPLOAD_DOCUMENTS.WARNING.WARNING_1_MINUTES_MEETING');
        this.sizeFile = 5242880;
        break;
      }
      case 'RESIDENCIAL_CERTIFICATE_LEGAL_PERSON': {
        this.subTitle = 'ONBORDING.UPLOAD_DOCUMENTS_WEB.SUBTITLE.RESIDENCIAL_CERTIFICATE_LEGAL_PERSON.MESSAGE';
        this.description = 'ONBORDING.UPLOAD_DOCUMENTS.RESIDENCIAL_CERTIFICATE_LEGAL_PERSON_DESCRIPTION';
        this.warnings.push('ONBORDING.UPLOAD_DOCUMENTS.WARNING.WARNING_1_MINUTES_MEETING');
        this.sizeFile = 5242880;
        break;
      }
      case 'MEI_OPENING_TERMS': {
        this.subTitle = 'ONBORDING.UPLOAD_DOCUMENTS_WEB.SUBTITLE.MEI_OPENING_TERMS.MESSAGE';
        this.description = 'ONBORDING.UPLOAD_DOCUMENTS.MEI_OPENING_TERMS_DESCRIPTION';
        this.warnings.push('ONBORDING.UPLOAD_DOCUMENTS.WARNING.WARNING_1_MEI_OPENING_TERMS');
        this.warnings.push('ONBORDING.UPLOAD_DOCUMENTS.WARNING.WARNING_2_MEI_OPENING_TERMS');
        this.warnings.push('ONBORDING.UPLOAD_DOCUMENTS.WARNING.WARNING_3_MEI_OPENING_TERMS');
        this.sizeFile = 5242880;
        break;
      }
    }
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }
}
