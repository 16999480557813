import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable()
export class LoadingService {
  spinner: 'bubbles' | 'circles' | 'circular' | 'crescent' | 'dots' | 'lines' | 'lines-small' | null | undefined =
    'circular';
  scssClass = 'ion-loading';
  isLoading = false;

  constructor(public loadingController: LoadingController) {}

  changeSpinner(spinner: any = 'circular') {
    this.spinner = spinner;
  }

  changeScssClass(scssClass: string) {
    this.scssClass = scssClass;
  }

  public async show() {
    this.isLoading = true;

    return await this.loadingController
      .create({
        spinner: this.spinner,
        cssClass: this.scssClass,
      })
      .then((a) => {
        a.present().then(() => {
          if (!this.isLoading) {
            a.dismiss();
          }
        });
      });
  }

  public async hide() {
    if (this.isLoading) {
      this.isLoading = false;
      return await this.loadingController
        .dismiss()
        .then((res) => {})
        .catch((error) => {
          // console.log('error', error);
        });
    }
    return null;
  }
}
