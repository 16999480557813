import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

@Component({
  selector: 'app-modal-csv-generate',
  templateUrl: './modal-csv-generate.component.html',
  styleUrls: ['./modal-csv-generate.component.scss'],
})
export class ModalCsvGenerateComponent implements OnInit {

  @Input() listStatmentShow: Array<any>
  @Input() userProfile: any;

  private csvPreview;
  private today =  new Date();

  constructor(
    private loadingService: LoadingService,
    private modal: ModalController
  ) { }

  ngOnInit() {
    this.csvPreview =  this.generatePreviewCSV(this.listStatmentShow)
  }

  generatePreviewCSV(data: any) {
    const filteredData = data.filter(value => value !== undefined)
    const newData =  filteredData.map(value => ({
      // 'nome': this.userProfile.nameComplete,
      // 'banco': this.userProfile.nameBank,
      // 'agencia': this.userProfile.agencyBank,
      // 'conta': this.userProfile.numberAccountBank,
      // 'emitido': this.dateControl(this.today),
      'data': value.dateRegister,
      'descricao': value.operationDescription,
      'credito': value.credit,
      'debito': value.debit,
      'saldo': value.balance
    }))
    
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(newData[0]);
    const csv = newData.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
    return csvArray
  }

  async downloadCSV() {
    await this.loadingService.show();
    let dataFormatada = ((this.today.getDate())) + "-" + ((this.today.getMonth() + 1)) + "-" + this.today.getFullYear();

    const a = document.createElement('a');
    const blob = new Blob([this.csvPreview], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = `extrato_${(this.userProfile.nameFirst as string).toLowerCase()}_${dataFormatada}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    await this.loadingService.hide();
    await this.dismiss();
  }

  public dateControl(date: any): string {
    try {
      return `${moment(this.formattedDate(date)).format('DD/MM/YYYY')}`;
    } catch (err) {
      return '';
    }
  }

  public formattedDate(date) {
    if (date && typeof date === 'object' && date.constructor === Array) {
      if (date.length >= 6) {
        return new Date(date[0], (date[1] - 1), date[2], date[3], date[4], date[5]);
      } else if (date.length === 5) {
        return new Date(date[0], (date[1] - 1), date[2], date[3], date[4]);
      }
      return new Date(date[0], date[1], date[2]);
    }

    return date;
  }

  async dismiss() {
    await this.modal.dismiss();
  }

}
