import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { ProfessionalTypeService } from '@wlp/ui-bs-signup';

@Component({
  selector: 'ui-t-professional-type',
  templateUrl: './professional-type.page.html',
  styleUrls: ['./professional-type.page.scss'],
})
export class ProfessionalTypePage {
  brand: string;
  errorMessages: any;
  formProfessionalType: FormGroup;
  listCertified: Array<any>;
  navigationBackground: string;
  constructor(
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private professionalTypeService: ProfessionalTypeService
  ) {
    this.formProfessionalType = this.professionalTypeService.getProfessionalTypeForm();
    this.errorMessages = this.professionalTypeService.getErrorMessage();
    this.listCertified = this.professionalTypeService.getCertified();
    this.professionalTypeService.updateForm();
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
  }

  continue(): void {
    this.professionalTypeService.sendProfessionalType().subscribe(
      (res) => {
        this.navCtrl.navigateRoot('ocr-scanner')},
      (err) => this.professionalTypeService.getErrorProfessionalType(err)
    );
  }

  goBack() {
    this.navCtrl.navigateForward('account-type');
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }
}
