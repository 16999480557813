import { Router } from '@angular/router';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { AccountBalanceService } from '@wlp/ui-bs-cashin';
import { DevolutionService, PaymentPixService, ConsentUseService } from '@wlp/ui-bs-pix';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserProfile } from 'src/app/core/dto/user-profile';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { TokenCodeService } from '@wlp/ui-bs-token';
import { of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { ModalPixComponent } from 'src/app/views/themes/sentinel/modals/modal-pix/modal-pix.component';
import { MaskService } from '@wlp/ui-bs-util';
import { PixService } from '@wlp/ui-bs-cashout';
import { TransferService } from '@wlp/ui-bs-cashout';

import * as moment from 'moment';

@Component({
  selector: 'app-devolution',
  templateUrl: './devolution.page.html',
  styleUrls: ['./devolution.page.scss'],
})
export class DevolutionPage implements OnInit {
  balance: string;

  backgroundImage: string;
  imagePath = 'assets/images/pix/pix.svg';

  devolutionForm: FormGroup;
  listDevolutionReason: any;
  errorMessages: any;

  transaction: any;
  transactionDescriptionObject: any;

  userProfile: UserProfile;
  recipientAccoundData: any = [];

  formTransferPix: FormGroup;

  public devolutionReason: any;
  public limitedValue = 0;

  constructor(
    private devolutionService: DevolutionService,
    private router: Router,
    private navCtrl: NavController,
    private authService: UserInfoService,
    private navController: NavController,
    private loadingService: LoadingService,
    private modalController: ModalController,
    private tokenCodeService: TokenCodeService,
    private paymentPixService: PaymentPixService,
    private layoutConfigService: LayoutConfigService,
    private accountBalanceService: AccountBalanceService,
    private toastController: ToastController,
    private maskService: MaskService,
    private pixService: PixService,
    private cdr: ChangeDetectorRef,
    private transferService: TransferService,
    private consentUseService: ConsentUseService
  ) {
    this.backgroundImage = this.layoutConfigService.getConfig('wlTheme.header.background.image');
    this.devolutionForm = this.devolutionService.getFormDevolution();
    this.errorMessages = this.devolutionService.getFormMessageError();
    this.listDevolutionReason = this.devolutionService.getListDevolutionReasons();
    this.formTransferPix = this.pixService.createFormTransfer();

    // this.getTransactionPix();
  }

  checkValue(value) {
    if (value === '0') {
      this.devolutionForm.controls.devolutionValue.setValue(null);
    }

    value = value.replace(/[^\w\s]/gi, '');
    value = parseFloat(value);

    if (value > this.limitedValue * 100) {
      this.devolutionForm.controls.devolutionValue.setErrors({ max: true });
    } else {
    }
  }

  ngOnInit() {
    this.loadingService.show();

    this.loadUser();

    if (this.router.getCurrentNavigation().extras.state === undefined) {
      this.navController.back();
    } else {
      this.transaction = this.router.getCurrentNavigation().extras.state.item;
      this.devolutionAlowed();
    }
  }

  loadUser() {
    const userDetails = this.authService.getInfoProfile();
    userDetails.then(user => {
      this.userProfile = user;
      this.loadBalance(this.userProfile);
    });
  }

  private loadBalance(userProfile: UserProfile): void {
    this.accountBalanceService
      .getBalance(userProfile.uuidWhiteLabel, userProfile.uuidAccount)
      .then((res) => {
        this.balance = res.balance;
      })
      .catch((err) => {
        this.loadingService.hide();
        this.accountBalanceService.getErroMessage(err);
      });
  }

  goBack() {
    this.navController.navigateRoot(['app/pix/extract-pix']);
  }

  continue() {
    const devolutionData = this.devolutionForm.getRawValue();

    const devolutionCode = devolutionData.devolutionCode;
    const devolutionValue = parseFloat(String(devolutionData.devolutionValue).replace(/\D/g, '')) / 100;

    this.formTransferPix.controls.uuidAccountPayment.setValue(this.userProfile.uuidAccount);
    this.formTransferPix.controls.uuidWhiteLabel.setValue(this.userProfile.uuidWhiteLabel);
    this.formTransferPix.controls.operationValue.setValue(devolutionValue);
    this.formTransferPix.controls.devolutionCode.setValue(devolutionCode);
    this.formTransferPix.controls.transactionIdCredit.setValue(this.transaction.transactionId);

    this.pixService.setTransfer(this.formTransferPix);

    this.validateOperation();
  }

  onChangeForm(value) {
    this.devolutionReason = value.detail.value;
    this.devolutionForm.get('devolutionCode').setValue(this.devolutionReason.code);
  }

  validateOperation() {
    this.loadingService.show();
    this.pixService
      .validateOperation()
      .then((res) => {
        this.loadingService.hide();

        if (!res) {
          this.statusTransaction('failed', null, 'PIX.PIX_DEVOLUTION.MESSAGES.FAILED', 'FECHAR', true);
          return;
        }
        if (!res.isAllowed) {
          this.statusTransaction('failed', null, res.message, 'FECHAR', false);
          this.toastPresent(res.message, 2000, 'bottom');
          return;
        }
        this.goToResume();
        return;
      })
      .catch((err) => {
        console.info(err);
        this.loadingService.hide();
        this.pixService.getErrorTransfer(err);
        this.statusTransaction('failed', null, 'PIX.PIX_DEVOLUTION.MESSAGES.FAILED', 'FECHAR', true);
      });
  }

  devolutionAlowed() {
    this.paymentPixService.pixCheckReturnAllowed(this.transaction.transactionId, 0).subscribe(
      (res) => {
        if (!res.isAllowed) {
          this.loadingService.hide();
          this.toastPresent('Devolução não permitida', 5000, 'bottom');
          this.goBack();
        }

        if (res.valueAllowed > 0) {
          this.limitedValue = res.valueAllowed;

          this.devolutionForm.patchValue({
            devolutionValue: String(this.limitedValue.toFixed(2))
              .replace(/\,/g, '-')
              .replace(/\./g, ',')
              .replace(/\-/g, '.'),
          });

          this.prepareDestinationData(res.originalTransaction);

          this.loadingService.hide();
        } else {
          this.loadingService.hide();
          this.toastPresent('Pix já devolvido integralmente', 5000, 'bottom');
          this.goBack();
        }
      },
      (err) => {
        this.loadingService.hide();
        this.toastPresent('Devolução não permitida', 5000, 'bottom');
        this.goBack();
      }
    );
  }

  prepareDestinationData(originalTransaction) {
    let name, institution, originalValue, document;

    if (originalTransaction) {
      name = originalTransaction.nameOrigin;
      institution = originalTransaction.ispbOrigin ? this.formatBank(originalTransaction.ispbOrigin) : null;
      originalValue = originalTransaction.valueTransaction;

      if (originalTransaction.documentOrigin) {
        if (originalTransaction.documentOrigin.length > 11) {
          document = this.consentUseService.maskCnpj(originalTransaction.documentOrigin);
        } else {
          document = this.consentUseService.maskCpf(originalTransaction.documentOrigin);
        }
      }
    }

    this.recipientAccoundData = [
      {
        attributeName: 'Nome',
        attributeValue: name ? name : this.getName(this.transaction),
        alwaysShow: true,
      },
    ];

    if (document) {
      this.recipientAccoundData.push({
        attributeName: 'CPF/CNPJ',
        attributeValue: document,
        alwaysShow: true,
      });
    }

    this.recipientAccoundData.push({
      attributeName: 'Instituição',
      attributeValue: institution ? institution : this.getBank(this.transaction),
      alwaysShow: true,
    });

    this.recipientAccoundData.push({
      attributeName: 'Valor original do pix',
      attributeValue: originalValue
        ? `R$ ${this.maskService.formatValueBRL(originalValue)}`
        : `R$ ${this.maskService.formatValueBRL(this.transaction.credit)}`,
    });

    this.recipientAccoundData.push({
      attributeName: 'Limite de devolução',
      attributeValue: `R$ ${this.maskService.formatValueBRL(this.limitedValue)}`,
    });
  }

  goToResume() {
    this.router.navigate(['/app/pix/devolution/resume'], {
      state: {
        recipientAccoundData: this.recipientAccoundData.filter((data) => data.alwaysShow),
      },
    });
  }

  public async statusTransaction(
    status: string,
    title: string,
    subtitle: string,
    textButton: string = 'Fechar',
    translate: boolean = false
  ) {
    this.loadingService.hide();
    const modalStatus = await this.modalController.create({
      component: ModalPixComponent,
      cssClass: 'modal-pix-small',
      componentProps: {
        title,
        subtitle,
        textButton,
        status,
        translate,
      },
      backdropDismiss: false,
    });
    modalStatus.onDidDismiss().then((data) => {
      if (data.data === true) {
        this.navCtrl.navigateRoot('app/pix');
      }
    });
  }

  async toastPresent(message, duration, position) {
    const toast = await this.toastController.create({
      message,
      duration,
      position,
    });
    toast.present();
  }

  getBank(extract) {
    const data = String(extract.description);
    if (data.toLowerCase().includes('banco')) {
      const divider = data.split(' | ');
      return divider[0].split(': ')[1];
    } else {
      return null;
    }
  }

  formatBank(ispbCode) {
    if (!ispbCode) {
      return '';
    }

    const banks = this.transferService.getBankList();
    const filteredBank = banks.find((bank) => {
      if (parseInt(bank.code) === parseInt(ispbCode) || parseInt(bank.ispb) == parseInt(ispbCode)) {
        return true;
      }
      return false;
    });

    if (filteredBank) {
      const name = String(filteredBank.name).split(' - ');
      if (name.length > 1) {
        return name[1];
      } else {
        return name[0];
      }
    } else {
      return ispbCode;
    }
  }

  getName(extract) {
    const data = String(extract.description);

    if (data.toLowerCase().includes('documento')) {
      // FROM FIRST STATEMENTS
      const parts = data.split(' | ');
      return parts[2].split(': ')[1];
    } else if (data.toLowerCase().includes('nome')) {
      // FROM NEW STATEMENTS
      const parts = data.split(' | ');
      return parts[1].split(': ')[1];
    } else {
      const parts = data.split(' - ');
      return parts[0];
    }
  }

  getDescription(extract) {
    const data = String(extract.description);

    if (data.toLowerCase().includes('chave')) {
      // FROM FIRST STATEMENTS
      const parts = data.split(' | ');
      return parts[4];
    } else if (data.toLowerCase().includes('-')) {
      return null;
    } else {
      // FROM NEW STATEMENTS
      const parts = data.split(' | ');
      if (parts.length === 3) {
        return parts[2];
      }
    }
  }
}
