import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ChooseKeyComponent } from './choose-key/choose-key.component';
import { ConfirmationPixComponent } from './confirmation-pix/confirmation-pix.component';
import { ContractPixComponent } from './contract-pix/contract-pix.component';
import { IntroPixComponent } from './intro-pix/intro-pix.component';
import { PixConclusionComponent } from './pix-conclusion/pix-conclusion.component';

const routes: Routes = [
  {
    path: '',
    component: IntroPixComponent,
  },
  {
    path: 'choose-key',
    component: ChooseKeyComponent,
  },
  {
    path: 'pix-conclusion',
    component: PixConclusionComponent,
  },
  {
    path: 'confirmation-pix',
    component: ConfirmationPixComponent,
  },
  {
    path: 'contract-pix',
    component: ContractPixComponent,
  },
  {
    path: 'confirmation-pix',
    component: ConfirmationPixComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PreRegisterRoutingModule {}
