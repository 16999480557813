import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { TokenCodeService } from '@wlp/ui-bs-token';

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-token.component.html',
  styleUrls: ['./modal-token.component.scss'],
})
export class ModalTokenComponent implements OnInit {
  public formToken: FormGroup;
  public errorMessages: any;
  public errorToken: string;
  public loading = false;
  public statusTransaction: string;
  public userProfile: UserInfoProfile;

  @Input() validationOnly: boolean;
  @Input() validationCard: boolean;
  @Input() buttonText: string;

  constructor(
    public modalController: ModalController,
    private tokenCodeService: TokenCodeService,
    protected userInfoService: UserInfoService
  ) {
    // Iniciando form
    this.errorMessages = this.tokenCodeService.getFormMessageError();
    this.formToken = this.tokenCodeService.createFormToken();
    this.buttonText = this.buttonText ? this.buttonText : this.validationOnly ? 'Confirmar' : 'Solicitar cartão';
  }

  keyUp() {
    this.errorToken = undefined;
  }

  ngOnInit(): void {
    this.loadUser();
  }

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  public async confirm() {
    this.loading = true;
    const token = this.formToken.get('token').value;
    // console.log('token', token);
    const validToken = await this.tokenCodeService.confirmToken(
      this.userProfile.uuidWhiteLabel,
      this.userProfile.uuidAccount,
      token
    );

    // console.log('validToken', await validToken);

    if (await validToken) {
      this.loading = false;
      if (this.validationOnly) {
        if (this.validationCard) {
          this.dismissSuccess(validToken, token);
          return;
        } else {
          this.dismissSuccess(validToken, token);
          return;
        }
      }
      this.dismissSuccess(validToken, token);
    } else {
      this.loading = false;
      this.errorToken = 'CASH_OUT.MODAL_TOKEN.INVALID';
    }
  }

  async dismiss(status: boolean) {
    this.modalController.dismiss(status);
  }

  async dismissSuccess(status: boolean, token: string) {
    this.modalController.dismiss(status, token);
  }
}
