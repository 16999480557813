import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'bankAccount' })
export class BankAccountPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';

    value = String(value).replace(/(\d+)(\d{1})/g, '$1-$2');

    return value;
  }
}
