import { Component, Inject, OnDestroy, OnInit, Input } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { AccountRechargeService, RechargeBilletResponse } from '@wlp/ui-bs-cashin';
import { DOCUMENT } from '@angular/common';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-billet-review-charging.component.html',
  styleUrls: ['./modal-billet-review-charging.component.scss'],
})
export class ModalBilletReviewChargingComponent implements OnDestroy, OnInit {
  @Input() billetRequest: boolean;
  @Input() data: any;
  @Input() billet: any;
  @Input() billetResponse: any;
  dataRecipient: {};
  // value: any = 40;
  // public billetData: RechargeBilletResponse;
  // public billetValue: any;
  userProfile: UserInfoProfile;

  public billetData = { linkRedirect: '', typeableLine: '' };
  public billetValue: any;

  constructor(
    public modalController: ModalController,
    private accountRechargeService: AccountRechargeService,
    private loadingService: LoadingService,
    @Inject(DOCUMENT) private document: Document,
    private navCtrl: NavController,
    protected userInfoService: UserInfoService
  ) {}
  ngOnInit() {
    this.billetData = {
      linkRedirect: this.billetResponse.linkRedirect,
      typeableLine: this.billetResponse.typeableLine,
    };

    this.billetValue = this.billet.billetValue;
  }
  ngOnDestroy() {
    this.accountRechargeService.unSubscribe();
  }

  dismiss() {
    this.modalController.dismiss().then(
      (res) => {},
      (err) => {
        console.error(err);
      }
    );
  }

  printBilletOnPage(msg: string) {
    console.log(this.billet);
    if (this.billetResponse.linkRedirect) {
      window.open(this.billetResponse.linkRedirect, '_blank');
    } else {
      window.open(this.data.linkBillet, '_blank');
    }
  }

  shareBilletOnPage(msg: string) {}

  copyContent() {
    var dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = this.billetResponse.typeableLine;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  }
}
