import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'ui-t-privacy-policy',
  templateUrl: './privacy-policy.page.html',
  styleUrls: ['./privacy-policy.page.scss'],
})
export class PrivacyPolicyPage {
  public brand: string;
  public navigationBackground: string;
  wlName: string;
  company: any;

  constructor(private navCtrl: NavController, private layoutConfigService: LayoutConfigService) {
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
    this.wlName = this.layoutConfigService.getConfig('wlTheme.wlName');
    this.company = this.layoutConfigService.getConfig('wlTheme.company');
  }

  goBack() {
    this.navCtrl.back();
  }
  continue() {
    this.navCtrl.navigateRoot('contract');
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }
}
