import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover-save-options',
  templateUrl: './popover-save-options.component.html',
  styleUrls: ['./popover-save-options.component.scss'],
})
export class PopoverSaveOptionsComponent implements OnInit {

  constructor(public popoverController: PopoverController) { }

  ngOnInit() { }

  async pdf() {
    await this.popoverController.dismiss("pdf");
  }

  async csv() {
    await this.popoverController.dismiss("csv");
  }

}
