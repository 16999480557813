import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

import { AfterContentInit, Component, OnInit } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import {
    AccountPaymentTypeResponse, AccountTypeService, PreAccountResponse
} from '@wlp/ui-bs-signup';

import { environment } from '../../../../../../../../environments/environment';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'ui-t-account-type',
  templateUrl: './account-type.page.html',
  styleUrls: ['./account-type.page.scss'],
})
export class AccountTypePage implements AfterContentInit, OnInit {
  brand: string;
  navigationBackground: string;
  listAccountType: AccountPaymentTypeResponse[];
  physicalPersonIcon: any;
  legalPersonIcon: any;
  meiIcon: any;
  selfEmployedIcon: any;
  accounts: any

  constructor(
    private navCtrl: NavController,
    private accountTypeService: AccountTypeService,
    private layoutConfigService: LayoutConfigService,
    private loadingService: LoadingService,
    public alertController: AlertController,
    private storage: Storage
  ) {
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
  }

  hasTypeFlow: boolean;
  didLoadFinish: boolean;
  typeFlow: string;

  ngOnInit(): void {
    this.physicalPersonIcon = this.layoutConfigService.getImagesPages('physical-person');
    this.legalPersonIcon = this.layoutConfigService.getImagesPages('legal-person');
    this.meiIcon = this.layoutConfigService.getImagesPages('mei');
    this.selfEmployedIcon = this.layoutConfigService.getImagesPages('self-employed');
    this.hasTypeFlow = false;
    this.didLoadFinish = false;
    this.typeFlow = null;
    this.loadingService.show();
    this.accountTypeService.loadUploadsArray().then((uploadsArray) => {
      if (uploadsArray.length) {
        this.accountTypeService
          .loadRegisteredAccountType()
          .then(({ accountType }) => {
            this.loadingService.hide();
            this.didLoadFinish = true;

            if ( accountType.transactionId && accountType.typeFlow ) {
              this.hasTypeFlow = true;
              this.typeFlow = accountType.typeFlow.toString();
            }

          })
          .catch((error) => {
            this.accountTypeService.getErrorMessages(error);
          });
      } else {
        this.loadingService.hide();
        this.didLoadFinish = true;
      }
    });

    this.storage.get('keyForm')
    .then(res => {
      sessionStorage.setItem('keyForm', res)
    })
    this.accounts = JSON.parse(sessionStorage.getItem('accounts'))
  }

  ngAfterContentInit(): void {
    this.listAccountType = [];
    this.loadAccountType();
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Atenção!',
      message: 'Não é possível selecionar esse tipo de conta, uma vez que você já selecionou um fluxo anteriormente.',
      buttons: ['OK'],
    });
    await alert.present();
  }

  defineFlow(accountType: AccountPaymentTypeResponse): void {
    if (accountType.typeFlow.toString() === 'PHHYSICAL_PERSON') {
      this.physicalPerson(accountType);
      return;
    }

    if (accountType.typeFlow.toString() === 'LEGAL_PERSON') {
      this.legalPerson(accountType);
      return;
    }

    if (accountType.typeFlow.toString() === 'MEI') {
      this.mei(accountType);
      return;
    }

    if (accountType.typeFlow.toString() === 'LIBERAL_PROFESSIONAL') {
      this.selfEmployed(accountType);
      return;
    }
  }

 async physicalPerson(accountType: AccountPaymentTypeResponse) {  

    sessionStorage.setItem('idDataFlow', accountType.idDataFlow.toString())
    sessionStorage.setItem('typeFlow', accountType.typeFlow.toString())

    this.accountTypeService
      .defineTypeAccount(accountType, 'device')
      .then((res: PreAccountResponse) => this.navCtrl.navigateRoot('device'));

      // this.accountTypeService
      // .defineTypeAccount(accountType, 'birth-date')
      // .then((res: PreAccountResponse) => this.navCtrl.navigateRoot('birth-date'));
  }

  async legalPerson(accountType: AccountPaymentTypeResponse) {

    sessionStorage.setItem('idDataFlow', accountType.idDataFlow.toString())
    sessionStorage.setItem('typeFlow', accountType.typeFlow.toString())

        this.accountTypeService
      .defineTypeAccount(accountType, 'device')
      .then((res: PreAccountResponse) => this.navCtrl.navigateRoot('device'));

    // this.accountTypeService
    // .defineTypeAccount(accountType, 'birth-date')
    // .then((res: PreAccountResponse) => this.navCtrl.navigateRoot('birth-date'));

    // this.accountTypeService
    //   .defineTypeAccount(accountType, 'partners')
    //   .then((res) => this.navCtrl.navigateRoot('partners'))
    //   .catch((err) => this.accountTypeService.getErrorMessages(err));
  }

  async mei(accountType: AccountPaymentTypeResponse) {

    sessionStorage.setItem('idDataFlow', accountType.idDataFlow.toString())
    sessionStorage.setItem('typeFlow', accountType.typeFlow.toString())

            this.accountTypeService
      .defineTypeAccount(accountType, 'device')
      .then((res: PreAccountResponse) => this.navCtrl.navigateRoot('device'));

    // this.accountTypeService
    // .defineTypeAccount(accountType, 'birth-date')
    // .then((res: PreAccountResponse) => this.navCtrl.navigateRoot('birth-date'));

    // this.accountTypeService
    //   .defineTypeAccount(accountType, 'document-type')
    //   .then((res) => this.navCtrl.navigateRoot('document-type'))
    //   .catch((err) => this.accountTypeService.getErrorMessages(err));
  }

  async selfEmployed(accountType: AccountPaymentTypeResponse) {

    sessionStorage.setItem('idDataFlow', accountType.idDataFlow.toString())
    sessionStorage.setItem('typeFlow', accountType.typeFlow.toString())

            this.accountTypeService
      .defineTypeAccount(accountType, 'device')
      .then((res: PreAccountResponse) => this.navCtrl.navigateRoot('device'));

    // this.accountTypeService
    // .defineTypeAccount(accountType, 'birth-date')
    // .then((res: PreAccountResponse) => this.navCtrl.navigateRoot('birth-date'));

    // this.accountTypeService
    //   .defineTypeAccount(accountType, 'professional-type')
    //   .then((res) => this.navCtrl.navigateRoot('professional-type'))
    //   .catch((err) => this.accountTypeService.getErrorMessages(err));
  }

  goBack() {
    this.navCtrl.navigateForward('create-account');
  }

  hasPhysicalPersonAccountType(accounts: any): boolean {
    for (const account of accounts) {
      if (account.accountType.typeFlow === 'PHHYSICAL_PERSON' && account.status !== 'IN_ONBORDING') {
        return true;
      }
    }
    return false;
  }

  private loadAccountType(): void {
    this.accountTypeService
      .loadTypeAccount(environment.wlp)
      .then((list: any) => {
        if(this.hasPhysicalPersonAccountType(this.accounts)){
          for(let i = 0; i < list.length; i++){
            if(list[i].typeFlow === 'PHHYSICAL_PERSON'){
              list.splice(i, 1)
            }
          }
        }
        this.listAccountType = list
      })
      .catch((err) => this.accountTypeService.getErrorMessages(err));
  }

  loadIcon(accountType) {
    let icon;

    switch (accountType) {
      case 'PHHYSICAL_PERSON':
        icon = this.physicalPersonIcon.path;
        break;
      case 'LEGAL_PERSON':
        icon = this.legalPersonIcon.path;
        break;
      case 'MEI':
        icon = this.meiIcon.path;
        break;
      case 'LIBERAL_PROFESSIONAL':
        icon = this.selfEmployedIcon.path;
        break;
    }
    return icon;
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }
}
