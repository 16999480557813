import { Component, OnInit, ViewChild } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { KeycloakService, QrCodeService, UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { CountdownComponent, CountdownEvent } from 'ngx-countdown';
import { interval, of, Subject, Subscription } from 'rxjs';
import { catchError, flatMap, retry, startWith, takeUntil, tap } from 'rxjs/operators';
import { QrCodeAuthenticationGuard } from 'src/app/core/auth/qrcode-authentication-guard';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account-closure',
  templateUrl: './account-closure.page.html',
  styleUrls: ['./account-closure.page.scss'],
})
export class AccountClosurePage {
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  public brand: string;
  public background: string;
  public brandFooter: string;
  public env: any;
  public wlName: string;

  constructor(
    private layoutConfigService: LayoutConfigService,
    protected userInfoService: UserInfoService,
    private keycloakService: KeycloakService,
  ) {
    this.wlName = environment.wlThemeParameterization.wlTheme.wlName.toLowerCase();
    this.brand = this.layoutConfigService.getConfig('wlTheme.intro.brand.logo');
    this.brandFooter = this.layoutConfigService.getConfig('wlTheme.intro.footer.brand');
    this.background = this.layoutConfigService.getConfig('wlTheme.intro.background.image');

    this.env = environment.production;
  }

  public login(): void {
    let redirectUrl = document.location.protocol + '//' + document.location.hostname;

    if (document.location.hostname.includes('localhost')) {
      redirectUrl += ':' + document.location.port;
    }

    this.keycloakService.login({redirectUri: redirectUrl + '/app/validate-qrcode'}).then( () => {
    });
  }

}
