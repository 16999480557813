import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { AccountRechargeService, RechargeBilletResponse } from '@wlp/ui-bs-cashin';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import {ModalOperationNotificationComponent} from '../modal-operation-notification/modal-operation-notification.component';

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-billet-review.component.html',
  styleUrls: ['./modal-billet-review.component.scss'],
})
export class ModalBilletReviewComponent implements OnDestroy, OnInit {

  @Input() public billetRequest: boolean;
  @Input() public data: any;

  public billetData: RechargeBilletResponse;
  public billetValue: any;
  public userProfile: UserInfoProfile;

  constructor(
    public modalController: ModalController,
    private accountRechargeService: AccountRechargeService,
    private loadingService: LoadingService,
    @Inject(DOCUMENT) private document: Document,
    private navCtrl: NavController,
    protected userInfoService: UserInfoService,
  ) { }

  ngOnInit() {
    if (this.billetRequest) {
      this.loadingService.show();
      this.accountRechargeService.getValue().subscribe((obs) => {
        if (!obs) {
          this.loadingService.hide();
          this.navCtrl.navigateBack('cp-manager/account-recharge');
        } else {
          const userDetails = this.userInfoService.getInfoProfile();
          userDetails.then(user => {
            this.userProfile = user;

            this.billetValue = Number(obs.valueField.replace(" ", "0").replace('.', '').replace(',', '.'));

            // console.log(parseFloat(obs.valueField.replace('.', '').replace(',', '.')))

            this.accountRechargeService.generateBillet(parseFloat(this.billetValue), user.uuidAccount, user.uuidWhiteLabel)
              .subscribe((res) => {
                this.loadingService.hide();
                if (res.message) {
                  this.dismiss(true);
                  this.alert('failed', res.message, false);
                  return;
                }
                this.billetData = res;
              });
          });
        }
      });

    } else {
      this.billetData = this.data;
    }
  }

  ngOnDestroy() {
    this.accountRechargeService.unSubscribe();
  }

  dismiss(error?: boolean) {
    this.modalController.dismiss(error).then((res) => { }, (err) => {
      console.error(err);
    });
  }

  printBilletOnPage(msg: string) {
    if (this.billetData.linkRedirect) {
      window.open(this.billetData.linkRedirect, '_blank');
    } else {
      window.open(this.data.linkBillet, '_blank');
    }
  }

  shareBilletOnPage(msg: string) {
  }

  copyContent() {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = this.billetData.typeableLine;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  public async alert(status: string, message: string, translate: boolean) {
    const modalStatus = await this.modalController.create({
      component: ModalOperationNotificationComponent,
      cssClass: 'modal-operation-notification',
      componentProps: {
        status,
        message,
        translate,
      },
      backdropDismiss: false,
    });
    return await modalStatus.present();
  }
}
