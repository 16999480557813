import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { UiCContainerBrandModule } from '@wlp/ui-c-container-brand';
import { UiCModalModule } from '@wlp/ui-c-modal';
import { UiCSwiperModule } from '@wlp/ui-c-swiper';

import { ComponentsModule } from '../../../../../core/shared/modules/components.module';
import { IntroPageRoutingModule } from './intro-routing.module';
import { IntroPage } from './intro.page';
import {InterceptService} from '../../../../../core/intercept/intercept.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';

@NgModule({
  imports: [
    IonicModule,
    TranslateModule,
    UiCSwiperModule,
    UiCContainerBrandModule,
    ComponentsModule,
    IntroPageRoutingModule,
    UiCModalModule,
    CommonModule,
  ],
  declarations: [IntroPage],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ]
})
export class IntroPageModule {}
