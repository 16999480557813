import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { FileAccount, PreAccountResponse, UploadService } from '@wlp/ui-bs-signup';

@Component({
  selector: 'ui-t-choose-document-ec',
  templateUrl: './choose-document-ec.page.html',
  styleUrls: ['./choose-document-ec.page.scss'],
})
export class ChooseDocumentEcPage {
  private fileAccount: FileAccount;
  private preAccount: PreAccountResponse;

  constructor(
    private navCtrl: NavController,
    private uploadService: UploadService,
    private layoutConfigService: LayoutConfigService
  ) {}

  /**
   * AO ENTRAR DA PAGINA
   */
  ionViewWillEnter(): void {
    this.loadFileAccount();
  }

  uploadRG() {
    let fileAccount = new FileAccount();
    fileAccount.title = 'Rg';
    fileAccount.uploadType = 'RG';
    fileAccount.positionPhoto = 'FRONT';
    fileAccount.groupDocument = this.fileAccount.groupDocument;
    fileAccount.optionParam = this.fileAccount.optionParam;
    this.uploadService.createFileAccount(fileAccount);
    this.navCtrl.navigateRoot('upload-documents');
  }

  uploadCNH() {
    let fileAccount = new FileAccount();
    fileAccount.title = 'CNH';
    fileAccount.uploadType = 'CNH';
    fileAccount.positionPhoto = 'FRONT';
    fileAccount.groupDocument = this.fileAccount.groupDocument;
    fileAccount.optionParam = this.fileAccount.optionParam;
    this.uploadService.createFileAccount(fileAccount);
    this.navCtrl.navigateRoot('upload-documents');
  }

  goBack() {
    this.uploadService
      .getAccountType()
      .then((res: PreAccountResponse) => {
        this.redirectToFlow(res);
      })
      .catch((err) => this.uploadService.getErroUpload(err));
  }

  private redirectToFlow(preAccountService: PreAccountResponse): void {
    if (
      preAccountService.accountType.typeFlow.toString() === 'PHHYSICAL_PERSON' ||
      preAccountService.accountType.typeFlow.toString() === 'PHYSICAL_PERSON'
    ) {
      this.navCtrl.navigateRoot('document-type');
      return;
    }

    if (preAccountService.accountType.typeFlow.toString() === 'LEGAL_PERSON') {
      this.navCtrl.navigateRoot('company-documents');
      return;
    }

    if (preAccountService.accountType.typeFlow.toString() === 'MEI') {
      this.navCtrl.navigateRoot('document-type');
      return;
    }

    if (preAccountService.accountType.typeFlow.toString() === 'LIBERAL_PROFESSIONAL') {
      this.navCtrl.navigateRoot('choose-document');
      return;
    }
  }

  private loadFileAccount(): void {
    this.uploadService.loadFileTypeUpload().then((res) => {
      this.fileAccount = res;
      if (!this.fileAccount) {
        this.goBack();
      }
    });
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }
}
