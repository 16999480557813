import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TransactionApprovalService } from '@wlp/ui-bs-cashout';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import * as moment from 'moment';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { ModalOperationNotificationComponent } from '../../../../modals/modal-operation-notification/modal-operation-notification.component';
import { ValidSmsUserPage } from '../../../../pages/cp-manager/cash-out/valid-sms/valid-sms-user.page';

const statuses = {
  WAITING_APPROVAL: 'Aguardando Aprovação',
  APPROVED: 'Aprovado',
  DISAPPROVED: 'Reprovado',
  REJECTED: 'Rejeitado',
};

const statusesColors = {
  WAITING_APPROVAL: 'primary',
  APPROVED: 'success',
  DISAPPROVED: 'danger',
  REJECTED: 'warning',
};

const types = {
  TED: 'TED',
  PIX: 'Pix',
  BILLET: 'Boleto',
};

@Component({
  selector: 'app-transaction-approval-list',
  templateUrl: './transaction-approval-list.page.html',
  styleUrls: ['./transaction-approval-list.page.scss'],
})
export class TransactionApprovalListPage implements OnInit {
  public errorMessages: any;
  public data: Array<any> = [];
  public filteredData: Array<any> = [];
  private userProfile: UserInfoProfile;
  public countUsers = 0;
  private codeAuthorization = '';
  protected nav;

  // filtro
  public filterInitialDate: string = null;
  public filterEndDate: string = null;
  public filterStatus: string = null;
  public filterType: string = null;

  constructor(
    private service: TransactionApprovalService,
    protected userInfoService: UserInfoService,
    private loadingService: LoadingService,
    public modalController: ModalController,
    private router: Router
  ) {
    this.nav = this.router.getCurrentNavigation();
    this.filterInitialDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
    this.filterEndDate = moment().format('YYYY-MM-DD');
  }

  ngOnInit() {}

  ionViewWillEnter() {
    this.loadUser();
  }

  loadUser() {
    this.userInfoService
      .getInfoProfile()
      .then((user) => {
        this.userProfile = user;
        this.refresh();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  public onSelectedDate(dateSelected: moment.Moment, status: string): boolean {
    if (dateSelected === undefined) {
      return;
    }

    const date = dateSelected.format('YYYY-MM-DD');
    if (status === 'initial') {
      this.filterInitialDate = date;
      return;
    }

    if (status === 'end') {
      this.filterEndDate = date;
      return;
    }
  }

  loadParams(): any {
    const params: { [key: string]: any } = {};

    if (this.filterInitialDate !== null) {
      params.start = this.filterInitialDate;
    }
    if (this.filterEndDate !== null) {
      params.end = this.filterEndDate;
    }
    if (this.filterStatus !== null && this.filterStatus !== 'ALL') {
      params.status = this.filterStatus;
    }
    if (this.filterType !== null && this.filterType !== 'ALL') {
      params.type = this.filterType;
    }

    return params;
  }

  async refresh() {
    await this.loadingService.show();
    try {
      await this.service
        .getTransactions(this.userProfile.uuidAccount, this.userProfile.uuidWhiteLabel, this.loadParams())
        .toPromise()
        .then((res) => {
          this.data = res.map((it) => ({
            ...it,
            dateRequested: it.dateRegister,
            amount: it.operationValue,
            parsedDateUpdated: it.dateUpdated ? this.dateControl(it.dateUpdated) : null,
            parsedStatus: statuses[it.status],
            statusColor: statusesColors[it.status],
          }));
          this.filteredData = this.data;
          this.countUsers = this.data.length;
        });
    } finally {
      await this.loadingService.hide();
    }
  }

  infiniteScrollEvent(event: Event) {
    console.log(event);
  }

  filter(event) {
    const val = event.toLowerCase() || '';

    this.filteredData = this.data.filter((d) => {
      return (
        String(d.user.fullName).toLowerCase().indexOf(val) !== -1 ||
        String(d.user.email).toLowerCase().indexOf(val) !== -1
      );
    });
  }

  async handleApproveTransactions() {
    const modalToken = await this.modalController.create({
      component: ValidSmsUserPage,
      cssClass: 'modal-resume',
    });
    modalToken.onDidDismiss().then((data) => {
      if (data.data[0].validToken === true) {
        this.codeAuthorization = data.data[0].token;
        this.approveTransactions();
      }
    });

    return modalToken.present();
  }

  async approveTransactions() {
    await this.loadingService.show();

    try {
      const selectedData = this.getSelectedData();

      const payload = {
        codeAuthorization: this.codeAuthorization,
        operations: selectedData.map((it) => it),
      };

      await this.service.approveTransactions(this.userProfile.uuidAccount, payload).toPromise();
    } catch {
      this.showMessage(
        'failed',
        'Ocorreu um erro ao aprovar as transações selecionadas. Tente novamente em breve',
        false
      );
    } finally {
      await this.loadingService.hide();
      this.refresh();
    }
  }

  async handleRejectTransactions() {
    await this.loadingService.show();

    try {
      const selectedData = this.getSelectedData();

      const payload = {
        operations: selectedData.map((it) => it),
      };

      await this.service.rejectTransactions(this.userProfile.uuidAccount, payload).toPromise();
    } catch {
      this.showMessage(
        'failed',
        'Ocorreu um erro ao desaprovar as transações selecionadas. Tente novamente em breve',
        false
      );
    } finally {
      await this.loadingService.hide();

      this.refresh();
    }
  }

  getSelectedData() {
    return this.filteredData.filter((it) => it.isChecked);
  }

  isButtonDisabled() {
    return !this.filteredData.some((it) => it.isChecked);
  }

  public dateControl(date: any): string {
    try {
      return `${moment(date).format('DD/MM/YYYY HH:mm')}`;
    } catch (err) {
      return '';
    }
  }

  translateStatus(status: string) {
    switch(status) {
      case 'TED_ON_US':
        return 'Transferência entre contas'

      case 'BILLET':
        return 'Boleto'
    }
  }

  isCheckBoxDisabled(data: any): boolean {
    if (this.userProfile.userId === data.userId) {
      return true;
    }

    return data.status !== 'WAITING_APPROVAL';
  }

  public async showMessage(status: string, message: string, translateFlag: boolean) {
    this.loadingService.hide();
    const translate = translateFlag ? true : false;
    const modalStatus = await this.modalController.create({
      component: ModalOperationNotificationComponent,
      cssClass: 'modal-operation-notification',
      componentProps: {
        status,
        message,
        translate,
      },
      backdropDismiss: false,
    });
    return await modalStatus.present();
  }

  getStatuses(): Array<string> {
    return Object.keys(statuses);
  }

  getStatusText(status: string): string {
    return statuses[status] ? statuses[status] : status;
  }

  getTypes(): Array<string> {
    return Object.keys(types);
  }

  getTypeText(type: string): string {
    return types[type] ? types[type] : status;
  }

  handleTypeChange(e) {
    this.filterType = e.detail.value;
  }

  handleStatusChange(e) {
    this.filterStatus = e.detail.value;
  }
}
