import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserInfoService } from '@wlp/ui-bs-login';

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-extract-review.component.html',
  styleUrls: ['./modal-extract-review.component.scss'],
})
export class ModalExtractReviewComponent {
  @Input() public confirmationData: any;

  public confirmation: any;
  public brand: string;
  public voucher: string;

  constructor(
    public modalController: ModalController,
    private loadingService: LoadingService,
    @Inject(DOCUMENT) private document: Document,
    protected userInfoService: UserInfoService,
    private layoutConfigService: LayoutConfigService
  ) {
    this.brand = this.layoutConfigService.getConfig('wlTheme.header.billet.brand.logo');
    this.loadingService.show();

    setTimeout(() => {
      this.loadingService.hide();
      this.confirmation = this.confirmationData;
    }, 2000);
  }

  voucherChoose(code) {
    switch (code) {
      case '112':
        return 'Payment';
      case '902':
        return 'Payment';
      case '903':
        return 'Payment';
      case '904':
        return 'Payment';
      case '905':
        return 'Recharge';
      case '901':
        return 'Transfer';
      case '117':
        return 'Transfer';
      case '213':
        return 'Transfer';
      case '805':
        return 'Transfer';
      case '120':
        return 'Transfer';
      case '209':
        return 'Transfer';
      case '1016':
        return 'Pix';
      case '1017':
        return 'Pix';
      default:
        return 'Payment';
    }
  }

  async dismiss() {
    this.modalController.dismiss();
  }

  print() {}
}
