import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { TransferService } from '@wlp/ui-bs-cashout';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UiBsMyDataService } from '@wlp/ui-bs-my-data';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

import { ModalOperationNotificationComponent } from '../../../../../modals/modal-operation-notification/modal-operation-notification.component';
import { ModalSearchBankComponent } from '../../../../../modals/modal-search-bank/modal-search-bank.component';
import { ModalTokenComponent } from '../../../../../modals/modal-token/modal-token.component';
import { ModalTransferResumeComponent } from '../../../../../modals/modal-transfer-resume/modal-transfer-resume.component';
import { ValidSmsUserPage } from '../../valid-sms/valid-sms-user.page';

@Component({
  selector: 'ui-t-transfer-other-accounts',
  templateUrl: './transfer-other-accounts.page.html',
  styleUrls: ['./transfer-other-accounts.page.scss'],
})
export class TransferOtherAccountsPage implements OnInit, OnDestroy {
  public formTransfer: FormGroup;
  public errorMessages: any;
  public bankList: Array<any>;
  userProfile: UserInfoProfile;
  ownerShip: boolean;

  constructor(
    private navCtrl: NavController,
    private transferService: TransferService,
    private myDataService: UiBsMyDataService,
    private modalController: ModalController,
    protected userInfoService: UserInfoService,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.errorMessages = this.transferService.getFormMessageError();
    this.formTransfer = this.transferService.createFormTransfer();
    this.loadUser();
    this.ownerShip = false;
  }

  ionViewWillEnter() {
    this.transferService.setTransferFlow('TED');
  }

  ngOnDestroy() {}

  public sameOwnership(event) {
    if (this.ownerShip === false) {
      this.ownerShip = true;
      this.myDataService.getAccountPaymentPerson(this.userProfile.uuidAccount).subscribe(
        (res) => {
          this.formTransfer.controls.document.setValue(this.formatCnpjCpf(res.documentDefault));
          this.formTransfer.controls.name.setValue(res.fullName);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      this.formTransfer.controls.document.setValue('');
      this.formTransfer.controls.name.setValue('');
      this.ownerShip = false;
      this.cdr.detectChanges();
    }
  }

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  checkValue(value) {
    if (/^0/.test(value)) {
      this.formTransfer.controls.transferValue.reset();
      return;
    }

    value = value.replace(/[^\w\s]/gi, '');
    value = parseFloat(value);

    if (value < 1000) {
      this.formTransfer.controls.transferValue.setErrors({ invalid10: true });
    }
  }

  public checkForm() {
    if (this.formTransfer.status === 'VALID') {
      return false;
    } else {
      return true;
    }
  }

  public validateOperation() {
    this.formTransfer.controls.typeTransfer.setValue('TED');
    this.transferService.setTransfer(this.formTransfer);
    this.loadingService.show();

    this.transferService
      .validateOperation(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount)
      .then((res) => {
        this.loadingService.hide();

        if (!res) {
          this.statusTransaction('failed', 'CASH_OUT.TRANSFER_OTHER_ACCOUNT.MESSAGES.FAILED', true);
          return;
        } else {
          if (!res.isAllowed) {
            // this.statusTransaction('failed', res.message, false);
            this.statusTransaction('failed', 'CASH_OUT.TRANSFER_OTHER_ACCOUNT.MESSAGES.FAILED_NOT_FUNDS', true);
            return;
          } else {
            this.goToResume();
            return;
          }
        }
      })
      .catch((err) => {
        this.loadingService.hide();
        this.transferService.getErrorTransfer(err);
        this.statusTransaction('failed', 'CASH_OUT.TRANSFER_OTHER_ACCOUNT.MESSAGES.FAILED', true);
      });
  }

  public async goToResume() {
    const modalResume = await this.modalController.create({
      component: ModalTransferResumeComponent,
      cssClass: 'modal-resume',
    });
    modalResume.onDidDismiss().then((data) => {
      if (data['data'] === true) {
        this.token();
      }
    });

    return await modalResume.present();
  }

  public async token() {
    const modalToken = await this.modalController.create({
      component: ValidSmsUserPage,
      cssClass: 'modal-resume',
    });
    modalToken.onDidDismiss().then((data) => {
      if (data.data[0].validToken === true) {
        this.sendTransaction(data.data[0].token);
      }
    });
    return await modalToken.present();
  }

  public async sendTransaction(token: string) {
    this.loadingService.show();
    await this.transferService
      .sendTransaction(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, token)
      .then((res) => {
        const response = res;
        if (response !== null) {
          if (response.isExecuted === true) {
            this.statusTransaction('success', 'CASH_OUT.TRANSFER_OTHER_ACCOUNT.MESSAGES.SUCCESS', true);
            return;
          } else if (response.isExecuted === false) {
            let status = 'failed';
            if (String(response.message).toLowerCase().includes('em processamento')) {
              status = 'info';
            } else if (String(response.message).toLowerCase().includes('aprovação manual')) {
              status = 'info';
            }

            this.statusTransaction(status, response.message, false);
            return;
          } else {
            this.statusTransaction('failed', 'CASH_OUT.TRANSFER_OTHER_ACCOUNT.MESSAGES.FAILED', true);
            return;
          }
        } else {
          this.statusTransaction('failed', 'CASH_OUT.TRANSFER_OTHER_ACCOUNT.MESSAGES.FAILED', true);
        }
      })
      .catch((err) => {
        this.transferService.getErrorTransfer(err);
        this.statusTransaction('failed', 'CASH_OUT.TRANSFER_OTHER_ACCOUNT.MESSAGES.FAILED', true);
      });
  }

  public async statusTransaction(status: string, message: string, translateFlag: boolean) {
    this.loadingService.hide();
    const translate = translateFlag ? true : false;
    const modalStatus = await this.modalController.create({
      component: ModalOperationNotificationComponent,
      cssClass: 'modal-operation-notification',
      componentProps: {
        status,
        message,
        translate,
      },
      backdropDismiss: false,
    });
    modalStatus.onDidDismiss().then((data) => {
      if (data['data'] === true) {
        this.navCtrl.navigateRoot('app/transfer/receipt');
      } else {
        this.navCtrl.navigateRoot('app');
      }
    });
    return await modalStatus.present();
  }

  public async searchBank() {
    const modalBank = await this.modalController.create({
      component: ModalSearchBankComponent,
      cssClass: 'modal-search-bank',
    });
    modalBank.onDidDismiss().then((data) => {
      if (data['data']) {
        this.formTransfer.controls.bank.setValue(data['data'].name);
        this.formTransfer.controls.bankCode.setValue(data['data'].code);
      }
    });
    return await modalBank.present();
  }

  formatCnpjCpf(value) {
    const cnpjCpf = value.replace(/\D/g, '');
    if (cnpjCpf.length === 11) {
      return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
    }
    return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
  }

  isValid() {
    if (this.formTransfer.invalid) {
      return true;
    } else {
      return false;
    }
  }
}
