import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'ui-t-modal-operation-notification',
  templateUrl: './modal-operation-notification.component.html',
  styleUrls: ['./modal-operation-notification.component.scss'],
})
export class ModalOperationNotificationComponent {
  @Input() status: string;
  @Input() message: string;

  public translator: boolean;
  @Input() set translate(value) {
    if (value !== undefined && value !== null) {
      this.translator = value;
    }
  }

  private confirmatedIcon: any;
  private recusadedIcon: any;
  private warnIcon: any;
  private infoIcon: any;

  constructor(public modalController: ModalController, private layoutConfigService: LayoutConfigService) {
    this.confirmatedIcon = this.layoutConfigService.getImagesPages('confirmated');
    this.recusadedIcon = this.layoutConfigService.getImagesPages('recused');
    this.warnIcon = this.layoutConfigService.getImagesPages('warning-icon');
    this.infoIcon = this.layoutConfigService.getImagesPages('info-icon');
  }

  modalImage() {
    if (this.status === 'success') {
      return this.confirmatedIcon.path;
    } else if (this.status === 'warn' || this.status === 'warning') {
      return this.warnIcon.path;
    } else if (this.status === 'info') {
      return this.infoIcon.path;
    } else {
      return this.recusadedIcon.path;
    }
  }

  classNotification() {
    if (this.status === 'success') {
      return 'success-text';
    } else if (this.status === 'warn' || this.status === 'warning') {
      return 'warn-text';
    } else if (this.status === 'info') {
      return 'info-text';
    } else {
      return 'failed-text';
    }
  }

  messageModal() {
    if (this.status === 'success') {
      return 'Ted enviada com sucesso!';
    } else if (this.status === 'noBalace') {
      return 'Não há saldo suficiente para a transação';
    } else {
      return 'Houve um erro ao processar sua transação';
    }
  }

  async dismiss() {
    if (this.status === 'success') {
      this.modalController.dismiss(true);
    } else {
      this.modalController.dismiss(false);
    }
  }
}
