import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { tap } from 'rxjs/operators';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavController, ToastController } from '@ionic/angular';
import { IPageBase, PartnersService } from '@wlp/ui-bs-signup';

@Component({
  selector: 'ui-t-partners',
  templateUrl: './partners.page.html',
  styleUrls: ['./partners.page.scss'],
})
export class PartnersPage implements OnInit, OnDestroy, IPageBase {
  brand: string;
  listPartners: any;
  subscribe: any;

  formAddPartners: FormGroup;
  errorFormMessages: any;
  businessPartner: any;
  readonly: boolean;
  isDisabled: boolean;

  indexItem: number;
  flagPartner: boolean;
  navigationBackground: string;
  edit: boolean = false;
  disabledCancel:boolean;

  constructor(
    private navCtrl: NavController,
    private partnersService: PartnersService,
    private loadingService: LoadingService,
    private toastController: ToastController,
    private layoutConfigService: LayoutConfigService
  ) {
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
  }

  ngOnInit() {
    this.partnersService.initializeBehaviourList();
    this.partnersService.updateForm();
    this.loadPartners();
    this.formAddPartners = this.partnersService.getFormBusinessPartner();
    this.errorFormMessages = this.partnersService.getFormMessageError();
    this.isDisabled = false;
    this.indexItem = null;
    this.readonly = false;

    this.indexItem = 0;

    this.disabledCancel = true;

    this.subscribe = this.partnersService.getListPartners().subscribe((x) => {
      this.businessPartner = x[this.indexItem];
      this.businessPartner ? (this.readonly = this.businessPartner.master) : false;
      this.toEdit();
    });
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }

  goBack() {
    this.navCtrl.navigateForward('account-type');
  }

  addPartner() {
    this.indexItem = null;
    this.isDisabled = false;
    this.readonly = false;
    this.edit = false;
  }

  editPartner(index) {
    this.isDisabled = false;
    this.indexItem = index;

    this.edit = false;

    this.subscribe = this.partnersService.getListPartners().subscribe((x) => {
      this.businessPartner = x[this.indexItem];
      this.businessPartner ? (this.readonly = this.businessPartner.master) : false;
      this.toEdit();
    });
  }

  toEdit() {
    if (this.indexItem !== null && this.businessPartner) {
      this.formAddPartners = this.partnersService.editForm(this.businessPartner);
    }
  }

  deletePartner(index) {
    this.partnersService.removePartner(index);
  }

  loadPartners() {
    this.subscribe = this.partnersService.getListPartners().subscribe((x) => {
      this.listPartners = x;
    });
  }

  loading() {
    this.loadingService.show();
    this.partnersService
      .getStatus()
      .pipe(
        tap((res) => {
          if (res === false) {
            this.toastError();
          }
          this.loadingService.hide();
        })
      )
      .subscribe();
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }

  public async savePartner() {
    if (this.indexItem === null) {
      if (await this.findCpfExists()) {
        await this.partnersService.addNewPartner(this.formAddPartners);
        // console.log(this.listPartners);
      } else {
        this.toastError();
      }
    } else {
      await this.partnersService.editPartner(this.formAddPartners, this.indexItem);
    }
    this.formAddPartners = this.partnersService.getFormBusinessPartner();
    this.indexItem = null;
    this.isDisabled = true;
    this.edit = true;
  }

  continue() {
    // checa qual o tipo de partner antes de enviar para a outra tela
    const types = [
      { type: 'legalRepresentative', value: this.listPartners[0].legalRepresentative },
      { type: 'attorney', value: this.listPartners[0].attorney },
      { type: 'partner', value: this.listPartners[0].partner },
    ];

    const type = types.filter((arr) => {
      if (arr.value === true) return arr.type;
    });

    // envia o usupario para a página de documentos com o tipo.
    // this.navCtrl.navigateRoot(['documents-partner'], { queryParams: { type: type[0].type } });
    this.navCtrl.navigateRoot('company-data');
  }

  async toastError() {
    const toast = await this.toastController.create({
      message: 'Ocorreu um erro ao salvar. Por favor tente novamente',
      duration: 3000,
    });
    toast.present();
  }

  findCpfExists(): boolean {
    const listCpf = this.partnersService.getListPartners().value;
    let valid = true;

    listCpf.forEach((el) => {
      if (el.docNumber === this.formAddPartners.get('docNumber').value) {
        valid = false;
      }
    });
    return valid;
  }

  cancelPartner() {    
    this.formAddPartners = this.partnersService.getFormBusinessPartner();
    this.indexItem = null;
    this.isDisabled = true;
    this.edit = true;
  }
}
