import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UICMenuAppService } from '@wlp/ui-c-menu-app';

@Component({
  selector: 'card-loading-page',
  templateUrl: './card-loading.page.html',
  styleUrls: ['./card-loading.page.scss'],
})
export class CardLoadingPage implements OnInit {
  public showValueFlag: boolean;
  public showValueIcon: string;
  public brand: string;

  constructor(private uicMenuApp: UICMenuAppService, private modalCtrl: ModalController) {}

  ngOnInit(): void {}

  showIcon(icon: string, extension?: string): string {
    let iconUrl = `assets/images/prize-draw/wallet/${icon}.svg`;
    if (extension) {
      iconUrl = iconUrl.replace(/\.svg$/, `.${extension}`);
    }

    return iconUrl;
  }

  ionViewDidEnter() {
    this.uicMenuApp.applyStatus(false);
  }

  closeModal(): void {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }
}
