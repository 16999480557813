import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { AccountBalanceService } from '@wlp/ui-bs-cashin';
import { TransferResponse, TransferService } from '@wlp/ui-bs-cashout';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

import { ModalOperationNotificationComponent } from '../../../../../modals/modal-operation-notification/modal-operation-notification.component';
import { ModalTokenComponent } from '../../../../../modals/modal-token/modal-token.component';
import { ModalTransferResumeComponent } from '../../../../../modals/modal-transfer-resume/modal-transfer-resume.component';
import { ValidSmsUserPage } from '../../valid-sms/valid-sms-user.page';

@Component({
  selector: 'app-transfer-on-us',
  templateUrl: './transfer-on-us.page.html',
  styleUrls: ['./transfer-on-us.page.scss'],
})
export class TransferOnUsPage implements OnInit {
  public formTransfer: FormGroup;
  public errorMessages: any;
  public transferData: any;
  public transferQuestion: string;
  param: object;

  userProfile: UserInfoProfile;

  constructor(
    private accountBalanceService: AccountBalanceService,
    private navCtrl: NavController,
    private transferService: TransferService,
    private loadingService: LoadingService,
    private modalController: ModalController,
    private layoutConfigService: LayoutConfigService,
    protected userInfoService: UserInfoService
  ) {
    this.param = { wlName: this.layoutConfigService.getConfig('wlTheme.wlName') };
  }

  ngOnInit() {
    this.errorMessages = this.transferService.getFormMessageError();
    this.formTransfer = this.transferService.getFormTransfer();
    this.loadUser();
  }

  ionViewWillEnter() {
    this.transferService.setTransferFlow('On-Us');
    this.loadData();
  }

  ngOnDestroy() {}

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
        // console.log('PROFILE', this.userProfile);
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  public loadData() {
    this.transferService.getTransfer().subscribe((res: TransferResponse) => {
      this.transferData = res;
    });
    if (this.transferData) {
      this.param = this.transferData;
      this.transferQuestion = 'CASH_OUT.TRANSFER_VALUE.DESCRIPTION.PRIMARY';
    } else {
      this.transferQuestion = 'CASH_OUT.TRANSFER_VALUE.DESCRIPTION.SECONDARY';
    }
  }

  public typeDocument() {
    if (this.formTransfer.get('document').value) {
      const document: string = this.formTransfer.get('document').value;
      if (document.length > 14) {
        return 'CNPJ:';
      } else {
        return 'CPF:';
      }
    } else {
      return '';
    }
  }

  checkValue(value) {
    if (/^0/.test(value)) {
      this.formTransfer.controls.transferValue.reset();
      return;
    }

    value = value.replace(/[^0+\w\s]/gi, '');
    value = parseFloat(value);

    if (value < 100) {
      this.formTransfer.controls.transferValue.setErrors({ invalid1: true });
    }
  }

  public checkFormValue() {
    if (this.formTransfer.status === 'VALID') {
      return false;
    } else {
      return true;
    }
  }

  public validateOperation() {
    this.formTransfer.controls.typeTransfer.setValue('On-Us');
    this.transferService.setTransfer(this.formTransfer);
    this.loadingService.show();

    this.transferService
      .validateOperation(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount)
      .then((res) => {
        this.loadingService.hide();

        if (!res) {
          this.statusTransaction('failed', 'CASH_OUT.TRANSFER_ON_US.MESSAGES.FAILED', true);
          return;
        } else {
          if (!res.isAllowed) {
            // this.statusTransaction('failed', res.message, false);
            this.statusTransaction('failed', 'CASH_OUT.TRANSFER_ON_US.MESSAGES.FAILED_NOT_FUNDS', true);
            return;
          } else {
            this.goToResume();
            return;
          }
        }
      })
      .catch((err) => {
        this.loadingService.hide();
        this.transferService.getErrorTransfer(err);
        this.statusTransaction('failed', 'CASH_OUT.TRANSFER_ON_US.MESSAGES.FAILED', true);
      });
  }

  public async goToResume() {
    const modalResume = await this.modalController.create({
      component: ModalTransferResumeComponent,
      cssClass: 'modal-resume',
    });
    modalResume.onDidDismiss().then((data) => {
      if (data['data'] === true) {
        this.token();
        this.loadBalance(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount);
      }
    });

    return await modalResume.present();
  }

  public async token() {
    const modalToken = await this.modalController.create({
      component: ValidSmsUserPage,
      cssClass: 'modal-resume',
    });
    modalToken.onDidDismiss().then((data) => {
      if (data.data[0].validToken === true) {
        this.sendTransaction(data.data[0].token);
      }
    });
    return await modalToken.present();
  }

  public async sendTransaction(token: string) {
    this.loadingService.show();
    await this.transferService
      .sendTransaction(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, token)
      .then((res) => {
        const response = res;

        if (response !== null) {
          if (response.isExecuted === true) {
            this.statusTransaction('success', 'CASH_OUT.TRANSFER_ON_US.MESSAGES.SUCCESS', true);
            return;
          } else if (response.isExecuted === false) {
            let status = 'failed';
            if (String(response.message).toLowerCase().includes('em processamento')) {
              status = 'info';
            } else if (String(response.message).toLowerCase().includes('aprovação manual')) {
              status = 'info';
            }

            this.statusTransaction(status, response.message, false);
            return;
          } else {
            this.statusTransaction('failed', 'CASH_OUT.TRANSFER_ON_US.MESSAGES.FAILED', true);
            return;
          }
        } else {
          this.statusTransaction('failed', 'CASH_OUT.TRANSFER_ON_US.MESSAGES.FAILED', true);
        }
      })
      .catch((err) => {
        this.transferService.getErrorTransfer(err);
        this.statusTransaction('failed', 'CASH_OUT.TRANSFER_ON_US.MESSAGES.FAILED', true);
      });
  }

  public async statusTransaction(status: string, message: string, translateFlag: boolean) {
    this.loadingService.hide();
    const translate = translateFlag ? true : false;
    const modalSuccess = await this.modalController.create({
      component: ModalOperationNotificationComponent,
      cssClass: 'modal-operation-notification',
      componentProps: {
        status,
        message,
        translate,
      },
      backdropDismiss: false,
    });
    modalSuccess.onDidDismiss().then((data) => {
      if (data['data'] === true) {
        this.navCtrl.navigateRoot('app/transfer/receipt');
      } else {
        this.navCtrl.navigateRoot('app');
      }
    });
    return await modalSuccess.present();
  }

  private loadBalance(uuidWL: string, uuidAccount: string): void {
    this.accountBalanceService
      .getBalance(uuidWL, uuidAccount)
      .then((res) => this.accountBalanceService.setBalanceSubject(res.balance))
      .catch((err) => this.accountBalanceService.getErroMessage(err));
  }

  formatAccountBank(digit) {
    if (digit === null) {
      return digit;
    }

    let account = '';
    const count = digit.length;
    const penultimate = count - 2;
    for (let i = 0; i < count; i++) {
      account += digit[i];
      if (i === penultimate) {
        account += '-';
      }
    }
    return account;
  }
}
