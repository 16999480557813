import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { environment } from 'src/environments/environment';

import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { ComercialAgentService, PreAccountResponse, UploadService } from '@wlp/ui-bs-signup';

@Component({
  selector: 'ui-t-comercial-agent',
  templateUrl: './comercial-agent.page.html',
  styleUrls: ['./comercial-agent.page.scss'],
})
export class ComercialAgentPage {
  brand: string;
  formComercialAgent: FormGroup;
  errorMessages: any;
  param: object;
  navigationBackground: string;
  constructor(
    private navCtrl: NavController,
    private comercialAgentService: ComercialAgentService,
    private uploadService: UploadService,
    private layoutConfigService: LayoutConfigService
  ) {
    this.errorMessages = this.comercialAgentService.getMessageError();
    this.formComercialAgent = this.comercialAgentService.getFormComercialAgent();
    this.comercialAgentService.updateForm();
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
    this.param = { wlName: this.layoutConfigService.getConfig('wlTheme.wlName') };
  }

  continue(): void {
    this.comercialAgentService.sendComercialAgentData(this.formComercialAgent).subscribe(
      (data) => this.navCtrl.navigateRoot('contract'),
      (error) => this.comercialAgentService.getErrorComercialAgentData(error)
    );
  }

  goBack() {
    this.uploadService
      .getAccountType()
      .then((res: PreAccountResponse) => this.redirectToFlow(res))
      .catch((err) => this.comercialAgentService.getErrorComercialAgentData(err));
  }

  private redirectToFlow(preAccountService: PreAccountResponse): void {
    if (
      preAccountService.accountType.typeFlow.toString() === 'PHHYSICAL_PERSON' ||
      preAccountService.accountType.typeFlow.toString() === 'PHYSICAL_PERSON'
    ) {
      this.navCtrl.navigateRoot('document-type');
      return;
    }

    if (preAccountService.accountType.typeFlow.toString() === 'LEGAL_PERSON' || preAccountService.accountType.typeFlow.toString() === 'MEI') {
      this.navCtrl.navigateRoot('company-documents');
      return;
    }

    // if (preAccountService.accountType.typeFlow.toString() === 'MEI') {
    //   this.navCtrl.navigateRoot('document-type');
    //   return;
    // }

    if (preAccountService.accountType.typeFlow.toString() === 'LIBERAL_PROFESSIONAL') {
      this.navCtrl.navigateRoot('documents-liberal');
      return;
    }
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }
}
