import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AccountBalanceService, NotificationsService } from '@wlp/ui-bs-cashin';
import { TransferService } from '@wlp/ui-bs-cashout';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { tap } from 'rxjs/operators';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'ui-t-transfer',
  templateUrl: './transfer.page.html',
  styleUrls: ['./transfer.page.scss'],
})
export class TransferPage implements OnInit {

  // Variáveis do Balance
  public iconName: string | 'eye-off-outline' | 'eye-outline';
  public showBLC = false;
  public balance: string;
  public balanceResponse: any;
  balanceSubject$: string;
  userProfile: UserInfoProfile;

  // Variáveis do bar-info
  public flagDisplayCard: boolean;
  public flagDisplayNotification: boolean;
  public messages: any[];
  description: string;
  transferType: string;

  // configs de url (para css class)
  public currentUrl: string;
  param: object;

  secundary: boolean

  constructor(
    private accountBalanceService: AccountBalanceService,
    private notificationsService: NotificationsService,
    private transferService: TransferService,
    protected userInfoService: UserInfoService,
    private navCtrl: NavController,
    public router: Router,
    public layoutConfigService: LayoutConfigService,
    private storage: Storage
  ) {
    this.iconName = 'eye-off-outline';
    this.balance = '';
    this.param = { wlName: this.layoutConfigService.getConfig('wlTheme.wlName') };

    // Carrega dados da barra de informação
    this.flagDisplayCard = false;
    this.flagDisplayNotification = false;

    this.router.events.subscribe(route => {
      if (route instanceof NavigationEnd) {
        this.currentUrl = route.url;
        if (this.currentUrl && this.currentUrl.length > 0) {
          const arr = this.currentUrl.slice(1).split('/');
          this.currentUrl = arr[arr.length - 1] + '-page';
        }
      }
    })
  }

  ngOnInit() {
    this.loadUser();
    this.accountBalanceService.getBalanceSubject().pipe(tap(data => {
      this.balanceSubject$ = String(data);
    })).subscribe();
  }

  // Carrega o tipo de transferência do componente filho
  ionViewWillEnter() {
    this.getTransferType();
    this.checkViewBalanceSecundaryUser();
  }

    async checkViewBalanceSecundaryUser(){
    await this.storage.get('permissions')
    .then(res => {
      if(res){
        const viewBalance = res.includes('viewBalanceAccess')
        if(res[0] === '*' || viewBalance == true){
          this.secundary = !true
        } else {
          this.secundary = !false
        }
        
        // this.secundary = res[0] === '*' ? false : true
      }
    })
  }

  goBackOnUs(){
    this.navCtrl.navigateRoot(['app/transfer/document'])
  }

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then(user => {
        this.userProfile = user;
        this.loadBalance(user.uuidWhiteLabel, user.uuidAccount);
        this.messages = this.notificationsService.getNotification(user.uuidAccount);
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  getTransferType() {
    this.transferType = '';
    this.transferService
      .getTransferFlow()
      .subscribe((res) => {
        this.transferType = res;
      });

    if (this.transferType === 'On-Us') {
      this.description = 'CASH_OUT.TRANSFER_ON_US.HEADER.SUBTITLE';
      return 'CASH_OUT.TRANSFER_ON_US.HEADER.TITLE';
    } else {
      this.description = 'CASH_OUT.TRANSFER_TED.HEADER.SUBTITLE';
      return 'CASH_OUT.TRANSFER_TED.HEADER.TITLE';
    }
  }

  private loadBalance(uuidWL: string, uuidAccount: string): void {
    this.balanceResponse = this.accountBalanceService
      .getBalance(uuidWL, uuidAccount)
      .then(res => this.accountBalanceService.setBalanceSubject(res.balance))
      .catch(err => this.accountBalanceService.getErroMessage(err));
  }

  public showBalance() {
    if (this.iconName === 'eye-off-outline') {
      this.iconName = 'eye-outline';
      this.showBLC = true;
    } else {
      this.iconName = 'eye-off-outline';
      this.showBLC = false;
    }
  }

  onFlagActive() {
    this.flagDisplayNotification = false;
    this.flagDisplayCard = (this.flagDisplayCard) ? false : true;
  }

  onFlagActiveNotification() {
    this.flagDisplayCard = false;
    this.flagDisplayNotification = (this.flagDisplayNotification) ? false : true;
  }
}
