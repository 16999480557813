import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
// import { PremiumInfosService } from '@wlp/ui-bs-nsc';
import { UICMenuAppService } from '@wlp/ui-c-menu-app';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

@Component({
  selector: 'card-manager-page',
  templateUrl: './card-manager.page.html',
  styleUrls: ['./card-manager.page.scss'],
})
export class CardManagerPage implements OnInit {
  public showValueFlag: boolean;
  public showValueIcon: string;
  public brand: string;

  public cards: Array<any>;

  public registerNscPlanPaymentForm: FormGroup;
  public errorMessagesRegister: any;

  constructor(
    private uicMenuApp: UICMenuAppService,
    private layoutConfigService: LayoutConfigService,
    private router: Router,
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    // this.registerNscPlanPaymentForm = this.premiumService.getFormPaymentInfo();
    // this.errorMessagesRegister = this.premiumService.getFormPlanPaymentInfoErrorMessage();

    this.brand = this.layoutConfigService.getConfig('wlTheme.header.brand.logo.secondary');
    this.cards = [
      { flag: 'visa', bankName: 'Banco', lastDigits: 4567 },
      { flag: 'visa', bankName: 'Banco', lastDigits: 4567 }
    ]
  }

  showValue(): void {
    this.showValueFlag = (this.showValueFlag) ? false : true;
    this.showValueIcon = (this.showValueFlag) ? this.showIcon('ocultarValor') : this.showIcon('verValor');
  }

  showIcon(icon: string, extension?: string): string {
    let iconUrl = `assets/images/nsc/wallet/${icon}.svg`;
    if (extension) {
      iconUrl = iconUrl.replace(/\.svg$/, `.${extension}`);
    }

    return iconUrl;
  }

  redirectUrl(link: string): void {
    if (link !== '') {
      this.router.navigate([link]);
    }
  }

  ionViewDidEnter() {
    this.uicMenuApp.applyStatus(false);
  }

  closeModal(): void {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
}
