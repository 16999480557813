import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DevolutionRoutingModule } from './devolution-routing.module';

import { DevolutionPage } from './devolution.page';
import { ComponentsModule } from 'src/app/core/shared/modules/components.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { UiBsTranslateModule } from '@wlp/ui-bs-translate';
import { UiCAccountBalanceModule } from '@wlp/ui-c-account-balance';
import { UiBsCashInModule } from '@wlp/ui-bs-cashin';
import { UiCHeaderPixModule } from '@wlp/ui-c-header-pix';
import { UiBsPixModule } from '@wlp/ui-bs-pix';
import { BrMaskerModule } from 'br-mask';
import { DevolutionResumePage } from './resume/devolution-resume.page';
import { UiCPixDevolutionTransferDataModule } from '@wlp/ui-c-pix-devolution-transfer-data';
import { UiCPixDevolutionFormModule } from '@wlp/ui-c-pix-devolution-form';
import { UiCAccountBalanceDevolutionModule } from '@wlp/ui-c-account-balance-devolution';
import { UiCButtonModule } from '@wlp/ui-c-button';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptService } from 'src/app/core/intercept/intercept.service';
import { UiCAccountDataCardPixModule } from '@wlp/ui-c-account-data-card-pix';

@NgModule({
  imports: [
    AngularSvgIconModule,
    ComponentsModule,
    CommonModule,
    FormsModule,
    IonicModule,
    DevolutionRoutingModule,
    UiCAccountBalanceModule,
    UiBsTranslateModule,
    UiBsCashInModule,
    UiBsPixModule,
    ReactiveFormsModule,
    BrMaskerModule,
    UiCPixDevolutionTransferDataModule,
    UiCHeaderPixModule,
    UiCButtonModule,
    UiCPixDevolutionFormModule,
    UiCAccountBalanceDevolutionModule,
    RouterModule,
    UiCAccountDataCardPixModule,
  ],
  declarations: [DevolutionPage, DevolutionResumePage],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
})
export class DevolutionModule {}
