import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'billetListDataFilter',
})
export class BilletsListDataFilterPipe implements PipeTransform {
  transform(data: any[], search) {
    if (!search) return data;

    return data.filter(info => {
      let text = info.description.toLowerCase();

      if (info.operationDescription !== null) {
        text = `${info.operationDescription.toLowerCase()} ${text}`;
      }

      return text.indexOf(search.toLowerCase()) > -1;
    });
  }

}
