import { DOCUMENT, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localept from '@angular/common/locales/pt';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { KeycloakAngularModule, KeycloakService } from '@wlp/ui-bs-login';
import { UiBsTranslateModule } from '@wlp/ui-bs-translate';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutConfig } from './core/config/layout.config';
import { LayoutConfigService } from './core/layout/services/layout-config.service';
import { ThemesModule } from './views/themes/themes.module';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { InterceptService } from './core/intercept/intercept.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { UiBsLoginModule } from '@wlp/ui-bs-login';

import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx'
import { WebIntent } from '@awesome-cordova-plugins/web-intent/ngx';

import { UiBsTransactionFileModule } from '@wlp/ui-bs-import-files'
import { FormsModule } from '@angular/forms';
import { BrMaskDirective, BrMaskerModule } from 'br-mask';

registerLocaleData(localept, 'pt');

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    ThemesModule,
    BrowserModule,
    BrMaskerModule,
    NoopAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    IonicStorageModule.forRoot({
      name: '__wlp_db',
      driverOrder: ['indexeddb', 'sqlite', 'websql'],
    }),
    IonicModule.forRoot(),
    UiBsTranslateModule,
    KeycloakAngularModule,
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true,
    }),
    UiBsLoginModule.forRoot({ device: 'WEB', config: environment }),
    UiBsTransactionFileModule
  ],
  providers: [
    BrMaskDirective,
    { provide: LOCALE_ID, useValue: 'pt' },
    StatusBar,
    SplashScreen,
    Geolocation,
    Device,
    Deeplinks,
    WebIntent,
    LayoutConfigService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [KeycloakService, DOCUMENT],
      multi: true,
    },
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function initializeLayoutConfig(appConfig: LayoutConfigService, document: Document) {
  // initialize app by loading default demo layout config
  return () => {
    // if (appConfig.getConfig() === null) {
    //   appConfig.loadConfigs(new LayoutConfig().configs);
    // }
    appConfig.loadConfigs(new LayoutConfig().configs);
  };
}

export function initializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      const { keycloakConfig } = environment;
      let redirectUrl = document.location.protocol + '//' + document.location.hostname;

      if (document.location.hostname.includes('localhost')) {
        redirectUrl += ':' + document.location.port;
      }

      try {
        await keycloak.init({
          config: keycloakConfig,
          initOptions: {
            onLoad: null,
            checkLoginIframe: false,
            // redirectUri: redirectUrl + '/app',
            silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
          },
          bearerExcludedUrls: [
            '/assets',
            '/clients/public',
            '/intro',
            '/account-type',
            '/choose-document',
            '/comercial-agent',
            '/company-address',
            '/company-data',
            '/company-documents',
            '/contract',
            '/create-account',
            '/document-type',
            '/documents-partner',
            '/partners',
            '/personal-address',
            '/spe-n-open-account/v1/personal-data',
            '/spe-view-pre-account/v1/personal-data',
            '/professional-type',
            '/upload-documents',
            '/privacy-policy',
            '/documents-liberal',
          ],
          enableBearerInterceptor: true,
        });
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };
}
