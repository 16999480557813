import { Pipe, PipeTransform } from '@angular/core';
import { TransferService } from '@wlp/ui-bs-cashout';

@Pipe({ name: 'bank' })
export class BankPipe implements PipeTransform {
  constructor(private transferService: TransferService) {}

  transform(value: string): string {
    if (!value) return '';

    const banks = this.transferService.getBankList();
    const filteredBank = banks.find((bank) => {
      return parseInt(bank.code) == parseInt(value);
    });

    if (filteredBank) {
      return `${filteredBank.name}`;
    } else {
      return value;
    }
  }
}
